<template>
  <div class="item-wrapper mt-5">
    <div
      :style="{backgroundColor: listColors[variant].bg}"
      class="item-icon"
    >
      <v-icon
        size="18px"
        color="white"
      >
        fa-user-o
      </v-icon>
    </div>
    <div
      v-if="item"
      class="card item-hovered-visible-block"
    >
      <div
        v-if="item"
        class="card-wrapper"
        style="width: 100%"
      >
        <div
          class="card-left"
          style="flex: auto"
        >
          <div class="card-header">
            <div class="card-header-info">
              <p class="card-title">
                <span>{{ item.attachEntity && item.attachEntity.label }}:</span>
              </p>
              <p class="card-time">
                {{ $Global.DateFormat.DateTimeShort(item.date) }}
              </p>
            </div>
          </div>
          <div class="card-notification-info">
            <div class="card-notification-text">
              <div class="tw-flex tw-items-center tw-gap-[6px] tw-flex-wrap">
                <ViewPanelLink
                  :id="entityData.oldUserId"
                  label="Сотрудник"
                  component="employee-show"
                >
                  <EmployeeName
                    :id="entityData.oldUserId"
                    is-avatar
                  />
                </ViewPanelLink>

                <svg
                  width="20"
                  height="10"
                  viewBox="0 0 17 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 0.113251L17 3L12 5.88675V4H0V2H12V0.113251Z"
                    fill="grey"
                  />
                </svg>
                <ViewPanelLink
                  :id="entityData.userId"
                  label="Сотрудник"
                  component="employee-show"
                >
                  <EmployeeName
                    :id="entityData.userId"
                    is-avatar
                  />
                </ViewPanelLink>
              </div>
            </div>
            <EmployeeLinkBlock
              :user-id="entityData.creatorId"
              title="Инициатор"
            />
          </div>
        </div>
        <div class="card-right">
          <ChronologyPinned
            v-if="!(variant === 'other' && item.pin)"
            :id="item.id"
            :pin-status="item.pin"
            @refresh="$emit('refresh')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listColors } from '@/components/core/Chronology/ListConfig'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import ChronologyPinned from '@/components/core/Chronology/ChronologyPinned'
import EmployeeLinkBlock from '@/components/other/employee/EmployeeLinkBlock'
export default {
  name: 'ChronologyChangeRespUserView',
  components: { EmployeeLinkBlock, ChronologyPinned, EmployeeName, ViewPanelLink },
  props: {
    item: {
      type: Object,
      required: true
    },
    variant: {
      type: String,
      required: true // other, plan, pin
    }
  },
  computed: {
    listColors () {
      return listColors
    },
    entityData () {
      return this.item?.attachEntityData || null
    },
    toStatus () {
      return this.entityData?.status?.name || null
    },
    fromStatus () {
      return this.entityData?.oldStatus?.name || null
    }
  }
}
</script>

<style scoped>

</style>
