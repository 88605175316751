import isEqual from 'lodash/isEqual'
import { RouteKeys } from '@/router/types'
import { plural } from '@/plugins/helpers/plural-ru-words'
import YandexMetrika from '@/plugins/helpers/yandex-merika'
import { DateFormat } from '@/plugins/helpers/date-time'
import { translite } from '@/plugins/helpers/ruTextTranslite'
import { DirectiveBinding } from 'vue/types/options'

interface ParamsData {
  [key: string]: string|number;
}
interface QueryData {
  [key: string]: string;
}

export default {
  install (Vue: any, options: any) {
    // Методы
    Vue.prototype.$isAdmin = false
    Vue.prototype.$Global = {
      YandexMetrika: new YandexMetrika(options.router),
      formatBytes: function (bytes = 0, decimals = 2) {
        if (bytes === 0) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
      },
      emailFirstChildToText: function (str: string): string {
        const parser = new DOMParser()
        const domStruct: any = parser.parseFromString(str, 'text/html')
        // console.log(domStruct?.body.firstChild?.innerText)
        return domStruct?.body?.firstChild?.innerText
      },
      peopleName: function (objInfo: any, format: 'fio' | 'fi' | 'io' | 'if'): any {
        if (!format) {
          console.error('$Global.peopleName BAD format: ', { format, objInfo })
          return '~'
        }
        if (!objInfo) {
          console.error('$Global.peopleName BAD objInfo: ', { format, objInfo })
          return '~'
        }
        const result: Array<string> = []
        const neededFields: any = {
          f: objInfo.surname,
          i: objInfo.name,
          o: objInfo.fatherName
        }
        for (const key of format) {
          if (neededFields[key]) {
            result.push(neededFields[key])
          }
        }
        return result.join(' ')
      },
      scrollToElem: function (elem: any): any {
        if (elem) {
          elem.scrollIntoView({ block: 'center', behavior: 'smooth' })
        } else {
          console.error('Element not found: ', { elem })
        }
      },
      nl2br: function (str: any): any {
        return (str || '').replace(/([^>])\n/g, '$1<br/>')
      },
      timeSecondsFormat: function (seconds: number | string): any {
        // привожу к цифре
        let time = seconds
        if (typeof time === 'string') {
          time = parseInt(time)
        }
        // Получаю часы минуты и секунды
        // знак ~~ заменяет Math.floor()
        const hrs = ~~(time / 3600)
        const mins = ~~((time % 3600) / 60)
        const secs = ~~time % 60

        let result = ''
        if (hrs > 0) {
          result += `${hrs} ч. `
        }
        result += mins ? `${mins} мин. ` : ''
        result += secs ? `${secs} сек.` : ''

        return result
      },
      randomColorsGenerate: function (oneColor: false): any {
        const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16)
        // console.warn('randomColor', randomColor)
        if (oneColor) {
          return randomColor
        } else {
          return {
            text: this.textColorFromBgColorHex(randomColor),
            bg: randomColor
          }
        }
      },
      generateColorsFromString (str: 'test', oneColor: false) {
        const strColor = this.stringToColor(str)
        if (oneColor) {
          return strColor
        } else {
          return {
            text: this.textColorFromBgColorHex(strColor),
            bg: strColor
          }
        }
      },
      stringToColor: function (str: any): any {
        if (typeof str !== 'string') {
          // eslint-disable-next-line no-param-reassign
          str = 'none'
        }
        let hash = 0
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 4) - hash)
        }
        let colour = '#'
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 10)) & 0xFF
          colour += ('00' + value.toString(16)).substr(-2)
        }
        return colour
      },
      textColorFromBgColorHex: function (hexcolor: string) {
        const color = hexcolor.replace('#', '')
        const r = parseInt(color.substr(0, 2), 16)
        const g = parseInt(color.substr(2, 2), 16)
        const b = parseInt(color.substr(4, 2), 16)
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
        return (yiq >= 128) ? 'black' : 'white'
      },
      hexToRGBA: function (hex: string, alpha = 1) :string {
        let r = 0
        let g = 0
        let b = 0
        let color: string = hex
        const a: number = alpha

        // Handling 3-digit hex
        if (color.length === 4 || color.length === 7) {
          // Remove '#' if present
          color = color.slice(1)
        }

        // Convert 3-digit hex to 6-digit hex
        if (color.length === 3) {
          color = color
            .split('')
            .map(char => char + char)
            .join('')
        }

        // Extract R, G, B values
        if (color.length === 6) {
          r = parseInt(color.slice(0, 2), 16)
          g = parseInt(color.slice(2, 4), 16)
          b = parseInt(color.slice(4, 6), 16)
        }

        return `rgba(${r}, ${g}, ${b}, ${a})`
      },
      calculateAge: function (bidthDateString: Date) {
        const bidthDate = new Date(bidthDateString)
        const diffMs = Date.now() - bidthDate.getTime()
        const ageDt = new Date(diffMs)
        return Math.abs(ageDt.getUTCFullYear() - 1970)
      },
      calculateExperience: function (dateString: any) {
        const df = new Date(dateString)
        const dt = new Date()
        // const allMonths = dt.getMonth() - df.getMonth() + (12 * (dt.getFullYear() - df.getFullYear()))
        let allYears = dt.getFullYear() - df.getFullYear()
        let partialMonths = dt.getMonth() - df.getMonth()
        if (partialMonths < 0) {
          allYears--
          partialMonths = partialMonths + 12
        }
        if (allYears === 0) {
          return `${partialMonths} ${this.textPlural(partialMonths, 'month')}`
        }
        return `${allYears} ${this.textPlural(allYears, 'year')} и ${partialMonths} ${this.textPlural(partialMonths, 'month')}`
      },
      RouterUrlManager: {
        set (params: ParamsData = {}) {
          // console.warn('objToQuery', options)
          const tmpParams: QueryData = {}
          // eslint-disable-next-line
          Object.keys(params).map((i: string) => {
            // eslint-disable-next-line
            const value: any = params[i];
            if (typeof value === 'string' && value !== '' && value !== 'undefined') {
              tmpParams[i] = value
            } else if (typeof value === 'boolean') {
              tmpParams[i] = value === true ? 'true' : 'false'
            } else if (typeof value === 'number') {
              tmpParams[i] = value.toString()
            } else if (typeof value === 'object' && value !== null) {
              const tmp: any = {}
              // eslint-disable-next-line
              value.forEach((val: any, k: string) => {
                tmp[k] = val
              })
              tmpParams[i] = tmp
            }
          })

          // console.warn('RouterUrlManager SET', tmpParams, options.router.currentRoute.query)
          if (!isEqual(options.router.currentRoute.query, tmpParams)) {
            options.router.replace({ query: tmpParams })
          }
        },
        get () {
          // console.warn('RouterUrlManager GET', options.router.currentRoute.query)
          return options.router.currentRoute.query || {}
        }
      },
      // типы ключей роутинга - нужны для наименования маршрутов по типу
      RouteKeys,
      // различные окончания в зависимости от количества едениц (1 год, 4 года, 5 лет,)
      textPlural: function (number:number, type:string) {
        return plural(number, type)
      },
      formatPhone: (value: string|number) => {
        return value?.toString().replace(/[^0-9]/g, '').replace(/^[8, 7]/, '+7').replace(/(\d{1,3})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 ($2) $3-$4-$5')
      },
      inputFormatPrice: {
        getNumber (value: string) {
          // eslint-disable-next-line no-irregular-whitespace
          // console.warn('inputFormatPrice - getNumber', (value && parseFloat(value.replace(/[a-zA-Zа-яА-Я]/g, '').replace(/,/g, '.').replace(/ /g, '').replace(/ | +$/g, ''))) || 0)
          // eslint-disable-next-line no-irregular-whitespace
          let adaptedValue: any = (value && parseFloat(value.replace(/[a-zA-Zа-яА-Я]/g, '').replace(/,/g, '.').replace(/ /g, '').replace(/ | +$/g, ''))) || value
          // console.log('adaptedValue', adaptedValue, 10e9, Number(10e9))
          if (adaptedValue < 0) {
            adaptedValue = 0
          } else if (adaptedValue > 10e8) {
            adaptedValue = 10e8
          }
          // console.warn('inputFormatPrice - getNumber', adaptedValue)
          return adaptedValue
        },
        getStringSpaced (value: any) {
          // console.warn('inputFormatPrice - getStringSpaced', value, (value || 0).toLocaleString('ru'))
          return typeof value === 'number' ? (value).toLocaleString('ru') : value
        }
      },
      // Метод адаптер объекта lat-lon в массив
      geoPointObjectToArray (pointData: any) {
        // console.warn('geoPointObjectToArray', pointData)
        let output: any = null
        if (pointData?.lat && pointData?.lon) {
          output = [pointData.lat, pointData.lon]
        }
        return output
      },
      // Метод адаптер массива точки на кате в объект lat-lon
      geoPointArrayToObject (pointData: any) {
        // console.warn('geoPointArrayToObject', pointData)
        let output: any = null
        if (Array.isArray(pointData) && pointData.length === 2) {
          const [lat, lon] = pointData
          output = { lat, lon }
        }
        return output
      },
      // Метод преобразования кирилицы в латиницу для генерации url code
      generateUrlCode (stringData: any) {
        // console.warn('generateUrlCode', stringData.split(''))
        let allowSting = ''
        // Оставляю только цифры и буквы
        for (const char of stringData.toLowerCase().split('')) {
          // console.warn(char)
          if (/[^A-Za-z0-9А-Яа-яЁё]/g.test(char)) {
            allowSting += '-'
          } else {
            allowSting += char
          }
        }
        // console.warn(allowSting)
        // символы твердого и мягкого знаков - замена на тире
        // eslint-disable-next-line
        return translite(allowSting, { 'ь': '-', 'ъ': '-', 'Ь': '-', 'Ъ': '-' })
      },
      ...{
        DateFormat
      }
    }
    // Фильтры
    Vue.filter('upper-case', (value: string) => {
      return value.toUpperCase()
    })
    Vue.filter('fixedPrice', (value: number) => {
      return value === null ? null : Number(value / 1000000).toFixed(1)
    })
    Vue.filter('formatPhone', (value: string|number) => {
      return value?.toString().replace(/[^0-9]/g, '').replace(/^[8, 7]/, '+7').replace(/(\d{1,3})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 ($2) $3-$4-$5')
    })
    Vue.filter('formatNumber', (value: any) => {
      const localValue = value ? Number(String(value).replace(',', '.')) : 0
      return localValue ? new Intl.NumberFormat('ru-RU', {}).format(localValue) : '0'
    })
    Vue.filter('formatMoney', (value: number) => {
      return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value || 0)
    })
    Vue.filter('textSubString', (value:string, to = 50) => {
      if (value && typeof value === 'string') {
        return (value.length > to) ? `${value.substring(0, to)}...` : value
      }
      return ''
    })
    Vue.filter('textPlural', (number:number, type:string) => {
      return plural(number, type)
    })
    // фильтр вывод первых букв из title
    Vue.filter('fullNameFirstLetters', function (str: string) {
      if (!str && typeof str !== 'string') {
        console.error('TYPE ERROR - Vue.filter - initialsFL')
        return ''
      }
      const Words = str.replaceAll('.', '').split(' ')
      const initials: any = []
      for (const Index in Words) {
        const word = Words[Index]
        const wordLength = word.length
        if (word) {
          let letter = word[0]
          for (let NumberWord = 0; NumberWord < wordLength; NumberWord++) {
            if (word[NumberWord].match(/[a-z0-9а-я]/i)) {
              letter = word[NumberWord]
              break
            }
          }
          const initial: any = letter.toUpperCase()
          initials.push(initial)
        }
      }
      const Length = initials.length
      let res: any = initials[0]
      if (Length > 1) {
        res += initials[Length - 1]
      }
      return res
    })

    // Директивы
    // Контроль пропускаемых значений при вводе через input: v-input-allow-regex="'[0-9.]'"
    Vue.directive('input-allow-regex', {
      bind: (el: HTMLElement, binding: DirectiveBinding) => {
        options.inputHandler = function (e: any) {
          const ch = String.fromCharCode(e.which)
          // console.warn(e, ch, binding.value)
          const re = new RegExp(binding.value)
          if (!ch.match(re)) {
            e.preventDefault()
          }
        }
        el.addEventListener('keypress', options.inputHandler)
      },
      unbind: (el: HTMLElement) => {
        el.removeEventListener('keypress', options.inputHandler)
      },
      inputHandler: null
    })
    Vue.directive('focus', {
      // Когда привязанный элемент вставлен в DOM...
      inserted: function (el: HTMLElement) {
        // Переключаем фокус на элемент
        el.focus()
      }
    })
  }
}
