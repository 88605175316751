<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    header-class="mb-2"
    :permission-denied="permissionDenied"
    relative
  >
    <TwPanelContent class="tw-flex-1">
      <TwPanelContentBlock
        class="tw-flex-1 tw-mb-[6px]"
        :content-classes="''"
      >
        <template #title>
          <div>
            Список проектов застройщика
          </div>
        </template>
        <template #action>
          <TwPanelContentBlockActionButton
            :disabled="loading"
            size="md"
            @click.prevent="addNewProject"
          >
            Добавить проект
          </TwPanelContentBlockActionButton>
        </template>
        <div>
          <TwTableBase
            :schema-key="filterSchemaKey"
            :loading-error-text="loadingErrorText"
            tbody-classes="tw-text-xs"
            :items="data"
            :headers="columns"
            :loading="loading"
            :fixed-header-margin="0"
            :table-columns-management="true"
            tbody-row-classes="tw-cursor-pointer"
            :pagination="pagination"
            @row-click="clickToItem"
            @sortUpdate="onSortUpdate"
            @changePagination="changePagination"
          >
            <template #item.gallery="{ item }">
              <div v-if="[...(Array.isArray(item.galleries) ? item.galleries:[]), ...(Array.isArray(item.planFiles) ? item.planFiles:[])].length">
                <div @click.stop="viewPlanFiles([...(Array.isArray(item.galleries) ? item.galleries:[]), ...(Array.isArray(item.planFiles) ? item.planFiles:[])])">
                  <img
                    v-if="[...(Array.isArray(item.galleries) ? item.galleries:[]), ...(Array.isArray(item.planFiles) ? item.planFiles:[])][0]"
                    :src="[...(Array.isArray(item.galleries) ? item.galleries:[]), ...(Array.isArray(item.planFiles) ? item.planFiles:[])][0].preview || [...(Array.isArray(item.galleries) ? item.galleries:[]), ...(Array.isArray(item.planFiles) ? item.planFiles:[])][0].url"
                    class="tw-border tw-border-solid tw-rounded-sm tw-cursor-zoom-in tw-object-contain tw-m-auto"
                    width="50"
                    height="50"
                    alt="Изображения"
                  >
                </div>
              </div>
            </template>
            <template #item.price="{ item }">
              <div v-if="item.price">
                {{ (item.price).toLocaleString('ru') }} ₽
              </div>
            </template>
            <template #item.planArea="{ item }">
              <div v-if="item.planArea">
                {{ item.planArea }} м²
              </div>
            </template>
            <template #item.description="{ item }">
              <div v-if="item.description">
                <ReadMoreExpander
                  :text="item.description"
                  :is-nl-trigger="false"
                  :less-length="150"
                />
              </div>
            </template>
            <template #item.date="{ item }">
              <div v-if="item.updatedAt">
                {{ item.updatedAt }}
              </div>
              <div v-else-if="item.createdAt">
                {{ item.createdAt }}
              </div>
            </template>
            <template #item.creatorId="{ item }">
              <div>
                <ViewPanelLink
                  v-if="item.creatorId && item.creatorId.value"
                  :id="item.creatorId.value"
                  label="Пользователь"
                  component="employee-show"
                >
                  {{ item.creatorId.label }}
                </ViewPanelLink>
              </div>
            </template>
          </TwTableBase>
        </div>
      </TwPanelContentBlock>
    </TwPanelContent>
  </PageViewDefaultWrapper>
</template>

<script>
import BuilderProjectAPI from '@/Api/ObjectStorage/Builder/project'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import { ServicesSchema } from '@/store/twfilter/servicesSchema'
import tableOptions from '@/views/Newbuildings/Editor/BuilderProjects/tableOptions'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import DataListMixin from '@/mixins/DataListMixin'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default {
  name: 'BuilderProjectsBlockList',
  components: {
    ViewPanelLink,
    ReadMoreExpander,
    TwTableBase,
    TwPanelContent,
    TwPanelContentBlock,
    PageViewDefaultWrapper,
    TwPanelContentBlockActionButton
  },
  mixins: [DataListMixin, ViewPanelShowMixin],
  props: {
    companyId: {
      type: [Number, String, null],
      default: null
    }
  },
  data () {
    return {
      API: BuilderProjectAPI,
      filterSchemaKey: ServicesSchema.BuilderProjectsList.key,
      columns: tableOptions,
      defaultFilters: {
        builder: this.companyId
      }
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    viewPlanFiles (files) {
      console.warn(files?.map(f => f.url))
      this.$store.dispatch('imageViewer/showImages', {
        files: files?.map(f => {
          return {
            path: f.url
          }
        }) || [],
        startIndex: 0
      })
    },
    clickToItem (eventData) {
      // console.log('clickToItem: ', eventData)
      this.showBuilderProjectCard({ id: eventData.id, builderId: this.companyId, presetTabEdit: false }, () => {
        this.refresh()
      })
    },
    addNewProject () {
      this.showBuilderProjectCard({ id: 0, builderId: this.companyId }, () => {
        this.refresh()
      })
    }
  }
}
</script>
