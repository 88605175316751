<template>
  <v-scroll-x-reverse-transition>
    <v-snackbar
      v-if="currentAlert && localVisible"
      :timeout="currentAlert.timeout"
      :value="currentAlert.visible"
      :elevation="4"
      :color="currentAlert.color || 'red accent-5'"
      app
      top
      right
      @input="input"
    >
      {{ currentAlert.text }}
      <template #action>
        <v-btn
          color="white"
          small
          icon
          @click="input(false)"
        >
          <v-icon>fa-times-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-scroll-x-reverse-transition>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'ErrorView',
  data: () => ({
    multiLine: true,
    localVisible: true // переменная для ожидания проигрывания анимации
  }),
  methods: {
    ...mapMutations({
      changeVisible: 'alerts/CHANGE_ALERT_VISIBLE'
    }),
    input () {
      this.changeVisible(this.currentAlertIndex)

      // скрываю и жду немного чтобы проигралась анимация скрытия
      this.localVisible = false
      setTimeout(() => {
        this.localVisible = true
      }, 300)
    }
  },
  computed: {
    ...mapGetters({
      currentAlert: 'alerts/getLastUnvisibleAlert',
      currentAlertIndex: 'alerts/getLastUnvisibleAlertIndex'
    })
  }
}
</script>
