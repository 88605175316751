<template>
  <PageViewDefaultWrapper
    header-class="mb-2"
    page-content-class=""
    :permission-denied="permissionDenied"
    relative
  >
    <template>
      <div
        v-if="lastLoad"
        class="tw-mb-[6px]"
      >
        <TwPanelInfoBlock
          v-if="!lastLoad.successDate"
          type="warning"
        >
          <template #title>
            Внимание!
          </template>
          <template #text>
            <div>Ещё не загружался</div>
          </template>
        </TwPanelInfoBlock>
        <TwPanelInfoBlock
          v-else-if="!lastLoad.lastNotLoaded"
          type="success"
        >
          <template #title>
            Всё хорошо!
          </template>
          <template #text>
            <div>
              Последняя загрузка: <b>{{ lastLoad.successDate }}</b>
            </div>
          </template>
        </TwPanelInfoBlock>
        <TwPanelInfoBlock
          v-else
          type="error"
        >
          <template #title>
            {{ lastLoad.lastNotLoadedMessage || 'Произошла ошибка' }}
          </template>
          <template #text>
            <div>
              Последняя загрузка: <b>{{ lastLoad.successDate }}</b>
            </div>
          </template>
        </TwPanelInfoBlock>
      </div>
      <div
        v-if="loading"
        class="tw-p-[10px] tw-flex"
      >
        <TwCircleLoader class="tw-m-auto" />
      </div>
      <div
        v-else-if="Array.isArray(data) && data.length"
        class="tw-flex tw-flex-col tw-gap-[1px] tw-mb-[12px]"
      >
        <div
          v-for="(item, index) in data"
          :key="index"
          class="tw-flex tw-items-center tw-bg-white tw-px-[6px] tw-py-[10px]"
        >
          <div>
            <ListMenu
              v-if="listItemActions.length && isAllowEditPermissions"
              offset-y
              :action-list="listItemActions"
              :target="item"
            />
          </div>
          <div class="tw-grow tw-px-[6px]">
            <span
              v-if="item.extComplex"
              title="Название ЖК"
            >
              <span class="tw-text-an-flat-disabled-text">ЖК:</span>
              {{ item.extComplex }}
            </span>
            <span
              v-if="item.extBuilding"
              title="Название Строения"
            >
              <span class="tw-text-an-flat-disabled-text">Строение:</span>
              {{ item.extBuilding }}
            </span>
            <div>
              <span class="tw-text-an-flat-disabled-text">Загрузка в:</span>
              <template v-if="Array.isArray(item.buildings)">
                <span
                  v-for="(building, buildingIndex) in item.buildings"
                  :id="building.value"
                  :key="building.value"
                  class="tw-cursor-pointer tw-text-an-flat-dark-blue-link hover:tw-text-an-flat-dark-blue-link-active hover:tw-underline"
                  @click.prevent="openBuilding(building.value)"
                >
                  Строение {{ building.label }}<span v-if="buildingIndex < item.buildings.length - 1">,</span>
                </span>
              </template>
            </div>
          </div>
          <div>
            <TwBooleanIcon
              size="18"
              class="tw-cursor-default"
              title="Статус и дата обновления"
              :value="!!item.inFile"
            >
              <template #right>
                {{ item.inFileDate }}
              </template>
            </TwBooleanIcon>
          </div>
        </div>
      </div>
      <div
        v-else
        class="tw-p-[10px] tw-text-an-flat-disabled-text tw-text-center"
      >
        Нет данных
      </div>
      <!--    Форма редактирования     -->
      <TwDialog
        :value="!!selectedEditId"
        max-width="600px"
        @close="selectedEditId = null"
      >
        <template #header>
          <div class="tw-text-h4">
            Изменить сопоставление
          </div>
        </template>
        <template #body>
          <div
            v-if="selectedEditId"
            class="tw-mt-[5px]"
          >
            <BuilderFileComparisionForm
              :id="selectedEditId"
              :builder-id="builderId"
              :default-form-fields="{}"
              @cancel="selectedEditId = null"
              @create="refresh"
              @update="refresh"
            />
          </div>
        </template>
      </TwDialog>
    </template>
    <template #footer>
      <div class="tw-flex tw-w-full">
        <TwPagination
          v-if="pageCount > 1"
          class="tw-m-auto"
          :disabled="loading"
          :value="pagination.current_page"
          :length="pagination.last_page"
          @change="changePagination"
        />
      </div>
    </template>
  </PageViewDefaultWrapper>
</template>

<script>
import { API } from '@/Api/BuilderImporter'
import { TypesRealtyObjects } from '@/views/Newbuildings/Editor/typesDict'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import { mapGetters } from 'vuex'
import ListMenu from '@/components/popup/menu/ListMenu.vue'
import BuilderFileComparisionForm from '@/views/BuilderImporter/ImportBuilderFile/comparision/BuilderFileComparisionForm.vue'
import dictPermissionRules from '@/Permissions/dictRules'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwBooleanIcon from '@/components/tw-ui/ui-kit/icons/TwBooleanIcon.vue'
import TwPagination from '@/components/tw-ui/ui-kit/table/TwPagination.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'

export default {
  name: 'ImportBuilderFileComparisonList',
  components: { TwDialog, TwPanelInfoBlock, BuilderFileComparisionForm, ListMenu, PageViewDefaultWrapper, TwCircleLoader, TwBooleanIcon, TwPagination },
  mixins: [ViewPanelShowMixin],
  props: {
    builderId: {
      type: [Number, String, null],
      default: null
    },
    importBuilderFileId: {
      type: [Number, String, null],
      default: null
    },
    isAllowEditPermissions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API.ImportBuilderFileComparison,
      defaultFilters: {
        fileId: this.importBuilderFileId
      },
      loading: false,
      permissionDenied: false,

      data: [],
      lastLoad: null,
      pagination: {},
      itemsPerPage: 25,

      selectedEditId: null
    }
  },
  methods: {
    openBuilding (buildingId) {
      this.showNbEditorObjectCard({
        id: buildingId,
        type: TypesRealtyObjects.building.type
      })
    },
    changePagination (targetPage) {
      this.fetch({
        page: targetPage
      })
    },
    fetch (params = {}) {
      this.selectedEditId = null
      if (this.loading) {
        return
      }

      if (!this.API) {
        throw Error('not set API')
      }

      this.permissionDenied = false
      this.loading = true
      // eslint-disable-next-line no-param-reassign
      params = {
        ...{ page: this.pagination.current_page },
        ...params,
        ...this.defaultFilters
      }

      this.lastLoad = null
      this.API.getList(params)
        .then((response) => {
          this.data = response?.data?.data
          this.lastLoad = response?.data?.lastLoad
          if (response.data.meta) {
            this.pagination = response.data.meta
            this.itemsPerPage = response.data.meta.per_page || 25
          }
        })
        .catch((error) => {
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    edit (eventData) {
      this.selectedEditId = eventData?.id || null
    },
    refresh () {
      this.fetch()
    },
    remove (eventData) {
      this.API.delete(eventData.id).then(() => {
        this.$store.commit('alerts/ADD_ALERT', {
          text: 'Успешно удалено!',
          timeout: 2500,
          color: 'success white--text',
          visible: true
        })
      }).catch(() => {
        this.$store.commit('alerts/ADD_ALERT', {
          text: 'Ошибка удаления!',
          timeout: 5000,
          color: 'error white--text',
          visible: true
        })
      }).finally(() => {
        this.refresh()
      })
    }
  },
  computed: {
    ...mapGetters({
      getFiltersByKey: 'filters/getFiltersByKey',
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    isAllowPermissions () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsBuilderFileManagementBuilderFileRule]
    },
    pageCount () {
      return Math.ceil(this.pagination.total / this.itemsPerPage)
    },
    // Формирую список действий для элементов списка
    listItemActions () {
      return [
        {
          code: 'edit',
          label: 'Изменить',
          color: 'primary',
          disabled: false,
          method: this.edit
        },
        ...(this.isAllowPermissions) && [
          {
            code: 'remove',
            isConfirmation: true,
            label: 'Удалить',
            color: 'red',
            disabled: false,
            method: this.remove
          }
        ]
      ]
    }
  },
  mounted () {
    this.refresh()
  }
}
</script>
