<template>
  <div>
    <div
      v-if="loading"
      class="tw-flex tw-justify-center tw-w-full tw-p-3"
    >
      <TwCircleLoader />
    </div>
    <v-expansion-panels
      v-else-if="Array.isArray(rulesForView) && rulesForView.length"
      class="ma-2"
      :disabled="loading"
    >
      <v-expansion-panel
        v-for="(group, gIndex) in rulesForView"
        :key="gIndex"
      >
        <v-expansion-panel-header class="font-weight-bold">
          <div>
            {{ group.name }}
            <v-chip
              v-if="group.changedRulesCount"
              title="персонально назначенные правила"
              class="mr-1"
              small
              color="primary"
              label
              text-color="white"
            >
              Персональные: {{ group.changedRulesCount }}
            </v-chip>
            <v-chip
              v-if="group.ruleValueInheritedCount"
              title="право наследуемое из структуры"
              class="mr-1"
              small
              color="secondary"
              label
              text-color="white"
            >
              Структурное: {{ group.ruleValueInheritedCount }}
            </v-chip>
            <v-chip
              v-if="group.ruleValueInheritedDepartmentCount"
              title="право наследуемое из отделов"
              small
              color="secondary"
              label
              text-color="white"
            >
              Отдела: {{ group.ruleValueInheritedDepartmentCount }}
            </v-chip>
          </div>
          <v-spacer />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-for="(rule, rIndex) in group.rules"
            :key="rIndex"
            class="mb-3"
            :data-id="rule.id"
            :data-namespace="rule.namespace"
          >
            <div class="font-weight-medium mb-1 tw-flex tw-justify-between tw-items-center">
              <div>
                {{ rule.name }}:
              </div>
              <div>
                <ClickBoard
                  :title-active="`Скопировать namespace правила: ${rule.namespace}`"
                  :disabled="!rule.namespace"
                  icon-size="15"
                  :value="rule.namespace"
                />
              </div>
            </div>
            <div class="tw-flex">
              <div
                v-if="rule.ruleValueInherited"
                title="Наследуемое право"
                class="mr-3 mb-3 text--disabled rounded py-1 px-2"
                style="border: 1px solid lightgray; cursor: help;"
              >
                Структурное: {{ getRuleInheritedLabel(rule) }}
              </div>
              <div
                v-if="rule.ruleValueInheritedDepartment"
                title="Наследуемое право"
                class="mb-3 text--disabled rounded py-1 px-2"
                style="border: 1px solid lightgray; cursor: help;"
              >
                Отдела: {{ getRuleInheritedDepartmentLabel(rule) }}
              </div>
            </div>
            <v-select
              hide-details
              dense
              outlined
              :disabled="loading"
              attach=""
              :background-color="formRules[rule.id] ? 'blue lighten-5' : ''"
              :items="rule.values"
              :value="formRules[rule.id]"
              clearable
              item-text="label"
              item-value="value"
              title="Назначенное право"
              placeholder="Не установлено"
              @input="onChangeRule($event, rule.id)"
            />
            <div
              v-if="rule.name !== rule.description"
              class="ml-3"
            >
              <small>*{{ rule.description }}</small>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div
      v-else
      class="text-center"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import ClickBoard from '@/components/button/ClickBoard.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'StructureFormInput',
  components: { TwCircleLoader, ClickBoard },
  props: {
    formRules: {
      type: Object,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rulesForView () {
      // Права для отрисовки
      return (this.groups || []).map((group) => {
        group.changedRulesCount = group?.rules?.filter(rule => !!rule.ruleValue)?.length || 0
        group.ruleValueInheritedCount = group?.rules?.filter(rule => !!rule.ruleValueInherited)?.length || 0
        group.ruleValueInheritedDepartmentCount = group?.rules?.filter(rule => !!rule.ruleValueInheritedDepartment)?.length || 0
        return group
      })
    }
  },
  methods: {
    onChangeRule (eventValue, ruleId) {
      console.warn('StructureFormInput', eventValue, ruleId)
      this.$emit('change-rule', eventValue, ruleId)
    },
    getRuleInheritedLabel (rule) {
      // console.warn('getRuleInheritedLabel', rule)
      return (rule?.values || []).find(item => item.value === rule.ruleValueInherited)?.label || 'Некорректное значение'
    },
    getRuleInheritedDepartmentLabel (rule) {
      // console.warn('getRuleInheritedDepartmentLabel', rule)
      return (rule?.values || []).find(item => item.value === rule.ruleValueInheritedDepartment)?.label || 'Некорректное значение'
    }
  }
}
</script>

<style scoped>

</style>
