<template>
  <div>
    <TwPanelContentBlock :content-classes="''">
      <template #title>
        <div>
          Информация по одобрению
        </div>
      </template>
      <template #action>
        <TwPanelContentBlockActionButton
          :disabled="disabled"
          :type="approveInfo ? 'edit':'add'"
          @click="onCreateItem"
        >
          {{ approveInfo ? 'изменить' : 'добавить' }}
        </TwPanelContentBlockActionButton>
      </template>
      <div>
        <MortgageApproveList
          ref="list"
          :disabled="disabled"
          :deal-id="dealId"
          :deal-type="dealType"
          @on-edit-item="onEditItem"
          @refresh="refresh"
        />
      </div>
    </TwPanelContentBlock>

    <v-dialog
      v-model="editDialog"
      persistent
      width="600"
      @close="targetEditId = 0"
    >
      <v-card>
        <v-card-title class="primary white--text d-flex justify-space-between pb-4">
          <div>{{ targetEditId ? 'Изменить одобрение' : 'Добавить одобрение' }}</div>
          <v-btn icon>
            <v-icon
              color="white"
              dark
              @click="editDialog = false"
            >
              fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <div class="pa-3">
          <MortgageApproveForm
            v-if="editDialog"
            :id="targetEditId || 0"
            :deal-id="dealId"
            @create="refreshList"
            @update="refreshList"
            @cancel="editDialog = false"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MortgageApproveForm from '@/views/Deal/TypeViews/mortgage/approve/MortgageApproveForm'
import MortgageApproveList from '@/views/Deal/TypeViews/mortgage/approve/MortgageApproveList'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'MortgageApproveBlock',
  components: { TwPanelContentBlockActionButton, TwPanelContentBlock, MortgageApproveList, MortgageApproveForm },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dealId: {
      type: [String, Number],
      required: true
    },
    dealType: {
      type: [String, Object],
      required: true
    },
    approveInfo: {
      type: [Object, null],
      default: null
    }
  },
  data () {
    return {
      editDialog: false,
      targetEditId: 0
    }
  },
  methods: {
    refreshList () {
      this.editDialog = false
      this.$refs.list.fetch()
    },
    refresh () {
      this.editDialog = false
      this.$emit('refresh')
    },
    onEditItem (id) {
      this.targetEditId = id || 0
      this.$nextTick(() => {
        this.editDialog = true
      })
    },
    onCreateItem () {
      this.targetEditId = 0
      this.$nextTick(() => {
        this.editDialog = true
      })
    }
  }
}
</script>
