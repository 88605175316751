import { RouteKeys } from '@/router/types'

import ComplexPlatformId from './complex-platform-id'
import PlatformAccount from './platform-account'
import PlatformPackage from './platform-package'
import DistributionText from './distribution-text'
import LandingAdmin from './landing-admin'

export default {
  path: RouteKeys.adsObjects,
  name: RouteKeys.adsObjects,
  meta: { name: 'Реклама', icon: 'ads' },
  redirect: { name: 'ads-complex' },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    ComplexPlatformId,
    PlatformAccount,
    PlatformPackage,
    DistributionText,
    LandingAdmin
  ]
}
