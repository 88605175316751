<template>
  <div>
    <v-row
      no-gutters
      dense
      class="flex-nowrap"
      justify="space-between"
      align="center"
    >
      <div class="text-body-2 text-muted">
        Подразделения / Должности
      </div>
      <v-btn
        outlined
        color="success"
        small
        @click="addNewElem"
      >
        Добавить
      </v-btn>
    </v-row>
    <template v-if="value.length">
      <v-card
        v-for="(item, index) in value"
        :key="index+'-row-'+item.id"
        class="my-3"
        outlined
      >
        <v-card-text>
          <v-row
            no-gutters
            dense
            class="flex-nowrap"
            justify="space-between"
            align="center"
          >
            <v-col cols="6">
              <!--              <StructureMultiAsyncSelect class="mx-2" label="Структура" v-model="item.structureId"/>-->
              <div class="ma-2">
                <label class="text-muted">Структура:</label>
                <AStructureTreeSimpleSelect
                  ref="structureId"
                  v-model="item.structureId"
                />
              </div>
            </v-col>
            <v-col cols="5">
              <!--              <PositionMultiAsyncSelect class="mx-2" label="Должность" v-model="item.positionId"/>-->
              <div class="ma-2">
                <label class="text-muted">Должность:</label>
                <APositionSimpleSelect
                  ref="positionId"
                  v-model="item.positionId"
                  :z-index="10"
                  no-select-text="Должность не выбрана"
                />
              </div>
            </v-col>
            <v-col
              cols="1"
              class="text-center"
            >
              <v-btn
                icon
                small
                @click.stop="removeElem(index)"
              >
                <v-icon color="red">
                  fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template v-else>
      <v-row
        dense
        justify="center"
        align="center"
      >
        <v-col
          cols="12"
          class="mb-3 text-center text-muted"
        >
          Не добавлены
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import AStructureTreeSimpleSelect from '@/components/filters/afilter/fields/apopover/select/AStructureTreeSimpleSelect'
import APositionSimpleSelect from '@/components/filters/afilter/fields/apopover/select/APositionSimpleSelect'
export default {
  name: 'InputUserStructures',
  components: { APositionSimpleSelect, AStructureTreeSimpleSelect },
  props: {
    value: {
      default: () => []
    },
    rules: {
      default: () => []
    },
    errorMessages: {
      default: () => []
    }
  },
  data () {
    return {}
  },
  methods: {
    addNewElem () {
      let value = this.value
      if (!Array.isArray(value)) {
        value = []
      }
      value.push(
        {
          id: null,
          // userId: null,
          structureId: null,
          positionId: null
        }
      )
      this.onChange(value)
    },
    removeElem (index) {
      const value = this.value
      console.log('removeElem', index, value)
      value.splice(index, 1)
      this.onChange(value)
    },
    onChange (value) {
      this.$emit('input', value)
    }
  }
}
</script>
