<template>
  <div class="tw-font-manrope">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <div class="tw-ml-[5px] tw-text-an-flat-blue-btn-active tw-text-main-sm2 tw-mb-[5px] tw-w-full tw-flex tw-justify-between">
          <div>
            Адрес объекта:
          </div>
          <div
            v-if="!inputHouseNum && !$v.form.objectAddress.$invalid"
            class="tw-truncate tw-flex-1 tw-text-an-flat-orange-warning tw-text-right"
          >
            Внимание проверьте адрес: номер дома и квартиры
          </div>
        </div>
        <DaDataAddressInput
          v-model="form.objectAddress"
          :class="$v.form.objectAddress.$invalid ? 'tw-border tw-border-solid tw-border-an-flat-red-main':''"
          placeholder="Ввод адреса"
          @input-object="updateDaDataAddressObj"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwSelect
          v-model="form.programCategoryId"
          label="Программа ипотеки"
          placeholder="Выбрать"
          :return-object="true"
          :multi="false"
          :dense="true"
          :cleanable="true"
          :persistent-label="true"
          :error-text="$v.form.programCategoryId.$invalid ? 'Обязательное поле' : ''"
          :options="dictionary.programCategory || []"
          @input="$v.form.programCategoryId.$touch()"
        />
      </TwFlexCell>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            type="text"
            placeholder="Сумма кредита"
            max-length="13"
            sub-type="int"
            :value="$Global.inputFormatPrice.getStringSpaced(form.creditSum)"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
            :error-text="creditSumErrors"
            @input="onInputCreditSum"
          >
            <template #append>
              руб.
            </template>
          </TwInputText>
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.dateExtradition"
            type="date"
            class="tw-flex-1"
            placeholder="Дата выдачи"
            :max="currentDate"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
            :error-text="dateExtraditionErrors"
            @input="$v.form.dateExtradition.$touch()"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.percentAtYear"
            type="text"
            max-length="5"
            sub-type="float"
            placeholder="Процентов в год"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
            :error-text="percentAtYearErrors"
            @input="$v.form.percentAtYear.$touch()"
          >
            <template #append>
              %
            </template>
          </TwInputText>
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model.number="form.years"
            type="text"
            max-length="2"
            sub-type="int"
            placeholder="Срок кредита"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
            :error-text="yearsErrors"
            @input="$v.form.years.$touch()"
          >
            <template #append>
              лет
            </template>
          </TwInputText>
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexCell>
        <TwSelectRelations
          v-model="form.bankId"
          :multi="false"
          :dense="true"
          :persistent-label="true"
          label="Банк"
          placeholder="Выберите банк"
          list-label="Найти банк"
          relation-name="bank"
          :disabled="formProcessing"
          :error-text="$v.form.bankId.$invalid ? 'Обязательное поле' : ''"
          @input="changeBank"
        />
      </TwFlexCell>
      <template v-if="form.bankId">
        <TwFlexCell>
          <strong>Вознаграждение Банк:</strong>
          <ViewPanelLink
            :id="form.bankId"
            label="Компания"
            component="company-show"
          >
            <TwCompanyName :id="form.bankId" />
          </ViewPanelLink>
        </TwFlexCell>
        <TwFlexRow>
          <TwFlexCell>
            <TwInputText
              ref="rewardBankPercent"
              v-model="form.rewardBankPercent"
              type="text"
              max-length="5"
              sub-type="float"
              placeholder="Процент"
              :disabled="formProcessing"
              :show-label="true"
              :dense="true"
              :persistent-label="true"
              :error-text="rewardBankPercentErrors"
            >
              <template #append>
                %
              </template>
            </TwInputText>
          </TwFlexCell>
          <TwFlexCell>
            <TwInputText
              type="text"
              placeholder="Сумма"
              max-length="13"
              :value="$Global.inputFormatPrice.getStringSpaced(rewardBankSumCalc)"
              :disabled="true"
              :show-label="true"
              :dense="true"
              :persistent-label="true"
            >
              <template #append>
                руб.
              </template>
            </TwInputText>
          </TwFlexCell>
        </TwFlexRow>
      </template>
      <TwFlexCell>
        <TwSelectRelations
          v-model="form.insuranceCompanyId"
          :multi="false"
          :dense="true"
          :persistent-label="true"
          placeholder="Выберите страховую компанию"
          label="Страховая компания"
          list-label="Найти страховую компанию"
          relation-name="insurance"
          :error-text="$v.form.insuranceCompanyId.$invalid ? 'Обязательное поле' : ''"
          :disabled="formProcessing"
          @input="changeInsurance"
        />
      </TwFlexCell>
      <template v-if="form.insuranceCompanyId">
        <TwFlexCell>
          <strong>Вознаграждение Страх. компании:</strong>
          <ViewPanelLink
            :id="form.insuranceCompanyId"
            label="Компания"
            component="company-show"
          >
            <TwCompanyName :id="form.insuranceCompanyId" />
          </ViewPanelLink>
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            type="text"
            placeholder="Сумма страховки"
            max-length="13"
            :value="$Global.inputFormatPrice.getStringSpaced(form.insuranceSum)"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
            :error-text="$v.form.insuranceSum.$invalid ? 'Обязательное поле' : ''"
            @input="form.insuranceSum = $Global.inputFormatPrice.getNumber($event)"
          >
            <template #append>
              руб.
            </template>
          </TwInputText>
        </TwFlexCell>
        <TwFlexRow>
          <TwFlexCell>
            <TwInputText
              ref="insuranceKvPercent"
              v-model="form.insuranceKvPercent"
              type="text"
              max-length="5"
              sub-type="float"
              placeholder="Процент КВ"
              :disabled="formProcessing"
              :show-label="true"
              :dense="true"
              :persistent-label="true"
              :error-text="insuranceKvPercentErrors"
            >
              <template #append>
                %
              </template>
            </TwInputText>
          </TwFlexCell>
          <TwFlexCell>
            <TwInputText
              type="text"
              placeholder="Сумма КВ"
              max-length="13"
              :value="$Global.inputFormatPrice.getStringSpaced(insuranceKvSum)"
              :disabled="true"
              :show-label="true"
              :dense="true"
              :persistent-label="true"
            >
              <template #append>
                руб.
              </template>
            </TwInputText>
          </TwFlexCell>
        </TwFlexRow>
        <TwFlexRow>
          <TwFlexCell>
            <TwCheckBox
              v-model="form.insuranceLife"
              :disabled="formProcessing"
              background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              label="Страхование жизни"
            />
          </TwFlexCell>
          <TwFlexCell>
            <TwCheckBox
              v-model="form.insuranceProperty"
              :disabled="formProcessing"
              background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              label="Страхование имущества"
            />
          </TwFlexCell>
          <TwFlexCell>
            <TwCheckBox
              v-model="form.insuranceTitle"
              :disabled="formProcessing"
              background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              label="Страхование титула"
            />
          </TwFlexCell>
        </TwFlexRow>
        <TwFlexCell
          v-if="$v.insuranceCheckboxes"
          class="tw-text-an-flat-red-main"
        >
          {{ $v.insuranceCheckboxes.$invalid ? '* Выберите минимум один тип страхования' : '' }}
        </TwFlexCell>
      </template>
    </TwFlexCol>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="true"
      :is-sticky="true"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/Mortgage/Result/index'
import APICompany from '@/Api/Crm/Company'
import { mapGetters } from 'vuex'
import { required, maxValue } from 'vuelidate/lib/validators'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import DaDataAddressInput from '@/components/input/daData/DaDataAddressInput.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwCompanyName from '@/components/tw-ui/other/TwCompanyName.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'MortgageResultForm',
  components: {
    TwCheckBox,
    TwCompanyName,
    ViewPanelLink,
    TwFlexCell,
    TwFlexRow,
    TwFlexCol,
    TwCardSimpleFormSaveBlock,
    TwInputText,
    TwSelectRelations,
    TwSelect,
    DaDataAddressInput
  },
  mixins: [FormCreateEditMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentDate: new Date().toISOString().substr(0, 10),
      API: API,
      APICompany: APICompany,
      form: {
        creditSum: 0,
        insuranceSum: ''
      },
      inputHouseNum: null
    }
  },
  validations () {
    return {
      form: {
        creditSum: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
          }
        },
        percentAtYear: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
          }
        },
        years: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
          }
        },
        programCategoryId: {
          required
        },
        objectAddress: {
          required
        },
        dateExtradition: {
          required
        },
        bankId: {
          required
        },
        rewardBankPercent: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && typeof parseFloat(v) === 'number')
          },
          maxValue: maxValue(50)
        },
        insuranceCompanyId: {
          required
        },
        insuranceSum: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && typeof parseFloat(v) === 'number')
          }
        },
        insuranceKvPercent: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && typeof parseFloat(v) === 'number')
          },
          maxValue: maxValue(50)
        }
      },
      insuranceCheckboxes: {
        required: function () {
          return !!(this.form.insuranceLife || this.form.insuranceTitle || this.form.insuranceProperty)
        }
      }
    }
  },
  methods: {
    onInputCreditSum (eventData) {
      this.form.creditSum = this.$Global.inputFormatPrice.getNumber(eventData)
      this.$v.form.creditSum.$touch()
    },
    changeBank (eventData) {
      if (!eventData) {
        this.form.rewardBankPercent = ''
        this.form.rewardBank = 0
      }
    },
    changeInsurance (eventData) {
      if (!eventData) {
        this.form.insuranceSum = ''
        this.form.insuranceKvPercent = ''
      }
    },
    updateDaDataAddressObj (eventData) {
      this.inputHouseNum = null
      console.log(eventData.value, eventData.data.house)
      this.inputHouseNum = eventData?.data?.house || null
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            bank: 'bankId',
            insuranceCompany: 'insuranceCompanyId',
            programCategory: 'programCategoryId',
            deal: 'dealId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              } else if (key === 'programCategory' && this.preset[key]) {
                this.$set(this.form, 'programCategoryId', this.preset[key].value || null)
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
          // Если дата не была заполнена с пресета, заполняю текущей
          // [WARN] Нельзя оставить пустым - ибо сафари заполняет своими значениями которые не попадают в форму
          if (!this.form?.dateExtradition) {
            this.form.dateExtradition = this.currentDate
          }
          // TODO DEL Костыль - пока не удалят на беке
          this.form.insuranceInBank = false
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      console.warn(this.$options.name, ' handleSubmit ', this.$v.form, this.$v.$invalid)
      if (!this.$v.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        this.form.rewardBank = this.rewardBankSumCalc
        if (this.formType === 'create') {
          this.API.store(this.dealId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    },
    getCompanyReward (companyId, typeCompany = '') {
      // Тип компании может быть либо 'bank', либо 'insurance'
      if (typeCompany) {
        this.formProcessing = true
        this.APICompany.show(companyId)
          .then(({ data }) => {
            if (data?.data?.reward) {
              if (typeCompany === 'bank') { this.form.rewardBankPercent = String(data.data.reward) }
              if (typeCompany === 'insurance') { this.form.insuranceKvPercent = String(data.data.reward) }
            }
          })
          .catch((err) => {
            console.error('ERROR getCompanyReward: ', err)
          })
          .finally(() => {
            this.formProcessing = false
          })
      }
    }
  },
  computed: {
    ...mapGetters({
      getBankById: 'tmpCache/getCompanyById'
    }),
    bank () {
      return this.form.bankId ? this.getBankById(this.form.bankId) : null
    },
    rewardBankSumCalc () {
      return this.form.creditSum * (this.form.rewardBankPercent / 100)
    },
    insuranceKvSum () {
      return this.form.insuranceSum * (this.form.insuranceKvPercent / 100)
    },
    creditSumErrors () {
      const errors = []
      this.$v?.form?.$touch()
      if (!this.$v.form.creditSum.$dirty) return errors
      if (!this.$v.form.creditSum.required) {
        errors.push('Поле обязательное')
      } else if (this.form.creditSum) {
        const creditSum = parseFloat(this.form.creditSum)
        if (creditSum < 100000) {
          errors.push('Минимум 100 т.р.')
        } else if (creditSum > 100000000) {
          errors.push('Максимум 100 млн.р.')
        }
      }

      if (errors.length > 0) {
        if (this.$refs?.creditSum?.$el) this.$Global.scrollToElem(this.$refs.creditSum.$el)
      }

      return errors
    },
    percentAtYearErrors () {
      const errors = []
      this.$v?.form?.$touch()
      if (!this.$v.form.percentAtYear.$dirty) return errors
      if (!this.$v.form.percentAtYear.required) {
        errors.push('Поле обязательное')
      } else if (this.form.percentAtYear && this.form.percentAtYear > 50) {
        errors.push('Максимум 50%')
      }

      if (errors.length > 0) {
        if (this.$refs?.percentAtYear?.$el) this.$Global.scrollToElem(this.$refs.percentAtYear.$el)
      }

      return errors
    },
    yearsErrors () {
      const errors = []
      this.$v?.form?.$touch()
      if (!this.$v.form.years.$dirty) return errors
      if (!this.$v.form.years.required) {
        errors.push('Поле обязательное')
      } else if (this.form.years && this.form.years > 50) {
        errors.push('Максимум 50 лет')
      }

      if (errors.length > 0) {
        if (this.$refs?.percentAtYear?.$el) this.$Global.scrollToElem(this.$refs.percentAtYear.$el)
      }
      return errors
    },
    rewardBankPercentErrors () {
      const errors = []
      this.$v?.form?.$touch()
      if (!this.$v.form.rewardBankPercent.$dirty) return errors
      if (!this.$v.form.rewardBankPercent.required) {
        errors.push('Поле обязательное')
      } else if (!this.$v.form.rewardBankPercent.maxValue) {
        errors.push('Максимум 50%')
      }

      if (errors.length > 0) {
        if (this.$refs?.rewardBankPercent?.$el) this.$Global.scrollToElem(this.$refs.rewardBankPercent.$el)
      }
      return errors
    },
    insuranceKvPercentErrors () {
      const errors = []
      this.$v?.form?.$touch()
      if (!this.$v.form.insuranceKvPercent.$dirty) return errors
      if (!this.$v.form.insuranceKvPercent.required) {
        errors.push('Поле обязательное')
      } else if (!this.$v.form.insuranceKvPercent.maxValue) {
        errors.push('Максимум 50%')
      }

      if (errors.length > 0) {
        if (this.$refs?.insuranceKvPercent?.$el) this.$Global.scrollToElem(this.$refs.insuranceKvPercent.$el)
      }
      return errors
    },
    dateExtraditionErrors () {
      const errors = []
      this.$v?.form?.$touch()
      if (!this.$v.form.dateExtradition.$dirty) return errors
      if (!this.$v.form.dateExtradition.required) {
        errors.push('Поле обязательное')
        if (this.$refs?.dateExtradition?.$el) this.$Global.scrollToElem(this.$refs.dateExtradition.$el)
      }
      return errors
    }
  },
  watch: {
    'form.bankId': function (newValue) {
      if (!this.form.rewardBankPercent && newValue) { this.getCompanyReward(newValue, 'bank') }
    },
    'form.insuranceCompanyId': function (newValue) {
      if (!this.form.insuranceKvPercent && newValue) { this.getCompanyReward(newValue, 'insurance') }
    }
  }
}
</script>
