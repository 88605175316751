<template>
  <div class="tw-flex tw-flex-col">
    <span
      class="tw-block"
      v-html="viewText.less"
    /> <span
      :class="show ? 'tw-block' : 'tw-hidden'"
      v-html="viewText.more"
    />
    <br v-if="isNlTrigger && viewText.more">
    <button
      v-if="viewText.more"
      class="tw-transition-all tw-mt-[4px] tw-group tw-text-main-xs2 hover:tw-text-an-flat-dark-blue-link"
      :class="[align === 'left' ? 'tw-mr-auto':align === 'right' ? 'tw-ml-auto' : '']"
      @click.stop="clickShow"
    >
      {{ show ? 'свернуть':'развернуть' }}
      <span
        class="tw-inline-block tw-transition-all tw-px-[2px] tw-pt-[2px]"
        :class="{'tw-rotate-180 tw-pb-[5px]': show}"
      >
        <svg
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="tw-transition-all group-hover:tw-stroke-an-flat-dark-blue-link"
            d="M1 1.5L4 4L7 1.5"
            stroke="#9FA6B4"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ReadMoreExpander',
  props: {
    text: {
      type: String,
      default: ''
    },
    lessLength: {
      type: Number,
      default: 50
    },
    isNlTrigger: {
      type: Boolean,
      default: false
    },
    isCleanHtml: {
      type: Boolean,
      default: true
    },
    align: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    cleanHtml () {
      if (this.text) {
        if (this.isCleanHtml) {
          const tmp = document.createElement('div')
          tmp.innerHTML = this.text
          return this.$Global.nl2br(tmp.textContent || tmp.innerText || '')
        }
        return this.$Global.nl2br(this.text)
      }
      return ''
    },
    viewText () {
      let less = this.cleanHtml.slice(0, this.lessLength)
      const more = this.cleanHtml.slice(this.lessLength)
      if (more && !this.show) {
        less = less + ' ...'
      }
      return {
        less,
        more
      }
    }
  },
  methods: {
    clickShow () {
      this.show = !this.show
      this.$emit('input', this.show)
    }
  }
}
</script>
