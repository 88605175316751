<template>
  <div>
    <div
      :class="[process ? 'text-muted' : 'phone-link']"
      :title="!process && 'Позвонить по номеру телефона'"
      @click.stop="start"
    >
      {{ phone | formatPhone }}
    </div>
    <div class="tw-text-right">
      <small
        v-if="process"
        :class="[message === statuses.error ? 'error--text':'primary--text']"
      >{{ message }}</small>
    </div>
  </div>
</template>

<script>
import { API } from '@/Api/Telephony'

const statusObject = {
  none: '',
  calling: 'Вызываю...',
  success: 'Ожидайте звонок',
  error: 'Ошибка вызова'
}

export default {
  name: 'SipCallLink',
  props: {
    phone: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      process: false,
      message: statusObject.none
    }
  },
  computed: {
    statuses () {
      return statusObject
    }
  },
  methods: {
    start () {
      confirm('DEBUG - ВЫЗОВ ЧЕРЕЗ СЕРВИС ТЕЛЕФОНИИ')
      const isConfirm = confirm(`Позвонить клиенту? По номеру: ${this.$Global.formatPhone(this.phone)}`)
      if (this.process || !isConfirm) return
      this.process = true
      this.message = this.statuses.calling
      API.Platform.call(this.phone)
        .then((data) => {
          console.log(this.$options.name, data)
          this.message = this.statuses.success
        })
        .catch((error) => {
          console.error(this.$options.name, error)
          this.message = this.statuses.error
        })
        .finally(() => {
          setTimeout(() => {
            this.message = this.statuses.none
            this.process = false
          }, 20000)
        })
    }
  }
}
</script>
<style lang="less" scoped>
.phone-link {
  color: dodgerblue;
  cursor: pointer;
  text-decoration: underline solid;
  text-decoration-thickness: 1px;
  &:hover {
    color: #005fbc;
    text-decoration: underline dashed;
  }
}
</style>
