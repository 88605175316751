<template>
  <div
    tabindex="0"
    title="Нажмите на поле или перетяните файл для загрузки!"
    :style="{ width, height }"
    class="tw-cursor-pointer tw-select-none tw-rounded-2xl tw-border tw-border-dashed tw-border-an-flat-disabled-text tw-p-[26px] tw-text-main-sm2 tw-flex tw-flex-col tw-gap-[6px] tw-justify-center tw-items-center hover:tw-bg-green-50"
    @click="dragZoneClick"
    @dragenter="dragHandler"
    @dragleave="dragHandler"
    @dragover="dragHandler"
    @drop="dragHandler"
  >
    <template v-if="!loading">
      <template v-if="!dragover">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.6973 12.8105C29.8129 12.3134 29.8793 11.7983 29.8793 11.2678C29.8793 7.41902 26.6747 4.29857 22.7212 4.29857C21.9384 4.29857 21.1869 4.42521 20.4823 4.65121C18.7493 2.09775 15.7765 0.412109 12.3975 0.412109C7.04917 0.412109 2.7132 4.63312 2.7132 9.84031C2.7132 10.6658 2.82312 11.4665 3.02754 12.2297C1.55755 13.2156 0.592773 14.8638 0.592773 16.7328C0.592773 19.7515 3.10597 22.1985 6.20692 22.1985H25.7934C28.8947 22.1985 31.4076 19.7515 31.4076 16.7328C31.4076 15.1925 30.7512 13.8037 29.6973 12.8105ZM21.3205 14.5446L16.1564 19.5718C16.1145 19.6126 16.0591 19.6347 16.0002 19.6347C15.941 19.6347 15.8858 19.6126 15.8443 19.5718L10.6802 14.5446C10.6386 14.5041 10.6153 14.4498 10.6153 14.3928C10.6153 14.3358 10.6383 14.2818 10.6802 14.241L12.1367 12.8234C12.2198 12.7418 12.3653 12.7418 12.4488 12.8234L14.6707 14.9862V5.69596C14.6707 5.5776 14.7699 5.481 14.8915 5.481H17.1095C17.2314 5.481 17.3303 5.57729 17.3303 5.69596V14.9859L19.5519 12.8231C19.6356 12.7415 19.7812 12.7415 19.8643 12.8231L21.3205 14.2407C21.3623 14.2815 21.3857 14.3355 21.3857 14.3925C21.3857 14.4495 21.3617 14.5041 21.3205 14.5446Z"
            fill="#D8DCE2"
          />
        </svg>
        {{ label }}
        <div class="tw-text-an-flat-gray-4">
          Нажмите или перетащите файл, чтобы загрузить его
        </div>
      </template>
      <template v-else>
        <svg
          class="tw-pointer-events-none"
          width="70"
          height="70"
          viewBox="0 0 32 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.6973 12.8105C29.8129 12.3134 29.8793 11.7983 29.8793 11.2678C29.8793 7.41902 26.6747 4.29857 22.7212 4.29857C21.9384 4.29857 21.1869 4.42521 20.4823 4.65121C18.7493 2.09775 15.7765 0.412109 12.3975 0.412109C7.04917 0.412109 2.7132 4.63312 2.7132 9.84031C2.7132 10.6658 2.82312 11.4665 3.02754 12.2297C1.55755 13.2156 0.592773 14.8638 0.592773 16.7328C0.592773 19.7515 3.10597 22.1985 6.20692 22.1985H25.7934C28.8947 22.1985 31.4076 19.7515 31.4076 16.7328C31.4076 15.1925 30.7512 13.8037 29.6973 12.8105ZM21.3205 14.5446L16.1564 19.5718C16.1145 19.6126 16.0591 19.6347 16.0002 19.6347C15.941 19.6347 15.8858 19.6126 15.8443 19.5718L10.6802 14.5446C10.6386 14.5041 10.6153 14.4498 10.6153 14.3928C10.6153 14.3358 10.6383 14.2818 10.6802 14.241L12.1367 12.8234C12.2198 12.7418 12.3653 12.7418 12.4488 12.8234L14.6707 14.9862V5.69596C14.6707 5.5776 14.7699 5.481 14.8915 5.481H17.1095C17.2314 5.481 17.3303 5.57729 17.3303 5.69596V14.9859L19.5519 12.8231C19.6356 12.7415 19.7812 12.7415 19.8643 12.8231L21.3205 14.2407C21.3623 14.2815 21.3857 14.3355 21.3857 14.3925C21.3857 14.4495 21.3617 14.5041 21.3205 14.5446Z"
            fill="#D8DCE2"
          />
        </svg>
      </template>
    </template>
    <template v-else>
      <TwCircleLoader />
    </template>
    <input
      ref="upload"
      type="file"
      :value="null"
      :accept="accept"
      :disabled="disabled || loading"
      :multiple="multiple"
      style="display:none"
      @change="inputChange"
    >
  </div>
</template>

<script>
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'TwFileDropPlace',
  components: { TwCircleLoader },
  props: {
    label: {
      type: String,
      default: 'Загрузить файл'
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/bmp'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '150px'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formUpload: false,
      dragover: false
    }
  },
  methods: {
    dragZoneClick () {
      // console.warn('++', e)
      if (this.disabled) return
      this.$refs.upload.click()
    },
    inputChange (e) {
      this.filesSelected(e.target.files)
    },
    dragHandler (e) {
      // console.warn('dragHandler', e)
      e.preventDefault()

      if (this.disabled) return

      if (e && e.type) {
        switch (e.type) {
          case 'dragenter':
            this.dragover = true
            break
          case 'dragleave':
            this.dragover = false
            break
          case 'dragover':
            this.dragover = true
            break
          case 'drop':
            if (e.dataTransfer) {
              this.filesSelected(e.dataTransfer.files)
            }
            break
        }
      }
    },
    filesSelected (files) {
      console.log('filesSelected ', files)
      this.$emit('filesSelected', files)
      this.dragover = false
    }
  }
}
</script>

<style scoped>

</style>
