<template>
  <div class="tw-flex tw-flex-col tw-gap-[12px]">
    <TwSelectRelations
      v-model="form.complexId"
      :disabled="formProcessing"
      label="Все ЖК"
      list-label="Выбрать ЖК"
      relation-name="complex"
      :default-filters="complexFilters"
    />
    <div>
      <TwSelect
        v-model="form.objectType"
        label="Тип объекта"
        :multi="false"
        :disabled="formProcessing"
        :options="dictionary.objectType"
        :error-text="$v.form.objectType.$invalid ? 'Заполните поле':''"
      />
    </div>
    <div>
      <TwSelect
        v-model="form.type"
        label="Тип комиссии"
        :multi="false"
        :disabled="formProcessing"
        :options="commissionTypesFromObjType"
        :error-text="$v.form.type.$invalid ? 'Заполните поле':''"
      />
    </div>
    <div v-if="form.type">
      <TwInputText
        v-model.number="form.commission"
        type="number"
        placeholder="Комиссия"
        :disabled="formProcessing"
        :show-label="true"
        persistent-label
        :error-text="$v.form.commission.$invalid ? 'Заполните поле':''"
      />
    </div>
    <div>
      <TwTextarea
        v-model="form.description"
        rows="5"
        persistent-label
        placeholder="Описание"
        :disabled="formProcessing"
        :show-label="true"
      />
    </div>
    <div>
      <TwCardBlockFormActions
        :title-submit="formType === 'create' ? 'Создать' : 'Изменить'"
        :handle-submit="handleSubmit"
        :handle-cancel="handleCancel"
        :disable-submit="!isEdited || formProcessing || $v.form.$invalid"
        :disable-cancel="formProcessing"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { required } from 'vuelidate/lib/validators'
import API from '@/Api/Rtd/Company/DeveloperCommission'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'

export default defineComponent({
  name: 'BuilderDeveloperCommissionForm',
  components: { TwCardBlockFormActions, TwInputText, TwSelect, TwTextarea, TwSelectRelations },
  mixins: [FormCreateEditMixin],
  props: {
    companyId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      API: API,
      form: {}
    }
  },
  validations () {
    return {
      form: {
        type: {
          required
        },
        objectType: {
          required
        },
        commission: {
          required
        }
      }
    }
  },
  computed: {
    complexFilters () {
      return {
        builderId: this.companyId ? [this.companyId] : []
      }
    },
    commissionTypesFromObjType () {
      if (this.form.objectType && this.form.objectType.includes('_ihc')) {
        return this.dictionary.type.filter((t) => {
          return t.value !== 'by_area'
        })
      }
      return this.dictionary.type
    }
  },
  methods: {
    handleSubmit () {
      this.$v.$reset()
      this.$v.form.$touch()
      if (this.formProcessing === true) {
        return
      }
      if (!this.$v.form.$invalid) {
        this.formProcessing = true
        this.form.companyId = this.companyId
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'type') {
              this.$set(this.form, key, this.preset[key].value)
            } else if (key === 'objectType') {
              this.$set(this.form, key, this.preset[key].value)
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    }
  }
})
</script>

<style scoped>

</style>
