import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { ChatState } from './types'

import { SOCKET_EVENT_MAP } from '@/plugins/SocketIoConfig'
import Vue from 'vue'

const namespaced = true

export const chat: Module<ChatState, RootState> = {
  namespaced,
  state: {
    todo: false,
    message: {
      chatId: 0,
      messageId: 0
    }
  },
  /*
  * Префикс SOCKET_**** обязательный
  * */
  actions: {
    // Пример - заменить на необходимые события
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.message}`]: function ({ commit, state }, server) {
      console.warn('SOCKET_EVENT_MAP.IN.message ->', SOCKET_EVENT_MAP.IN.message, server)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.imChatCreated}`]: function ({ state }, server) {
      console.warn('SOCKET_im.chat.created  ->', server)
      console.warn(SOCKET_EVENT_MAP.IN.imChatCreated, server)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.imMessageCreated}`]: function ({ state, commit }, server) {
      commit('SET_MESSAGES_CREATED', server)
    }
  },

  mutations: {
    SET_MESSAGES_CREATED (state, message) {
      Vue.set(state.message, 'chatId', message.chatId)
      Vue.set(state.message, 'messageId', message.messageId)
      console.warn('SOCKET_MESSAGES+++', state.message)
    }
  },

  getters: {
    getSocketMessage (state:any) {
      return state.message
    }
  }
}
