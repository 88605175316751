<template>
  <v-card
    v-cloak
    :flat="flat"
  >
    <v-card-text>
      <v-form
        ref="form"
        autocomplete="off"
      >
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.email"
              autocomplete="nope"
              label="Email"
              placeholder="name@anflat.ru"
              type="email"
              :rules="emailRequireRules"
              required
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              ref="password"
              v-model="form.password"
              autocomplete="new-password"
              label="Пароль от почты"
              min="8"
              :append-icon="viewPassword ? 'fa-eye-slash' : 'fa-eye'"
              :type="viewPassword ? 'text' : 'password'"
              :rules="formType === 'create' ? passwordRules : []"
              :required="formType === 'create'"
              @click:append="() => (viewPassword = !viewPassword)"
            />
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="6"
          >
            <v-radio-group
              v-model="form.protocolIncoming"
              :rules="requireRules"
              label="Протокол входящей почты:"
            >
              <v-radio
                v-for="(item, key) of dictionary.protocol"
                :key="key"
                :value="item.value"
              >
                <template #label>
                  {{ item.label }}
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-radio-group
              v-model="form.protectConnect"
              :rules="requireRules"
              label="Протокол защищеного соединения:"
            >
              <v-radio
                v-for="(item, key) of dictionary.protect"
                :key="key"
                :value="item.value"
              >
                <template #label>
                  {{ item.label }}
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.serverOutgoing"
              autocomplete="off"
              label="Сервер исходящей почты(smtp)"
              type="text"
              required
            />
            <v-text-field
              v-model.number="form.portOutgoingServer"
              autocomplete="off"
              label="Порт сервера исходящей почты(smtp)"
              type="number"
              required
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.serverIncoming"
              autocomplete="off"
              :label="`Сервер входящей почты(${form.protocolIncoming})`"
              type="text"
              required
            />
            <v-text-field
              v-model.number="form.portIncomingServer"
              autocomplete="off"
              :label="`Порт сервера входящей почты(${form.protocolIncoming})`"
              type="number"
              required
            />
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="6"
          >
            <InputRadioGroup
              v-model="form.useDefault"
              label="Использовать по умолчанию:"
              :classes="['mt-0']"
              :rules="requireBooleanRules"
            />
          </v-col>
        </v-row>
      </v-form>
      <EditFormFooter
        :disabled="!isEdited || formProcessing || preset.system"
        :show-reset="showReset"
        @submit="handleSubmit"
        @reset="handleReset"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import API from '@/Api/Email/Config'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import InputRadioGroup from '@/components/radio/InputRadioGroup'
import EditFormFooter from '@/components/form/EditFormFooter'

export default {
  name: 'EmailConfigForm',
  components: { EditFormFooter, InputRadioGroup },
  mixins: [FormCreateEditMixin],
  props: {
    userId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      API: API,
      form: {
        login: null,
        email: null,
        password: null,
        serverOutgoing: null,
        serverIncoming: null,
        portOutgoingServer: 0,
        portIncomingServer: 0,
        protocolIncoming: 'pop3',
        protectConnect: 'ssl',
        useDefault: true,
        userId: null
      },
      viewPassword: false
    }
  },
  computed: {
    targetId () {
      return this.id || null
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'userId') {
              this.$set(this.form, key, this.userId || this.preset[key])
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.$refs.form.validate()) {
        if (this.formProcessing === true) {
          return
        }
        const formData = this.form
        // TODO Логин равен почте - сделано для уменьшения полей в форме
        formData.login = formData.email

        this.formProcessing = true
        const request = this.formType === 'create'
          ? API.store(formData)
          : API.update(this.targetId, formData)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
            this.$nextTick(() => {
              this.fetchData()
            })
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
