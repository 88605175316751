/* Параметры socket.io
* https://socket.io/docs/v4/client-options/
*  */
export const IoOptions = {
  reconnection: true,
  reconnectionDelay: 6000,
  reconnectionDelayMax: 10000,
  reconnectionAttempts: 10
}

/* Схема событий сокетов */
// События Получния данных
enum IN {
  message = 'message',
  imChatCreated = 'im.chat.created',
  imMessageCreated = 'im.message.created',
  auth = 'auth' // Ответ на [user](проверки токена) - 1 || 0
}

// События Отправки данных
enum OUT {
  message = 'message',
  user = 'user' // Запрос проверки токена пользователя { id: [userId], token: [stringToken] }
}

export const SOCKET_EVENT_MAP = {
  IN, // Входящее событие
  OUT // Исходящее событие
}
