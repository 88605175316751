<template>
  <a
    v-if="link"
    :href="link"
    target="_blank"
    title="Открыть в Bitrix 24"
  >
    <slot>
      {{ link }}
    </slot>
  </a>
</template>

<script>
export default {
  name: 'LinkToBitrix',
  props: {
    bxId: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    link () {
      const dict = {
        lead: `https://bitrix.anflat.ru/crm/lead/details/${this.bxId}/`,
        deal: `https://bitrix.anflat.ru/crm/deal/details/${this.bxId}/`
      }
      return dict[this.type]
    }
  }
}
</script>

<style scoped>

</style>
