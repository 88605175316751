<template>
  <v-form ref="form">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div>
      <v-text-field
        v-model="form.name"
        label="Название"
        autocomplete="off"
        :rules="nameRules"
        required
      />
    </div>
    <div>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="form.date"
            label="Документ с даты"
            append-icon="fa-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          ref="picker"
          v-model="form.date"
          :first-day-of-week="1"
          locale="ru-ru"
          :max="new Date().toISOString().substr(0, 10)"
          min="1950-01-01"
          @change="dateSave"
        />
      </v-menu>
    </div>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-model="form.section"
          label="Раздел:"
          :classes="['mt-0']"
          :options="dictionary.section"
          :rules="requireRules"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-if="form.section && Array.isArray(subsectionOptions) && subsectionOptions.length"
          v-model="form.subsection"
          label="Группа документов:"
          :classes="['mt-0']"
          :options="subsectionOptions"
          :rules="requireRules"
        />
      </v-col>
      <v-col cols="12">
        <div v-if="form.subsection && form.subsection !== 'himself'">
          <div
            class="font-weight-medium subtitle-2"
            style="opacity: 0.75"
          >
            Связанный объект:
          </div>
          <CompanyAsyncSelect
            v-if="form.subsection === 'bank'"
            v-model="form.entityId"
            chips
            clearable
            :rules="requireRules"
            placeholder="Выбрать Банк"
            :filters="{ 'Company.type': 'bank' }"
          />
          <MortgageProgramAsyncSelect
            v-if="form.subsection === 'program'"
            v-model="form.entityId"
            chips
            clearable
            :rules="requireRules"
            placeholder="Выбрать Программу кредитования"
          />
        </div>
      </v-col>
      <v-col cols="12">
        <div class="my-3">
          <div class="mb-2 font-weight-medium subtitle-2">
            Файл документа:
          </div>
          <SimpleDocumentUploadAndView
            v-for="(id, index) of form.filesId"
            :key="index"
            v-model="form.filesId[index]"
            accept=".docx"
            :label="''"
            width="200px"
            height="300px"
          />
        </div>
        <SectionFieldsView
          v-if="form.subsection && subsectionFields && Array.isArray(subsectionFields.subsection)"
          :info="subsectionFields"
        />
        <!--        <div class="body-2" v-if="form.subsection && Array.isArray(subsectionOptions) && subsectionOptions.length">-->
        <!--          <template v-if="subsectionOptions.find(ss => form.subsection === ss.value) && subsectionOptions.find(ss => form.subsection === ss.value).fields">-->
        <!--            <div class="font-weight-medium subtitle-2" style="opacity: 0.75">Поля автозаполнения документа:</div>-->
        <!--            <div v-for="(value, key) of subsectionOptions.find(ss => form.subsection === ss.value).fields" :key="key">-->

        <!--              <div class="d-flex align-center">-->
        <!--                <ClickBoard :disabled="false" :value="`\${${key}}`"/>-->
        <!--                <div v-text="`\${${key}}`"></div> <span class="ml-3 grey&#45;&#45;text">- {{value}}</span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </template>-->
        <!--        </div>-->
      </v-col>
    </v-row>
    <div class="my-3">
      <div class="ma-0 pa-0 grey--text">
        Активность:
      </div>
      <div class="d-flex">
        <v-checkbox
          v-model="form.active"
          class="mt-0"
          color="success"
          :label="form.active ? 'Да':'Нет'"
        />
      </div>
    </div>
    <CardSimpleFormSaveBlock
      :loading="formProcessing"
      style="z-index: 10"
      cancel-confirm
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </v-form>
</template>

<script>
import API from '@/Api/Crm/Dictionary/General/Document/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import InputRadioGroup from '@/components/radio/InputRadioGroup'
import SimpleDocumentUploadAndView from '@/components/input/file/SimpleDocumentUploadAndView'
import CompanyAsyncSelect from '@/components/select/company/CompanyAsyncSelect'
import MortgageProgramAsyncSelect from '@/components/select/program-mortgage/MortgageProgramAsyncSelect'
import SectionFieldsView from '@/views/Dictionary/General/Document/main/SectionFieldsView/SectionFieldsView'
import CardSimpleFormSaveBlock from '@/components/core/ViewPanel/Card/CardSimpleFormSaveBlock'

export default {
  name: 'DocumentMainForm',
  components: { CardSimpleFormSaveBlock, SectionFieldsView, MortgageProgramAsyncSelect, CompanyAsyncSelect, SimpleDocumentUploadAndView, InputRadioGroup },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API,
      form: {
        id: 0,
        active: true,
        section: null, // mortgage
        subsection: null,
        name: null,
        date: null,
        entityId: null,
        filesId: []
      },
      menu: false
    }
  },
  computed: {
    subsectionFields () {
      if (this.form.section && this.dictionary && Array.isArray(this.dictionary.section)) {
        const targetSection = this.dictionary.section.find(s => s.value === this.form.section)
        if (targetSection.subsection && this.form.subsection) {
          const subSection = targetSection.subsection.find(s => s.value === this.form.subsection)
          return {
            label: 'Назначения полей - ' + subSection.label,
            fields: [],
            subsection: subSection.subsection || []
          }
        }
      }
      return {}
    },
    subsectionOptions () {
      if (this.form.section && this.dictionary && Array.isArray(this.dictionary.section)) {
        const targetSection = this.dictionary.section.find(s => s.value === this.form.section)
        if (targetSection) {
          return targetSection.subsection
        }
      }
      return []
    }
  },
  watch: {
    'form.subsection' (newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.form.entityId = null
      }
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  methods: {
    addNewFile () {
      this.form.filesId.push(0)
    },
    dateSave (date) {
      this.$refs.menu.save(date)
    },
    handleResetCustom () {
      this.handleReset()
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            files: 'filesId',
            entity: 'entityId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'files' && (!this.preset[key] || !Array.isArray(this.preset[key]) || !this.preset[key].length)) {
                this.$set(this.form, adaperMap[key], [0])
              } else if (key === 'files' && (Array.isArray(this.preset[key]) && this.preset[key].length)) {
                this.$set(this.form, adaperMap[key], this.preset[key].map(file => file.id))
              }
              if (key === 'entity') {
                this.$set(this.form, adaperMap[key], (this.preset[key] ? this.preset[key].id : this.preset[adaperMap[key]]))
              }
            } else {
              if (key === 'subsection' && this.preset[key]) {
                this.$set(this.form, key, this.preset[key].value)
              } else if (key === 'section' && this.preset[key]) {
                this.$set(this.form, key, this.preset[key].value)
              } else {
                this.$set(this.form, key, this.preset[key])
              }
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.$refs.form.validate()) {
        if (!Array.isArray(this.form.filesId) || !this.form.filesId.length || !this.form.filesId[0]) {
          return alert('Загрузите файл')
        }
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true

        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
            // если текущий путь равен пути создания то редирект в просмотр
            if (this.$route.name === `${RouteKeys.document}-create`) {
              this.$router.replace({
                name: `${RouteKeys.document}-show`,
                params: { id: data.data.id }
              })
            }
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
