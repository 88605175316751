<template>
  <v-form ref="form">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <v-row class="mt-1">
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-text-field
          ref="salaryMonth"
          label="Ежемесячный доход"
          require
          outlined
          type="text"
          autocomplete="off"
          suffix="руб."
          :dense="denseFields"
          :value="salaryMonthSpaced"
          :hide-details="!(!salaryMonthErrors || (salaryMonthErrors && salaryMonthErrors.length))"
          :error-messages="salaryMonthErrors"
          @input="onInputSalaryMonth"
          @focus="$event.target.select()"
          @blur="$v.form.salaryMonth.$touch()"
        />
      </v-col>
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-menu
          ref="expDateMenu"
          v-model="expDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              ref="experience"
              v-model="form.experience"
              clearable
              :dense="denseFields"
              label="Дата начала работы(Общий стаж)"
              append-icon="fa-calendar"
              readonly
              outlined
              :hide-details="!(!experienceErrors || (experienceErrors && experienceErrors.length))"
              :error-messages="experienceErrors"
              v-on="on"
              @input="$v.form.experience.$touch()"
              @blur="$v.form.experience.$touch()"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="form.experience"
            :first-day-of-week="1"
            locale="ru-ru"
            :max="new Date().toISOString().slice(0, 10)"
            min="1950-01-01"
            @change="expDateSave"
          />
        </v-menu>
      </v-col>
      <v-col cols="12">
        <v-menu
          ref="expDateMenuLast"
          v-model="expDateMenuLast"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              ref="experienceLast"
              v-model="form.experienceLast"
              clearable
              :dense="denseFields"
              label="Дата начала работы на послед. месте работы"
              append-icon="fa-calendar"
              readonly
              hide-details
              outlined
              :error-messages="experienceErrors"
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="form.experienceLast"
            :first-day-of-week="1"
            locale="ru-ru"
            :max="new Date().toISOString().slice(0, 10)"
            min="1950-01-01"
            @change="expDateLastSave"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-select
          ref="confirmationMethod"
          v-model="form.confirmationMethod"
          clearable
          dense
          outlined
          :items="dictionary.typeConfirm"
          item-text="label"
          item-value="value"
          label="Способ подтверждения дохода"
          :error-messages="confirmationMethodErrors"
          @input="$v.form.confirmationMethod.$touch()"
          @blur="$v.form.confirmationMethod.$touch()"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div class="mb-2">
          Зарплатный проект:
        </div>
        <TwSelectRelations
          v-model="form.salaryProjectCompanyId"
          :multi="false"
          :dense="denseFields"
          :persistent-label="true"
          label="Банк"
          placeholder="Выберите банк"
          list-label="Найти банк"
          relation-name="bank"
          :disabled="formProcessing"
        />
        <TwInputBankCard
          ref="salaryProjectCard"
          v-model="form.salaryProjectCard"
          class="tw-my-[12px] tw-flex-1"
          :disabled="false"
          :disable-error-border="false"
          :show-label="true"
          :persistent-label="true"
          :dense="true"
          :error-text="salaryProjectCardErrors"
          label="Номер зарплатной карты"
          @input="$v.form.salaryProjectCard.$touch()"
        />
      </v-col>
    </v-row>
    <!-- ОСНОВНОЕ МЕСТО РАБОТЫ -->
    <div class="mb-3 pa-2 sub-white-bordered-block">
      <div class="mb-3 font-weight-bold">
        Основное место работы:
      </div>
      <v-text-field
        ref="mainCompanyName"
        v-model="form.mainCompanyName"
        class="mt-3"
        hide-details
        label="Название"
        require
        outlined
        type="text"
        autocomplete="off"
        :dense="denseFields"
      />

      <TwInputPhone
        ref="mainCompanyPhone"
        v-model="form.mainCompanyPhone"
        class="tw-mt-[12px] tw-flex-1"
        :disabled="false"
        :disable-error-border="false"
        :show-label="true"
        :persistent-label="true"
        :dense="true"
        label="Телефон"
      />

      <v-text-field
        ref="mainCompanyInn"
        v-model="form.mainCompanyInn"
        counter
        clearable
        class="mt-3"
        label="ИНН"
        outlined
        type="number"
        autocomplete="off"
        :dense="denseFields"
        :hide-details="!(!mainCompanyInnErrors || (mainCompanyInnErrors && mainCompanyInnErrors.length))"
        :error-messages="mainCompanyInnErrors"
        @input="$v.form.mainCompanyInn.$touch()"
        @blur="$v.form.mainCompanyInn.$touch()"
      />
      <v-text-field
        ref="mainCompanyAddress"
        v-model="form.mainCompanyAddress"
        class="mt-3"
        hide-details
        label="Адрес"
        outlined
        type="text"
        autocomplete="off"
        :dense="denseFields"
      />
      <v-text-field
        ref="mainCompanyDirection"
        v-model="form.mainCompanyDirection"
        class="mt-3"
        hide-details
        label="Сфера деятельности"
        outlined
        type="text"
        autocomplete="off"
        :dense="denseFields"
      />
      <v-text-field
        ref="mainCompanyPosition"
        v-model="form.mainCompanyPosition"
        class="mt-3"
        hide-details
        label="Должность"
        outlined
        type="text"
        autocomplete="off"
        :dense="denseFields"
      />
      <v-text-field
        ref="mainCompanySite"
        v-model="form.mainCompanySite"
        class="mt-3"
        hide-details
        label="Веб сайт"
        outlined
        prefix="http://"
        type="text"
        autocomplete="off"
        :dense="denseFields"
      />
    </div>
    <!-- ДОП МЕСТО РАБОТЫ И ДОХОД -->
    <v-checkbox
      v-model="isAdditionalSalary"
      class="pt-0"
      :dense="denseFields"
      color="green"
      label="Есть подтвержденный дополнительный доход"
    />
    <div
      v-if="isAdditionalSalary"
      class="mb-3 pa-2 sub-white-bordered-block"
    >
      <div class="mb-3 font-weight-bold">
        Дополнительный доход:
      </div>
      <v-text-field
        outlined
        type="text"
        :dense="denseFields"
        label="Сумма дополнительного дохода"
        autocomplete="off"
        suffix="руб."
        :value="additionalSalarySumSpaced"
        @focus="$event.target.select()"
        @input="onInputAdditionalSalarySum"
      />
      <div class="mb-3 font-weight-bold">
        Дополнительное место работы:
      </div>
      <v-text-field
        ref="additionalCompanyName"
        v-model="form.additionalCompanyName"
        class="mt-3"
        hide-details
        label="Название"
        require
        outlined
        type="text"
        autocomplete="off"
        :dense="denseFields"
      />

      <TwInputPhone
        ref="additionalCompanyPhone"
        v-model="form.additionalCompanyPhone"
        class="tw-mt-[12px] tw-flex-1"
        :disabled="false"
        :disable-error-border="false"
        :show-label="true"
        :persistent-label="true"
        :dense="true"
        label="Телефон"
      />

      <v-text-field
        ref="additionalCompanyInn"
        v-model="form.additionalCompanyInn"
        counter
        clearable
        class="mt-3"
        label="ИНН"
        outlined
        type="number"
        autocomplete="off"
        :dense="denseFields"
        :hide-details="!(!additionalCompanyInnErrors || (additionalCompanyInnErrors && additionalCompanyInnErrors.length))"
        :error-messages="additionalCompanyInnErrors"
        @input="$v.form.additionalCompanyInn.$touch()"
        @blur="$v.form.additionalCompanyInn.$touch()"
      />
      <v-text-field
        ref="additionalCompanyAddress"
        v-model="form.additionalCompanyAddress"
        class="mt-3"
        hide-details
        label="Адрес"
        outlined
        type="text"
        autocomplete="off"
        :dense="denseFields"
      />
      <v-text-field
        ref="additionalCompanyDirection"
        v-model="form.additionalCompanyDirection"
        class="mt-3"
        hide-details
        label="Сфера деятельности"
        outlined
        type="text"
        autocomplete="off"
        :dense="denseFields"
      />
      <v-text-field
        ref="additionalCompanyPosition"
        v-model="form.additionalCompanyPosition"
        class="mt-3"
        hide-details
        label="Должность"
        outlined
        type="text"
        autocomplete="off"
        :dense="denseFields"
      />
    </div>
    <!-- НАКОПЛЕНИЯ -->
    <v-checkbox
      v-model="form.accumulation"
      class="ma-0 pt-0"
      :dense="denseFields"
      color="green"
      label="Есть накопления"
    />

    <CardSimpleFormSaveBlock
      :loading="formProcessing"
      cancel-confirm
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </v-form>
</template>

<script>
import API from '@/Api/Crm/Person/Salary/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { required } from 'vuelidate/lib/validators'
import validateInn from '@/plugins/helpers/validateInn'
import CardSimpleFormSaveBlock from '@/components/core/ViewPanel/Card/CardSimpleFormSaveBlock'
import TwInputPhone from '@/components/tw-ui/ui-kit/custom/TwInputPhone.vue'
import TwInputBankCard from '@/components/tw-ui/ui-kit/custom/TwInputBankCard.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'

export default {
  name: 'PersonSalaryForm',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    TwSelectRelations,
    TwInputBankCard,
    TwInputPhone,
    CardSimpleFormSaveBlock
  },
  mixins: [FormCreateEditMixin],
  props: {
    personId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      form: {
        personId: this.personId,
        id: null,
        salaryProjectCompanyId: null, // integer*ID Банк
        salaryProjectCard: null, // string Зарплатная карта
        confirmationMethod: null, // string*Enum Подтверждение дохода
        salaryMonth: null, // number*float Доход в месяц
        experience: null, // string*date Общий стаж, дата
        experienceLast: null, // string*date Стаж на последнем месте работы, дата
        additionalSalarySum: null, // number*float Сумма дополнительного дохода
        accumulation: null, // boolean Накопления

        mainCompanyName: null, // string Название основного места работы
        mainCompanyInn: null, // string ИНН основного места работы
        mainCompanyAddress: null, // string Адрес основного места работы
        mainCompanyDirection: null, // string Сфера деятельности основного места работы
        mainCompanyPosition: null, // string Должность на основном месте работы
        mainCompanyPhone: null, // string телефон основного места работы
        mainCompanySite: null, // string сайт основного места работы

        additionalCompanyInn: null, // string ИНН дополнительного места работы
        additionalCompanyName: null, // string Название дополнительного места работы
        additionalCompanyAddress: null, // string Адрес дополнительного места работы
        additionalCompanyDirection: null, // string Сфера деятельности дополнительного места работы
        additionalCompanyPosition: null, // string Должность на дополнительном месте работы
        additionalCompanyPhone: null // string телефон дополнительного места работы
      },
      additionalSalaryLocal: false,
      expDateMenu: false,
      expDateMenuLast: false,
      serverErrors: null
    }
  },
  validations: {
    form: {
      mainCompanyInn: {
        isValid: function (v) {
          const errors = {}
          validateInn(v, errors)
          if (v) {
            return !errors.message
          } else {
            return true
          }
        }
      },
      additionalCompanyInn: {
        isValid: function (v) {
          const errors = {}
          validateInn(v, errors)
          if (v) {
            return !errors.message
          } else {
            return true
          }
        }
      },
      salaryMonth: {
        required: (v) => !!(v && !isNaN(parseFloat(v)) && parseFloat(v))
      },
      experience: {
        required
      },
      confirmationMethod: {
        required
      },
      salaryProjectCard: {
        valideCardNum: (v) => !!(!v || (typeof v === 'string' && /^([0-9 ]){16}/.test(v)))
      }
    }
  },
  computed: {
    isAdditionalSalary: {
      get () {
        return this.form.additionalCompanyName || this.form.additionalSalarySum || this.additionalSalaryLocal
      },
      set (val) {
        if (!val) {
          this.form.additionalSalarySum = 0
        }
        this.additionalSalaryLocal = val
      }
    },
    salaryMonthSpaced () {
      return ((this.form.salaryMonth && this.form.salaryMonth) || 0).toLocaleString()
    },
    additionalSalarySumSpaced () {
      return ((this.form.additionalSalarySum && this.form.additionalSalarySum) || 0).toLocaleString()
    },
    salaryMonthErrors () {
      const errors = []
      if (!this.$v.form.salaryMonth.$dirty) return errors
      if (!this.$v.form.salaryMonth.required) {
        errors.push('Поле обязательное')
        this.$Global.scrollToElem(this.$refs.salaryMonth.$el)
      }
      return errors
    },
    mainCompanyInnErrors () {
      const errors = []
      if (!this.$v.form.mainCompanyInn.$dirty) return errors
      if (!this.$v.form.mainCompanyInn.isValid) {
        const error = {}
        validateInn(this.form.mainCompanyInn, error)
        errors.push(error.message)
        this.$Global.scrollToElem(this.$refs.mainCompanyInn.$el)
      }
      return errors
    },
    additionalCompanyInnErrors () {
      const errors = []
      if (!this.$v.form.additionalCompanyInn.$dirty) return errors
      if (!this.$v.form.additionalCompanyInn.isValid) {
        const error = {}
        validateInn(this.form.additionalCompanyInn, error)
        errors.push(error.message)
        this.$Global.scrollToElem(this.$refs.additionalCompanyInn.$el)
      }
      return errors
    },
    experienceErrors () {
      const errors = []
      if (!this.$v.form.experience.$dirty) return errors
      if (!this.$v.form.experience.required) {
        errors.push('Поле обязательное')
        this.$Global.scrollToElem(this.$refs.experience.$el)
      }
      return errors
    },
    confirmationMethodErrors () {
      const errors = []
      if (!this.$v.form.confirmationMethod.$dirty) return errors
      if (!this.$v.form.confirmationMethod.required) {
        errors.push('Поле обязательное')
        this.$Global.scrollToElem(this.$refs.confirmationMethod.$el)
      }
      return errors
    },
    salaryProjectCardErrors () {
      const errors = []
      if (!this.$v.form.salaryProjectCard.$dirty) return errors
      if (!this.$v.form.salaryProjectCard.valideCardNum) {
        errors.push('Номер карты должен состоять из 16 цифр')
        this.$Global.scrollToElem(this.$refs.salaryProjectCard.$el)
      }
      return errors
    }
  },
  watch: {
    expDateMenu (newVal) {
      newVal && setTimeout(() => (this.$refs.expDateMenu.activePicker = 'YEAR'))
    },
    expDateMenuLast (newVal) {
      newVal && setTimeout(() => (this.$refs.expDateMenuLast.activePicker = 'YEAR'))
    }
  },
  methods: {
    onInputSalaryMonth (value) {
      this.$v.form.salaryMonth.$touch()
      // eslint-disable-next-line no-irregular-whitespace
      this.form.salaryMonth = (value && parseFloat(value.replace(/[a-z]/g, '').replace(/,/g, '.').replace(/ | +$/g, ''))) || 0
    },
    onInputAdditionalSalarySum (value) {
      // eslint-disable-next-line no-irregular-whitespace
      this.form.additionalSalarySum = (value && parseFloat(value.replace(/[a-z]/g, '').replace(/,/g, '.').replace(/ | +$/g, ''))) || 0
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            confirmationMethod: 'confirmationMethod',
            salaryProjectCompany: 'salaryProjectCompanyId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              }
              if (key === 'confirmationMethod') {
                this.$set(this.form, adaperMap[key], this.preset[key].value || null)
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      // console.log('handleSubmit ', this.$v)
      // console.log('$invalid ', this.$v.form.$invalid)
      console.log('this.$refs.form.validate ', this.$refs.form.validate())
      if (this.$refs.form.validate() && !this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else {
          alert(`Error handleSubmit: ${this.$options.name}`)
        }
      }
    },
    expDateSave (date) {
      this.$refs.expDateMenu.save(date)
    },
    expDateLastSave (date) {
      this.$refs.expDateMenuLast.save(date)
    }
  }
}
</script>

<style scoped>

</style>
