<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model.number="minutes"
          min="0"
          max="59"
          type="number"
          dense
          :rules="[minutesRule]"
          label="Минуты"
          placeholder="0"
          outlined
          :clearable="minutes !== 0"
          @click:clear="minutes = 0"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model.number="hours"
          min="0"
          max="23"
          type="number"
          dense
          :rules="[hoursRule]"
          label="Часы"
          placeholder="0"
          outlined
          :clearable="hours !== 0"
          @click:clear="hours = 0"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model.number="days"
          min="0"
          max="7"
          type="number"
          dense
          :rules="[daysRule]"
          label="Дни"
          placeholder="0"
          outlined
          :clearable="days !== 0"
          @click:clear="days = 0"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'InputMinHourDayFrom',
  props: {
    value: {
      type: String,
      default: null
    },
    fromDate: {
      type: String,
      required: true
    },
    isIncrement: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      minutes: 0,
      hours: 0,
      days: 0,

      minutesRule: value => (value >= 0 && value <= 59) || 'от 0 до 59 минут',
      hoursRule: value => (value >= 0 && value <= 23) || 'от 0 до 23 часов',
      daysRule: value => (value >= 0 && value <= 7) || 'от 0 до 7 дней'
    }
  },
  computed: {
    calcTime () {
      let result = 0
      result += this.minutes > 0 ? (1000 * 60) * this.minutes : 0
      result += this.hours > 0 ? (1000 * 60 * 60) * this.hours : 0
      result += this.days > 0 ? (1000 * 60 * 60 * 24) * this.days : 0
      return result
    },
    resultDate () {
      const datetime = new Date(this.fromDate).getTime()
      if (this.isIncrement) {
        return new Date((datetime) + this.calcTime).toISOString()
      } else {
        return new Date((datetime) - this.calcTime).toISOString()
      }
    }
  },
  watch: {
    resultDate (newValue) {
      if (!this.minutes && !this.hours && !this.days) {
        this.$emit('input', null)
      } else {
        this.$emit('input', newValue)
      }
    }
  },
  mounted () {
    if (this.value && this.fromDate) {
      const datesDiffObj = this.$Global.DateFormat.datesDiffObj(new Date(this.fromDate), new Date(this.value))
      // console.warn({ datesDiffObj })
      this.days = datesDiffObj.days
      this.hours = datesDiffObj.hours
      this.minutes = datesDiffObj.minutes
    }
  },
  methods: {}
}
</script>

<style lang="less">
</style>
