<template>
  <div
    v-if="formProcessing"
    class="py-3 text-center ma-auto"
  >
    <TwCircleLoader />
  </div>
  <div
    v-else
    class="tw-font-manrope"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexRow class="tw-mt-[10px]">
        <TwFlexCell class="tw-font-medium tw-text-main-md">
          Основное
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.surname"
            type="text"
            label="Фамилия"
            placeholder="Сидоров"
            dense
            :show-label="true"
            :persistent-label="true"
            :disabled="formProcessing"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.name"
            type="text"
            label="Имя"
            placeholder="Иван"
            dense
            :show-label="true"
            :persistent-label="true"
            :disabled="formProcessing"
          />
        </TwFlexCell>
      </TwFlexRow>

      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.patronymic"
            type="text"
            label="Отчество"
            placeholder="Петрович"
            dense
            :disabled="formProcessing"
            :show-label="true"
            :persistent-label="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.birthDate"
            type="date"
            persistent-label
            dense
            label="Дата рождения"
            :max="new Date().toISOString().substr(0, 10)"
            min="1920-01-01"
            :disabled="formProcessing"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow class="tw-mt-[20px]">
        <TwFlexCell class="tw-font-medium tw-text-main-md">
          Паспорт
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputPassportFields
            v-model="form.passportSeria"
            label="Серия"
            dense
            persistent-label
            type="series"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputPassportFields
            v-model="form.passportNumber"
            label="Номер"
            dense
            persistent-label
            type="number"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.passportDate"
            type="date"
            label="Дата выдачи"
            dense
            :show-label="true"
            :persistent-label="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputPassportFields
            v-model="form.passportCode"
            label="Код подразделения"
            dense
            persistent-label
            type="code"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow class="tw-mt-[20px]">
        <TwFlexCell class="tw-font-medium tw-text-main-md">
          Др. документы
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.inn"
            type="number"
            label="ИНН"
            placeholder="ИНН"
            dense
            :show-label="true"
            :persistent-label="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.snils"
            type="number"
            label="СНИЛС"
            placeholder="СНИЛС"
            dense
            :show-label="true"
            :persistent-label="true"
          />
        </TwFlexCell>
      </TwFlexRow>
    </TwFlexCol>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="false"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
// TODO Валидации будут?
// import validateInn from '@/plugins/helpers/validateInn'
// import validateSnils from '@/plugins/helpers/validateSnils'
// import { required } from 'vuelidate/lib/validators'
import API from '@/Api/DocCheck/Kontur/Check/Person'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwInputPassportFields from '@/components/tw-ui/ui-kit/custom/TwInputPassportFields.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'

export default {
  name: 'DocCheckKonturPersonForm',
  components: {
    TwCardSimpleFormSaveBlock,
    TwCircleLoader,
    TwInputPassportFields,
    TwFlexRow,
    TwFlexCell,
    TwFlexCol,
    TwInputText
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API,
      form: {
        subjectId: '',
        name: '',
        surname: '',
        patronymic: '',
        birthDate: '',
        inn: '',
        snils: '',
        passportSeria: '',
        passportNumber: '',
        passportDate: '',
        passportCode: ''
      }
    }
  },
  // Модель для валидации, которую Vuelidate превратит в computed-поле $v
  validations () {
    return {
      form: {}
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            this.$set(this.form, key, this.preset[key])
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.$touch()
      // console.warn('handleSubmit', this.$v.form.$invalid === false, this.formProcessing)
      if (!this.$v.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? API.store(this.form)
          : API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
