<template>
  <div
    v-cloak
    v-if="info"
  >
    <CardBlockField>
      <template #title>
        Название
      </template>
      <template #content>
        <div v-if="info.name">
          {{ info.name }}
        </div>
        <div v-else>
          отсутствует
        </div>
      </template>
    </CardBlockField>
    <CardBlockField>
      <template #title>
        Раздел
      </template>
      <template #content>
        <div v-if="info.section">
          {{ sectionInfo.sectionLabel }}
        </div>
        <div v-else>
          отсутствует
        </div>
      </template>
    </CardBlockField>
    <CardBlockField>
      <template #title>
        Группа документов
      </template>
      <template #content>
        <div v-if="info.subsection">
          {{ sectionInfo.subsectionLabel }}
        </div>
        <div v-else>
          отсутствует
        </div>
      </template>
    </CardBlockField>
    <CardBlockField>
      <template #title>
        Связанный объект
      </template>
      <template #content>
        <div
          v-if="info.entity && info.subsection"
          class="pa-3 sub-white-bordered-block"
        >
          <div v-if="info.subsection.value === 'bank'">
            <small>
              {{ info.subsection.label }}
            </small>
            <div>
              <ViewPanelLink
                :id="info.entity.id"
                label="Компания"
                component="company-show"
                :callback="refresh"
              >
                {{ info.entity.name }}
              </ViewPanelLink>
            </div>
          </div>
          <div v-if="info.subsection.value === 'program'">
            <small>
              {{ info.subsection.label }}
            </small>
            <div>
              <ViewPanelLink
                :id="info.entity.id"
                label="Программа"
                component="mortgage-program-show"
                :callback="refresh"
              >
                {{ info.entity.name }}
              </ViewPanelLink>
            </div>
          </div>
        </div>
        <div v-else>
          отсутствует
        </div>
      </template>
    </CardBlockField>
    <CardBlockField>
      <template #title>
        Дата документа
      </template>
      <template #content>
        <div v-if="info.date">
          {{ info.date }}
        </div>
        <div v-else>
          отсутствует
        </div>
      </template>
    </CardBlockField>
    <CardBlockField>
      <template #title>
        Загруженный шаблон
      </template>
      <template #content>
        <div v-if="Array.isArray(info.files) && info.files.length">
          <v-btn
            color="primary"
            small
            elevation="0"
            outlined
            @click="download(info.files[0])"
          >
            Скачать
            <v-icon
              x-small
              right
            >
              fa-download
            </v-icon>
          </v-btn>
        </div>
        <div v-else>
          отсутствует
        </div>
      </template>
    </CardBlockField>

    <CardBlockField>
      <template #title>
        Активность
      </template>
      <template #content>
        {{ info.active ? 'Да':'Нет' }}
      </template>
    </CardBlockField>
    <SectionFieldsView :info="sectionInfo.subsectionFields" />
  </div>
</template>

<script>
import docAPI from '@/Api/Crm/Dictionary/General/Document'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import CardBlockField from '@/components/core/ViewPanel/Card/CardBlockField'
import SectionFieldsView from '@/views/Dictionary/General/Document/main/SectionFieldsView/SectionFieldsView'

export default {
  name: 'DocumentMainShow',
  components: { SectionFieldsView, CardBlockField, ViewPanelLink },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    dictionary: {}
  },
  computed: {
    sectionInfo () {
      const result = {
        sectionLabel: this.info?.section.label,
        subsectionLabel: this.info?.subsection.label
      }
      if (this.dictionary && Array.isArray(this.dictionary.section)) {
        const targetSection = this.dictionary.section.find(s => s.value === this.info.section.value)
        if (Array.isArray(targetSection.subsection) && this.info.subsection.value) {
          const targetSubsection = targetSection.subsection.find(s => s.value === this.info.subsection.value)
          console.log(targetSubsection)
          result.subsectionLabel = targetSubsection.label
          result.subsectionFields = {
            label: 'Назначения полей',
            fields: [],
            subsection: targetSubsection.subsection
          }
        }
      }
      return result
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    },
    download (file) {
      console.log('download file: ', file)
      docAPI.download(file.url, file.name)
    }
  }
}
</script>
