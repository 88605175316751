<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    header-class="mb-2"
    :permission-denied="permissionDenied"
    relative
  >
    <TwPanelContent class="tw-flex-1">
      <TwPanelContentBlock
        class="tw-flex-1 tw-mb-[6px]"
        :content-classes="''"
      >
        <template #title>
          <div>
            Список доступных планировок
          </div>
        </template>
        <template #action>
          <TwPanelContentBlockActionButton
            :disabled="!isAllowEdit || loading"
            type="add"
            @click.prevent="addNewPlan"
          >
            Добавить планировку
          </TwPanelContentBlockActionButton>
        </template>
        <div>
          <TwTableBase
            :schema-key="filterSchemaKey"
            :loading-error-text="loadingErrorText"
            tbody-classes="tw-text-xs"
            :items="data"
            :headers="columns"
            :loading="loading"
            :fixed-header-margin="0"
            :table-columns-management="true"
            tbody-row-classes="tw-cursor-pointer"
            :pagination="pagination"
            @row-click="clickToItem"
            @sortUpdate="onSortUpdate"
            @changePagination="changePagination"
          >
            <template #item.genPlan="{ item }">
              <div v-if="Array.isArray(item.genPlan) && item.genPlan.length">
                <div @click.stop="viewPlanFiles(item.genPlan)">
                  <img
                    v-if="item.genPlan[0]"
                    :src="item.genPlan[0].preview || item.genPlan[0].url"
                    class="tw-border tw-border-solid tw-rounded-sm tw-cursor-zoom-in tw-object-contain tw-m-auto"
                    width="50"
                    height="50"
                    alt="Планировка"
                  >
                </div>
              </div>
              <div
                v-else
                class="tw-text-an-flat-disabled-text"
              >
                -
              </div>
            </template>
            <template #item.roomCount="{ item }">
              {{ item.roomCount }}
            </template>
            <template #item.totalArea="{ item }">
              {{ item.totalArea }} м²
            </template>
            <template #item.residentialArea="{ item }">
              {{ item.residentialArea }} м²
            </template>
            <template #item.commercial="{ item }">
              {{ item.commercial ? 'Да':'Нет' }}
            </template>
          </TwTableBase>
        </div>
      </TwPanelContentBlock>
    </TwPanelContent>
  </PageViewDefaultWrapper>
</template>

<script>
import realtyEditorPlanAPI from '@/Api/ObjectStorage/Realty/Editor/Plan/index.ts'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import { ServicesSchema } from '@/store/twfilter/servicesSchema'
import tableOptions from '@/views/Newbuildings/Editor/Plans/tableOptions'
import DataListMixin from '@/mixins/DataListMixin'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'RealtyPlansBlockList',
  components: { TwTableBase, TwPanelContent, TwPanelContentBlock, PageViewDefaultWrapper, TwPanelContentBlockActionButton },
  mixins: [DataListMixin, ViewPanelShowMixin],
  props: {
    complexId: {
      type: [Number, String, null],
      default: null
    },
    builderId: {
      type: [Number, String, null],
      default: null
    },
    isAllowEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: realtyEditorPlanAPI,
      filterSchemaKey: ServicesSchema.RealtyEditorPlan.key,
      columns: tableOptions,
      defaultFilters: {
        complexId: this.complexId,
        builderId: this.builderId
      }
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    viewPlanFiles (files) {
      console.warn(files?.map(f => f.url))
      this.$store.dispatch('imageViewer/showImages', {
        files: files?.map(f => {
          return {
            path: f.url
          }
        }) || [],
        startIndex: 0
      })
    },
    clickToItem (eventData) {
      // console.log('clickToItem: ', eventData)
      this.showRealtyPlanCard({ id: eventData.id, presetTabEdit: true }, () => {
        this.refresh()
      })
    },
    addNewPlan () {
      this.showPanel({
        component: 'realty-plan-show',
        props: {
          id: 0,
          workToClose: false,
          builderId: this.builderId,
          complexId: this.complexId
        },
        label: 'Планировка',
        callback: () => {
          console.log('addNewPlan callback')
          this.refresh()
        }
      })
    }
  }
}
</script>
