import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { ImageViewerState } from './types'

const namespaced = true

export const imageViewer: Module<ImageViewerState, RootState> = {
  namespaced,
  state: {
    images: [],
    startIndex: 0
  },
  getters: {
    getImages (state) {
      return state.images
    },
    getStartIndex (state) {
      return state.startIndex
    }
  },
  actions: {
    showImages ({ commit }, { files, startIndex = 0 }) {
      // console.warn(`showImages(): ${files} ${startIndex}`)
      const images: any = []
      if (files && Array.isArray(files) && files.length) {
        for (const index in files) {
          let imagePath = '/static/img/err-image-512.png'

          // console.log(item);
          if (files[index] && files[index].path) {
            imagePath = `${files[index].path}` // http://anflat.crm3:8080/storage/
          }

          images.push(imagePath)
        }
      } else {
        console.warn(`WARNING: ${files} ${startIndex} BAD files argument`)
      }
      commit('SET_IMAGES', { images, startIndex })
    }
  },
  mutations: {
    SET_IMAGES (state, { images, startIndex }) {
      state.startIndex = startIndex
      state.images = images
    },
    CLEAR_IMAGES (state) {
      state.startIndex = 0
      state.images = []
    }
  }
}
