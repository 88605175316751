<template>
  <div class="tw-px-[15px] tw-pt-[15px]">
    <TwInputText
      ref="title"
      v-model="form.title"
      type="text"
      label="Заголовок"
      placeholder="Введите заголовок"
      :disabled="formProcessing"
      :dense="true"
      :disable-error-border="true"
      :show-label="true"
      :error-text="!form.title ? 'Обязательное поле':''"
      :persistent-label="true"
    />
    <div class="tw-flex tw-mt-[12px]">
      <TwInputText
        v-model="form.notificationAt"
        class="tw-flex-1"
        type="datetime-local"
        placeholder="Дата/время события"
        :min="nowDate"
        :disabled="formProcessing"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
      />
      <TwCheckBox
        v-model="isRemind"
        :disabled="formProcessing"
        label="Напоминание"
      />
    </div>
    <div
      v-if="form.notificationAt && isRemind"
      title="За сколько до события нужно напомнить?"
    >
      <div class="tw-my-[10px] tw-font-medium">
        Напомнить за:
      </div>
      <InputMinHourDayFrom
        v-model="form.remindAt"
        :from-date="form.notificationAt"
      />
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[5px]"
      bg-color="white"
      :loading="formProcessing"
      :cancel-confirm="false"
      :is-sticky="false"
      :disable-success="formProcessing || !form.title"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="closeComment"
    />
  </div>
</template>

<script>

import API from '@/Api/Crm/Message/Notification/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import InputMinHourDayFrom from '@/components/input/datetime/InputMinHourDayFrom'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'FormNotificationSend',
  components: { TwCardSimpleFormSaveBlock, TwCheckBox, TwInputText, InputMinHourDayFrom },
  mixins: [FormCreateEditMixin],
  props: {
    id: {
      type: [String, Number, null],
      default: null
    },
    entity: {
      type: String,
      required: true // person | lead | deal
    },
    entityId: {
      type: [String, Number, null],
      default: null
    },
    personId: {
      type: [String, Number, null],
      default: null
    },
    active: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: null
    }
  },
  data: () => ({
    API,
    isRemind: false,
    form: {
      notificationAt: null,
      remindAt: null
    }
  }),
  computed: {
    nowDate () {
      return this.$Global.DateFormat.yyyyMmDdHhM().replace(' ', 'T')
    }
  },
  watch: {
    isRemind (newValue) {
      if (!newValue) {
        this.form.remindAt = null
      }
    },
    type: {
      handler () {
        this.$nextTick(() => {
          if (this.$refs.title) this.$refs.title.focus()
        })
      },
      immediate: true
    }
  },
  methods: {
    closeComment () {
      this.$emit('cancel')
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'person') {
              this.$set(this.form, 'personId', this.preset[key]?.id || this.personId)
            } else if ((key === 'remindAt' || key === 'notificationAt') && this.preset[key]) {
              this.$set(this.form, key, this.$Global.DateFormat.yyyyMmDdHhM(this.preset[key]).replace(' ', 'T'))
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        if (!this.preset.notificationAt) {
          // по умолчанию выставляю 9 утра следующего дня
          const now = new Date()
          let defaultDateTime = now.setDate(now.getDate() + 1)
          defaultDateTime = new Date(defaultDateTime).toISOString().substr(0, 10) + 'T09:00'
          this.$set(this.form, 'notificationAt', defaultDateTime)
        }
        if (this.form.remindAt) {
          this.isRemind = true
        }
        this.$set(this.form, 'entity', this.entity)
        this.$set(this.form, 'entityId', this.entityId.toString())
        resolve()
      })
    },
    handleSubmit () {
      if (this.formProcessing === true) return
      this.formProcessing = true
      const formData = { ...{}, ...this.form }
      formData.remindAt = (this.form?.remindAt && this.$Global.DateFormat.yyyyMmDdHhM(this.form.remindAt).replace('T', ' ')) || null
      formData.notificationAt = (this.form?.notificationAt && this.$Global.DateFormat.yyyyMmDdHhM(this.form.notificationAt).replace('T', ' ')) || null
      formData.type = this.type
      const request = this.formType === 'create'
        ? API.store(formData)
        : API.update(this.targetId, formData)

      request.then(({ data }) => {
        if (this.formType === 'create') {
          this.$emit('create', data.data)
          this.$emit('cancel')
          this.$emit('success')
        } else {
          this.$emit('update', data.data)
          this.$emit('cancel')
          this.$emit('success')
        }
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>

<style scoped>
.form-input {
  padding: 4px;
  border: 1px solid red;
}
.form-notification {
  background-color: white;
  padding: 20px 20px 10px 20px
}
.buttons-notification{
  padding-top: 10px;
  border-top: 1px solid #f0f1f2;
}
</style>
