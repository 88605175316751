<template>
  <div
    v-if="updatesInfo.uiVisible"
    class="tw-fixed tw-bottom-[30px] tw-right-[60px] tw-z-50 tw-bg-an-flat-gray-15 tw-rounded-[20px] tw-w-[400px] tw-p-[23px] tw-border tw-border-solid tw-border-[#E3E7EB] tw-shadow-[0_0_10px_3px_rgba(227,231,235,1)]"
  >
    <div class="tw-flex tw-justify-between tw-relative">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
        >
          <g clip-path="url(#clip0_41_1877)">
            <path
              d="M26.6854 6.3895L23.6553 9.93494C21.9154 8.29536 19.5733 7.28864 17 7.28864C11.6457 7.28864 7.28873 11.6456 7.28873 16.9999C7.28873 17.2158 7.2967 17.4302 7.31064 17.6427H9.46022C10.1781 17.6427 10.5619 18.4881 10.0904 19.0287L8.22838 21.1643L5.14911 24.6971L4.29114 23.713L0.2078 19.0287C-0.264351 18.4881 0.120143 17.6427 0.837998 17.6427H2.64824C2.63894 17.4296 2.63363 17.2151 2.63363 16.9999C2.63363 13.1623 4.12778 9.55443 6.84115 6.84106C9.55452 4.12769 13.1624 2.63354 17 2.63354C20.6185 2.63354 24.0325 3.963 26.6854 6.3895Z"
              fill="#4A5664"
            />
            <path
              d="M33.1622 17.6823H31.3507C31.1847 21.2636 29.7111 24.6065 27.1591 27.1592C24.4457 29.8725 20.8379 31.3667 17.0002 31.3667C13.6972 31.3667 10.5648 30.259 8.02539 28.2197L11.0562 24.6742C12.7004 25.9506 14.763 26.7116 17.0002 26.7116C22.1255 26.7116 26.3357 22.7199 26.6869 17.6823H24.54C23.8222 17.6823 23.4383 16.8376 23.9098 16.2964L26.1451 13.7324L28.8511 10.6279L30.747 12.8027L33.7924 16.2964C34.2646 16.8376 33.8801 17.6823 33.1622 17.6823Z"
              fill="#4A5664"
            />
            <path
              d="M23.6552 9.93491C21.9154 8.29534 19.5732 7.28862 17 7.28862V2.63354C20.6185 2.63354 24.0324 3.963 26.6854 6.38948L23.6552 9.93491Z"
              fill="#7B838D"
            />
            <path
              d="M33.162 17.6823H31.3504C31.1844 21.2636 29.7109 24.6065 27.1589 27.1592C24.4455 29.8725 20.8376 31.3667 17 31.3667V26.7116C22.1253 26.7116 26.3354 22.7199 26.6867 17.6823H24.5398C23.8219 17.6823 23.4381 16.8376 23.9096 16.2964L26.1448 13.7324L28.8509 10.6279L30.7468 12.8027L33.7922 16.2964C34.2644 16.8376 33.8799 17.6823 33.162 17.6823Z"
              fill="#7B838D"
            />
          </g>
          <defs>
            <clipPath id="clip0_41_1877">
              <rect
                width="34"
                height="34"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <button
        :disabled="pending"
        class="tw-group tw-absolute tw-right-[-6px] tw-top-[-6px] tw-rounded-full tw-border tw-h-[22px] tw-w-[22px] tw-cursor-pointer hover:tw-border-an-flat-red-main"
        title="Закрыть"
        @click.stop="onCancelUpdate"
      >
        <svg
          class="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2"
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <g clip-path="url(#clip0_41_1899)">
            <path
              class="group-hover:tw-fill-an-flat-red-main"
              d="M11 0C4.93192 0 0 4.93192 0 11C0 17.0681 4.93192 22 11 22C17.0681 22 22 17.0681 22 11C22 4.93192 17.0681 0 11 0ZM11 20.8897C5.55164 20.8897 1.11033 16.4484 1.11033 11C1.11033 5.55164 5.55164 1.11033 11 1.11033C16.4484 1.11033 20.8897 5.55164 20.8897 11C20.8897 16.4484 16.4484 20.8897 11 20.8897Z"
              fill="#4B5763"
              fill-opacity="0.48"
            />
            <path
              class="group-hover:tw-fill-an-flat-red-main"
              d="M11.7757 10.9483L15.0034 7.72059C15.2099 7.51402 15.2099 7.15252 15.0034 6.94595C14.7968 6.73937 14.4353 6.73937 14.2287 6.94595L11.001 10.1736L7.77333 6.94595C7.56675 6.73937 7.20525 6.73937 6.99868 6.94595C6.79211 7.15252 6.79211 7.51402 6.99868 7.72059L10.2264 10.9483L6.99868 14.1502C6.79211 14.3567 6.79211 14.7182 6.99868 14.9248C7.10197 15.0281 7.2569 15.0797 7.386 15.0797C7.51511 15.0797 7.67004 15.0281 7.77333 14.9248L11.001 11.6971L14.2287 14.9248C14.332 15.0281 14.4869 15.0797 14.6161 15.0797C14.7452 15.0797 14.9001 15.0281 15.0034 14.9248C15.2099 14.7182 15.2099 14.3567 15.0034 14.1502L11.7757 10.9483Z"
              fill="#4B5763"
              fill-opacity="0.48"
            />
          </g>
          <defs>
            <clipPath id="clip0_41_1899">
              <rect
                width="22"
                height="22"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
    <div class="tw-mt-[9px] tw-mb-[20px]">
      <div class="tw-text-an-flat-loader-default tw-font-bold tw-text-[20px] tw-mb-[2px]">
        Доступна новая версия CRM
      </div>
      <div class="tw-flex tw-flex-row tw-gap-[14px] tw-text-an-flat-loader-default tw-font-medium tw-text-main-sm">
        <div>
          Ваша версия: <span class="tw-text-an-flat-red-main tw-text-[14px]">{{ currentVersion }}</span>
        </div>
        <div>
          Новая версия: <span
            class="tw-text-an-flat-green-btn-bg tw-text-[14px]"
            :class="[loadingVersion ? 'tw-blur-[3px]':'']"
          >{{ actualVersion }}</span>
        </div>
      </div>
      <div class="tw-text-an-flat-grey-3 tw-font-normal tw-text-[14px] tw-mt-[6px]">
        Обновите страницу, чтобы получить последние изменения. Без обновления некоторые функции CRM «ФЛЭТ» могут быть недоступны
      </div>
    </div>
    <div class="tw-flex tw-justify-between tw-items-center tw-gap-[14px]">
      <button
        :disabled="pending"
        title="Обновить до последней версии"
        class="tw-flex-1 tw-text-white tw-bg-an-flat-blue-dark-2 tw-font-semibold
              tw-text-[14px] tw-rounded-[8px] tw-px-5 tw-py-[8px]
              enabled:hover:tw-bg-an-flat-message-block-active enabled:hover:tw-shadow-[0_1px_11.5px_0_rgba(74,86,100,0.81)]
              enabled:active:tw-bg-an-flat-message-block-active enabled:active:tw-shadow-0 enabled:active:tw-bg-opacity-80
              disabled:tw-bg-opacity-50"
        @click.stop="refreshApp"
      >
        <template v-if="pending">
          <TwCircleLoader
            border-color-class="tw-border-white"
            border-width="2px"
            width="16px"
            height="16px"
          />
        </template>
        <template v-else>
          Обновить
        </template>
      </button>
      <button
        :disabled="pending"
        title="Обновиться позже"
        class="tw-flex-1 tw-text-an-flat-black-table tw-bg-white tw-font-medium
              tw-text-[14px] tw-rounded-[8px] tw-px-5 tw-py-[8px]
              enabled:hover:tw-bg-an-flat-not-active-input enabled:hover:tw-text-white
              enabled:active:tw-bg-an-flat-not-active-input enabled:active:tw-text-white enabled:active:tw-bg-opacity-70
              disabled:tw-bg-opacity-50"
        @click.stop="onCancelUpdate"
      >
        Не сейчас
      </button>
    </div>
  </div>
</template>

<script>
import UpdateApp from '@/mixins/UpdateApp'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'TwUpdateAlert',
  components: { TwCircleLoader },
  mixins: [UpdateApp]
}
</script>

<style scoped>

</style>
