export const routeKey = 'distribution-text'

export default {
  path: `${routeKey}`,
  name: routeKey,
  redirect: { name: `${routeKey}-list` },
  meta: { name: 'Выгрузка тексты размещений', icon: 'advertising-distribution-text' },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${routeKey}-list`,
      meta: { name: 'Список текстов выгрузки', icon: 'advertising-distribution-text', hideInMenu: true },
      component: () => import('@/views/ObjectStorage/Ads/DistributionText/List.vue')
    },
    {
      path: ':id/edit',
      name: `${routeKey}-edit`,
      meta: { name: 'Редактирование текста выгрузки', icon: 'advertising-distribution-text', hideInMenu: true },
      component: () => import('@/views/ObjectStorage/Ads/DistributionText/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${routeKey}-create`,
      meta: { name: 'Добавление текста выгрузки', icon: 'advertising-distribution-text', hideInMenu: true },
      component: () => import('@/views/ObjectStorage/Ads/DistributionText/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    }
  ]
}
