<template>
  <v-toolbar
    color="transparent"
    elevation="0"
    short
    dense
  >
    <v-text-field
      v-model="fields.search"
      :solo="!disabled"
      :outlined="disabled"
      class="pa-0"
      :disabled="disabled"
      clearable
      full-width
      label="Поиск..."
      :rounded="false"
      :loading="loading"
      hide-no-data
      hide-details
      :dense="true"
      @input="changeField"
      @keypress.enter="changeField"
    />
  </v-toolbar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import FiltersMixin from '@/components/filters/FiltersMixin'

export default {
  name: 'Filters',
  mixins: [FiltersMixin],
  props: {
    fromPanel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    initialFilters: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showFilterPanel: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fields: {
        search: null
      },
      loading: false
    }
  },
  methods: {
    ...mapMutations({
      setFiltersByKey: 'filters/SET_FILTER_BY_KEY',
      clearFiltersByKey: 'filters/CLEAR_FILTER_BY_KEY',
      setCurrentFilterParams: 'filters/SET_CURRENT_FILTER_PARAMS'
    }),
    clickFilterIcon () {
      const payloadData = {
        visible: true,
        type: this.type,
        props: {}
      }
      this.setCurrentFilterParams(payloadData)
    },
    // Инициируем значения фильтров из URL query
    initFilters () {
      let fields = {}
      // если компонент открыт из панели то не учитываю URL параметры
      if (!this.fromPanel) {
        fields = this.$Global.RouterUrlManager.get()
      }
      fields = { ...this.initialFilters, ...fields }
      console.warn('initFilters ', this.type, fields)
      if (fields && Object.keys(fields).length) {
        this.setFiltersByKey({
          type: this.type,
          fields
        })
      }
      this.fields.search = fields.search || ''
    }
  },
  computed: {
    ...mapGetters({
      getFiltersByKey: 'filters/getFiltersByKey'
    }),
    currentFilters () {
      return this.getFiltersByKey(this.type)
    }
  },
  created () {
    // подписываюсь на событие отработки мутаций
    // если мутация нужная то запускаю перезапрос списка по обновлённым данным
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      // console.warn('subscribe', mutation, this.type)
      if (this.type && mutation.type === 'filters/SET_FILTER_BY_KEY' && mutation.payload && mutation.payload.type === this.type && state.filters[this.type]) {
        console.warn('$store.subscribe ', this.type, state.filters[this.type], mutation)
        this.$emit('change', state.filters[this.type])

        // если компонент открыт из панели то не изменяю URL параметры
        if (!this.fromPanel) {
          this.$Global.RouterUrlManager.set(state.filters[this.type])
        }
      }
    })
    // подписываюсь на хук уничтожения компонента, и прекращаю подписку на событие
    this.$on('hook:beforeDestroy', () => {
      // очищаю фильтры
      this.clearFiltersByKey({
        type: this.type
      })
      this.unsubscribe()
    })
  },
  mounted () {
    this.initFilters()
  }
}
</script>
