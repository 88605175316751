<template>
  <div
    :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
    class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
  >
    <template v-if="isCardContentVisible">
      <TwPanelTitle v-if="fromPanel">
        <template #left>
          <div
            v-if="data"
            class="tw-text-h4 tw-font-bold"
          >
            <span>{{ data.name }}</span>
          </div>
          <div
            v-else-if="isNew"
            class="tw-text-h4 tw-font-bold tw-text-an-flat-disabled-text"
          >
            Новый Жилой Массив
          </div>
          <div v-else>
            ~
          </div>
        </template>
        <template #right>
          <div
            v-if="data && data.type"
            class="tw-text-h4 tw-font-normal tw-text-an-flat-disabled-text"
          >
            {{ data.type.label }}
          </div>
        </template>
      </TwPanelTitle>
      <TwPanelTabsFull
        v-model="currentTab"
        :items="tabList"
      >
        <template #content.main>
          <TwPanelContent :wrapper-classes="['tw-grid tw-gap-[8px]', editMain ? 'tw-grid-cols-1':'tw-grid-cols-2']">
            <div class="tw-flex-1">
              <TwPanelContentBlock class="tw-my-[6px]">
                <template #title>
                  <div>
                    О комплексе
                  </div>
                </template>
                <template #action>
                  <button
                    :disabled="isNew || !isAllowEdit"
                    class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
                    @click="editMain = !editMain"
                  >
                    {{ editMain ? 'отменить':'изменить' }}
                  </button>
                </template>
                <div>
                  <div v-if="editMain">
                    <TwSelect
                      v-if="isNew"
                      :value="defaultFormFields.type"
                      placeholder="Тип"
                      label="Тип"
                      :disabled="!isNew"
                      :return-object="false"
                      :multi="false"
                      :dense="true"
                      :cleanable="true"
                      :persistent-label="true"
                      :error-text="defaultFormFields.type ? '' : 'Обязательное поле'"
                      :options="RealtyComplexTypesOptions"
                      @input="defaultFormFields.type = $event"
                    />
                    <RealtyComplexMainForm
                      v-if="defaultFormFields.type"
                      :id="data ? data.id : 0"
                      ref="EditMain"
                      :default-form-fields="defaultFormFields"
                      @create="onCreated"
                      @update="onUpdated"
                      @cancel="editMain = false"
                    />
                  </div>
                  <RealtyComplexMainShow
                    v-else
                    :dictionary="dictionary"
                    :scheme="scheme"
                    :info="data"
                    @refresh="refresh"
                  />
                </div>
              </TwPanelContentBlock>

              <TwPanelContentBlock
                v-if="!isNew"
                class="tw-my-[6px]"
                :content-classes="''"
              >
                <template #title>
                  <div>
                    Объекты на карте
                  </div>
                </template>
                <RealtyComplexBuildingMapMarking
                  v-if="data && Array.isArray(data.allObjectsMapPolygon) && data.allObjectsMapPolygon.length"
                  :view-only="true"
                  :buildings-list="data.allObjectsMapPolygon || []"
                  :value="[]"
                />
                <div
                  v-else
                  class="tw-text-center tw-text-an-flat-disabled-text"
                >
                  Нет отмеченных объектов<br>
                  <small>* Отредактируйте объекты ЖК, указав расположение объектов на карте</small>
                </div>
              </TwPanelContentBlock>
            </div>
            <div
              v-if="!isNew && !editMain && data && data.id"
              class="tw-flex-1"
            >
              <RealtyComplexInvestAppeal
                :complex-id="data.id"
              />
              <RealtyComplexGallery
                :dictionary="dictionary"
                :info="data"
                :is-allow-edit="isAllowEdit"
                @refresh="refresh"
              />
              <RealtyComplexAdvantages
                :id="data.id"
                :is-allow-edit="isAllowEdit"
              />
              <RealtyComplexDescription
                :dictionary="dictionary"
                :info="data"
                :is-allow-edit="isAllowEdit"
                @refresh="refresh"
              />
            </div>
          </TwPanelContent>
        </template>
        <template #content.objects>
          <RealtyComplexObjectsTab
            :complex-id="data.id"
            :complex-type="complexType"
          />
        </template>
        <template #content.renderComplex>
          <RealtyComplexRenderPlan :complex-id="data.id" />
        </template>
        <template #content.constructionProgress>
          <RealtyComplexConstructionProgress
            :complex-id="data.id"
            :is-allow-edit="isAllowEdit"
          />
        </template>
        <template #content.plans>
          <RealtyPlansBlockList
            :complex-id="data.id"
            :is-allow-edit="isAllowEdit"
          />
        </template>
      </TwPanelTabsFull>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import CardMixin from '@/mixins/CardMixin'
import RealtyComplexMainForm from '@/views/Newbuildings/Complex/main/RealtyComplexMainForm'
import RealtyComplexMainShow from '@/views/Newbuildings/Complex/main/RealtyComplexMainShow'
import RealtyComplexGallery from '@/views/Newbuildings/Complex/gallery/RealtyComplexGallery'
import RealtyComplexDescription from '@/views/Newbuildings/Complex/description/RealtyComplexDescription'
import RealtyComplexRenderPlan from '@/views/Newbuildings/Complex/highlight-buildings-plan/RealtyComplexRenderPlan.vue'
import { mapGetters } from 'vuex'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import RealtyPlansBlockList from '@/views/Newbuildings/Editor/Plans/RealtyPlansBlockList.vue'
import RealtyComplexConstructionProgress
  from '@/views/Newbuildings/Complex/constructionProgress/RealtyComplexConstructionProgress'
import dictPermissionRules from '@/Permissions/dictRules'
import RealtyComplexAdvantages from '@/views/Newbuildings/Complex/advantages/RealtyComplexAdvantages.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import PermissionDenied from '@/views/PermissionDenied.vue'
import RealtyComplexBuildingMapMarking from '@/views/Newbuildings/Editor/OtherComponents/Map/RealtyComplexBuildingMapMarking.vue'
import { RealtyComplexTypesOptions } from '@/views/Newbuildings/Editor/typesDict'
import RealtyComplexObjectsTab from '@/views/Newbuildings/Complex/buildings/RealtyComplexObjectsTab.vue'
import RealtyComplexInvestAppeal from './InvestAppeal/RealtyComplexInvestAppeal.vue'

export default {
  name: 'RealtyComplexCard',
  components: {
    RealtyComplexInvestAppeal,
    RealtyComplexObjectsTab,
    RealtyComplexBuildingMapMarking,
    PermissionDenied,
    TwPanelTitle,
    TwCircleLoader,
    TwPanelTabsFull,
    TwSelect,
    TwPanelContent,
    TwPanelContentBlock,
    RealtyComplexAdvantages,
    RealtyComplexConstructionProgress,
    RealtyPlansBlockList,
    RealtyComplexRenderPlan,
    RealtyComplexDescription,
    RealtyComplexGallery,
    RealtyComplexMainShow,
    RealtyComplexMainForm
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  data () {
    return {
      currentTab: 'main',
      API: API.Complex,
      defaultFormFields: { type: null },
      RealtyComplexTypesOptions
    }
  },
  methods: {
    postFetch () {
      this.defaultFormFields.type = this.data.type?.value || null
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    complexType () {
      return this.data?.type?.value || null
    },
    tabList () {
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'Объекты',
          key: 'objects',
          disabled: this.isNew
        },
        {
          label: 'Планировки',
          key: 'plans',
          disabled: this.isNew,
          hidden: this.complexType !== 'residential_complex'
        },
        {
          label: 'Ход строительства',
          key: 'constructionProgress',
          disabled: this.isNew
        },
        {
          label: 'Рендер ЖК',
          key: 'renderComplex',
          disabled: this.isNew || !this.isAdmin,
          hidden: this.complexType !== 'residential_complex'
        }
      ]
    },
    isAllowEdit () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesOBJECTSAdvertingEditComplexPlatformRule] ||
        !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsRealtyEditRealtyNbRule]
    }
  }
}
</script>
