<template>
  <div
    v-if="loading && !data"
    class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
  >
    <TwCircleLoader
      height="50px"
      width="50px"
    />
  </div>
  <div v-else>
    <div
      :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
      class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
    >
      <TwPanelTitle v-if="fromPanel">
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div v-if="data">
              <span>{{ $Global.peopleName(data, 'fi') }}</span>
              <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
            </div>
            <div
              v-else-if="isNew"
              class="title text-muted"
            >
              Новый пользователь
            </div>
            <div v-else>
              ~
            </div>
          </div>
        </template>
      </TwPanelTitle>
      <TwPanelTabsFull
        v-model="currentTab"
        :items="tabList"
      >
        <template #content.main>
          <TwPanelContent :class="greyWrapper">
            <!-- LEFT -->
            <div
              v-if="data"
              class="tw-flex-1"
            >
              <!-- Базовая информация и манипуляция аватаром пользователя -->
              <TwAvatarAndInfo
                v-model="data.file"
                :employee="data"
                :is-my-profile="isMyProfile"
                @change="onUpdateAvatar"
              />
              <!-- Администрирование -->
              <TwPanelContentBlock
                v-if="data && data.active && isAdmin"
                class="tw-mb-[6px] tw-bg-white"
                style-hr="tw-bg-an-flat-hr-border-grey"
                content-classes="''"
              >
                <template #title>
                  <div>
                    Администрирование
                  </div>
                </template>
                <div class="tw-flex tw-flex-col tw-gap-[12px] tw-font-medium">
                  <div>
                    <button
                      v-if="!subAuth"
                      class="tw-bg-an-flat-blue-btn3 tw-text-an-flat-table-color-black-blue tw-w-full tw-px-2 tw-py-[7px] tw-rounded-sm tw-border tw-border-solid enabled:hover:tw-text-an-flat-black-main enabled:hover:tw-bg-an-flat-bg-main disabled:tw-opacity-50"
                      :disabled="isMyProfile || authThisUserProcess || subAuth"
                      @click="onAuthThisUser"
                    >
                      Авторизоваться под пользователем
                    </button>
                    <TwAdminSubAuth v-else />
                  </div>
                  <div v-if="isMyProfile && data && data.id === 1">
                    <button
                      class="tw-bg-an-flat-red-light tw-text-white tw-w-full tw-px-2 tw-py-[7px] tw-rounded-sm tw-border tw-border-solid enabled:hover:tw-text-an-flat-black-main enabled:hover:tw-bg-an-flat-bg-main disabled:tw-opacity-50"
                      @click="onGenError"
                    >
                      Генерация ошибки
                    </button>
                  </div>
                  <div v-if="isMyProfile && data && data.id === 1">
                    <button
                      class="tw-bg-an-flat-orange-warning tw-text-white tw-w-full tw-px-2 tw-py-[7px] tw-rounded-sm tw-border tw-border-solid enabled:hover:tw-text-an-flat-black-main enabled:hover:tw-bg-an-flat-bg-main disabled:tw-opacity-50"
                      @click="onSendSocketMessage"
                    >
                      onSendSocketMessage
                    </button>
                  </div>
                </div>
              </TwPanelContentBlock>
              <!-- Внешние сервисы -->
              <TwPanelContentBlock
                v-if="data && data.active"
                class="tw-mb-[6px] tw-bg-white"
                style-hr="tw-bg-an-flat-hr-border-grey"
                content-classes="''"
              >
                <template #title>
                  <div>
                    Внешние сервисы
                  </div>
                </template>
                <TwPanelContentBlockFieldView>
                  <template #title>
                    Телеграм
                  </template>
                  <div v-if="telegramDataForUserLogin">
                    <TelegramLoginWidget
                      mode="redirect"
                      request-access="write"
                      size="medium"
                      :redirect-url="telegramDataForUserLogin.authCallback"
                      :telegram-login="telegramDataForUserLogin.botName"
                    />
                    <div
                      v-if="telegramDataForUserLogin && telegramDataForUserLogin.userBlocked"
                      class="error--text"
                    >
                      Вы заблокировали Бота. Повторите авторизацию
                    </div>
                    <div
                      v-if="telegramDataForUserLogin"
                      class="tw-mt-[6px] tw-pt-[10px] tw-border-t tw-border-solid"
                    >
                      <strong>
                        Авторизация в <a
                          :href="`https://t.me/${telegramDataForUserLogin.botName}`"
                          target="_blank"
                        >боте</a>
                      </strong>
                      <small style="color: grey"> (Меню -> Авторизация)</small>:<br>
                      <div
                        class="tw-flex tw-items-center tw-mt-1"
                        style="gap: 10px"
                      >
                        <div>
                          <strong>Email:</strong>
                        </div>
                        <div>
                          {{ telegramDataForUserLogin.userEmail }}
                        </div>
                        <ClickBoard
                          v-if="telegramDataForUserLogin.userEmail"
                          :value="telegramDataForUserLogin.userEmail"
                          :disabled="false"
                          :small-btn="true"
                          icon-size="10"
                        />
                      </div>
                      <div
                        class="tw-flex tw-items-center"
                        style="gap: 10px"
                      >
                        <div>
                          <strong>Код:</strong>
                        </div>
                        <div>
                          {{ telegramDataForUserLogin.authCode }}
                        </div>
                        <ClickBoard
                          v-if="telegramDataForUserLogin.authCode"
                          :value="telegramDataForUserLogin.authCode"
                          :disabled="false"
                          :small-btn="true"
                          icon-size="10"
                        />
                      </div>
                    </div>
                    <small
                      v-if="telegramDataForUserLogin"
                      style="color: grey"
                    >
                      <b>Отключение:</b>
                      <i>
                        {{ telegramDataForUserLogin.buttonMessage }}
                      </i>
                    </small>
                  </div>
                </TwPanelContentBlockFieldView>
              </TwPanelContentBlock>
              <!-- Публикация на сайте -->
              <EmployeeSiteBlock
                v-if="data"
                :user-data="data"
                @refresh="refresh"
              />
              <!-- Социальные сети сотрудника -->
              <UserSocialMediaBlock
                v-if="data"
                :user-data="data"
                @refresh="refresh"
              />
            </div>
            <div
              v-else
              class="tw-flex-1 tw-text-center tw-pt-[24px]"
            >
              Загрузка фото и другие возможности откроются после сохранения
            </div>
            <!-- RIGHT -->
            <div class="tw-flex-1 tw-min-w-[500px]">
              <!-- Информация -->
              <TwPanelContentBlock
                class="tw-mb-[6px] tw-bg-white"
                style-hr="tw-bg-an-flat-hr-border-grey"
                content-classes="''"
              >
                <template #title>
                  <div>
                    Информация
                  </div>
                </template>
                <template #action>
                  <TwPanelContentBlockActionButton
                    :disabled="isNew"
                    type="edit"
                    @click="editMain = !editMain"
                  >
                    {{ editMain ? 'отменить':'изменить' }}
                  </TwPanelContentBlockActionButton>
                </template>
                <div class="tw-flex tw-flex-col tw-gap-[12px]">
                  <div class="tw-mb-[12px] tw-flex tw-items-center tw-gap-[10px] tw-p-[10px] tw-text-an-flat-red-main tw-bg-an-flat-red-main/5 tw-rounded-sm">
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 0C4.52973 0 0.5 4.02973 0.5 9C0.5 13.9708 4.52973 18 9.5 18C14.4708 18 18.5 13.9714 18.5 9C18.5 4.02973 14.4708 0 9.5 0ZM9.5 16.3125C5.46127 16.3125 2.18753 13.0387 2.18753 9C2.18753 4.96127 5.46127 1.68753 9.5 1.68753C13.5387 1.68753 16.8125 4.96127 16.8125 9C16.8125 13.0387 13.5387 16.3125 9.5 16.3125ZM9.50057 3.94705C8.85596 3.94705 8.36823 4.28344 8.36823 4.8268V9.81054C8.36823 10.3545 8.8559 10.6897 9.50057 10.6897C10.1294 10.6897 10.6329 10.3404 10.6329 9.81054V4.8268C10.6328 4.29634 10.1294 3.94705 9.50057 3.94705ZM9.50057 11.8125C8.88126 11.8125 8.37723 12.3165 8.37723 12.9363C8.37723 13.5551 8.88126 14.0591 9.50057 14.0591C10.1199 14.0591 10.6233 13.5551 10.6233 12.9363C10.6233 12.3165 10.1199 11.8125 9.50057 11.8125Z"
                        fill="#E63114"
                      />
                    </svg>
                    Для вывода информации (ФИО, дата рождения, номер телефона) на сайт, данные необходимо изменить в Битриксе
                  </div>
                  <EmployeeMainForm
                    v-if="editMain"
                    :id="data ? data.id : 0"
                    ref="EditMain"
                    :is-my-profile="isMyProfile"
                    @create="onCreated"
                    @update="onUpdated"
                    @cancel="editMain = false"
                    @refresh="refresh"
                  />
                  <EmployeeMainShow
                    v-else
                    :dictionary="dictionary"
                    :info="data"
                    @refresh="refresh"
                  />
                </div>
              </TwPanelContentBlock>
              <!-- Обо мне -->
              <EmployeeAboutBlock
                v-if="data"
                :user-data="data"
                @refresh="refresh"
              />
              <!-- Теги по интересам -->
              <UserTagsBlock
                v-if="data && isAdmin"
                :user-id="data.id"
                :allow-edit="itsMeLocalRule"
              />
            </div>
          </TwPanelContent>
        </template>
        <template #content.settings>
          <TwPanelContent v-if="data">
            <div class="tw-flex-1">
              <!-- Двухфакторная аутентификация -->
              <TwoFactorBlock
                :user-id="data.id"
                :is-my-profile="isMyProfile"
              />
            </div>
            <div class="tw-flex-1">
              <!-- Иное -->
              <TwPanelContentBlock
                class="tw-mb-[6px]"
                no-data-text="В разработке..."
              >
                <template #title>
                  <div>
                    Иное
                  </div>
                </template>
              </TwPanelContentBlock>
            </div>
          </TwPanelContent>
          <TwPanelContent v-if="data">
            <div class="tw-flex-1">
              <UserEmailConfigList
                :user-id="data.id"
                @refresh="refresh"
                @update="onUpdated(data)"
              />
            </div>
          </TwPanelContent>
        </template>
        <template #content.rules>
          <UserRulesTab
            v-if="data && data.id"
            :user-id="data.id"
          />
        </template>
        <template #content.deals>
          <EmployeeDealList
            v-if="data"
            :employee-id="data.id"
          />
        </template>
      </TwPanelTabsFull>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Auth/User/index'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import messengerSystemAPI from '@/Api/Messenger/PlatformSystem/index'
import EmployeeMainForm from '@/views/Company/Employee/main/EmployeeMainForm'
import EmployeeMainShow from '@/views/Company/Employee/main/EmployeeMainShow'
import UserEmailConfigList from '@/views/Company/Employee/settings/UserEmailConfig/UserEmailConfigList'
import CardMixin from '@/mixins/CardMixin'
import TwoFactorBlock from '@/views/Company/Employee/settings/TwoFactorBlock'
import UserRulesTab from '@/views/Company/Employee/settings/UserPermissions/UserRulesTab'
import EmployeeAboutBlock from '@/views/Company/Employee/about/EmployeeAboutBlock'
import EmployeeSiteBlock from '@/views/Company/Employee/site/EmployeeSiteBlock'
import { SOCKET_EVENT_MAP } from '@/plugins/SocketIoConfig'
import TelegramLoginWidget from '@/views/Company/Employee/externalServices/TelegramLoginWidget'
import ClickBoard from '@/components/button/ClickBoard.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwAvatarAndInfo from '@/views/Company/Employee/main/TwAvatarAndInfo.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwAdminSubAuth from '@/components/tw-ui/toolbar/TwAdminSubAuth.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import UserSocialMediaBlock from '@/views/Company/Employee/social-media/UserSocialMediaBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import dictPermissionRules from '@/Permissions/dictRules'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import UserTagsBlock from '@/views/Company/Employee/user-tags/UserTagsBlock.vue'
import { cardWrapStyles } from '@/utils/tw-classes'
import EmployeeDealList from '@/views/Company/Employee/Deals/EmployeeDealList.vue'

export default {
  name: 'EmployeeCard',
  components: {
    EmployeeDealList,
    UserTagsBlock,
    TwPanelContentBlockActionButton,
    TwPanelContent,
    TwCircleLoader,
    UserSocialMediaBlock,
    TwPanelContentBlockFieldView,
    TwAdminSubAuth,
    TwPanelContentBlock,
    TwAvatarAndInfo,
    TwPanelTabsFull,
    TwPanelTitle,
    ClickBoard,
    TelegramLoginWidget,
    EmployeeSiteBlock,
    EmployeeAboutBlock,
    UserRulesTab,
    TwoFactorBlock,
    UserEmailConfigList,
    EmployeeMainShow,
    EmployeeMainForm
  },
  mixins: [CardMixin],
  data () {
    return {
      API: API,
      authThisUserProcess: false,
      telegramDataForUserLogin: null,
      currentTab: this.presetTab || 'main',
      greyWrapper: cardWrapStyles.greyWrapper
    }
  },
  methods: {
    ...mapMutations({
      updateCacheUserData: 'users/SET_USER'
    }),
    ...mapActions({
      subAuthLogIn: 'profile/subAuthLogIn',
      refreshMyProfileData: 'profile/checkToken'
    }),
    onGenError () {
      JSON.parse('{///}')
    },
    onAuthThisUser () {
      this.authThisUserProcess = true
      this.subAuthLogIn({
        id: this.data.id,
        info: this.data
      })
        .then(() => {
          //
        })
        .finally(() => {
          this.authThisUserProcess = false
        })
    },
    onUpdateAvatar () {
      this.refresh()
      this.$emit('callback', {})
    },
    onSendSocketMessage () {
      this.$socket.emit(SOCKET_EVENT_MAP.OUT.message, 'TODO: ' + Math.random())
    },
    postFetch (data) {
      this.updateCacheUserData({
        id: data.id,
        data: data
      })

      this.$nextTick(() => {
        this.telegramDataForUserLogin = null
        if (this.isMyProfile) {
          messengerSystemAPI.telegramUserButton().then(({ data }) => {
            console.warn(data)
            this.telegramDataForUserLogin = data?.data || null
          }).catch((error) => {
            console.error('messengerSystemAPI.telegramUserButton()', error)
          })
        }
      })
    },
    refresh (event) {
      console.warn('refresh', this.$options.name, event)
      if (this.isNew) {
        this.editMain = true
        console.log('NEW', this.$options.name)
      } else {
        this.fetch()
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict',
      subAuth: 'profile/subAuth',
      subUserInfo: 'profile/getSubAuthUser',
      userInfo: 'profile/getUser'
    }),
    tabList () {
      return [
        {
          label: 'Профиль',
          key: 'main'
        },
        {
          label: 'Настройки',
          key: 'settings',
          disabled: this.isNew || !this.itsMeLocalRule
        },
        {
          label: 'Права доступа',
          key: 'rules',
          disabled: this.isNew || !(this.allowUpdateRules || this.isMyProfile)
        },
        {
          label: 'Сделки',
          key: 'deals',
          disabled: this.isNew,
          hidden: !this.itsMeLocalRule
        }
      ]
    },
    itsMeLocalRule () {
      return this.isMyProfile || this.isAdmin
    },
    allowUpdateRules () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMUsersUpdateUserRule]
    },
    isMyProfile () {
      // приоритет суб авторизации
      if (this.subUserInfo) {
        return !!(this.data && this.subUserInfo && this.subUserInfo?.id === this.data?.id)
      }
      return !!(this.data && this.userInfo && this.userInfo?.id === this.data?.id)
    }
  },
  watch: {
    'userInfo.id': {
      handler () {
        this.refreshMyProfileData()
        this.$nextTick(() => {
          this.fetch()
        })
      }
    }
  }
}
</script>
