<template>
  <div>
    <div
      v-if="variant === 'plan'"
      class="tw-flex tw-justify-center tw-items-center tw-mt-[20px] entity-stream"
    >
      <div class="tw-bg-an-flat-dark-blue-link tw-rounded-lg tw-text-white tw-font-semibold tw-text-main-xs tw-py-[3px] tw-px-[12px] tw-z-0">
        Запланировано
      </div>
    </div>
    <div
      v-if="variant === 'other' && !list.length"
      class="tw-flex tw-justify-center tw-items-center tw-mt-[20px] date-stream"
    >
      <div class="tw-bg-an-flat-gray-light tw-rounded-lg tw-text-an-flat-black-table tw-font-semibold tw-text-main-xs tw-py-[3px] tw-px-[12px] tw-z-0">
        События
      </div>
    </div>
    <template v-if="loading">
      <div class="loading">
        <TwCircleLoader />
      </div>
    </template>
    <template v-else-if="!Array.isArray(list) || !list.length">
      <div v-if="variant === 'other'">
        <div class="mb-5 mt-5">
          <div class="item-icon">
            <svg
              width="34"
              height="35"
              viewBox="0 0 34 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17"
                cy="17.1768"
                r="17"
                fill="#E8ECF2"
              />
              <g clip-path="url(#clip0_308_17577)">
                <path
                  d="M17.3376 11.3257C18.3163 11.3257 19.1079 12.0645 19.1079 12.978C19.1079 13.8914 18.3163 14.6302 17.3376 14.6302C16.3589 14.6302 15.5673 13.8914 15.5673 12.978C15.5673 12.0645 16.3589 11.3257 17.3376 11.3257ZM18.9483 15.6669H18.9023H16.0551H15.9508C15.3679 15.6669 14.8984 16.1078 14.8984 16.6491C14.8984 17.1931 15.3709 17.6313 15.9508 17.6313H16.0551V23.3584H14.8984V25.3257H20.102V23.3584H18.9514V15.6669H18.9483Z"
                  fill="#7A8797"
                />
              </g>
              <defs>
                <clipPath id="clip0_308_17577">
                  <rect
                    width="15"
                    height="14"
                    fill="white"
                    transform="translate(10 10)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div class="tw-text-an-flat-black-table tw-bg-an-flat-gray-light tw-px-[10px] tw-py-[4px] tw-rounded-md">
            <p>У вас нет событий.</p>
          </div>
        </div>
      </div>
      <div v-if="variant === 'plan'">
        <div class="mb-5 mt-5">
          <div
            style="background-color: #9dcf00"
            class="item-icon"
          >
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17"
                cy="17"
                r="17"
                fill="#2D82D0"
              />
              <g clip-path="url(#clip0_308_17577)">
                <path
                  d="M17.3376 10.9722C18.3163 10.9722 19.1079 11.711 19.1079 12.6244C19.1079 13.5379 18.3163 14.2767 17.3376 14.2767C16.3589 14.2767 15.5673 13.5379 15.5673 12.6244C15.5673 11.711 16.3589 10.9722 17.3376 10.9722ZM18.9483 15.3133H18.9023H16.0551H15.9508C15.3679 15.3133 14.8984 15.7543 14.8984 16.2955C14.8984 16.8396 15.3709 17.2778 15.9508 17.2778H16.0551V23.0049H14.8984V24.9722H20.102V23.0049H18.9514V15.3133H18.9483Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_308_17577">
                  <rect
                    width="15"
                    height="14"
                    fill="white"
                    transform="translate(10 9.82324)"
                  />
                </clipPath>
              </defs>
            </svg>
            <!--            <div class="item-circle-total">1</div>-->
          </div>
          <div class="tw-text-an-flat-dark-blue-link tw-bg-an-flat-dark-blue-link/10 tw-px-[10px] tw-py-[4px] tw-rounded-md">
            <p>У вас нет запланированных дел. Передвиньте стадию сделки или запланируйте дело.</p>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Список событий хронологии -->
      <div
        v-for="(item, index) in list"
        :key="item.id"
      >
        <!-- Маркировка заголовка в зависимости от Типа и Даты  -->
        <template v-if="variant === 'other'">
          <div class="date-stream">
            <LayoutDate
              :list="list"
              :item="item"
              :index="index"
            />
          </div>
        </template>
        <!-- ШАБЛОНЫ ОТРИСОВКИ ОБЪЕКТОВ ХРОНОЛОГИИ -->
        <template v-if="item.attachEntity && item.attachEntity.value === 'comment'">
          <div class="item-wrapper mt-5">
            <div
              :style="{backgroundColor: listColors[variant].bg}"
              class="item-icon"
            >
              <v-icon
                size="18px"
                color="white"
              >
                fa-commenting-o
              </v-icon>
            </div>
            <ChronologyCommentView
              :style="{backgroundColor:`${listColors[variant].bgBody}`, borderLeft: `2px solid ${listColors[variant].bl}`}"
              :variant="variant"
              :entity-id="entityId"
              :entity="entity"
              :item="item"
              @refresh="refresh"
            />
          </div>
        </template>
        <template v-else-if="item.attachEntity && item.attachEntity.value === 'notification'">
          <div class="item-wrapper mt-5">
            <div
              :style="{backgroundColor: listColors[variant].bg}"
              class="item-icon"
            >
              <v-icon
                v-if="item.attachEntityData.type === 'outgoing_call'"
                size="18px"
                color="white"
              >
                fa-phone
              </v-icon>
              <v-icon
                v-else
                size="18px"
                color="white"
              >
                fa-map-marker
              </v-icon>
              <div
                v-if="item.expired"
                class="item-circle-total"
              >
                1
              </div>
            </div>
            <notification-show
              :style="{backgroundColor:`${listColors[variant].bgBody}`}"
              :variant="variant"
              :entity-id="entityId"
              :entity="entity"
              :item="item"
              @refresh="refresh"
            />
          </div>
        </template>
        <template v-else-if="item.attachEntity && item.attachEntity.value === 'email'">
          <div class="item-wrapper mt-5">
            <div
              :style="{backgroundColor: listColors[variant].bg}"
              class="item-icon"
            >
              <v-icon
                size="18px"
                color="white"
              >
                fa-envelope-o
              </v-icon>
            </div>
            <email-show
              :style="{backgroundColor:`${listColors[variant].bgBody}`}"
              :variant="variant"
              :entity-id="entityId"
              :entity="entity"
              :item="item"
              @refresh="refresh"
            />
          </div>
        </template>
        <template v-else-if="item.attachEntity && item.attachEntity.value === 'call'">
          <ChronologyCallView
            v-if="item.attachEntityId && item.attachEntityId.length > 10"
            :variant="variant"
            :item="item"
            @refresh="refresh"
          />
        </template>
        <template v-else-if="item.attachEntity && item.attachEntity.value && item.attachEntity.value.indexOf('changed_status') !== -1">
          <ChronologyChangeStatusView
            :variant="variant"
            :item="item"
            @refresh="refresh"
          />
        </template>
        <template v-else-if="item.attachEntity && item.attachEntity.value && item.attachEntity.value.indexOf('deal_bounce_handling') !== -1">
          <ChronologyDealBounceHandlingView
            :variant="variant"
            :item="item"
            @refresh="refresh"
          />
        </template>
        <template v-else-if="item.attachEntity && item.attachEntity.value && item.attachEntity.value.indexOf('responsible_user_history') !== -1">
          <ChronologyChangeRespUserView
            :variant="variant"
            :item="item"
            @refresh="refresh"
          />
        </template>
        <template v-else-if="item.attachEntity && item.attachEntity.value && item.attachEntity.value.indexOf('deal_object_booking') !== -1">
          <ChronologyBookingView
            :variant="variant"
            :item="item"
            @refresh="refresh"
          />
        </template>
        <template v-else-if="item.attachEntity && item.attachEntity.value && item.attachEntity.value.indexOf('update_person_data') !== -1">
          <ChronologyChangePersonDataView
            :variant="variant"
            :item="item"
            @refresh="refresh"
          />
        </template>
        <template v-else-if="item.attachEntity && item.attachEntity.value && item.attachEntity.value.indexOf('deal_related_service_request') !== -1">
          <ChronologyDealRelatedServiceRequest
            :variant="variant"
            :item="item"
            @refresh="refresh"
          />
        </template>
        <template v-else>
          <div class="my-2 white--text error pa-2">
            Нет шаблона отрисовки({{ $options.name }}):
            {{ item.attachEntity }}
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import ChronologyCommentView from '@/components/core/Chronology/ListItem/ChronologyCommentView'
import NotificationShow from '@/components/core/Chronology/notificationShow'
import EmailShow from '@/components/core/Chronology/emailShow'
import { listColors } from '@/components/core/Chronology/ListConfig'
import ChronologyCallView from '@/components/core/Chronology/ListItem/ChronologyCallView.vue'
import ChronologyChangeStatusView from '@/components/core/Chronology/ListItem/ChronologyChangeStatusView'
import ChronologyChangeRespUserView from '@/components/core/Chronology/ListItem/ChronologyChangeRespUserView'
import ChronologyBookingView from '@/components/core/Chronology/ListItem/ChronologyBookingView'
import LayoutDate from '@/components/interfaceLayoutDate/LayoutDate'
import ChronologyChangePersonDataView from '@/components/core/Chronology/ListItem/ChronologyChangePersonDataView.vue'
import ChronologyDealBounceHandlingView
  from '@/components/core/Chronology/ListItem/ChronologyDealBounceHandlingView.vue'
import ChronologyDealRelatedServiceRequest from './ListItem/ChronologyDealRelatedServiceRequest.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'ChronologyList',
  components: { TwCircleLoader, ChronologyDealBounceHandlingView, ChronologyChangePersonDataView, ChronologyCallView, ChronologyBookingView, ChronologyChangeRespUserView, ChronologyChangeStatusView, EmailShow, NotificationShow, ChronologyCommentView, LayoutDate, ChronologyDealRelatedServiceRequest },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    variant: {
      type: String,
      default: 'other' // other, plan, pin
    },
    entityId: {},
    entity: {}
  },
  data () {
    return {
      commentEdit: false
    }
  },
  computed: {
    listColors () {
      return listColors
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    }
  }
}
</script>

<style>
  p {
    margin: 0;
    padding: 0
  }
</style>
