import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = (dealId: number) => `/deal/buy-nb/${dealId}/questionnaire`

export default {
  create (params = {}, dealId: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path(dealId)}/create`, $config)
  },
  show (dealId: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(path(dealId), $config)
  },
  store (dealId: number, data: any): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.post(path(dealId), data, $config)
  },
  update (dealId: number, data: any): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.put(path(dealId), data, $config)
  }
}
