<template>
  <div>
    <v-autocomplete
      ref="select"
      v-model="select"
      attach
      type="text"
      :items="items"
      no-filter
      :clearable="clearable"
      :class="classes"
      :chips="chips"
      :loading="loading"
      :search-input.sync="searchStr"
      :hide-no-data="noDataHide"
      item-text="name"
      item-value="id"
      :placeholder="placeholder"
      return-object
      flat
      :rules="rules"
      @input="input"
      @focus="searchFromServer('')"
    >
      <template #item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template #selection="data">
        <v-chip
          small
          :color="chipColor"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import API from '@/Api/Mortgage/Program/index'
import AsyncSelectMixin from '@/components/select/AsyncSelectMixin'

export default {
  name: 'MortgageProgramAsyncSelect',
  mixins: [AsyncSelectMixin],
  data: () => ({
    API: API,
    noDataHide: true,
    searchStr: ''
  })
}
</script>

<style>
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 100%;
}
</style>
