<template>
  <div class="employee-link-block">
    <div
      v-if="!hideTitle"
      class="employee-link-block---title"
    >
      {{ title }}:
    </div>
    <div
      v-if="userId"
      class="employee-link-block---link"
    >
      <EmployeeAvatar
        :id="userId"
        size="20px"
      />
      <ViewPanelLink
        :id="userId"
        label="Пользователь"
        component="employee-show"
      >
        <EmployeeName :id="userId" />
      </ViewPanelLink>
    </div>
    <div
      v-else
      class="employee-link-block---link text-muted"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import EmployeeAvatar from '@/components/other/employee/EmployeeAvatar'
export default {
  name: 'EmployeeLinkBlock',
  components: { EmployeeAvatar, EmployeeName, ViewPanelLink },
  props: {
    title: {
      type: String,
      default: 'Сотрудник'
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.employee-link-block {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  font: 12px/21px "Helvetica Neue",Helvetica,Arial,sans-serif;

  >.employee-link-block---title {}
  >.employee-link-block---link {
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
</style>
