<template>
  <v-form ref="form">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="px-2">
      <v-select
        v-model="form.education"
        attach=""
        hide-details
        :items="dictionary.education"
        item-text="label"
        item-value="value"
        label="Образование"
      />
      <v-checkbox
        v-model="form.bankruptcy"
        hide-details
        :dense="denseFields"
        color="green"
        label="Была ли процедура банкротства"
      />
      <v-checkbox
        v-model="form.surnameChange"
        :dense="denseFields"
        color="green"
        label="Менял фамилию"
        :hide-details="!form.surnameChange"
      />
      <template v-if="form.surnameChange">
        <v-text-field
          v-model="form.surnameChangeOld"
          type="text"
          outlined
          :dense="denseFields"
          label="Старая фамилия"
          autocomplete="off"
          require
        />
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="form.surnameChangeDate"
              clearable
              :dense="denseFields"
              label="Дата изменения(гггг-мм-дд)"
              append-icon="fa-calendar"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="form.surnameChangeDate"
            :first-day-of-week="1"
            locale="ru-ru"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="dateSave"
          />
        </v-menu>
        <v-textarea
          v-model="form.surnameChangeCause"
          style="font-size: small"
          :dense="denseFields"
          hide-details
          outlined
          label="Причина изменения фамилии"
          rows="3"
        />
      </template>

      <v-checkbox
        :dense="denseFields"
        color="green"
        :input-value="isAlimony"
        label="Алименты"
        @change="alimonyChange"
      />
      <template v-if="isAlimony">
        <v-text-field
          v-model.number="form.alimony"
          type="number"
          outlined
          :dense="denseFields"
          label="Сумма"
          autocomplete="off"
          suffix="руб."
        />
      </template>
    </div>

    <CardSimpleFormSaveBlock
      :loading="formProcessing"
      cancel-confirm
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </v-form>
</template>

<script>
import API from '@/Api/Crm/Person/AdditionalInfo/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import InputPersonPhones from '@/components/input/phone/InputPersonPhones'
import InputAdditionalContacts from '@/components/input/InputAdditionalContacts'
import CompanyAsyncSelect from '@/components/select/company/CompanyAsyncSelect'
import CardSimpleFormSaveBlock from '@/components/core/ViewPanel/Card/CardSimpleFormSaveBlock'

export default {
  name: 'PersonAdditionalInfoForm',
  // eslint-disable-next-line vue/no-unused-components
  components: { CardSimpleFormSaveBlock, CompanyAsyncSelect, InputAdditionalContacts, InputPersonPhones },
  mixins: [FormCreateEditMixin],
  props: {
    personId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      form: {
        id: null,
        personId: this.personId,
        alimony: 0,
        bankruptcy: false,
        education: false,
        surnameChange: false,
        surnameChangeCause: '',
        surnameChangeDate: '',
        surnameChangeOld: ''
      },
      isAlimony: false,
      menu: false,
      serverErrors: null
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  methods: {
    alimonyChange (val) {
      if (val) {
        this.form.alimony = 0
      } else {
        this.form.alimony = null
      }
      this.isAlimony = val
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            person: 'personId'
          }
          for (const key in this.preset) {
            if (key === 'alimony' && this.preset[key] > 0) {
              this.isAlimony = true
            }
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              }
            } else if (key === 'education') {
              this.$set(this.form, key, this.preset[key]?.value || null)
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      // console.log('this.$refs.form.validate ', this.$refs.form.validate())
      if (this.$refs.form.validate()) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else {
          alert(`Error handleSubmit: ${this.$options.name}`)
        }
      }
    },
    dateSave (date) {
      this.$refs.menu.save(date)
    }
  }
}
</script>
