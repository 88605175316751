<template>
  <div>
    <v-row
      no-gutters
      dense
      class="flex-nowrap"
      justify="space-between"
      align="center"
    >
      <div class="text-body-1 text-muted">
        Доп. контакты:
      </div>
      <v-btn
        text
        color="success"
        small
        @click="addNewContact"
      >
        Новый контакт
      </v-btn>
    </v-row>

    <template v-if="Array.isArray(value) && value.length">
      <v-row
        v-for="(item, index) in value"
        :key="index+'-row'"
        dense
        class="flex-nowrap"
        align="center"
      >
        <v-col style="max-width: 130px">
          <v-select
            v-model="item.type"
            :error-messages="errorMessages[index] ? [errorMessages[index].type || ''] : []"
            style="z-index: 10;"
            :rules="rules"
            :items="items"
            item-text="label"
            item-value="value"
            label="Тип"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="item.contact"
            :error-messages="errorMessages[index] ? [errorMessages[index].contact || ''] : []"
            :rules="rules"
            label="Значение"
          />
        </v-col>
        <v-btn
          icon
          small
          @click="removeContact(index)"
        >
          <v-icon color="red">
            fa-times
          </v-icon>
        </v-btn>
      </v-row>
    </template>
    <v-row
      v-else
      dense
      class="flex-nowrap text-center"
      justify="center"
      align="center"
    >
      <v-col class="text-muted">
        Отсутствуют
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'InputAdditionalContacts',
  props: {
    value: {
      default: () => []
    },
    rules: {
      default: () => []
    },
    items: {
      default: () => [
        {
          label: 'Тест 1',
          value: 'test1'
        }, {
          label: 'Тест 2',
          value: 'test2'
        }
      ]
    },
    errorMessages: {
      default: () => []
    }
  },
  data () {
    return {}
  },
  methods: {
    changeContact (index, val) {
      console.log('changeContact: ', index, val)
      // const value = this.value
      // value[index].phone = val
      // this.onChange(value)
    },
    changeType (index, val) {
      console.log('changeType: ', index, val)
      // const value = this.value
      // value[index].phone = val
      // this.onChange(value)
    },
    addNewContact () {
      let value = this.value
      if (!Array.isArray(value)) {
        value = []
      }
      value.push(
        {
          id: null,
          type: null,
          contact: ''
        }
      )
      this.onChange(value)
    },
    removeContact (index) {
      const value = this.value
      console.log('removeContact', index, value)
      value.splice(index, 1)
      this.onChange(value)
    },
    onChange (value) {
      this.$emit('input', value)
    }
  }
}
</script>
