<template>
  <div class="tw-font-manrope">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <TwSelect
          v-model="form.programCategoryId"
          label="Программа ипотеки"
          placeholder="Выбрать"
          :return-object="true"
          :multi="false"
          :dense="true"
          :cleanable="true"
          :persistent-label="true"
          :error-text="$v.form.programCategoryId.$invalid ? 'Обязательное поле' : ''"
          :options="dictionary.programCategory || []"
          @input="$v.form.programCategoryId.$touch()"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwSelectRelations
          v-model="form.bankId"
          :multi="false"
          :dense="true"
          :persistent-label="true"
          label="Банк"
          placeholder="Выберите банк"
          list-label="Найти банк"
          relation-name="bank"
          :disabled="formProcessing"
          :error-text="$v.form.bankId.$invalid ? 'Обязательное поле' : ''"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          type="text"
          placeholder="Сумма кредита"
          max-length="13"
          :value="$Global.inputFormatPrice.getStringSpaced(form.creditSum)"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
          :error-text="creditSumErrors"
          @input="onInputCreditSum"
        >
          <template #append>
            руб.
          </template>
        </TwInputText>
      </TwFlexCell>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model.number="form.percentAtYear"
            type="number"
            placeholder="Процентов в год"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
            :error-text="percentAtYearErrors"
            @input="$v.form.percentAtYear.$touch()"
          >
            <template #append>
              %
            </template>
          </TwInputText>
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model.number="form.years"
            type="number"
            class="tw-flex-1"
            placeholder="Срок кредита"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
            :error-text="yearsErrors"
            @input="$v.form.years.$touch()"
          >
            <template #append>
              лет
            </template>
          </TwInputText>
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexCell>
        <TwSelectRelations
          v-model="form.insuranceCompanyId"
          :multi="false"
          :dense="true"
          :persistent-label="true"
          placeholder="Выберите страховую компанию"
          label="Страховая компания"
          list-label="Найти страховую компанию"
          relation-name="insurance"
          :disabled="formProcessing"
        />
      </TwFlexCell>
    </TwFlexCol>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="true"
      :is-sticky="true"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/ResultMortgage/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { required } from 'vuelidate/lib/validators'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'

export default {
  name: 'BuyNbResultMortgageForm',
  components: {
    TwFlexCol,
    TwFlexRow,
    TwFlexCell,
    TwCardSimpleFormSaveBlock,
    TwInputText,
    TwSelectRelations,
    TwSelect
  },
  mixins: [FormCreateEditMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentDate: new Date().toISOString().substr(0, 10),
      API: API,
      createQueryParams: { dealId: this.dealId },
      form: {
        creditSum: 0
      },
      inputHouseNum: null
    }
  },
  validations () {
    return {
      form: {
        creditSum: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
          }
        },
        percentAtYear: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
          }
        },
        years: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
          }
        },
        bankId: {
          required
        },
        programCategoryId: {
          required
        }
      }
    }
  },
  computed: {
    creditSumErrors () {
      const errors = []
      if (!this.$v.form.creditSum.$dirty) return errors
      if (!this.$v.form.creditSum.required) {
        errors.push('Поле обязательное')
      } else if (this.form.creditSum) {
        const creditSum = parseFloat(this.form.creditSum)
        if (creditSum < 100000) {
          errors.push('Минимум 100 т.р.')
        } else if (creditSum > 100000000) {
          errors.push('Максимум 100 млн.р.')
        }
      }

      if (errors.length > 0) {
        if (this.$refs?.creditSum?.$el) this.$Global.scrollToElem(this.$refs.creditSum.$el)
      }

      return errors
    },
    percentAtYearErrors () {
      const errors = []
      if (!this.$v.form.percentAtYear.$dirty) return errors
      if (!this.$v.form.percentAtYear.required) {
        errors.push('Поле обязательное')
      } else if (this.form.percentAtYear && this.form.percentAtYear > 50) {
        errors.push('Максимум 50%')
      }

      if (errors.length > 0) {
        if (this.$refs?.percentAtYear?.$el) this.$Global.scrollToElem(this.$refs.percentAtYear.$el)
      }

      return errors
    },
    yearsErrors () {
      const errors = []
      if (!this.$v.form.years.$dirty) return errors
      if (!this.$v.form.years.required) {
        errors.push('Поле обязательное')
      } else if (this.form.years && this.form.years > 50) {
        errors.push('Максимум 50 лет')
      }

      if (errors.length > 0) {
        if (this.$refs?.percentAtYear?.$el) this.$Global.scrollToElem(this.$refs.percentAtYear.$el)
      }
      return errors
    }
  },
  methods: {
    onInputCreditSum (eventData) {
      this.form.creditSum = this.$Global.inputFormatPrice.getNumber(eventData)
      this.$v.form.creditSum.$touch()
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            bank: 'bankId',
            insuranceCompany: 'insuranceCompanyId',
            programCategory: 'programCategoryId',
            deal: 'dealId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              } else if (key === 'programCategory' && this.preset[key]) {
                this.$set(this.form, 'programCategoryId', this.preset[key].value || null)
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      console.warn(this.$options.name, ' handleSubmit ', this.$v.form, this.$v.form.$invalid)
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.dealId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    }
  }
}
</script>
