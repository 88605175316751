<template>
  <div class="tw-font-manrope tw-flex tw-flex-col tw-gap-[16px]">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexRow>
      <TwFlexCell />
      <TwFlexCell class="tw-flex tw-justify-end">
        <TwCheckBox
          :value="form.free"
          :disabled="formProcessing || !!preset.contractId"
          label="Бесплатная услуга"
          @input="onInputFree"
        />
      </TwFlexCell>
    </TwFlexRow>
    <!-- Бесплатная услуга   -->
    <TwFlexCol v-if="form.free">
      <TwFlexCell>
        <TwSelect
          ref="freeCondition"
          v-model="form.freeCondition"
          label="Причина предоставления бесплатной услуги"
          placeholder="Выбрать"
          :dense="true"
          :disable-error-border="true"
          :persistent-label="true"
          :error-text="freeConditionErrors"
          :options="dictionary ? dictionary.freeCondition : []"
          :disabled="formProcessing"
          @input="onChangeFreeCondition"
          @focus="$event.target.select()"
          @blur="$v.form.freeCondition.$touch()"
        />
      </TwFlexCell>
    </TwFlexCol>
    <!-- Платная услуга   -->
    <TwFlexCol v-else>
      <TwFlexCell>
        <TwInputText
          ref="commissionSum"
          :disabled="formProcessing"
          placeholder="Комиссия агенства"
          type="text"
          sub-type="int"
          max-length="13"
          :value="$Global.inputFormatPrice.getStringSpaced(form.commissionSum)"
          :error-text="commissionSumErrors"
          :persistent-label="true"
          :show-label="true"
          :dense="true"
          @input="form.commissionSum = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            руб.
          </template>
        </TwInputText>
      </TwFlexCell>
    </TwFlexCol>

    <!-- Блок с выбором или созданием договора   -->
    <TwFlexCol v-if="requireContract">
      <TwFlexCell>
        <TwSelect
          v-model="form.contractNeedCreate"
          label="Ввод договора"
          placeholder="Ввод договора"
          :dense="true"
          :persistent-label="true"
          :options="contractNeedCreateOptions"
          :error-text="$v.form.contractNeedCreate.$invalid ? 'Обязательное поле':''"
          :clearable="form.contractNeedCreate !== null"
          :disabled="formProcessing || (!!preset.contractId && !!form.contractId)"
          @input="form.contractId = null"
        />
      </TwFlexCell>
      <TwFlexCell v-if="form.contractNeedCreate === false">
        <TwSelectRelations
          v-model="form.contractId"
          prefetch-request
          :default-filters="contractDealTypeFilters"
          :dense="true"
          :persistent-label="true"
          :check-disabled="contractAllowSelectUnsigned"
          label="Номер договора"
          list-label="Найти договор"
          placeholder="Выбрать договор"
          :error-text="form.contractId ? '':'Обязательное поле'"
          :disabled="formProcessing || (!!preset.contractId && !!form.contractId)"
          relation-name="contract"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          ref="contractDate"
          v-model="form.contractDate"
          type="date"
          autocomplete="off"
          label="Дата заключения"
          placeholder="Дата заключения"
          min="1920-01-01"
          dense
          :max="currentDate"
          :error-text="contractDateErrors"
          :disabled="formProcessing"
          :show-label="true"
          :persistent-label="true"
        />
      </TwFlexCell>
    </TwFlexCol>

    <!-- Доп параметры бесплатной услуги   -->
    <TwFlexCol v-if="form.free">
      <TwFlexCell v-if="currentRelCondition && currentRelCondition.type">
        <!-- Выбор ЖК -->
        <template v-if="currentRelCondition.type === 'complex'">
          <TwSelectRelations
            v-model="form.relatedComplexId"
            relation-name="complex"
            label="Выбрать ЖК"
            :default-filters="selectComplexFilters"
            list-label="Поиск ЖК"
            placeholder="Выбрать"
            :persistent-label="true"
            :dense="true"
            :multi="false"
            :disabled="formProcessing"
            :error-text="$v.form.relatedComplexId.$invalid ? 'Обязательное поле' : ''"
          />
        </template>
        <!-- Выбор связанной заявки клиента -->
        <template v-else-if="currentRelCondition.type === 'deal'">
          <TwDealsSelect
            v-model="form.relatedDealId"
            label="Выбрать связанную заявку"
            :deal-id="dealId"
            :cleanable="false"
            :default-filters="selectDealFilters"
            :error-text="$v.form.relatedDealId.$invalid ? 'Обязательное поле' : ''"
            @inputFullData="relatedDealFullData = $event"
          >
            <template #append>
              <div class="tw-bg-white">
                <ViewPanelLink
                  v-if="form.relatedDealId"
                  :id="form.relatedDealId"
                  label="Заявка"
                  component="deal-show"
                  class="tw-mr-[20px]"
                >
                  Просмотр
                </ViewPanelLink>
              </div>
            </template>
          </TwDealsSelect>
        </template>
        <!-- Выбор связанного договора -->
        <template v-else-if="currentRelCondition.type === 'contract'">
          <TwSelectRelations
            ref="relatedContractId"
            v-model="form.relatedContractId"
            :check-disabled="contractAllowSelectSigned"
            prefetch-request
            :disabled="formProcessing"
            :default-filters="contractFromConditionFilters"
            return-object
            :dense="true"
            :persistent-label="true"
            label="Номер связанного договора"
            list-label="Найти договор"
            placeholder="Выбрать договор"
            relation-name="contract"
            :error-text="$v.form.relatedContractId.$invalid ? 'Обязательное поле' : ''"
            @input="$v.form.relatedContractId.$touch()"
            @input-object="relatedContractFullData = $event"
          />
        </template>
      </TwFlexCell>
    </TwFlexCol>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="true"
      :is-sticky="false"
      bg-color="transparent"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleFormSuccess"
      @cancel="handleCancel"
    />

    <!--    ПОДТВЕРЖДЕНИЕ СОХРАНЕНИЯ-->
    <TwDialog
      v-model="confirmDialog"
      max-width="500px"
      @input="confirmDialog = $event"
    >
      <template #header>
        <div class="tw-text-h4 tw-font-semibold">
          Подтверждение
        </div>
      </template>
      <template #body>
        <div class="tw-flex tw-gap-[12px] tw-flex-col">
          <TwPanelContentBlockFieldView v-if="!form.contractId">
            <template #title>
              Номер договора на оказание услуг по ипотеке
            </template>
            <div>
              <div v-if="preset && preset.contractNum">
                {{ preset.contractNum }}
              </div>
              <div v-else>
                Будет сгененрирован после сохранения
              </div>
            </div>
          </TwPanelContentBlockFieldView>
          <TwPanelContentBlockFieldView v-if="requireContract && form.contractId">
            <template #title>
              ИД договора
            </template>
            <div>
              <div>{{ form.contractId }}</div>
            </div>
          </TwPanelContentBlockFieldView>
          <TwPanelContentBlockFieldView>
            <template #title>
              Дата договора
            </template>
            <div>
              <div v-if="form.contractDate">
                {{ form.contractDate }}
              </div>
              <div
                v-else
                class="tw-text-an-flat-red-main"
              >
                Это обязательное поле
              </div>
            </div>
          </TwPanelContentBlockFieldView>
          <!-- Если услуга бесплатная -->
          <TwPanelContentBlockFieldView v-if="form.free">
            <template #title>
              Причина бесплатной услуги
            </template>
            <div v-if="currentConditions && currentConditions.name">
              {{ currentConditions.name }}
            </div>
            <div v-else>
              Неизвестно
            </div>
          </TwPanelContentBlockFieldView>
          <!-- Если это не покупка новостойки или Юр услуга - обязательно нужно выбрать связанный договор по нему будет заявка -->
          <TwPanelContentBlockFieldView v-if="form.free && form.freeCondition !== 'buy_nb' && form.freeCondition !== 'legal_service' && form.freeCondition !== 'buy_nb_exclusive'">
            <template #title>
              Заявка
            </template>
            <div>
              <div>
                <div v-if="form.relatedContractId">
                  <div class="tw-m-2">
                    <span class="tw-text-an-flat-gray-4">Договор: </span> {{ relatedContractFullData.contractNum }}
                  </div>
                  <template v-if="relatedContractFullData.deal">
                    <div class="tw-m-2">
                      <span class="tw-text-an-flat-gray-4">Заявка клиента: </span> #{{ relatedContractFullData.deal.id }} {{ relatedContractFullData.deal.name }}
                    </div>
                    <div class="tw-m-2">
                      <span class="tw-text-an-flat-gray-4">Статус заявки: </span> {{ relatedContractFullData.deal.status && relatedContractFullData.deal.status.name }}
                    </div>
                    <div class="tw-m-2">
                      <span class="tw-text-an-flat-gray-4">Тип заявки: </span> {{ relatedContractFullData.deal.type && relatedContractFullData.deal.type.label }}
                    </div>
                    <div class="tw-m-2">
                      <span class="tw-text-an-flat-gray-4">Ответственный: </span> <EmployeeName :id="relatedContractFullData.deal.responsibleUserId" />
                    </div>
                  </template>
                  <div
                    v-else
                    class="tw-m-2"
                  >
                    <span class="tw-text-an-flat-gray-4">Информация по заявке отсутствует</span>
                  </div>
                </div>
                <div
                  v-else
                  class="error--text"
                >
                  Не выбран связанный договор
                </div>
              </div>
            </div>
          </TwPanelContentBlockFieldView>
          <!-- Если это покупка новостойки обязательно нужно выбрать связанную заявку -->
          <TwPanelContentBlockFieldView v-else-if="form.free && form.freeCondition && form.freeCondition === 'buy_nb'">
            <template #title>
              Заявка
            </template>
            <div v-if="relatedDealFullData">
              {{ relatedDealFullData.label || relatedDealFullData.name || 'Выберите заявку' }}
            </div>
            <div v-else>
              {{ form.relatedDealId || 'Выберите заявку' }}
            </div>
          </TwPanelContentBlockFieldView>
          <TwPanelContentBlockFieldView v-else-if="form.free && form.freeCondition && form.freeCondition === 'buy_nb_exclusive'">
            <template #title>
              Название ЖК
            </template>
            <div>
              <RealtyComplexName
                v-if="form.relatedComplexId"
                :id="form.relatedComplexId"
              />
              <div v-else>
                не выбран
              </div>
            </div>
          </TwPanelContentBlockFieldView>
          <TwPanelContentBlockFieldView>
            <template #title>
              Комиссия агенства
            </template>
            <div class="tw-font-bold">
              {{ commissionSumSpaced }} руб.
            </div>
          </TwPanelContentBlockFieldView>
        </div>
      </template>
      <template #actions>
        <div class="tw-text-center tw-font-medium tw-text-an-flat-red-main tw-py-[10px]">
          Уверены, что хотите сохранить договор?
        </div>
        <div class="tw-flex tw-w-full tw-gap-[15px] tw-justify-center">
          <TwPanelActionButton
            :disabled="$v.form.$invalid"
            color="green"
            @click.stop="handleSubmit"
          >
            Сохранить
          </TwPanelActionButton>
          <TwPanelActionButton
            :disabled="false"
            color="gray"
            @click="confirmDialog = false"
          >
            Отмена
          </TwPanelActionButton>
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/DealDocument'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { required } from 'vuelidate/lib/validators'
import EmployeeName from '@/components/other/employee/EmployeeName'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwDealsSelect from '@/components/tw-ui/ui-kit/custom/TwDealsSelect.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import RealtyComplexName from '@/components/other/complex/RealtyComplexName.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'

export default {
  name: 'DealDocumentForm',
  components: {
    TwFlexRow,
    TwFlexCell,
    TwFlexCol,
    RealtyComplexName,
    TwSelectRelations,
    ViewPanelLink,
    TwDealsSelect,
    TwPanelActionButton,
    TwPanelContentBlockFieldView,
    TwDialog,
    TwCardSimpleFormSaveBlock,
    TwSelect,
    TwCheckBox,
    TwInputText,
    EmployeeName
  },
  mixins: [FormCreateEditMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    },
    personId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      relatedObjectId: this.dealId,
      contractNeedCreateOptions: [
        {
          label: 'Не выбрано',
          value: null
        },
        {
          label: 'Сгенерировать новый номер для договора',
          value: true
        },
        {
          label: 'Выбрать номер договора из созданных',
          value: false
        }
      ],
      currentDate: new Date().toISOString().slice(0, 10),
      confirmDialog: false,
      API: API,
      relatedContractFullData: null,
      relatedDealFullData: null,
      form: {
        id: null,
        dealId: this.dealId,
        free: false,
        contractId: null,
        contractDate: null,
        commissionSum: 0,
        freeCondition: null,
        relatedContractId: null, // Номер договора не нужно указывать, если в бесплатной услуге выбрана "Покупка новостройки"
        relatedDealId: null // Номер заявки, если в бесплатной услуге выбрана "Покупка новостройки"
      }
    }
  },
  validations () {
    return {
      form: {
        contractNeedCreate: {
          ...(!!this.requireContract) && {
            required
          }
        },
        commissionSum: {
          required: function (v) {
            if (!this.form.free) {
              return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
            }
            return true
          }
        },
        freeCondition: {
          required: function (v) {
            if (this.form.free) {
              return !!v
            }
            return true
          }
        },
        relatedContractId: {
          required: function (v) {
            if (this.form.free && this.currentRelCondition?.type === 'contract') {
              return !!v
            }
            return true
          }
        },
        relatedDealId: {
          required: function (v) {
            if (this.form.free && this.currentRelCondition?.type === 'deal') {
              return !!v
            }
            return true
          }
        },
        relatedComplexId: {
          required: function (v) {
            if (this.form.free && this.currentRelCondition?.type === 'complex') {
              return !!v
            }
            return true
          }
        },
        contractDate: {
          required
        },
        contractId: {
          required: function (v) {
            if (this.contractNeedCreate === false) {
              return !!v
            }
            return true
          }
        }
      }
    }
  },
  methods: {
    onInputFree (eventValue) {
      this.form.free = eventValue
      // Сброс полей
      this.form.contractNeedCreate = null
      this.form.contractId = null
      this.form.relatedContractId = null
      this.form.relatedDealId = null
      this.form.freeCondition = null
      this.form.commissionSum = null

      if (this.form.free) {
        // Если изначально были заданы значения - возвращаю их на в поля ввода
        if (this.preset?.freeCondition) {
          this.form.freeCondition = this.preset?.freeCondition?.value
        } else {
          this.form.freeCondition = null
        }
        if (this.preset?.relatedContract) {
          this.form.relatedContractId = this.preset.relatedContract.id || null
        } else {
          this.form.relatedContractId = null
        }
      } else {
        // Если изначально была задана сумма - возвращаю её в поле ввода
        if (this.preset?.commissionSum) {
          this.form.commissionSum = this.preset.commissionSum
        } else {
          this.form.commissionSum = 0
        }
      }
    },
    onChangeFreeCondition () {
      this.form.relatedContractId = null
      this.form.relatedDealId = null
      this.form.relatedDealFullData = null
      this.relatedContractFullData = null
    },
    // Разрешить только НЕ подписанные договора
    contractAllowSelectUnsigned (item, isAlert = false) {
      if (item.signed) {
        if (isAlert) {
          alert('Договор уже подписан в другой заявке! Выберите не подписанный договор')
        }
        return true
      }
      return false
    },
    // Разрешить только подписанные договора
    contractAllowSelectSigned (item, isAlert = false) {
      if (!item.signed) {
        if (isAlert) {
          alert('Договор не подписан! Выберите подписанный договор')
        }
        return true
      }
      return false
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            deal: 'dealId',
            contract: 'contractId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              }
            } else {
              if (key === 'freeCondition') {
                this.$set(this.form, key, this.preset[key] ? this.preset[key].value : null)
              } else if (key === 'relatedContract' && this.preset[key]) {
                this.$set(this.form, 'relatedContractId', this.preset[key]?.id || null)
                this.relatedContractFullData = this.preset[key] || null
              } else if (key === 'relatedDeal' && this.preset[key]) {
                this.$set(this.form, 'relatedDealId', this.preset[key]?.id || null)
                this.relatedDealFullData = this.preset[key] || null
              } else if (key === 'relatedComplex' && this.preset[key]) {
                this.$set(this.form, 'relatedComplexId', this.preset[key]?.id || null)
                this.relatedDealFullData = this.preset[key] || null
              } else {
                this.$set(this.form, key, this.preset[key])
              }
            }
          }
          // Если дата не была заполнена с пресета, заполняю текущей
          // [WARN] Нельзя оставить пустым - ибо сафари заполняет своими значениями которые не попадают в форму
          if (!this.form?.contractDate) {
            this.form.contractDate = this.currentDate
          }
          // Если нет инструкции - добавляю
          if (this.form.contractNeedCreate === undefined) {
            this.$set(this.form, 'contractNeedCreate', null)
          }
        }
        resolve()
      })
    },
    handleFormSuccess () {
      console.warn(this.$options.name, ' handleSubmit ', this.$v.form)
      this.$v.$reset()
      this.$v.form.$touch()
      // Провалидирую
      if (!this.$v.form.$invalid) {
        this.confirmDialog = true
      }
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      this.confirmDialog = false
      console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      console.warn(this.$options.name, ' handleSubmit ', this.$v.form, this.$v.form.$invalid)
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.relatedObjectId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.relatedObjectId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    }
  },
  computed: {
    currentConditions () {
      if (this.form?.free) {
        return this.form.freeCondition && Array.isArray(this.scheme?.freeConditions) ? this.scheme.freeConditions.find(item => item.code === this.form.freeCondition) : {}
      }
      return this.scheme?.paid || {}
    },
    requireContract () {
      return this.currentConditions?.requireContract || false
    },
    currentRelCondition () {
      return this.currentConditions ? this.currentConditions?.relCondition : null
    },
    commissionSumSpaced () {
      return ((this.form.commissionSum && this.form.commissionSum) || 0).toLocaleString()
    },
    contractDateErrors () {
      const errors = []
      if (!this.$v.form.contractDate.$dirty) return errors
      if (!this.$v.form.contractDate.required) {
        errors.push('Поле обязательное')
        if (this.$refs?.contractDate?.$el) this.$Global.scrollToElem(this.$refs.contractDate.$el)
      }
      return errors
    },
    commissionSumErrors () {
      const errors = []
      if (!this.$v.form.commissionSum.$dirty) return errors
      if (!this.$v.form.commissionSum.required) {
        errors.push('Поле обязательное')
        if (this.$refs?.commissionSum?.$el) this.$Global.scrollToElem(this.$refs.commissionSum.$el)
      }
      return errors
    },
    freeConditionErrors () {
      const errors = []
      if (!this.$v.form.freeCondition.$dirty) return errors
      if (!this.$v.form.freeCondition.required) {
        errors.push('Поле обязательное')
        if (this.$refs?.freeCondition?.$el) this.$Global.scrollToElem(this.$refs.freeCondition.$el)
      }
      return errors
    },
    relatedContractIdErrors () {
      const errors = []
      if (!this.$v.form.relatedContractId.$dirty) return errors
      if (!this.$v.form.relatedContractId.required) {
        errors.push('Поле обязательное')
        if (this.$refs?.relatedContractId?.$el) this.$Global.scrollToElem(this.$refs.relatedContractId.$el)
      }
      return errors
    },
    relatedDealIdErrors () {
      const errors = []
      if (!this.$v.form.relatedDealId.$dirty) return errors
      if (!this.$v.form.relatedDealId.required) {
        errors.push('Поле обязательное')
        if (this.$refs?.relatedDealId?.$el) this.$Global.scrollToElem(this.$refs.relatedDealId.$el)
      }
      return errors
    },
    selectDealFilters () {
      return {
        type: this.currentRelCondition?.dealType || null
      }
    },
    selectComplexFilters () {
      return {
        isExclusive: true
      }
    },
    contractFromConditionFilters () {
      if (this.currentRelCondition?.type && this.currentRelCondition?.dealType) {
        return { type: this.currentRelCondition.dealType }
      }
      return null
    },
    contractDealTypeFilters () {
      return { type: this.scheme?.dealType || undefined }
    }
  }
}
</script>
