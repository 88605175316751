<template>
  <v-form ref="form">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <v-text-field
      v-model="form.surname"
      v-input-allow-regex="'[a-zA-Zа-яА-ЯёЁ-]'"
      role="presentation"
      type="text"
      :dense="denseFields"
      label="Фамилия"
      autocomplete="off"
      required
    />
    <v-text-field
      ref="name"
      v-model="form.name"
      v-input-allow-regex="'[a-zA-Zа-яА-ЯёЁ-]'"
      role="presentation"
      type="text"
      :dense="denseFields"
      label="Имя"
      autocomplete="off"
      require
      :error-messages="nameErrors"
      @input="$v.form.name.$touch()"
      @blur="$v.form.name.$touch()"
    />
    <v-text-field
      v-model="form.fatherName"
      v-input-allow-regex="'[a-zA-Zа-яА-ЯёЁ -]'"
      role="presentation"
      type="text"
      :dense="denseFields"
      label="Отчество"
      autocomplete="off"
    />
    <v-radio-group
      ref="gender"
      v-model="form.gender"
      label="Пол"
      :dense="denseFields"
      row
      require
      :error-messages="genderErrors"
      @input="$v.form.gender.$touch()"
      @blur="$v.form.gender.$touch()"
    >
      <v-radio
        v-for="(item, key) in dictionary.gender"
        :key="key"
        :value="item.value"
      >
        <template #label>
          {{ item.label }}
        </template>
      </v-radio>
    </v-radio-group>
    <div>
      <TwInputText
        ref="birthDate"
        v-model="form.birthDate"
        type="date"
        persistent-label
        dense
        label="Дата рождения"
        :max="new Date().toISOString().substr(0, 10)"
        min="1920-01-01"
        :error-text="Array.isArray(ageErrors) && ageErrors.length ? ageErrors[0] : null"
        :disabled="formProcessing"
        @input="$v.form.birthDate.$touch()"
        @change="$v.form.birthDate.$touch()"
        @blur="$v.form.birthDate.$touch()"
      >
        <template #append>
          <div class="tw-font-medium tw-min-w-[50px]">
            <template v-if="form.birthDate">
              {{ $Global.calculateAge(form.birthDate) }}
              {{ $Global.textPlural($Global.calculateAge(form.birthDate), 'year') }}
            </template>
            <template v-else>
              ~
            </template>
          </div>
        </template>
      </TwInputText>
    </div>
    <template v-if="!personTypes.isChildren">
      <InputPersonPhones
        ref="phones"
        v-model="form.phones"
        class="mt-3"
        require
        :error-messages="phonesErrors"
        @input="$v.form.phones.$touch()"
        @blur="$v.form.phones.$touch()"
      />
      <InputAdditionalContacts
        ref="additionalContacts"
        v-model="form.additionalContacts"
        class="mt-3"
        :items="dictionary.typeAdditionalContact"
        require
        :error-messages="additionalContactsErrors"
        @input="$v.form.additionalContacts.$touch()"
        @blur="$v.form.additionalContacts.$touch()"
      />
      <div
        class="text-muted text-body-1 mt-4 d-flex align-center"
        title="Например сотрудник банка"
      >
        <v-icon
          color="primary"
          small
          class="mr-1"
        >
          fa-info-circle
        </v-icon>
        <span class="">
          Сотрудник компании:
        </span>
      </div>
      <CompanyAsyncSelect
        v-model="form.companyId"
        chips
        clearable
        :filters="{ 'Company.type': 'bank' }"
        placeholder="Выбрать компанию"
      />
    </template>

    <CardSimpleFormSaveBlock
      :loading="formProcessing"
      style="z-index: 10"
      :cancel-confirm="isEdited"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing || (formType === 'create' && !isEdited)"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </v-form>
</template>

<script>
import API from '@/Api/Crm/Person'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import InputPersonPhones from '@/components/input/phone/InputPersonPhones'
import InputAdditionalContacts from '@/components/input/InputAdditionalContacts'
import CompanyAsyncSelect from '@/components/select/company/CompanyAsyncSelect'
import { RouteKeys } from '@/router/types'
import { required } from 'vuelidate/lib/validators'
import CardSimpleFormSaveBlock from '@/components/core/ViewPanel/Card/CardSimpleFormSaveBlock'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'

export default {
  name: 'PersonMainForm',
  components: {
    TwInputText,
    CardSimpleFormSaveBlock,
    CompanyAsyncSelect,
    InputAdditionalContacts,
    InputPersonPhones
  },
  mixins: [FormCreateEditMixin],
  props: {
    personTypes: {},
    searchedPhone: {
      type: String,
      default: ''
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    },
    personObjectId: {
      type: [Number, null],
      default: null
    }
  },
  data () {
    return {
      API: API,
      form: {
        id: null,
        surname: null,
        name: null,
        fatherName: null,
        gender: null,
        birthDate: '',
        ...(!this.personTypes.isChildren) && {
          companyId: null,
          phones: [{
            id: null,
            phone: '',
            main: true
          }],
          additionalContacts: []
        }
      },
      serverErrors: null
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      gender: {
        required
      },
      birthDate: {
        required: function (v) {
          if (!this.personTypes.isChildren) {
            return true
          } else {
            return !!v
          }
        },
        ageValidator: function (bidthDateString) {
          if (bidthDateString && !this.personTypes.isChildren) {
            const bidthDate = new Date(bidthDateString)
            const diffMs = Date.now() - bidthDate.getTime()
            const ageDt = new Date(diffMs)
            const ageValue = Math.abs(ageDt.getUTCFullYear() - 1970)
            return ageValue && ageValue >= 18 && ageValue <= 85
          } else if (this.personTypes.isChildren) {
            return !!bidthDateString
          }
          return true
        }
      },
      phones: {
        $each: {
          phone: {
            required: function (v) {
              if (Array.isArray(this.form.additionalContacts) && this.form.additionalContacts.some(item => !!item.contact)) {
                return true
              } else {
                return !!v
              }
            },
            isFormatValid (value) {
              // console.warn('+isFormatValid', value, item)
              return value ? (!!value && value.toString().replace(/[^\d]/g, '') && value.toString().replace(/[^\d]/g, '').length >= 11) : true
            }
          }
        }
      },
      additionalContacts: {
        $each: {
          type: {
            required
          },
          contact: {
            required
          }
        }
      },
      personObjectId: {
        required: function (v) {
          if (!this.personTypes.isChildren || this.formType !== 'create') {
            return true
          } else {
            return !!v
          }
        }
      }
    }
  },
  computed: {
    additionalContactsErrors () {
      let errors = []
      if (!this.$v.form.additionalContacts || !this.$v.form.additionalContacts.$dirty) return errors
      if (this.serverErrors?.additionalContacts) {
        errors = this.serverErrors.additionalContacts.map((msg) => {
          return { contact: msg }
        })
        return errors
      }
      if (!this.personTypes.isChildren) {
        Object.values(this.$v.form.additionalContacts.$each.$iter).forEach((item) => {
          if (!item.type.required || !item.contact.required) {
            errors.push({
              type: !item.type.required ? 'Обязательное поле' : '',
              contact: !item.contact.required ? 'Обязательное поле' : ''
            })
            this.$Global.scrollToElem(this.$refs.additionalContacts.$el)
          } else {
            errors.push('')
          }
        })
      }
      return errors
    },
    phonesErrors () {
      let errors = []
      if (!this.$v.form.phones || !this.$v.form.phones.$dirty) return errors
      if (this.serverErrors?.phones) {
        errors = this.serverErrors.phones
        return errors
      }
      if (!this.personTypes.isChildren) {
        Object.values(this.$v.form.phones.$each.$iter).forEach((item) => {
          if (!item.phone.required) {
            errors.push('Обязательное поле')
            this.$Global.scrollToElem(this.$refs.phones.$el)
          } else if (!item.phone.isFormatValid) {
            errors.push('Неверный формат номера телефона')
            this.$Global.scrollToElem(this.$refs.phones.$el)
          } else {
            errors.push('')
          }
        })
      }
      return errors
    },
    nameErrors () {
      const errors = []
      if (!this.$v.form.name.$dirty) return errors
      if (!this.$v.form.name.required) {
        errors.push('Обязательное поле')
        this.$Global.scrollToElem(this.$refs.name.$el)
      }
      return errors
    },
    genderErrors () {
      const errors = []
      if (!this.$v.form.gender.$dirty) return errors
      if (!this.$v.form.gender.required) {
        errors.push('Обязательное поле')
        this.$Global.scrollToElem(this.$refs.gender.$el)
      }
      return errors
    },
    ageErrors () {
      const errors = []
      if (!this.$v.form.birthDate.$dirty) return errors
      if (this.personTypes.isChildren) {
        if (!this.$v.form.birthDate.required) {
          errors.push('Обязательное поле')
          this.$Global.scrollToElem(this.$refs.birthDate.$el)
        }
      } else {
        if (!this.$v.form.birthDate.ageValidator) {
          errors.push('Возраст: больше 18 и меньше 85 лет')
          this.$Global.scrollToElem(this.$refs.birthDate.$el)
        }
      }
      return errors
    }
  },
  mounted () {
    if (this.targetId) {
      // сообщу родительской обертке о том что имеется ID
      this.$emit('changeId', this.targetId)
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            gender: 'gender',
            company: 'companyId',
            personObject: 'personObjectId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              }
              if (key === 'gender') {
                this.$set(this.form, key, this.preset[key]?.value || null)
              }
            } else if (key === 'phones') {
              if (this.preset[key].length) {
                this.$set(this.form, key, this.preset[key])
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        if (this.personTypes.isChildren) {
          this.$set(this.form, 'type', 'children')
        }
        if (this.personObjectId) {
          this.$set(this.form, 'personObjectId', this.personObjectId)
        }
        // Проброс номера телефона из поисковой строки
        if (this.formType === 'create' && this.searchedPhone) {
          this.form.phones[0].phone = this.searchedPhone.trim().replace(/\D/g, '').substr(0, 11)
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        const formData = { ...this.form }
        // очистка массивов от пустых данных
        formData.phones = formData.phones?.filter(item => !!item.phone)
        formData.additionalContacts = formData.additionalContacts?.filter(item => !!item.contact)
        console.warn('handleSubmit', { formData })

        if (this.formType === 'create') {
          this.API.store(formData)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
              // если текущий путь равен пути создания то редирект в просмотр
              if (this.$route.name === `${RouteKeys.person}-create`) {
                this.$router.replace({
                  name: `${RouteKeys.person}-show`,
                  params: { id: data.data.id }
                })
              }
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, formData)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else {
          alert(`Error handleSubmit: ${this.$options.name}`)
        }
      } else {
        console.error('form.validate ', this.$v.form)
      }
    }
  }
}
</script>

<style scoped>

</style>
