export function ResponceErrorMessageGenerator (error) {
  let message = ''
  if (error?.response?.data) {
    for (const key in error?.response?.data) {
      if (Array.isArray(error?.response?.data?.[key])) {
        for (const err of error?.response?.data[key]) {
          message += err
        }
      } else {
        message += error?.response?.data?.[key]
      }
    }
  }
  return message
}

export default {}
