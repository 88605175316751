import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currentUser: 'profile/getUser'
    })
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    }),
    showBuilderProjectCard (props = {}, callback) {
      this.showPanel({
        component: 'builder-project-show',
        props,
        label: 'Проект',
        callback
      })
    },
    showMortgageProgramCard (props = {}, callback) {
      console.warn(this.$options.name, ' showMortgageProgramCard ', props)
      return this.showPanel({
        component: 'mortgage-program-show',
        props,
        label: 'Программа',
        callback
      })
    },
    showPersonCard (props = {}, callback) {
      console.warn(this.$options.name, 'showPersonCard', props)
      return this.showPanel({
        component: 'person-show',
        props,
        label: 'Контакт',
        callback
      })
    },
    showDiscountMatrixCard (props = {}, callback) {
      console.warn(this.$options.name, 'showDiscountMatrixCard', props)
      return this.showPanel({
        component: 'discount-matrix-show',
        props,
        label: 'Матрица скидок',
        callback
      })
    },
    showPersonCheckKontur (props = {}, callback) {
      console.warn(this.$options.name, 'showDiscountMatrixCard', props)
      return this.showPanel({
        component: 'person-check-kontur-show',
        props,
        label: 'Проверка',
        callback
      })
    },
    showDealReviewCard (props = {}, callback) {
      console.warn(this.$options.name, 'showDealReviewCard', props)
      return this.showPanel({
        component: 'deal-review-show',
        props,
        label: '',
        callback
      })
    },
    showDealNbResultManagementCard (props = {}, callback) {
      console.warn(this.$options.name, 'showDealNbResultManagementCard', props)
      return this.showPanel({
        component: 'deal-nb-result-management-show',
        props,
        label: '',
        callback
      })
    },
    showPersonChildrenCard (props = {}, callback) {
      console.warn(this.$options.name, 'showPersonChildrenCard', props)
      return this.showPanel({
        component: 'person-show',
        props,
        label: 'Ребёнок',
        callback
      })
    },
    showLeadCard (props = {}, callback) {
      console.warn(this.$options.name, ' showLeadCard ', props)
      return this.showPanel({
        component: 'lead-show',
        props,
        label: 'Лид',
        callback
      })
    },
    showSupportCard (props = {}, callback) {
      console.warn(this.$options.name, ' showSupportCard ', props)
      return this.showPanel({
        component: 'support-show',
        props,
        label: 'Обращение',
        callback
      })
    },
    showDealCard (props = {}, callback) {
      console.warn(this.$options.name, ' showDealCard ', props)
      // TODO props в устаревшем варианте это ИД, поэтому такая карусель
      if (typeof props === 'object') {
        return this.showPanel({
          component: 'deal-show',
          props,
          label: 'Заявка',
          callback
        })
      } else {
        return this.showPanel({
          component: 'deal-show',
          props: { id: props },
          label: 'Заявка',
          callback
        })
      }
    },
    showCompanyCard (props = {}, callback) {
      console.warn(this.$options.name, 'showCompanyCard', props)
      return this.showPanel({
        component: 'company-show',
        props,
        label: 'Компания',
        callback
      })
    },
    showDocumentCard (props = {}, callback) {
      console.warn(this.$options.name, 'showDocumentCard', props)
      return this.showPanel({
        component: 'document-show',
        props,
        label: 'Документ',
        callback
      })
    },
    showRequirementCard (props = {}, callback) {
      console.warn(this.$options.name, 'showRequirementCard', props)
      return this.showPanel({
        component: 'requirement-show',
        props,
        label: 'Требования банка',
        callback
      })
    },
    showVariantSelectionCard (props = {}, callback) {
      console.warn(this.$options.name, 'showVariantSelectionCard', props)
      return this.showPanel({
        component: 'mortgage-variant-selection',
        props,
        label: 'Ипотека. Подбор',
        callback
      })
    },
    showDealContactCard (props = {}, callback) {
      console.warn(this.$options.name, 'showDealContactCard', props)
      return this.showPanel({
        component: 'deal-contract-show',
        props,
        label: 'Договор',
        callback
      })
    },
    showEmailCard (props = {}, callback, isCloseConfirm = false) {
      console.warn(this.$options.name, 'showEmailCard', props)
      return this.showPanel({
        component: 'email-show',
        props,
        label: 'Email',
        fixedWidth: '800px',
        isCloseConfirm,
        callback
      })
    },
    showEmployeeCard (props = {}, callback) {
      console.warn(this.$options.name, ' showEmployeeCard ', props)
      return this.showPanel({
        component: 'employee-show',
        props,
        label: 'Пользователь',
        callback
      })
    },
    showUserSurveysCard (props = {}, callback) {
      console.warn(this.$options.name, ' showUserSurveysCard ', props)
      return this.showPanel({
        component: 'user-surveys-show',
        props,
        label: '',
        callback
      })
    },
    showMyProfilePanel (props = {}, callback) {
      console.warn(this.$options.name, 'showEmployee', props)
      return this.showPanel({
        component: 'employee-show',
        props: { ...props, id: this.currentUser.id },
        label: 'Профиль',
        callback
      })
    },
    showMyEmailList (props = {}, callback) {
      console.warn(this.$options.name, 'showMyEmailList', props)
      return this.showPanel({
        component: 'email-list-show',
        props: { ...props },
        label: 'Почта',
        callback
      })
    },
    showMortgageProgramWorksheetCard (props = {}, callback) {
      console.warn(this.$options.name, ' showMortgageProgramWorksheetCard ', props)
      return this.showPanel({
        component: 'mortgage-program-worksheet-show',
        props,
        label: 'Анкета',
        callback
      })
    },
    showDomClickWorksheetCard (props = {}, callback) {
      console.warn(this.$options.name, ' showDomClickWorksheetCard ', props)
      return this.showPanel({
        component: 'domclick-worksheet-show',
        props,
        label: 'Домклик',
        callback
      })
    },
    showRealtyBuilderCard (props = {}, callback) {
      console.warn(this.$options.name, ' showRealtyBuilderCard ', props)
      return this.showPanel({
        component: 'realty-builder-show',
        props,
        label: 'Застройщик',
        callback
      })
    },
    showRealtyComplexCard (props = {}, callback) {
      console.warn(this.$options.name, ' showRealtyComplexCard ', props)
      return this.showPanel({
        component: 'realty-complex-show-page',
        props,
        label: 'ЖК',
        callback
      })
    },
    showRealtyComplexEditableCard (props = {}, callback) {
      console.warn(this.$options.name, ' showRealtyComplexEditableCard ', props)
      return this.showPanel({
        component: 'realty-complex-show',
        props,
        label: 'ЖК',
        callback
      })
    },
    showImportBuilderFileCard (props = {}, callback) {
      console.warn(this.$options.name, ' showImportBuilderFileCard ', props)
      return this.showPanel({
        component: 'import-builder-file-show',
        props,
        label: '',
        callback
      })
    },
    showImportSiteDeveloperFileCard (props = {}, callback) {
      console.warn(this.$options.name, ' showImportSiteDeveloperFileCard ', props)
      return this.showPanel({
        component: 'import-site-developer-file-show',
        props,
        label: '',
        callback
      })
    },
    showImportDocumentFileCard (props = {}, callback) {
      console.warn(this.$options.name, ' showImportDocumentFileCard ', props)
      return this.showPanel({
        component: 'import-document-file-show',
        props,
        label: '',
        callback
      })
    },
    showCompanyPeriodResultManagementCard (props = {}, callback) {
      console.warn(this.$options.name, ' showCompanyPeriodResultManagementCard ', props)
      return this.showPanel({
        component: 'company-period-result-management-show',
        props,
        label: 'Закрытие',
        callback
      })
    },
    showManagementRequestToDeveloperCard (props = {}, callback) {
      console.warn(this.$options.name, ' showManagementRequestToDeveloperCard ', props)
      return this.showPanel({
        component: 'management-request-to-developer-show',
        props,
        label: '',
        callback
      })
    },
    showNbEditorObjectCard (props = {}, callback) {
      console.warn(this.$options.name, 'showNbEditorObjectCard', props)
      return this.showPanel({
        component: 'nb-editor-object',
        props,
        label: '',
        callback
      })
    },
    showRealtyPlanCard (props = {}, callback) {
      console.warn(this.$options.name, 'showRealtyPlanCard', props)
      return this.showPanel({
        component: 'realty-plan-show',
        props,
        label: 'Планировка',
        callback
      })
    }
  }
}
