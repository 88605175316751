<template>
  <div
    v-if="item"
    :data-item-id="item.attachEntityId"
    class="item-wrapper mt-5"
  >
    <div
      :style="{backgroundColor: listColors[variant].bg}"
      class="item-icon"
    >
      <v-icon
        size="18px"
        color="white"
      >
        fa-phone
      </v-icon>
    </div>
    <div
      v-if="item"
      class="card item-hovered-visible-block"
    >
      <div
        v-if="callInfo"
        :data-call-id="callInfo.id"
        class="card-wrapper"
        style="width: 100%"
      >
        <div
          class="card-left"
          style="flex: auto"
        >
          <div class="card-header">
            <div class="card-header-info">
              <p class="card-title">
                <span> {{ callInfo.type === 'incoming' ? 'Входящий' : 'Исходящий' }} звонок:</span>
              </p>
              <p class="card-time">
                {{ $Global.DateFormat.DateTimeShort(callInfo.date) }}
              </p>
            </div>
          </div>
          <div
            v-if="callInfo && callInfo.id"
            class="card-notification-info"
          >
            <div class="card-notification-text d-flex">
              <span
                v-if="callInfo.type && callInfo.number"
                class="mr-3"
              >
                {{ callInfo.type === 'incoming' ? 'с номера' : 'на номер' }}
              </span>
              <SipCallLink
                v-if="callInfo.number"
                :phone="callInfo.number"
              />
              <small
                v-if="callInfo.fileAssess && callInfo.duration"
                class="ml-2"
              >
                ({{ $Global.timeSecondsFormat(callInfo.duration) }})
              </small>
            </div>
            <div class="card-notification-towhom tw-text-an-flat-disabled-text">
              <audio
                v-if="callInfo.file"
                style="height: 30px;width: 100%"
                :src="callInfo.file.url"
                controls
                preload="none"
              />
              <div v-else>
                {{ callInfo.description }}
              </div>
            </div>
            <div class="card-notification-towhom mt-2">
              <div class="mr-2">
                Сотрудник:
              </div>
              <div v-if="callInfo.responsibleUserId">
                <ViewPanelLink
                  :id="callInfo.responsibleUserId"
                  label="Пользователь"
                  component="employee-show"
                >
                  <EmployeeName :id="callInfo.responsibleUserId" />
                </ViewPanelLink>
              </div>
            </div>
          </div>
          <div
            v-else
            class="card-notification-info tw-mb-[10px] tw-text-an-flat-disabled-text"
          >
            Звонок не найден
          </div>
        </div>
        <div class="card-right">
          <ChronologyPinned
            v-if="!(variant === 'other' && item.pin)"
            :id="item.id"
            :pin-status="item.pin"
            @refresh="$emit('refresh')"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="text-muted"
  >
    Нет данных
  </div>
</template>

<script>
import { listColors } from '@/components/core/Chronology/ListConfig'
import SipCallLink from '@/components/telephony/SipCallLink'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import ChronologyPinned from '@/components/core/Chronology/ChronologyPinned'
import { mapGetters } from 'vuex'

export default {
  name: 'ChronologyCallView',
  components: { ChronologyPinned, EmployeeName, ViewPanelLink, SipCallLink },
  props: {
    item: {
      type: Object,
      required: true
    },
    variant: {
      type: String,
      required: true // other, plan, pin
    }
  },
  computed: {
    ...mapGetters({
      getCallById: 'tmpCache/telephony/getCallById'
    }),
    listColors () {
      return listColors
    },
    callInfo () {
      return this.item?.attachEntityId ? this.getCallById(this.item.attachEntityId) : {}
    }
  }
}
</script>

<style scoped>

</style>
