<template>
  <div>
    <div class="tw-relative tw-text-main-sm2 tw-flex tw-justify-center">
      <label
        v-if="errorText"
        class="tw-text-[12px] tw-text-an-flat-red-main tw-absolute tw-top-[10px] -tw-translate-y-1/2 tw-right-0"
        :for="uid"
      >
        {{ errorText }}
      </label>
      <label
        v-if="label"
        :for="uid"
        class="tw-text-an-flat-dark-blue-link tw-pointer-events-none tw-font-medium tw-truncate tw-text-main-sm2 tw-absolute -tw-top-[2px] tw-left-[5px] tw-transition-all tw-duration-200 tw-ease-out motion-reduce:tw-transition-none"
      >
        {{ label }}
        <span
          v-if="isRequired"
          class="tw-text-an-flat-red-main tw-text-main-sm"
        >
          <slot name="requiredSymbol">*</slot>
        </span>
      </label>
    </div>
    <div
      class="tw-flex tw-columns-2 tw-gap-[16px] tw-mt-[18px]"
      :class="sizeClasses"
    >
      <div
        v-if="!onlyMax"
        class="tw-w-full tw-flex tw-flex-row-reverse tw-font-an-flat-manrope tw-text-[13px] tw-font-medium tw-border-[1px] tw-border-an-flat-gray-17
        tw-rounded-r-[6px] tw-rounded-l-[6px] tw-overflow-hidden
        tw-outline tw-outline-1
        tw-group/hover"
        :class="[disabled ? '':'hover:tw-shadow-[10px_8px_15px_-15px_rgba(0,0,0,0.3)]', errorText && !disableErrorBorder ? 'tw-outline-an-flat-red-main':'tw-outline-transparent']"
      >
        <label
          v-show="suffix"
          :for="uid + '-min-input'"
          :class="{ 'tw-text-an-flat-gray-1 tw-bg-an-flat-disabled-input-bg' : disabled }"
          class="tw-flex tw-items-center tw-justify-center tw-min-w-[38px] tw-px-[12px] tw-bg-an-flat-gray-17 tw-text-an-flat-active-input tw-text-center
            group-hover/hover:tw-bg-an-flat-red-lighten group-hover/hover:tw-text-an-flat-red-main
            peer-focus:tw-text-an-flat-red-main
            peer-disabled:tw-text-an-flat-gray-1 peer-disabled:tw-bg-an-flat-disabled-input-bg"
        >
          {{ suffix }}
        </label>
        <div class="tw-flex tw-w-full tw-relative">
          <input
            :id="uid + '-min-input'"
            :disabled="disabled"
            class="tw-w-full tw-block tw-py-[7px] tw-pr-[17px] tw-pl-[12px] tw-text-an-flat-header focus:tw-outline-none tw-peer tw-bg-white"
            :class="{ 'disabled:tw-bg-an-flat-grey-1 disabled:tw-text-an-flat-gray-1' : disabled }"
            type="text"
            pattern="\d\s"
            :placeholder="min"
            :value="stringValues.min"
            @input="onChange($event, 'min')"
          >
          <button
            v-if="cleanable && stringValues.min"
            class="tw-absolute tw-right-[3px] tw-top-1/2 -tw-translate-y-1/2 tw-p-[4px] tw-rounded-[100%] tw-bg-an-flat-red-main tw-hidden peer-hover:tw-block hover:tw-block"
            @click.stop="onChange(null, 'min')"
          >
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.73292 4.00779L7.84796 0.892655C8.05069 0.690029 8.05069 0.362409 7.84796 0.159783C7.64534 -0.0428442 7.31772 -0.0428442 7.11509 0.159783L3.99996 3.27492L0.884914 0.159783C0.682192 -0.0428442 0.354668 -0.0428442 0.152041 0.159783C-0.0506804 0.362409 -0.0506804 0.690029 0.152041 0.892655L3.26708 4.00779L0.152041 7.12293C-0.0506804 7.32555 -0.0506804 7.65317 0.152041 7.8558C0.253022 7.95688 0.385797 8.00765 0.518478 8.00765C0.651158 8.00765 0.783838 7.95688 0.884914 7.8558L3.99996 4.74066L7.11509 7.8558C7.21617 7.95688 7.34885 8.00765 7.48153 8.00765C7.61421 8.00765 7.74689 7.95688 7.84796 7.8558C8.05069 7.65317 8.05069 7.32555 7.84796 7.12293L4.73292 4.00779Z"
                fill="#FFFFFF"
              />
            </svg>
          </button>
        </div>
        <label
          for="min-input"
          class="tw-flex tw-items-center tw-min-w-[38px] tw-px-[12px] tw-bg-an-flat-gray-17 tw-text-an-flat-active-input tw-text-center
            group-hover/hover:tw-bg-an-flat-red-lighten group-hover/hover:tw-text-an-flat-red-main
            peer-focus:tw-text-an-flat-red-main
            peer-disabled:tw-text-an-flat-gray-1 peer-disabled:tw-bg-an-flat-disabled-input-bg"
        >
          {{ prefixStart }}
        </label>
      </div>
      <div
        v-if="!onlyMin"
        class="tw-w-full tw-flex tw-flex-row-reverse tw-font-an-flat-manrope tw-text-[13px] tw-font-medium tw-border-[1px] tw-border-an-flat-gray-17
                  tw-rounded-r-[6px] tw-rounded-l-[6px] tw-overflow-hidden
                  tw-outline tw-outline-1
                  tw-group/hover"
        :class="[disabled ? '':'hover:tw-shadow-[10px_8px_15px_-15px_rgba(0,0,0,0.3)]', errorText && !disableErrorBorder ? 'tw-outline-an-flat-red-main':'tw-outline-transparent']"
      >
        <label
          v-show="suffix"
          :for="uid + '-max-input'"
          class="tw-flex tw-items-center tw-justify-center tw-min-w-[38px] tw-px-[12px] tw-bg-an-flat-gray-17 tw-text-an-flat-active-input tw-text-center
            group-hover/hover:tw-bg-an-flat-red-lighten group-hover/hover:tw-text-an-flat-red-main
            peer-focus:tw-text-an-flat-red-main
            peer-disabled:tw-text-an-flat-gray-1 peer-disabled:tw-bg-an-flat-disabled-input-bg"
        >
          {{ suffix }}
        </label>
        <div class="tw-flex tw-w-full tw-relative">
          <input
            :id="uid + '-max-input'"
            :disabled="disabled"
            class="tw-w-full tw-block tw-py-[7px] tw-pr-[17px] tw-pl-[12px] tw-text-an-flat-header focus:tw-outline-none tw-peer tw-bg-white"
            :class="{ 'disabled:tw-bg-an-flat-grey-1 disabled:tw-text-an-flat-gray-1' : disabled }"
            pattern="\d"
            type="text"
            :placeholder="max"
            :value="stringValues.max"
            @input="onChange($event, 'max')"
          >
          <button
            v-if="cleanable && stringValues.max"
            class="tw-absolute tw-right-[3px] tw-top-1/2 -tw-translate-y-1/2 tw-p-[4px] tw-rounded-[100%] tw-bg-an-flat-red-main tw-hidden peer-hover:tw-block hover:tw-block"
            @click.stop="onChange(null, 'max')"
          >
            <svg
              class="tw-pointer-events-none"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.73292 4.00779L7.84796 0.892655C8.05069 0.690029 8.05069 0.362409 7.84796 0.159783C7.64534 -0.0428442 7.31772 -0.0428442 7.11509 0.159783L3.99996 3.27492L0.884914 0.159783C0.682192 -0.0428442 0.354668 -0.0428442 0.152041 0.159783C-0.0506804 0.362409 -0.0506804 0.690029 0.152041 0.892655L3.26708 4.00779L0.152041 7.12293C-0.0506804 7.32555 -0.0506804 7.65317 0.152041 7.8558C0.253022 7.95688 0.385797 8.00765 0.518478 8.00765C0.651158 8.00765 0.783838 7.95688 0.884914 7.8558L3.99996 4.74066L7.11509 7.8558C7.21617 7.95688 7.34885 8.00765 7.48153 8.00765C7.61421 8.00765 7.74689 7.95688 7.84796 7.8558C8.05069 7.65317 8.05069 7.32555 7.84796 7.12293L4.73292 4.00779Z"
                fill="#FFFFFF"
              />
            </svg>
          </button>
        </div>
        <label
          for="min-input"
          class="tw-flex tw-items-center tw-min-w-[38px] tw-px-[12px] tw-bg-an-flat-gray-17 tw-text-an-flat-active-input tw-text-center
            group-hover/hover:tw-bg-an-flat-red-lighten group-hover/hover:tw-text-an-flat-red-main
            peer-focus:tw-text-an-flat-red-main
            peer-disabled:tw-text-an-flat-gray-1 peer-disabled:tw-bg-an-flat-disabled-input-bg"
        >
          {{ prefixEnd }}
        </label>
      </div>
    </div>
    <div class="tw-hidden">
      {{ localValue }}
    </div>
  </div>
</template>

<script>
import lodashDebounce from 'lodash/debounce'

export default {
  name: 'TwInputMinMaxFields',
  props: {
    value: {
      type: [Array, Number, String],
      default: () => [null, null]
    },
    label: {
      type: String,
      default: ''
    },
    // сжатые поля
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableErrorBorder: {
      type: Boolean,
      default: false
    },
    min: {
      default: null
    },
    max: {
      default: null
    },
    suffix: {
      type: String,
      default: ''
    },
    prefixStart: {
      type: String,
      default: 'от'
    },
    prefixEnd: {
      type: String,
      default: 'до'
    },
    errorText: {
      type: String,
      default: null
    },
    cleanable: {
      type: Boolean,
      default: true
    },
    onlyMin: {
      type: Boolean,
      default: false
    },
    onlyMax: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      maxValue: null,
      minValue: null,
      customValue: {
        min: this.minValue,
        max: this.maxValue
      }
    }
  },
  methods: {
    onChange (event, type) {
      if (event === null) {
        if (type === 'min') {
          this.localValue = [null, this.maxValue]
        } else if (type === 'max') {
          this.localValue = [this.minValue, null]
        }
      } else {
        // Разрешаем ввод только цифр
        event.target.value = event.target.value.replace(/\D+/g, '')
        // По типу смотрим, куда мы присваиваем значения event
        // Делаем это для того, чтобы данные при любом вводе перезаписывались
        if (type === 'min') {
          this.minValue = event.target.value.replace(/\s+?/g, '')
        } else if (type === 'max') {
          this.maxValue = event.target.value.replace(/\s+?/g, '')
        }
        // Вызываем функцию валидации наших значений min/max
        if (type === 'min' || type === 'max') {
          this.debounceMethod(event)
        } else {
          // Если это не min/max, то выводим ошибку
          console.error('BAD TYPE', this.$options.name, event, type)
        }
      }
    },

    // Функция валидации min/max
    debounceMethod: lodashDebounce(function (event) {
      // создаём объект для быстрого доступа
      const result = {
        min: this.minValue,
        max: this.maxValue
      }
      if (result.max === null) {
        result.max = ''
      }
      if (!result.min === null) {
        result.min = ''
      }

      // Удаление символа 0, если он есть
      if (result.min && result.min.length >= 2 && result.min[0] === '0') {
        let str = result.min
        str = str.replace('0', '')
        result.min = str
      }
      if (result.max && result.max.length >= 2 && result.max[0] === '0') {
        let str = result.max
        str = str.replace('0', '')
        result.max = str
      }

      // Если у нас есть значение min и макс
      if (result.min && result.min.length > 0 && result.max && result.max.length > 0) {
        // То проверяем, чтобы min, не был больше макс
        if (Number(result.min) > Number(result.max)) { result.min = result.max }
      }

      // Если max имеет значение, то проводится валидацмя max
      if (result.max) {
        if (Number(result.max) > Number(this.max) && this.max !== null) {
          result.max = this.max
        } else if (Number(result.max) < Number(this.min) && this.min !== null) {
          result.max = this.min
        }
      }
      // Если min имеет значение, то проводится валидация min
      if (result.min) {
        if (Number(result.min) > Number(result.max) && result.max !== '') {
          result.min = result.max
        } else if (Number(result.min) < Number(this.min) && this.min !== null) {
          result.min = this.min
        } else if (Number(result.min) > Number(this.max) && this.max !== null) {
          result.min = this.max
        }
      }

      // Если какое-то из значений пустое, то возвращаем null, иначе Number значения
      result.min === '' || result.min === null ? result.min = null : result.min = Number(result.min)
      result.max === '' || result.max === null ? result.max = null : result.max = Number(result.max)
      // Изменяем данные в Data
      this.minValue = result.min
      this.maxValue = result.max
      // Отдаём данные на компонент выше через v-model
      this.localValue = [result.min, result.max]
      this.$set(this, 'customValue', result)
      // Сносим значение event, чтобы данные с прошлой валидации не применялись
      if (event.target) {
        event.target.value = ''
      }
    }, 500),

    // Валидация данных поступающих от родителя
    parentDataValidation (array, bool) {
      const result = {
        min: array[0],
        max: array[1]
      }
      if (result.max === null) {
        result.max = ''
      } else if (result.max === 0) {
        result.max = '0'
      }
      if (result.min === null) {
        result.min = ''
      } else if (result.min === 0) {
        result.min = '0'
      }

      // Если max имеет значение, то проводится валидацмя max
      if (result.max) {
        if (Number(result.max) > Number(this.max) && this.max !== null) {
          result.max = this.max
        } else if (Number(result.max) < Number(this.min) && this.min !== null) {
          result.max = this.min
        }
      }
      // Если min имеет значение, то проводится валидация min
      if (result.min) {
        if (Number(result.min) > Number(result.max) && result.max !== '') {
          result.min = result.max
        } else if (Number(result.min) < Number(this.min) && this.min !== null) {
          result.min = this.min
        } else if (Number(result.min) > Number(this.max) && this.max !== null) {
          result.min = this.max
        }
      }

      this.$set(this, 'customValue', result)
      this.minValue = result.min
      this.maxValue = result.max

      if (bool) {
        return [result.min, result.max]
      }
    }
  },
  computed: {
    isRequired () {
      // определение обязательности поля
      // - если тестовая ошибка имеет дефолтное значение
      // и если текст лейбла показан над полем ввода
      return this.errorText !== null && !!this.label
    },
    sizeClasses () {
      if (this.dense) {
        return 'tw-min-h-[40px] tw-text-main-xs tw-min-w-[100px]'
      } else {
        return 'tw-min-h-[52px] tw-text-main-sm2 tw-min-w-[100px]'
      }
    },
    localValue: {
      get () {
        if (this.onlyMin) {
          return this.parentDataValidation([this.value, null], true)
        } else if (this.onlyMax) {
          return this.parentDataValidation([null, this.value], true)
        }
        return this.parentDataValidation(this.value, true)
      },
      set (val) {
        // console.warn('localValue', val)
        if (this.onlyMin) {
          this.$emit('input', val[0])
        } else if (this.onlyMax) {
          this.$emit('input', val[1])
        } else {
          this.$emit('input', val)
        }
      }
    },
    stringValues () {
      // Данные, которые передаются в Value
      let stringMin = ''
      let stringMax = ''
      // Если в customValue пустая строка, то возвращаем пустую строку, иначе Number значения
      if (this.customValue.min === 0) {
        stringMin = '0'
      } else {
        !this.customValue?.min ? stringMin = '' : stringMin = Number(this.customValue?.min).toLocaleString('ru')
      }

      if (this.customValue.max === 0) {
        stringMax = '0'
      } else {
        !this.customValue?.max ? stringMax = '' : stringMax = Number(this.customValue?.max).toLocaleString('ru')
      }
      return {
        min: stringMin,
        max: stringMax
      }
    },
    uid () {
      return `${this.$options.name}_${this._uid}`
    }
  },
  watch: {
    value (newArray, oldArray) {
      if (this.onlyMin) {
        this.parentDataValidation([newArray, null], false)
      } else if (this.onlyMax) {
        this.parentDataValidation([null, newArray], false)
      } else if (newArray[0] !== oldArray[0] || newArray[1] !== oldArray[1]) {
        this.parentDataValidation(newArray, false)
        this.debounceMethod('')
      }
    }
  }
}
</script>
