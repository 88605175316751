<template>
  <div class="view-panel tw-font-manrope">
    <div v-if="targetPanelComponent.error">
      <div>
        {{ $options.name }} - компонент не определён!
        <pre>
          {{ targetPanelComponent }}
        </pre>
        <pre>
          {{ info }}
        </pre>
      </div>
    </div>
    <component
      :is="targetPanelComponent"
      v-else
      ref="subComponent"
      v-bind="info.props"
      from-panel
      @callback="info.callback"
      @close-all="$emit('close-all')"
      @close-me="$emit('close-me')"
      @refresh-all="$emit('refresh-all')"
    />
  </div>
</template>

<script>
import PersonCard from '@/views/Person/PersonCard'
import VariantSelection from '@/views/Mortgage/Variant/Matrix/VariantSelectionMatrix'
import LeadCard from '@/views/Lead/LeadCard'
import EmployeeCard from '@/views/Company/Employee/EmployeeCard'
import CompanyCard from '@/views/Dictionary/General/Company/CompanyCard'
import MortgageProgramCard from '@/views/Mortgage/Program/MortgageProgramCard'
import DealCard from '@/views/Deal/DealCard'
import DocumentCard from '@/views/Dictionary/General/Document/DocumentCard'
import RequirementCard from '@/views/Mortgage/Requirement/RequirementCard'
import EmailCard from '@/views/Email/EmailCard'
import UserEmailList from '@/views/Email/List'
import MortgageProgramWorksheetCard from '@/views/MortgageProgramWorksheet/MortgageProgramWorksheetCard'
import SupportTaskCard from '@/views/Support/task/SupportTaskCard'
import DealContractCard from '@/views/Company/Contract/DealContractCard'
import RealtyComplexCard from '@/views/Newbuildings/Complex/RealtyComplexCard'
import RealtyComplexPageCard from '@/views/Newbuildings/Complex/RealtyComplexPageCard'
import NotFound from '@/views/NotFound'
import ImportBuilderFileCard from '@/views/BuilderImporter/ImportBuilderFile/ImportBuilderFileCard'
import ImportSiteDeveloperFileCard from '@/views/BuilderImporter/ImportSiteDeveloperFile/ImportSiteDeveloperFileCard'
import ImportDocumentFileCard from '@/views/BuilderImporter/ImportDocumentFile/ImportDocumentFileCard.vue'
import RealtyPlanCard from '@/views/Newbuildings/Editor/Plans/RealtyPlanCard'
import DomClickWorksheetCard from '@/views/MortgageProgramWorksheet/DomClickWorksheet/DomClickWorksheetCard'
import DiscountMatrixCard from '@/views/Mortgage/DiscountMatrix/DiscountMatrixCard'
import NbSelectionApartmentCardModal from '@/views/Newbuildings/view/ApartmentInModal/NbSelectionApartmentCardModal.vue'
import BuilderProjectCard from '@/views/Newbuildings/Editor/BuilderProjects/BuilderProjectCard.vue'
import ManagementRequestToDeveloperCard from '@/views/Newbuildings/Management/Request/ManagementRequestToDeveloperCard.vue'
import RequestToDeveloperPanel from '@/views/Newbuildings/RequestToDeveloper/RequestToDeveloperPanel.vue'
import ModalInformationShow from '@/components/core/ViewPanel/Modal/ModalInformationShow.vue'
import DealReviewCard from '@/views/Review/DealReview/DealReviewCard.vue'
import DealNbResultManagementCard from '@/views/Newbuildings/ResultManagement/DealNbResultManagementCard.vue'
import UserSurveysCard from '@/views/Company/UserSurveys/UserSurveysCard.vue'
// TODO TWF - это реально нужно в глобальном доступе??? Разобраться и перенести в локальную модалк
import NbShowingHousePriceDynamics from '@/views/Newbuildings/view/ApartmentInModal/NbShowingHousePriceDynamics.vue'
import CompanyPeriodResultManagementCard from '@/views/Newbuildings/CompanyPeriodResultManagement/CompanyPeriodResultManagementCard.vue'
import NbEditorUniversalObjectCard from '@/views/Newbuildings/Editor/NbEditorUniversalObjectCard.vue'
import DocCheckKonturCard from '@/views/Tools/DocCheckKontur/DocCheckKonturCard.vue'
import SamplePanelCard from '@/views/Dev/UiKit/SamplePanelCard.vue'

export default {
  name: 'ViewPanel',
  components: {
    ModalInformationShow,
    DomClickWorksheetCard,
    ImportBuilderFileCard,
    ImportSiteDeveloperFileCard,
    NotFound,
    RealtyComplexCard,
    RealtyComplexPageCard,
    DealContractCard,
    SupportTaskCard,
    MortgageProgramWorksheetCard,
    UserEmailList,
    EmailCard,
    RequirementCard,
    DocumentCard,
    DealCard,
    MortgageProgramCard,
    CompanyCard,
    EmployeeCard,
    LeadCard,
    VariantSelection,
    PersonCard,
    BuilderProjectCard,
    NbSelectionApartmentCardModal,
    ManagementRequestToDeveloperCard,
    DealReviewCard,
    DealNbResultManagementCard,
    UserSurveysCard,
    // TODO TWF - это реально нужно в глобальном доступе??? Разобраться и перенести в локальную модалку
    NbShowingHousePriceDynamics,
    CompanyPeriodResultManagementCard
  },
  props: {
    info: {
      default: 'none info'
    }
  },
  computed: {
    targetPanelComponent () {
      if (this.info?.component) {
        switch (this.info.component) {
          case 'person-show':
            return PersonCard
          case 'mortgage-variant-selection':
            return VariantSelection
          case 'lead-show':
            return LeadCard
          case 'employee-show':
            return EmployeeCard
          case 'company-show':
            return CompanyCard
          case 'deal-show':
            return DealCard
          case 'mortgage-program-show':
            return MortgageProgramCard
          case 'document-show':
            return DocumentCard
          case 'requirement-show':
            return RequirementCard
          case 'email-show':
            return EmailCard
          case 'email-list-show':
            return UserEmailList
          case 'mortgage-program-worksheet-show':
            return MortgageProgramWorksheetCard
          case 'support-show':
            return SupportTaskCard
          case 'deal-contract-show':
            return DealContractCard
          case 'realty-complex-show': // Информация о комплексе с редактированием для админов и тех у кого есть права(Управление)
            return RealtyComplexCard
          case 'realty-complex-show-page': // Информация о комплексе в виде отдельной страницы - для риелторов (Просмотр)
            return RealtyComplexPageCard
          case 'realty-builder-show':
            return CompanyCard // Карточка застройщика заменена на карточку компании, туда пересена вся инфа о застройщике и настройки
          case 'import-builder-file-show':
            return ImportBuilderFileCard
          case 'import-site-developer-file-show':
            return ImportSiteDeveloperFileCard
          case 'import-document-file-show':
            return ImportDocumentFileCard
          case 'realty-plan-show':
            return RealtyPlanCard
          case 'domclick-worksheet-show':
            return DomClickWorksheetCard
          case 'discount-matrix-show':
            return DiscountMatrixCard
          // TODO TWF - это реально нужно в глобальном доступе??? Разобраться и перенести в локальную модалку
          case 'nb-showing-house-price-dynamics':
            return NbShowingHousePriceDynamics
          case 'company-period-result-management-show':
            return CompanyPeriodResultManagementCard
          case 'management-request-to-developer-show':
            return ManagementRequestToDeveloperCard
          case 'nb-request-to-developer-panel':
            return RequestToDeveloperPanel
          case 'modal-information-show':
            return ModalInformationShow
          case 'deal-review-show':
            return DealReviewCard
          case 'deal-nb-result-management-show':
            return DealNbResultManagementCard
          case 'user-surveys-show':
            return UserSurveysCard
          // Карточки работы с объектами недвижимости
          case 'builder-project-show':
            // Карточка просмотра информации о проекте застройщика на дом
            return BuilderProjectCard
          case 'nb-selection-apartment-modal-show':
            // Карточка просмотра информации о объекте новостройки - в модальном окне как в подборе
            return NbSelectionApartmentCardModal
          case 'nb-editor-object':
            // Универсальная карточка для редактора объектов, обязательный пропс [type] - без него не опрелить тип недвижимости и его UI
            return NbEditorUniversalObjectCard
            // Проверка клиента сервисом контур
          case 'check-kontur-show':
            return DocCheckKonturCard
          case 'sample-panel-show':
            // пример карточки для UI кита
            return SamplePanelCard
          default:
            return NotFound
        }
      }
      return {
        error: 'Error! Component name not found!',
        data: this.info
      }
    }
  },
  methods: {
    refreshPanel () {
      if (this.$refs.subComponent && this.$refs.subComponent.refresh) {
        this.$refs.subComponent.refresh()
      } else {
        console.error('REFRESH method not found: ', this.$options.name, this.$refs.subComponent)
      }
    }
  }
}
</script>

<style scoped>
.view-panel {
  width: 100%;
  height: 100%;
  border-left: 1px solid #eef2f4;
  position: relative;
  overflow: auto;
}
</style>
