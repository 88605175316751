import isEqual from 'lodash/isEqual'

export default {
  props: {
    id: {
      type: [Number, String],
      default: 0
    },
    flat: {
      type: Boolean,
      default: false
    },
    inModal: {
      type: Boolean,
      default: false
    },
    showReset: {
      type: Boolean,
      default: true
    },
    copyData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      API: null,
      formProcessing: false,
      relatedObjectId: null, // ИД связанного объекта, используемого для подстановки в URL при запросе
      createQueryParams: {}, // Параметры отправляемые при инициализации формы
      dictionary: {}, // Словари
      scheme: {}, // Схемы данных инструкции для фронта с бека
      permissions: {}, // Разрешения
      allData: {},
      preset: {},
      form: {},
      originalForm: {},
      serverErrors: {},

      // Получение читабельной ошибки по полям
      // .catch((error) => {
      //   this.serverErrorMessage = error.serverErrorMessage || null
      // })
      serverErrorMessage: null,

      requireRules: [
        v => {
          return !!v || 'Поле обязательное'
        }
      ],

      requireArrayRules: [
        v => !!(Array.isArray(v) && v.length) || 'Поле обязательное'
      ],

      requireBooleanRules: [
        v => typeof v === 'boolean' || 'Поле обязательное'
      ],

      percentRules: [
        v => (v >= 0 && v <= 100) || '% должен быть от 0 до 100'
      ],
      nameRules: [
        v => !!v || 'Поле обязательное'
      ],
      genderRules: [
        v => !!v || 'Поле обязательное'
      ],
      emailRules: [
        (v) => /^\S+@\S+\.\S+$/.test(v) || 'Неправильный E-mail'
      ],
      emailRequireRules: [
        (v) => !!v || 'Поле обязательное',
        (v) => /^\S+@\S+\.\S+$/.test(v) || 'Неправильный E-mail'
        // (v) => /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный E-mail' // todo old validation regex
      ],
      phoneRules: [
        (v) => (!!v && v.toString().replace(/[^\d]/g, '') && v.replace(/[^\d]/g, '').length >= 11) || 'Неверный формат номера телефона'
      ],
      passwordRules: [
        v => !!v || 'Поле обязательное'
      ],
      confirmPasswordRules: [
        v => !!v || 'Поле обязательное',
        v => v === this.form.password || 'Пароли не совпадают'
      ],

      booleanRadioOptions: [
        {
          label: 'Да',
          value: true
        },
        {
          label: 'Нет',
          value: false
        }
      ]
    }
  },
  methods: {
    handleCancel () {
      this.$emit('cancel')
    },
    handleReset () {
      this.form = JSON.parse(JSON.stringify(this.originalForm))
    },
    copyOriginalForm () {
      this.originalForm = JSON.parse(JSON.stringify(this.form))
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            // this.form[key] = this.preset[key]
            this.$set(this.form, key, this.preset[key])
          }
        }
        resolve()
      })
    },
    fetchData (id = this.targetId, params = {}) {
      // TODO
      // иерархия приоритетов ID id, this.$props.id
      // определение типа формы Create || Edit в computed.formType на основе иерархии описаний выше
      let targetId = id || this.targetId
      targetId = targetId && targetId !== '0' ? targetId : null
      // console.warn('fetchData', { targetId, id, todo1: this.targetId }, params)
      if (this.API) {
        if (this.formType === 'edit' && targetId) {
          this.formProcessing = true
          // ИД связанного объекта приоритетней - так как будет вставлен в URL вместо редактируемого объета - это для тех случаев когда сущность редактируется не
          this.API.show(this.relatedObjectId || targetId, params)
            .then((response) => {
              // console.error(this.$options.name, response)
              this.permissions = response?.data?.permissions || {}
              this.dictionary = response?.data?.dictionary || {}
              this.scheme = response?.data?.scheme || {}
              this.preset = response?.data?.data || {}
              this.presetData()?.finally(() => {
                this.copyOriginalForm()
              })
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'create') {
          this.formProcessing = true
          this.API.create({ ...(this.createQueryParams || {}), ...(params || {}) }, this.relatedObjectId)
            .then((response) => {
              // console.error(this.$options.name, response)
              this.allData = response?.data || {}
              this.permissions = response?.data?.permissions || {}
              this.dictionary = response?.data?.dictionary || {}
              this.scheme = response?.data?.scheme || {}
              if (this.copyData) {
                // При наличии данных для копирования - добавляю их в preset
                this.preset = this.copyData
              } else {
                // иначе добавляю дефолтные данные с сервера
                this.preset = response?.data?.data
              }
              this.presetData()?.finally(this.copyOriginalForm)
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else {
          console.error('undefined action: ', this.$options.name, this.$route)
        }
      } else {
        alert(`Error variable API: ${this.API}, in ${this.$options.name}`)
      }
    },
    handleSubmit () {
      // метод который будет прописан внутри шаблона
      alert(`${this.$options.name} - Отсутствует метод: handleSubmit()`)
    },
    successedSubmit () {
      if (!this.inModal) {
        const prevPage = this.$store.getters.getPrevPage
        if (prevPage && prevPage.name && this.$route.name !== prevPage.name) {
          this.$router.push({
            name: prevPage.name,
            props: prevPage.props
          })
        } else {
          this.$router.go(-1)
        }
      }
    }
  },
  computed: {
    isEdited () {
      const status = !isEqual(this.form, this.originalForm)
      // console.log('change-edited-status ', status)
      this.$emit('change-edited-status', status)
      return status
    },
    formType () {
      if (this.targetId) {
        return 'edit'
      } else {
        return 'create'
      }
    },
    targetId () {
      return this.id || null
    },
    formPermissionActionsStatus () {
      const { entity } = this.permissions || {}
      return {
        create: entity?.create || null,
        edit: entity?.update || null
      }
    }
  },
  watch: {
    targetId (newValue) {
      this.fetchData(newValue)
    }
  },
  mounted () {
    this.fetchData()
  }
}
