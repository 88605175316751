<template>
  <div
    :id="uniqId"
    class="tw-font-manrope tw-text-main-sm tw-relative"
    :style="{ zIndex }"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <transition
      enter-active-class="tw-ease-out tw-duration-300"
      enter-from-class="tw-transform tw-opacity-0"
      enter-to-class="tw-opacity-100"
      leave-active-class="tw-ease-in tw-duration-200"
      leave-from-class="tw-opacity-100"
      leave-to-class="tw-transform tw-opacity-0"
    >
      <div
        v-if="isVisible"
        class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"
      />
    </transition>

    <div
      v-show="isVisible"
      class="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-hidden"
    >
      <div class="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
        <transition
          enter-active-class="tw-ease-out tw-duration-300"
          enter-from-class="tw-transform tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          enter-to-class="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave-active-class="tw-ease-in tw-duration-200"
          leave-from-class="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave-to-class="tw-transform tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
        >
          <div
            v-if="isVisible"
            :style="{ maxWidth: maxWidth }"
            :class="paddingClass"
            class="tw-relative tw-transform tw-overflow-hidden tw-text-left tw-shadow-xl tw-transition-all tw-bg-an-flat-bg-main tw-rounded-2xl sm:tw-my-8 sm:tw-w-full sm:tw-max-w-[90vw]"
          >
            <button
              v-if="isCloseBtn"
              :disabled="disableClose"
              class="tw-absolute tw-top-0 tw-right-0 tw-pr-[20px] tw-pt-[20px] tw-group"
              @click.stop="onClose"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="enabled:group-hover:tw-fill-an-flat-red-main"
                  d="M17.7071 16.2933C17.8054 16.3848 17.8842 16.4952 17.9388 16.6179C17.9935 16.7406 18.0229 16.873 18.0252 17.0073C18.0276 17.1415 18.0029 17.2749 17.9526 17.3994C17.9023 17.5239 17.8275 17.6371 17.7325 17.732C17.6375 17.827 17.5244 17.9018 17.3999 17.9521C17.2754 18.0024 17.142 18.0271 17.0078 18.0247C16.8735 18.0224 16.7411 17.993 16.6184 17.9383C16.4957 17.8837 16.3853 17.8049 16.2938 17.7066L9.00045 10.4146L1.70711 17.7066C1.51755 17.8833 1.26682 17.9794 1.00775 17.9749C0.748681 17.9703 0.501501 17.8653 0.318284 17.6821C0.135066 17.4989 0.0301174 17.2517 0.0255464 16.9927C0.0209755 16.7336 0.117139 16.4829 0.293779 16.2933L7.58578 8.99996L0.293779 1.70662C0.117139 1.51706 0.0209755 1.26633 0.0255464 1.00726C0.0301174 0.748193 0.135066 0.501013 0.318284 0.317795C0.501501 0.134578 0.748681 0.0296291 1.00775 0.0250581C1.26682 0.0204872 1.51755 0.116651 1.70711 0.293291L9.00045 7.58529L16.2938 0.293291C16.4833 0.116651 16.7341 0.0204872 16.9931 0.0250581C17.2522 0.0296291 17.4994 0.134578 17.6826 0.317795C17.8658 0.501013 17.9708 0.748193 17.9753 1.00726C17.9799 1.26633 17.8838 1.51706 17.7071 1.70662L10.4151 8.99996L17.7071 16.2933Z"
                  fill="#A1A5A9"
                />
              </svg>
            </button>
            <slot name="content">
              <div class="tw-max-h-[80vh] tw-overflow-y-auto">
                <div class="tw-z-10 tw-sticky tw-top-0 tw-bg-an-flat-bg-main tw-pb-[15px]">
                  <slot name="header" />
                </div>
                <div class="tw-relative">
                  <slot name="body" />
                </div>
                <div class="tw-sticky tw-bottom-0">
                  <slot name="footer" />
                </div>
              </div>
            </slot>
            <div>
              <slot
                name="actions"
                v-bind="{ onClose }"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isCloseBtn: {
      type: Boolean,
      default: true
    },
    disableClose: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 10
    },
    maxWidth: {
      type: String
    },
    paddingClass: {
      type: String,
      default: 'tw-px-[46px] tw-py-[26px]'
    }
  },
  data () {
    return {
      // Локальная видимость - для того чтобы можно было использовать компонент без передачи value и вызывать через ref
      isVisible: false,
      tmpDocOverflow: ''
    }
  },
  computed: {
    uniqId () {
      return `${this.$options.name}-${this._uid}`
    }
  },
  watch: {
    value (newValue) {
      if (newValue) {
        this.onOpen()
      } else {
        this.onClose()
      }
    }
  },
  beforeDestroy () {
    this.removeElFromDom()
  },
  mounted () {
    // Нужно для hot-reload режима разработки
    if (this.$el && this.value) {
      document.querySelector('body').appendChild(this.$el)
    }
  },
  methods: {
    onOpen () {
      this.isVisible = true
      this.tmpDocOverflow = document.documentElement?.style?.overflow || ''
      document.documentElement.style.overflow = 'hidden'
      document.querySelector('body').appendChild(this.$el)
      this.$emit('input', true)
      this.$emit('open')
      document.addEventListener('keydown', this.keyDownHandler)
    },
    onClose () {
      this.isVisible = false
      document.documentElement.style.overflow = this.tmpDocOverflow || ''
      this.removeElFromDom()
      this.$emit('input', false)
      this.$emit('close')
      document.removeEventListener('keydown', this.keyDownHandler)
    },
    removeElFromDom () {
      const removeEl = document.getElementById(this.uniqId)
      if (removeEl) {
        document.querySelector('body').removeChild(removeEl)
      }
    },
    keyDownHandler (e) {
      if (e.code === 'Escape') {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>

</style>
