const pluralDict: any = {
  file: {
    one: 'файл',
    two: 'файла',
    five: 'файлов'
  },
  year: {
    one: 'год',
    two: 'года',
    five: 'лет'
  },
  month: {
    one: 'месяц',
    two: 'месяца',
    five: 'месяцев'
  },
  day: {
    one: 'день',
    two: 'дня',
    five: 'дней'
  },
  hour: {
    one: 'час',
    two: 'часа',
    five: 'часов'
  },
  minute: {
    one: 'минута',
    two: 'минуты',
    five: 'минут'
  },
  user: {
    one: 'пользователь',
    two: 'пользователя',
    five: 'пользователей'
  },
  employee: {
    one: 'сотрудник',
    two: 'сотрудника',
    five: 'сотрудников'
  },
  members: {
    one: 'участник',
    two: 'участника',
    five: 'участников'
  },
  ruble: {
    one: 'рубль',
    two: 'рубля',
    five: 'рублей'
  },
  rub: {
    one: 'руб.',
    two: 'руб.',
    five: 'руб.'
  },
  element: {
    one: 'элемент',
    two: 'элемента',
    five: 'элементов'
  },
  lead: {
    one: 'лид',
    two: 'лида',
    five: 'лидов'
  },
  deal: {
    one: 'заявка',
    two: 'заявки',
    five: 'заявок'
  },
  message: {
    one: 'непрочитанное сообщение',
    two: 'непрочитанных сообщения',
    five: 'непрочитанных сообщений'
  },
  object: {
    one: 'объект',
    two: 'объекта',
    five: 'объектов'
  },
  buildingKorp: {
    one: 'корпус',
    two: 'корпуса',
    five: 'корпусов'
  },
  unit: {
    one: 'подразделение',
    two: 'подразделения',
    five: 'подразделений'
  }
}

export const plural = (number:number, type:string) => {
  // console.warn('number:number, type', number, type)
  let count = Math.abs(number)
  count %= 100
  if (count >= 5 && count <= 20) {
    return pluralDict[type].five
  }
  count %= 10
  if (count === 1) {
    return pluralDict[type].one
  }
  if (count >= 2 && count <= 4) {
    return pluralDict[type].two
  }
  return pluralDict[type].five
}
