<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <div
      v-if="loading && !data"
      class="tw-py-3 tw-w-full tw-min-h-screen tw-flex tw-justify-center tw-items-center"
    >
      <TwCircleLoader
        width="50px"
        height="50px"
      />
    </div>
    <template v-else-if="data || isNew">
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            Пpоверка Контур
            <span
              v-if="isNew"
              class="tw-text-an-flat-disabled-text tw-font-medium"
            >Новая</span>
            <span
              v-else-if="data && data.id"
              class="tw-text-an-flat-disabled-text tw-font-medium"
            ># {{ data.id }}</span>
          </div>
        </template>
        <template #right>
          <TwPanelActionButton
            color="red"
            :disabled="loading"
          >
            Сделать что то
          </TwPanelActionButton>
        </template>
      </TwPanelTitle>
      <TwPanelTabs>
        <button class="tw-bg-white tw-rounded-sm tw-px-[14px] tw-py-[8px]">
          Общая информация
        </button>
      </TwPanelTabs>
      <TwPanelContent>
        <div class="tw-w-full">
          <TwPanelInfoBlock
            type="warning"
            class="tw-mb-[6px]"
          >
            <template #title>
              Внимание!
            </template>
            <template #text>
              <div>
                Не все проверки прошли - перезапустите проверку! TODO DEV
              </div>
            </template>
          </TwPanelInfoBlock>
        </div>
        <div class="tw-flex-1">
          <TwPanelContentBlock class="tw-mb-[6px]">
            <template #title>
              <div>
                Информация о клиенте
              </div>
            </template>
            <template #action>
              <TwPanelContentBlockActionButton
                v-if="isNew"
                :disabled="isNew || loading"
                class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
                @click="editMain = !editMain"
              >
                {{ editMain ? 'отменить':'изменить параметры запроса' }}
              </TwPanelContentBlockActionButton>
            </template>
            <div>
              <DocCheckKonturPersonForm
                v-if="editMain"
                :id="data ? data.id : 0"
                @create="onCreated"
                @update="onUpdated"
                @cancel="onCancelAndClose"
              />
              <DocCheckKonturPersonShow
                v-else
                :dictionary="dictionary"
                :info="data"
                @refresh="refresh"
              />
              <pre>
                {{ data }}
              </pre>
            </div>
          </TwPanelContentBlock>
        </div>
        <div class="tw-flex-1">
          adsasd
        </div>
      </TwPanelContent>
    </template>
  </div>
</template>

<script>
import API from '@/Api/DocCheck/Kontur/Check/Person'
import { mapGetters } from 'vuex'
import CardMixin from '@/mixins/CardMixin'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelTabs from '@/components/tw-ui/panels/TwPanelTabs.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'
import DocCheckKonturPersonForm from './main/DocCheckKonturPersonForm.vue'
import DocCheckKonturPersonShow from './main/DocCheckKonturPersonShow.vue'

export default {
  name: 'DocCheckKonturPersonCard',
  components: {
    TwPanelInfoBlock,
    TwPanelContentBlockActionButton,
    DocCheckKonturPersonShow,
    DocCheckKonturPersonForm,
    TwPanelActionButton,
    TwCircleLoader,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTabs,
    TwPanelTitle
  },
  mixins: [CardMixin],
  data () {
    return {
      API
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    })
  }
}
</script>
