<template>
  <TwPanelContentBlock>
    <template #title>
      <div>
        Скачивание документов
      </div>
    </template>
    <PageViewDefaultWrapper
      v-if="Array.isArray(data) && data.length"
      ref="wrapper"
      header-class="mb-2"
      :permission-denied="permissionDenied"
      relative
    >
      <template #header />
      <template>
        <v-data-table
          fixed-header
          item-key="id"
          :loading="loading"
          :loading-text="loadingText"
          :no-data-text="noDataText"
          :no-results-text="noResultsText"
          :headers="columns"
          :items="data"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :disable-sort="true"
          @dblclick:row="doubleRowClick"
        >
          <template #item.menu="{ item }">
            <v-btn
              title="Скачать"
              color="primary"
              small
              @click="download(item)"
            >
              <v-icon
                left
                small
              >
                fa-download
              </v-icon> Скачать
            </v-btn>
          </template>
        </v-data-table>
      </template>
      <template #footer>
        <DefaultListPagination
          class="text-center pt-2"
          :disabled="loading"
          :pagination="pagination"
          :page-count="pageCount"
          @change="changePagination"
        />
      </template>
    </PageViewDefaultWrapper>
    <div
      v-else
      class="tw-text-center tw-text-an-flat-disabled-text"
    >
      Документы отсутствуют
    </div>
  </TwPanelContentBlock>
</template>

<script>
import DefaultListPagination from '@/components/list/pagination/DefaultListPagination'
import DataListMixin from '@/mixins/DataListMixin'
import API from '@/Api/Crm/Dictionary/General/Document'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  name: 'DocumentDownloadList',
  components: { TwPanelContentBlock, PageViewDefaultWrapper, DefaultListPagination },
  mixins: [DataListMixin],
  props: {
    title: {
      type: String,
      default: 'Документы'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    dealId: {
      type: Number,
      default: 0
    },
    dealProgramId: {
      type: Number,
      default: 0
    },
    entityId: {
      type: Number
    },
    section: {
      type: String,
      default: ''
    },
    subsection: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      defaultFilters: {
        active: true
      },
      API,
      columns: [
        {
          text: 'Название',
          value: 'name',
          width: 'auto'
        },
        {
          text: '',
          value: 'menu',
          width: '70px'
        }
      ]
    }
  },
  mounted () {
    this.fetch({
      section: this.section,
      subsection: this.subsection,
      entityId: this.entityId
    })
  },
  methods: {
    download (item) {
      // EXAMPLE http://anflat.crm3:8080/api/v1/dictionary/document/1/download?id=1&dealId=7
      const url = `${this.$store.getters.getApiUrl}dictionary/document/${item.id}/download?id=${item.id}&dealId=${this.dealId}&dealProgramId=${this.dealProgramId}`
      // console.log('DOC download from ID:', item, url)
      this.API.download(url)
    },
    doubleRowClick (event, rowInfo) {
      console.log('doubleRowClick: ', this.$options.name)
      this.edit(rowInfo.item)
    },
    edit (item) {
      console.log('edit', item)
      this.$router.push({
        name: 'document-edit',
        params: { id: item.id }
      })
    },
    remove (item) {
      console.log('remove', item)
      this.API.delete(item.id).finally(() => {
        this.fetch()
      })
    }
  }
}
</script>

<style scoped>

</style>
