<template>
  <div class="card-container">
    <div
      v-if="!loading || data"
      class="px-3"
    >
      <div class="pa-2 d-flex align-center">
        <div
          v-if="data"
          class="title"
        >
          {{ data.name }}
          <span class="text-muted ml-2">
            # {{ data.id }}
          </span>
        </div>
        <div
          v-else
          class="title text-muted"
        >
          Новый документ
        </div>
      </div>
      <v-divider />
      <div class="py-2 body-2">
        <v-tabs
          v-model="currentTab"
          show-arrows
          background-color="transparent"
        >
          <v-tab>Общие</v-tab>
          <v-tab disabled>
            История
          </v-tab>
          <v-tabs-items
            v-model="currentTab"
            style="background-color: transparent"
          >
            <v-tab-item>
              <v-layout>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-card
                    flat
                    tile
                    class="my-2 card-tab-block"
                  >
                    <v-card-subtitle class="d-flex">
                      О документе
                      <v-spacer />
                      <v-btn
                        x-small
                        text
                        :color="editMain ? 'primary':'secondary'"
                        :disabled="isNew"
                        @click="editMain = !editMain"
                      >
                        {{ editMain ? 'отменить':'изменить' }}
                      </v-btn>
                    </v-card-subtitle>
                    <v-divider />
                    <v-card-text>
                      <DocumentMainForm
                        v-if="editMain"
                        :id="data ? data.id : 0"
                        ref="EditMain"
                        @create="onCreated"
                        @update="onUpdated"
                        @cancel="editMain = false"
                      />
                      <DocumentMainShow
                        v-else
                        :dictionary="dictionary"
                        :info="data"
                        @refresh="refresh"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-card
                    flat
                    tile
                    class="my-2"
                  >
                    <v-card-subtitle class="d-flex">
                      Данные <span class="text-muted" />
                    </v-card-subtitle>
                    <v-divider />
                    <v-card-text>
                      В разработке...
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-layout>
            </v-tab-item>
            <v-tab-item>
              <v-card-text>
                В разработке...
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
    </div>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="filler">
      <v-progress-circular
        v-if="loading"
        size="50"
        color="primary"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Dictionary/General/Document/index'
import CardMixin from '@/mixins/CardMixin'
import DocumentMainForm from '@/views/Dictionary/General/Document/main/DocumentMainForm'
import DocumentMainShow from '@/views/Dictionary/General/Document/main/DocumentMainShow'

export default {
  name: 'DocumentCard',
  components: { DocumentMainShow, DocumentMainForm },
  mixins: [CardMixin],
  data () {
    return {
      API: API
    }
  }
}
</script>
