<template>
  <TwPanelTabsFull
    v-model="currentTab"
    :items="tabList"
  >
    <template #content.main>
      <DealGeneralTab
        :deal="regroupDealData.mainDeal"
        :deal-state="dealState"
        :dictionary="dictionary"
        :permissions="permissions"
        :is-new="isNew"
        :edit-main="editMain"
        :loading="loading"
        @changeEdit="editMain = $event"
        @refresh="refreshDeal"
        @create="refreshDeal"
        @update="refreshDeal"
      />
    </template>
    <template #content.client>
      <DealPersonsTab :deal-data="dealData" />
    </template>
    <template #content.clientDocs>
      <DealPersonsDocsTab :deal-data="dealData" />
    </template>
    <template #content.worksheets>
      <DealMortgageProgramsWorksheetTab
        :dictionary="dictionary"
        :deal-id="regroupDealData && regroupDealData.mainDeal && regroupDealData.mainDeal.id"
        @currentTabChange="currentTab = $event"
        @refresh="refreshDeal"
      />
    </template>
    <template #content.variants>
      <DealMortgageProgramsSelectorTab
        :regroup-deal-data="regroupDealData"
        :dictionary="dictionary"
        :disabled="updateProcessing"
        @refresh="refreshDeal(true)"
      />
    </template>
    <template #content.myCompanyDocs>
      <TwPanelContent>
        <div class="tw-flex-1">
          <DealDocumentBlock
            class="tw-mb-[6px]"
            :permissions="permissions"
            :disabled="loading || (isSuccessDeal && !isAdmin)"
            :deal-id="regroupDealData && regroupDealData.mainDeal && regroupDealData.mainDeal.id"
            :deal-type="regroupDealData && regroupDealData.mainDeal && regroupDealData.mainDeal.type"
            :person-id="commonDealPersonId"
            :deal-sub-type-value="dealSubTypeValue"
            @refresh="refreshDeal"
          />
        </div>
        <div class="tw-flex-1">
          <MortgageResultBlock
            class="tw-mb-[6px]"
            :disabled="loading || (isSuccessDeal && !isAdmin)"
            :deal-state="dealState"
            :deal-data="regroupDealData.mainDeal"
            @refresh="refreshDeal"
          />
          <MortgageApprovedBanksBlock
            v-if="dealSubTypeValue === 'approval_borrower'"
            class="tw-mb-[6px]"
            :deal-id="regroupDealData && regroupDealData.mainDeal && regroupDealData.mainDeal.id"
          />
        </div>
      </TwPanelContent>
      <TwPanelContent class="tw-block">
        <MortgageApproveBlock
          class="tw-mb-[6px] tw-w-full"
          :disabled="loading || (isSuccessDeal && !isAdmin)"
          :approve-info="regroupDealData.dealApprovalInfo"
          :deal-id="regroupDealData && regroupDealData.mainDeal && regroupDealData.mainDeal.id"
          :deal-type="regroupDealData && regroupDealData.mainDeal && regroupDealData.mainDeal.type"
          @refresh="refreshDeal"
        />
        <DocumentDownloadList
          class="tw-mb-[6px] tw-w-full"
          section="mortgage"
          subsection="himself"
          :deal-id="dealData.id"
        />
      </TwPanelContent>
    </template>
    <template #content.files>
      <DealFilesTab
        :deal="regroupDealData.mainDeal"
        :dictionary="dictionary"
        :permissions="permissions"
        :is-new="isNew"
        :loading="loading"
      />
    </template>
  </TwPanelTabsFull>
</template>

<script>
import DealMortgageProgramsSelectorTab from '@/views/Deal/TypeViews/mortgage/tabs/DealMortgageProgramsSelectorTab'
import DealMortgageProgramsWorksheetTab from '@/views/Deal/TypeViews/mortgage/tabs/DealMortgageProgramsWorksheetTab'
import DealGeneralTab from '@/views/Deal/TypeViews/DealGeneralTab'
import DocumentDownloadList from '@/views/Dictionary/General/Document/DocumentDownloadList'
import DealDocumentBlock from '@/views/Deal/TypeViews/mortgage/dealDocument/DealDocumentBlock.vue'
import DealPersonsTab from '@/views/Deal/TypeViews/mortgage/tabs/DealPersonsTab'
import DealPersonsDocsTab from '@/views/Deal/TypeViews/mortgage/tabs/DealPersonsDocsTab'
import MortgageResultBlock from '@/views/Deal/TypeViews/mortgage/result/MortgageResultBlock'
import { mapGetters } from 'vuex'
import MortgageApproveBlock from '@/views/Deal/TypeViews/mortgage/approve/MortgageApproveBlock'
import DealFilesTab from '@/views/Deal/TypeViews/DealFilesTab.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import MortgageApprovedBanksBlock from '@/views/Deal/TypeViews/mortgage/approvedBanks/MortgageApprovedBanksBlock.vue'

export default {
  name: 'DealMortgage',
  components: {
    MortgageApprovedBanksBlock,
    TwPanelContent,
    TwPanelTabsFull,
    DealFilesTab,
    MortgageApproveBlock,
    MortgageResultBlock,
    DealPersonsDocsTab,
    DealPersonsTab,
    DealDocumentBlock,
    DocumentDownloadList,
    DealGeneralTab,
    DealMortgageProgramsWorksheetTab,
    DealMortgageProgramsSelectorTab
  },
  props: {
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    },
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    dealData: {
      required: true
    },
    dealState: {
      default: null
    }
  },
  data () {
    return {
      typedDealData: null,
      updateProcessing: false,
      currentTab: 'main',
      editMain: false
    }
  },
  methods: {
    dealMortgageUpdate ({ newProgramId, dealMortagageProps }) {
      this.updateProcessing = true
      const { programs = [] } = this.regroupDealData?.mortgageDeal || {}
      // формирую список программ
      const newProgramList = [
        ...programs.map(i => {
          // вытаскиваю только нужные для отправки данные
          const { program: { id: programId }, approve, comment, id } = i
          return {
            id,
            programId,
            approve,
            comment
          }
        }),
        ...(newProgramId) ? [
          {
            programId: newProgramId,
            approve: null,
            comment: ''
          }
        ] : []
      ]
      console.log('dealMortgageUpdate newProgramList: ', newProgramList)
      console.log('dealMortgageUpdate dealMortagageProps: ', dealMortagageProps)

      const formData = {
        ...(dealMortagageProps) && {
          ...dealMortagageProps
        },
        ...newProgramId && {
          programs: newProgramList
        }
      }
      console.warn('dealMortgageUpdate formData: ', formData)
    },
    refreshDeal () {
      this.editMain = false
      this.$emit('refresh')
    }
    // Нужно получить информацию по типу сделки например инфо по сделке-ипотека
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    dealSubTypeValue () {
      return this.regroupDealData && this.regroupDealData?.mainDeal && this.regroupDealData?.mainDeal?.subtype ? this.regroupDealData?.mainDeal?.subtype?.value : null
    },
    commonDealPersonId () {
      return this.regroupDealData?.mainDeal?.person?.id || null
    },
    tabList () {
      // TODO Ваня, я убрал проверку (!this.typedDealData) с остальных вкладок
      // Оставил проверку только на Анкету и Подбор, потому как это данные с mortgage приходит
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'Клиенты',
          key: 'client',
          disabled: this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Документы клиентов',
          key: 'clientDocs',
          disabled: this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Анкеты',
          key: 'worksheets',
          disabled: !(this.dealSubTypeValue === 'approval_borrower') || this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Подбор',
          key: 'variants',
          disabled: !(this.dealSubTypeValue === 'approval_borrower') || this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Документы компании',
          key: 'myCompanyDocs',
          disabled: this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Файлы',
          key: 'files',
          disabled: this.isAdmin || this.isNew || this.loading || this.updateProcessing
        }
      ]
    },
    isSuccessDeal () {
      return (this?.regroupDealData?.mainDeal?.status?.code === 'success')
    },
    isTypedDealData () {
      return !!(this?.regroupDealData?.mortgageDeal)
    },
    regroupDealData () {
      const { programs = [], dealApprovalInfo, ...variantFilters } = this.typedDealData || {} // исключаю лишнее оставляю только фильтры
      console.log(this.$options.name, programs)
      // добавляю к фильтрам значения из основной Заявки - TODO клиентов добавлять не нужно??? Ибо тут не создание а обновление
      Object.assign(variantFilters || {}, {
        personId: this.dealData?.person?.id,
        otherPersons: Array.isArray(this.dealData?.otherPersons) && this.dealData?.otherPersons.length ? this.dealData?.otherPersons.map(c => c.id) : []
      })
      return {
        mainDeal: this.dealData,
        mortgageDeal: this.typedDealData,
        dealApprovalInfo,
        variantFilters
      }
    }
  },
  watch: {
    // при переходах между вкладками отключаю редактирование
    currentTab () {
      this.editMain = false
    }
  }
}
</script>
