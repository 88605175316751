<template>
  <div
    :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
    class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
  >
    <component
      :is="schemaRealtyTypeObject.component"
      v-if="realtyTypeObject && schemaRealtyTypeObject"
      v-bind="$props"
      :realty-type-object="realtyTypeObject"
      @create="onCreated"
      v-on="$listeners"
    >
      <template #titleLeft="{ data }">
        <div class="tw-text-h4 tw-font-bold">
          <template v-if="data">
            <span class="tw-font-medium">
              {{ realtyTypeObject && realtyTypeObject.label }}
            </span>
            <span>
              №{{ data.addressNumber }}
            </span>
          </template>
          <span
            v-else
            class="tw-text-an-flat-disabled-text tw-font-medium"
          >Форма создания: {{ realtyTypeObject && realtyTypeObject.label }}</span>
        </div>
      </template>
      <template #actionsRight="{ data }">
        <div
          v-if="allowEdit && data && data.id"
          class="tw-flex tw-gap-[8px] tw-font-manrope tw-font-bold tw-text-main-xs ml-3"
        >
          <TwPanelActionButton
            title="Создать копию"
            color="gray"
            @click.prevent="copyObject(data)"
          >
            Скопировать
          </TwPanelActionButton>
          <TwPanelActionButton
            title="Удаление"
            color="red"
            @click.prevent="deleteObject(data)"
          >
            Удалить
          </TwPanelActionButton>
        </div>
      </template>
    </component>
    <!--  Диалог подтверждения действий  -->
    <NbEditorActionsConfirmDialog
      ref="confirmDialog"
      :loading="actionProcess"
    />
  </div>
</template>

<script>
import { TypesRealtyObjects, RealtyObjectTypeOptions } from '@/views/Newbuildings/Editor/typesDict'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import { mapGetters } from 'vuex'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import dictPermissionRules from '@/Permissions/dictRules'
import NbEditorActionsConfirmDialog
  from '@/views/Newbuildings/Editor/OtherComponents/Actions/NbEditorActionsConfirmDialog.vue'
import TwEditorChessContextMenu from '@/views/Newbuildings/Editor/Building/chess/TwEditorChessContextMenu.vue'
import NbEditorConfirmActionsMixin
  from '@/views/Newbuildings/Editor/OtherComponents/Actions/NbEditorConfirmActionsMixin'

export default {
  name: 'NbEditorUniversalObjectCard',
  components: { TwEditorChessContextMenu, NbEditorActionsConfirmDialog, TwCircleLoader, TwDialog, TwPanelActionButton },
  mixins: [NbEditorConfirmActionsMixin],
  props: {
    id: {
      type: [Number, String],
      default: 0
    },
    fromPanel: {
      type: Boolean,
      default: false
    },
    workToClose: {
      type: Boolean,
      default: false
    },
    // основное свойство
    // - если оно не указано показываю селект типа для определения интерфейса для от рисовки карточки
    // или для создания - если ID = 0
    type: {
      type: String,
      default: null
    },
    // ниже наборы свойств для создания различных типов недвижимости
    parentId: {
      type: [String, Number],
      default: null
    },
    addressNumber: {
      type: [String, Number],
      default: null
    },
    builderId: {
      type: [Number, String],
      default: null
    },
    complexId: {
      type: [Number, String],
      default: null
    },
    entranceId: {
      type: [Number, String],
      default: null
    },
    floorNum: {
      type: [Number, String],
      default: null
    },
    // Предзаданное состояние формы редактивания
    presetTabEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedType: null // для локального выбора типа и последующего создания объекта
    }
  },
  methods: {
    onCreated (data) {
      const text = `${data?.data?.type?.label || 'Объект'} - успешно создан!`
      this.$store.commit('alerts/ADD_ALERT', {
        text,
        timeout: 3000,
        color: 'success white--text',
        visible: true
      })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    allowEdit () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsRealtyEditRealtyNbRule]
    },
    objectType () {
      return this.type || this.data?.type?.value || this.selectedType || null
    },
    schemaRealtyTypeObject () {
      return (this.objectType && TypesRealtyObjects[this.objectType]) || null
    },
    realtyTypeObject () {
      return (this.objectType && RealtyObjectTypeOptions.find(i => i.value === this.objectType)) || null
    }
  }
}
</script>
