<template>
  <TwPanelContentBlock
    class="tw-mb-[6px]"
    :content-classes="''"
  >
    <template #title>
      <div>
        Список сопоставлений
      </div>
    </template>
    <template #action>
      <button
        :disabled="processing || !isAllowEditPermissions"
        class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
        @click="refresh"
      >
        Обновить
      </button>
      <button
        :disabled="processing || !isAllowEditPermissions"
        class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
        @click="initComparisonFromFile"
      >
        Получить из файла
      </button>
    </template>
    <ImportBuilderFileComparisonList
      ref="list"
      :builder-id="builderId"
      :import-builder-file-id="builderFileId"
      :is-allow-edit-permissions="isAllowEditPermissions"
    />
  </TwPanelContentBlock>
</template>

<script>
import ImportBuilderFileComparisonList
  from '@/views/BuilderImporter/ImportBuilderFile/comparision/ImportBuilderFileComparisonList'
import { API } from '@/Api/BuilderImporter'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  name: 'BuilderFileComparisonBlock',
  components: {
    TwPanelContentBlock,
    ImportBuilderFileComparisonList
  },
  props: {
    info: {
      type: Object,
      required: true
    },
    isAllowEditPermissions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      processing: false
    }
  },
  computed: {
    builderFileId () {
      return this?.info?.id || null
    },
    builderId () {
      return this.info?.company?.id || null
    }
  },
  methods: {
    initComparisonFromFile () {
      this.processing = true
      API.ImportBuilderFileLoader.initComparisonFromFile({
        fileId: this.builderFileId
      }).then(() => {
        this.$store.commit('alerts/ADD_ALERT', {
          text: 'Инициализация сопоставлений прошла успешно!',
          timeout: 2500,
          color: 'success white--text',
          visible: true
        })
      }).catch(() => {
        this.$store.commit('alerts/ADD_ALERT', {
          text: 'Ошибка инициализации сопоставлений!',
          timeout: 3000,
          color: 'error white--text',
          visible: true
        })
      }).finally(() => {
        this.processing = false
      })
    },
    refresh () {
      this.$nextTick(() => {
        if (this.$refs.list) {
          this.$refs.list.fetch()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
