<template>
  <div :class="[wrapperClass, sizeClasses]">
    <div
      v-if="showLabel || persistentLabel || errorValues.length"
      class="tw-flex tw-justify-between tw-gap-[8px] tw-h-[21px]"
    >
      <label
        v-if="showLabel || persistentLabel"
        :for="uid"
        :style="{ ...inputLabelPaddingStyle, ...{ color: persistentLabel || localValue || focusState ? '' : 'transparent' } }"
        :class="persistentLabel || localValue || focusState ? 'tw-text-an-flat-dark-blue-link' : ''"
        class="flex-1 tw-pointer-events-none tw-font-medium tw-truncate tw-relative "
      >
        {{ label || placeholder }}
        <span
          v-if="isRequired"
          class="tw-text-an-flat-red-main tw-text-main-sm"
        >
          <slot name="requiredSymbol">*</slot>
        </span>
      </label>
      <label
        v-if="errorValues.length"
        :class="labelErrorClass"
        :for="uid"
      >
        <span
          v-for="(err, index) in errorValues"
          :key="index"
        >
          {{ err }}<span v-if="errorValues.length - 1 > index">,</span>
        </span>
      </label>
    </div>
    <div
      :class="[focusState ? 'tw-outline tw-outline-an-flat-blue-light ':'', errorValues.length ? inputClassError : '']"
      class="tw-relative tw-flex tw-items-center tw-border tw-border-solid tw-outline-1 tw-rounded-sm tw-overflow-hidden"
    >
      <label
        v-show="$slots.prepend"
        ref="prepend"
        :class="[dense ? 'tw-min-h-[40px]':'tw-min-h-[52px]', prependSlotBgClass]"
        class="tw-flex tw-place-items-center"
        @click.prevent="focus"
      >
        <span class="tw-px-[10px]">
          <slot
            name="prepend"
            v-bind="{ focusState }"
          />
        </span>
      </label>
      <input
        :id="uid"
        ref="input"
        v-model="localValue"
        v-input-allow-regex="valueFilteredRegex"
        :maxlength="maxLength"
        :min="min"
        :max="max"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :required="required"
        :type="type"
        :autocomplete="autocomplete"
        :class="[inputClassBase, sizeClasses, inputClassFocused, inputClassDisabled]"
        @keyup.enter="$emit('enter', $event)"
        @keyup.esc="$emit('esc', $event)"
        @focus="focusSelect ? $event.target && $event.target.select() : ''"
        @focusin.passive="onFocus"
        @focusout.passive="onFocus"
      >
      <label
        v-show="$slots.append"
        ref="append"
        :class="[dense ? 'tw-min-h-[40px]':'tw-min-h-[52px]', appendSlotBgClass]"
        class="tw-flex tw-place-items-center"
      >
        <span class="tw-px-[10px]">
          <slot
            name="append"
            v-bind="{ focusState }"
          />
        </span>
      </label>
      <div
        v-if="!disabled && cleanable && localValue"
        :class="[$slots.append ? 'tw-right-[15px]':'tw-right-0']"
        class="tw-absolute tw-top-1/2 tw-transform -tw-translate-x-0 -tw-translate-y-1/2 tw-mr-[15px] tw-hidden group-hover:tw-block"
      >
        <button
          class="tw-p-[5px]"
          @click.stop="onClearValue"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 5C10 7.76142 7.76143 10 5 10C2.23857 10 0 7.76142 0 5C0 2.23858 2.23857 0 5 0C7.76143 0 10 2.23858 10 5ZM3.71445 3.71445C3.89197 3.53694 4.17976 3.53694 4.35727 3.71445L5 4.35719L5.64273 3.71445C5.82024 3.53694 6.10805 3.53694 6.28555 3.71445C6.46307 3.89196 6.46307 4.17976 6.28555 4.35728L5.64282 5.00001L6.28575 5.64293C6.46326 5.82044 6.46326 6.10824 6.28575 6.28575C6.10823 6.46326 5.82043 6.46326 5.64293 6.28575L5 5.64283L4.35709 6.28574C4.17957 6.46326 3.89177 6.46326 3.71426 6.28574C3.53674 6.10824 3.53674 5.82043 3.71426 5.64292L4.35718 5.00001L3.71445 4.35728C3.53694 4.17977 3.53694 3.89197 3.71445 3.71445Z"
              fill="#E63114"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwInputText',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    // НА базе субтипа отпределяется регулярка фильтрующая ввод после нажатия
    subType: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'presentation' // off presentation
    },
    // сжатые поля
    dense: {
      type: Boolean,
      default: false
    },
    // КНопка очистки поля
    cleanable: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableErrorBorder: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    focusSelect: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    persistentLabel: {
      type: Boolean,
      default: false
    },
    appendSlotBgClass: {
      type: String,
      default: ''
    },
    prependSlotBgClass: {
      type: String,
      default: ''
    },
    wrapperClass: {
      type: String,
      default: 'tw-group tw-relative tw-font-manrope tw-block tw-leading-normal'
    },
    // не совсем текст - раширено - добавлена возможность использовать массив ошибок
    errorText: {
      type: [String, Array],
      default: null
    },
    max: {
      type: [String, Number],
      default: ''
    },
    min: {
      type: [String, Number],
      default: ''
    },
    labelErrorClass: {
      type: String,
      default: 'tw-text-[12px] tw-truncate tw-relative tw-right-0 tw-text-an-flat-red-main'
    },
    inputClassBase: {
      type: String,
      default: 'tw-px-[20px] tw-border-none tw-outline-0 tw-text-an-flat-black-main tw-w-full tw-bg-white tw-block placeholder:tw-text-an-flat-not-active-input'
    },
    inputClassFocused: {
      type: String,
      default: 'focus:placeholder:tw-text-opacity-40'
    },
    inputClassDisabled: {
      type: String,
      default: 'disabled:tw-bg-an-flat-disabled-input-bg disabled:tw-text-an-flat-disabled-input-text'
    }
  },
  data () {
    return {
      myInputModel: this.value,
      focusState: false,
      isMounted: false
    }
  },
  computed: {
    valueFilteredRegex () {
      if (this.subType === 'number') {
        return '[0-9.]'
      } else if (this.subType === 'int') {
        return '[0-9]'
      } else if (this.subType === 'float') {
        return '([0-9.,])'
      }
      if (this.subType === 'lettersOnly') {
        return '[a-zA-Zа-яА-ЯёЁ-]'
      }
      return ''
    },
    isRequired () {
      // определение обязательности поля
      // - если тестовая ошибка имеет дефолтное значение
      // и если текст лейбла показан над полем ввода
      return this.errorText !== null && this.errorText !== '' && (this.persistentLabel || this.localValue || this.focusState)
    },
    errorValues () {
      if (Array.isArray(this.errorText)) {
        return this.errorText
      } else if (this.errorText) {
        return [this.errorText]
      }
      return []
    },
    localValue: {
      get () {
        return this.value
      },
      set (newValue) {
        let val = newValue
        // Для десятичных чисел - поменять запятую на точку
        if (val && (this.subType === 'number' || this.subType === 'float')) {
          val = val.toString().replace(',', '.')
        }
        this.$emit('input', val)
      }
    },
    sizeClasses () {
      if (this.dense) {
        return 'tw-min-h-[40px] tw-min-w-[90px] tw-text-main-xs'
      } else {
        return 'tw-min-h-[52px] tw-min-w-[120px] tw-text-main-sm2'
      }
    },
    inputClassError () {
      if (this.disableErrorBorder) {
        return ''
      }
      return 'tw-border-an-flat-red-main'
    },
    uid () {
      return `${this.$options.name}_${this._uid}`
    },
    inputPaddingStyle () {
      if (!this.isMounted) {
        return
      }
      const leftPadding = 23 + (this.$refs.prepend?.offsetWidth || 0)
      const rightPadding = 23 + (this.$refs.append?.offsetWidth || 0)
      return {
        paddingLeft: `${leftPadding}px`,
        paddingRight: `${rightPadding}px`
      }
    },
    inputLabelPaddingStyle () {
      if (!this.isMounted) {
        return
      }
      const leftPadding = 5
      const rightPadding = 5 + (this.$refs.append?.offsetWidth || 0)
      return {
        paddingLeft: `${leftPadding}px`,
        paddingRight: `${rightPadding}px`
      }
    }
  },
  mounted () {
    if (this.value) {
      this.localValue = this.value
    }
    this.$nextTick(() => {
      this.isMounted = true
    })
  },
  methods: {
    onFocus (event) {
      this.focusState = event.type === 'focusin'
      this.$emit('focus', !!this.focusState)
      this.$emit(event.type, this.localValue)
    },
    focus () {
      setTimeout(() => {
        this.$refs.input?.focus()
      }, 0)
    },
    onClearValue () {
      this.localValue = null
    }
  }
}
</script>

<style scoped>

</style>
