<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <div
      v-if="loading"
      class="tw-flex tw-justify-center tw-items-center tw-h-full"
    >
      <TwCircleLoader
        width="50px"
        height="50px"
      />
    </div>
    <template v-else-if="data">
      <div
        v-show="data.type && data.type.value === 'fixation'"
        class="tw-block tw-mb-[10px] tw-text-an-flat-black-main tw-leading-[27px] tw-text-h3"
      >
        Запрос на фиксацию №{{ data.id }}
      </div>
      <div
        v-show="data.type && data.type.value === 'reservation'"
        class="tw-block tw-mb-[10px] tw-text-an-flat-black-main tw-leading-[27px] tw-text-h3"
      >
        Запрос на бронирование №{{ data.id }}
      </div>
      <div class="tw-mb-[16px]">
        <DeveloperRequestStatusUi
          v-if="data && data.status"
          ref="StatusChange"
          :request-type="data.type"
          :reservation-price="reservationPrice"
          :disabled="updateStatusProcess || data.blocked"
          :status-value="data.status.value"
          :list="dictionary.status"
          :update-status-error="updateStatusError"
          @clearError="updateStatusError = null"
          @change="onChangeStatus"
        />
      </div>
      <div
        v-if="requestInfo && requestInfo.ui && requestInfo.ui.blockedText"
        class="tw-mb-[16px]"
      >
        <div class="tw-px-[28px] tw-py-[14px] tw-bg-white tw-rounded-[10px]">
          <div class="tw-mb-[2px] tw-text-an-flat-red-main tw-text-[14px] tw-font-bold">
            Данный запрос нельзя изменить
          </div>
          <div class="tw-text-[14px] tw-text-an-flat-black-main">
            {{ requestInfo.ui.blockedText }}
          </div>
        </div>
      </div>
      <div
        v-if="data && data.cancelRequest"
        class="tw-mb-[16px]"
      >
        <div class="tw-px-[28px] tw-py-[14px] tw-bg-white tw-rounded-[10px]">
          <div class="tw-mb-[2px] tw-text-an-flat-red-main tw-text-[14px] tw-font-bold">
            Риелтор запросил отмену запроса:
          </div>
          <div class="tw-text-[14px] tw-text-an-flat-black-main">
            {{ data.cancelRequest.comment }}
          </div>
        </div>
      </div>
      <template v-if="data.type && data.type.value === 'fixation'">
        <ApplicationFixationWrapper
          :data="requestInfo"
          @downloadFiles="downloadFiles"
          @showHistory="showHistory"
        />
      </template>
      <template v-else-if="data.type && data.type.value === 'reservation'">
        <ApplicationReservationWrapper
          :data="requestInfo"
          :dictionary="dictionary"
          @changeReservationPrice="reservationPrice = $event"
          @downloadFiles="downloadFiles"
          @showHistory="showHistory"
        />
      </template>
      <template v-else>
        Неописанный тип запроса застройщику {{ data.type }}
      </template>
      <RequestToDeveloperHistoryModal
        :id="data.id"
        v-model="isVisibleHistoryModal"
      />
    </template>
    <div v-else>
      Не удалось получить объект данных
    </div>
  </div>
</template>

<script>
import API from '@/Api/Rtd/RequestToDeveloper/Management'
import CardMixin from '@/mixins/CardMixin'
import ApplicationFixationWrapper
  from '@/views/Newbuildings/Management/Request/ApplicationFixation/ApplicationFixationWrapper.vue'
import ApplicationReservationWrapper
  from '@/views/Newbuildings/Management/Request/ApplicationReservation/ApplicationReservationWrapper.vue'
import DeveloperRequestStatusUi
  from '@/views/Newbuildings/Management/Request/ApplicationComponents/DeveloperRequestStatusUi.vue'
import RequestToDeveloperHistoryModal
  from '@/views/Newbuildings/Management/Request/History/RequestToDeveloperHistoryModal.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'ManagementRequestToDeveloperCard',
  components: { TwCircleLoader, RequestToDeveloperHistoryModal, DeveloperRequestStatusUi, ApplicationReservationWrapper, ApplicationFixationWrapper },
  mixins: [CardMixin],
  data () {
    return {
      API,
      updateStatusError: null,
      updateStatusProcess: false,
      isVisibleHistoryModal: false,
      reservationPrice: 0
    }
  },
  computed: {
    requestInfo () {
      return {
        ...this.data,
        ...this.otherData
      }
    }
  },
  methods: {
    onChangeStatus (newStatusData) {
      console.warn('onChangeStatus', newStatusData)
      this.updateStatusError = null
      API.setStatus(this.id, newStatusData).then((data) => {
        this.updateStatusProcess = true
        // Закрываю модалку статусов только если успешно
        this.$refs.StatusChange.onCloseModal()
        this.$emit('callback', { data })
        this.refresh()
      }).catch((error) => {
        this.updateStatusError = error?.serverErrorMessage || 'Ошибка'
      }).finally(() => {
        this.updateStatusProcess = false
      })
    },
    showHistory () {
      this.isVisibleHistoryModal = true
    },
    downloadFiles () {
      API.downloadFiles(this.id)
    }
  }
}
</script>

<style>
.v-application ul {
  padding: 0 !important;
}
</style>
