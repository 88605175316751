<template>
  <TwUnAuthWrapper
    v-if="needTwoStepVerify"
    :show-close="true"
    @close="onCloseVerify"
  >
    <template #title>
      <div class="tw-flex tw-items-start tw-gap-2">
        Проверка
        <APopoverWrapper
          :hide-close="true"
          :z-index="1"
          popover-trigger-display="inline-block"
        >
          <template #trigger>
            <svg
              class="tw-group tw-cursor-pointer"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.5 2C5.35787 2 2 5.35786 2 9.5C2 13.6421 5.35787 17 9.5 17C13.6421 17 17 13.6421 17 9.5C17 5.35786 13.6421 2 9.5 2ZM0 9.5C0 4.25329 4.2533 0 9.5 0C14.7467 0 19 4.25329 19 9.5C19 14.7467 14.7467 19 9.5 19C4.2533 19 0 14.7467 0 9.5Z"
                fill="black"
              />
              <path
                class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.78172 6.31251C9.41804 6.25012 9.04401 6.31847 8.72588 6.50543C8.40775 6.6924 8.16606 6.98592 8.04361 7.33401C7.86034 7.855 7.28942 8.12877 6.76844 7.9455C6.24745 7.76223 5.97367 7.19131 6.15694 6.67032C6.43613 5.87668 6.98719 5.20745 7.71251 4.78117C8.43784 4.35489 9.29063 4.19906 10.1198 4.34129C10.949 4.48353 11.7012 4.91464 12.243 5.55826C12.7847 6.20175 13.0812 7.01614 13.0801 7.85726C13.0796 9.24177 12.0544 10.143 11.3535 10.6103C10.9726 10.8642 10.5987 11.0504 10.3241 11.1725C10.1854 11.2342 10.0683 11.281 9.98367 11.3133C9.94125 11.3294 9.90671 11.342 9.88136 11.351L9.85028 11.3619L9.84016 11.3654L9.8365 11.3666L9.83503 11.3671C9.83473 11.3672 9.8338 11.3675 9.51757 10.4188L9.8338 11.3675C9.30985 11.5422 8.74353 11.259 8.56888 10.7351C8.39444 10.2117 8.67674 9.64612 9.1995 9.47076C9.19967 9.47071 9.19934 9.47082 9.1995 9.47076L9.21134 9.46661C9.22351 9.46228 9.24403 9.45484 9.27168 9.44431C9.32715 9.42317 9.41033 9.38997 9.51182 9.34486C9.71764 9.25339 9.98444 9.11928 10.2441 8.94616C10.8243 8.55934 11.0801 8.17964 11.0801 7.85634L11.0801 7.85485C11.0806 7.48585 10.9506 7.12856 10.7129 6.84626C10.4753 6.56397 10.1454 6.37489 9.78172 6.31251ZM8.5859 13.8355C8.5859 13.2832 9.03362 12.8355 9.5859 12.8355H9.59444C10.1467 12.8355 10.5944 13.2832 10.5944 13.8355C10.5944 14.3878 10.1467 14.8355 9.59444 14.8355H9.5859C9.03362 14.8355 8.5859 14.3878 8.5859 13.8355ZM9.1995 9.47076C9.19967 9.47071 9.19934 9.47082 9.1995 9.47076Z"
                fill="black"
              />
            </svg>
          </template>
          <template #content>
            <div class="tw-px-3 tw-py-2 tw-min-w-[320px] tw-max-h-[50vh] tw-overflow-auto tw-font-manrope">
              <div class="tw-font-semibold tw-text-[16px] tw-mb-2">
                Двухэтапная авторизация
              </div>
              <div class="tw-font-medium tw-text-[12px]">
                <div class="tw-mb-2">
                  Для безопасности на вашей учетной записи подключена дополнительная защита - двухэтапная авторизация.
                </div>
                <div class="tw-mb-2">
                  Двухэтапная авторизация - это два фактора защиты. Первый - это ваш основной пароль. Второй фактор защиты - это одноразовый код,
                  который вы получаете в мобильном телефоне.
                </div>
                <div class="tw-mb-2">
                  Одноразовый код можно получить в мобильном приложении <b>FreeOTP</b>. Которое вы скачали при установке двухэтапной авторизации.
                </div>
                <div>
                  <div class="tw-font-semibold tw-text-[14px] tw-mb-1">
                    Для авторизации:
                  </div>
                  <ul>
                    <li class="tw-font-semibold tw-mb-1">
                      Запустите приложение FreeOTP
                    </li>
                    <li>
                      <div class="tw-font-semibold tw-mb-1">
                        Введите код который видите на экране в поле "Код подтверждения"
                      </div>
                      <img
                        width="300"
                        :src="optSecretCode"
                      >
                    </li>
                    <li class="tw-font-semibold tw-my-1">
                      Нажмите "Войти"
                    </li>
                  </ul>
                </div>
                <div class="tw-mb-2">
                  Если вы не настроили двухэтапную авторизацию, переустановили приложение или сменили телефон, для восстановления доступа к учетной записи обратитесь руководителю или в ИТ отдел компании "ФЛЭТ"
                </div>
                <div class="tw-mb-2">
                  Скачать приложение можно по ссылкам
                  <a
                    :href="appLink.android"
                    target="_blank"
                    title="Скачать приложение"
                  >Android</a>,
                  <a
                    :href="appLink.ios"
                    target="_blank"
                    title="Скачать приложение"
                  >IOS</a>
                </div>
              </div>
            </div>
          </template>
        </APopoverWrapper>
      </div>
    </template>
    <template #content>
      <div>
        <form
          class="tw-flex tw-flex-col tw-max-w-full"
          @submit.prevent="onSubmit"
        >
          <TwUnAuthInputText
            ref="verify"
            v-model="verifyCode"
            :disabled="formProcessing"
            label-text="Введите одноразовый код"
            autocomplete="one-time-code"
            placeholder="Код потверждения"
            :error-text="error || (($v.verifyCode && !$v.verifyCode.required) ? 'Обязательное поле' : '')"
            type="text"
          />
          <button
            type="submit"
            :disabled="formProcessing"
            class="tw-transition tw-ease-in tw-delay-100 tw-bg-an-flat-blue-dark-2 tw-text-white tw-p-[15px] tw-rounded-[10px] tw-text-center tw-w-full tw-font-bold tw-text-[14px] hover:tw-bg-an-flat-blue-dark-1 hover:tw-text-white disabled:tw-opacity-50"
          >
            Подтвердить вход
          </button>
        </form>
      </div>
    </template>
  </TwUnAuthWrapper>
  <TwUnAuthWrapper v-else>
    <template #title>
      Войти в управление
    </template>
    <template #content>
      <div
        v-if="user"
        class="tw-flex tw-flex-row tw-items-center tw-p-[18px] tw-rounded-[10px] tw-text-start tw-mb-[18px] tw-w-full tw-bg-white"
      >
        <div class="tw-w-[46px] tw-h-[46px] tw-mr-[9px]">
          <img
            v-if="userAvatar"
            class="tw-rounded-full"
            :src="userAvatar"
          >
          <svg
            v-else
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1_13355)">
              <path
                d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
                fill="#F0F5F9"
              />
              <path
                d="M19.7147 24.6428L15.8764 26.0136C15.2226 26.2472 14.7861 26.8665 14.7861 27.5608V30.0841C14.7861 30.4976 14.8148 30.9357 15.1122 31.223C15.8692 31.9543 18.0473 32.8571 23.0004 32.8571C27.9535 32.8571 30.1316 31.9543 30.8886 31.223C31.186 30.9357 31.2147 30.4976 31.2147 30.0841V27.5608C31.2147 26.8665 30.7783 26.2472 30.1244 26.0136L26.2861 24.6428M23.0004 23C21.1044 23 19.4863 21.6292 19.1745 19.7589L18.8821 18.0044C18.4579 15.4595 20.4205 13.1428 23.0004 13.1428C25.5804 13.1428 27.5429 15.4595 27.1187 18.0044L26.8263 19.7589C26.5146 21.6292 24.8964 23 23.0004 23Z"
                stroke="#99A1A7"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_13355">
                <rect
                  width="46"
                  height="46"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="tw-flex tw-flex-col">
          <div class="tw-text-[16px] tw-font-semibold tw-text-an-flat-blue-dark-2 tw-mb-[2px]">
            {{ $Global.peopleName(user, 'fi') }}
          </div>
          <div class="tw-text-[14px] tw-font-regular tw-text-an-flat-grey-22">
            {{ user.email }}
          </div>
        </div>
        <button
          :disabled="formProcessing"
          class="tw-cursor-pointer tw-transition tw-ease-in tw-delay-100 hover:tw-rotate-[90deg] tw-bg-transparent tw-ml-auto tw-mr-2 disabled:tw-opacity-50"
          @click.stop="fullResetUser"
        >
          <svg
            class="tw-m-auto"
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L10 10M1 10L10 1"
              stroke="#B3BDC6"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>
      <form @submit.prevent="onSubmit">
        <TwUnAuthInputText
          v-show="!user"
          v-model="email"
          label-text="E-mail"
          autocomplete="email"
          type="email"
          placeholder="Email"
          :error-text="error || (($v.email && !$v.email.required) ? 'Обязательное поле' : '')"
          :required="true"
          :disabled="formProcessing"
        />
        <TwUnAuthInputText
          v-model="password"
          :required="true"
          label-text="Пароль"
          autocomplete="current-password"
          type="password"
          class="tw-mb-[30px]"
          :error-text="error || (($v.password && !$v.password.required) ? 'Обязательное поле' : '')"
          :disabled="formProcessing"
        />
        <div class="tw-flex tw-row">
          <button
            :disabled="formProcessing"
            type="button"
            class="tw-transition tw-ease-in tw-delay-100 tw-bg-an-flat-blue-light tw-text-an-flat-blue-dark-2 tw-p-[15px] tw-rounded-[10px] tw-text-center  tw-mr-[11px] tw-w-full tw-max-w-[204px] tw-font-semibold tw-text-[14px] hover:tw-bg-an-flat-blue-dark-1 hover:tw-text-white disabled:tw-opacity-50"
            @click.stop="$router.push({ name: 'reset_password', query: { email: email } })"
          >
            Забыли пароль?
          </button>
          <button
            :disabled="formProcessing"
            type="submit"
            class="tw-transition tw-ease-in tw-delay-100 tw-bg-an-flat-blue-dark-2 tw-text-white tw-p-[15px] tw-rounded-[10px] tw-text-center tw-w-full tw-font-bold tw-text-[14px] hover:tw-bg-an-flat-blue-dark-1 hover:tw-text-white disabled:tw-opacity-50"
          >
            {{ formProcessing ? 'Подождите' : 'Войти' }}
          </button>
        </div>
      </form>
    </template>
  </TwUnAuthWrapper>
</template>

<script>
import { get, remove } from 'local-storage'
import TwUnAuthWrapper from '@/components/tw-ui/un-auth-ui/TwUnAuthWrapper.vue'
import APopoverWrapper from '@/components/filters/afilter/fields/apopover/APopoverWrapper.vue'
import { twoFactorVerifyAppLink } from '@/plugins/helpers/GlobalConfigVars'
import API from '@/Api/Auth/Profile'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import twoFactorAPI from '@/Api/Auth/TwoFactor'
import { deploymentTypeColors } from '@/views/dictonaries'
import { employeeAvatarListGetLink } from '@/views/Company/Employee/employeeHelpers'
import TwUnAuthInputText from '@/components/tw-ui/un-auth-ui/TwUnAuthInputText.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TwLoginView',
  components: { TwUnAuthInputText, APopoverWrapper, TwUnAuthWrapper },
  data () {
    return {
      API,
      formProcessing: false,
      user: null,
      password: '',
      email: '',
      needTwoStepVerify: false,
      verifyCode: '',
      verifyToken: '',
      error: ''
    }
  },
  validations () {
    if (this.needTwoStepVerify) {
      return {
        verifyCode: {
          required
        }
      }
    }
    return {
      email: {
        required
      },
      password: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      initAuth: 'profile/initAuth',
      logout: 'profile/resetAuth',
      checkToken: 'profile/checkToken'
    }),
    ...mapMutations({
      setToken: 'profile/setToken',
      hideAlerts: 'alerts/ALL_ALERTS_UNVISIBLE'
    }),
    onSubmit () {
      this.$v.$reset()
      this.$v.$touch()
      console.warn(this.$options.name, 'onSubmit - isInvalid: ', this.$v)
      if (!this.$v.$invalid) {
        if (this.needTwoStepVerify) {
          this.twoStepSubmit()
        } else {
          this.firstStepSubmit()
        }
      }
    },
    onCloseVerify () {
      console.warn(this.$options.name, 'onSubmit')
      this.needTwoStepVerify = false
      this.error = ''
    },
    twoStepSubmit (e) {
      console.warn(this.$options.name, 'twoStepSubmit', e)
      if (this.formProcessing) return

      this.formProcessing = true
      twoFactorAPI.verify({ code: this.verifyCode }, this.verifyToken)
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.successAuth(data)
        })
        .catch((error) => {
          console.error(this.$options.name, error)
          this.verifyCode = ''
          this.error = error?.response?.data?.error || 'Ошибка авторизации'
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    firstStepSubmit (e) {
      console.warn(this.$options.name, 'firstStepSubmit', e)
      if (this.formProcessing) return

      this.hideAlerts()

      this.error = ''
      this.formProcessing = true
      API.login(this.email, this.password)
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          if (data.need_two_factor_auth) {
            console.warn(this.$options.name, 'NEED TWO FACTOR CODE')
            this.needTwoStepVerify = true
            this.verifyToken = data.token
          } else {
            this.successAuth(data)
          }
        })
        .catch((error) => {
          console.error(this.$options.name, error.response)
          if (error?.response?.data?.error) {
            this.error = Array.isArray(error?.response?.data?.error) && error?.response?.data?.error.length ? error.response.data.error[0] : 'Ошибка авторизации'
          }
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    successAuth (data) {
      this.$store.commit('profile/setToken', data)
      this.$nextTick(() => {
        window.location?.reload()
      })
      // TODO Отключил, вместо данной механики просто рефрешу страницу
      // API.getProfile()
      //   .then((responseProfile) => {
      //     console.warn(responseProfile.data.data)
      //     if (responseProfile.data && responseProfile.data.data) {
      //       this.$store.commit('profile/setUser', responseProfile.data.data)
      //       this.$store.dispatch('profile/fetchEmailConfigs')
      //       this.$store.dispatch('profile/currentPlannedForUser')
      //     } else {
      //       this.logout()
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(this.$options.name, error)
      //   })
    },
    getLocalstorageData () {
      const authData = get('token')
      if (authData) {
        this.setToken(authData)
        this.initAuth().then(({ isToken }) => {
          // если токен нашелся в localstorage его нужно проверить
          if (isToken) {
            this.checkToken()
          }
        })
          .catch((error) => {
            console.error(this.$options.name, error)
          })
      }
      const user = get('user')
      if (user) {
        this.user = user
        this.email = user.email
      }
    },
    fullResetUser () {
      console.warn('fullResetUser')
      this.user = null
      this.email = ''
      remove('user')
      remove('token')
    }
  },
  computed: {
    ...mapGetters({
      token: 'profile/getToken',
      deploymentType: 'getDeploymentType'
    }),
    optSecretCode () {
      return require('@/assets/images/two-factor/get-secret-otp-code.png')
    },
    appLink () {
      return twoFactorVerifyAppLink
    },
    deploymentTypeStyle () {
      return {
        backgroundColor: deploymentTypeColors[this.deploymentType]
      }
    },
    userAvatar () {
      return this.user?.file ? employeeAvatarListGetLink(this.user.file) : null
    }
  },
  watch: {
    needTwoStepVerify (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.verify.focus()
        })
      }
    }
  },
  created () {
    this.getLocalstorageData()
  }
}
</script>
