<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Застройщик
      </template>
      <div v-if="info.builderId">
        <ViewPanelLink
          :id="info.builderId"
          component="company-show"
        >
          <TwCompanyName :id="info.builderId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Жилой массив
      </template>
      <div v-if="info.complexId">
        <ViewPanelLink
          :id="info.complexId"
          component="realty-complex-show-page"
        >
          <RealtyComplexName :id="info.complexId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Название планировки
      </template>
      <div v-if="info.name">
        {{ info.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Студия
      </template>
      <div>
        {{ info.studio ? 'Да' : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Коммерческая
      </template>
      <div>
        {{ info.commercial ? 'Да' : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Общая площадь
      </template>
      <div v-if="info.totalArea">
        {{ info.totalArea || 0 }} м²
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Жилая площадь
      </template>
      <div v-if="info.residentialArea">
        {{ info.residentialArea || 0 }} м²
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Кол-во комнат
      </template>
      <div v-if="info.roomCount">
        {{ info.roomCount }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Проходные комнаты
      </template>
      <div>
        {{ info.connectingRooms ? 'Есть' : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import RealtyComplexName from '@/components/other/complex/RealtyComplexName.vue'
import TwCompanyName from '@/components/tw-ui/other/TwCompanyName.vue'

export default {
  name: 'RealtyComplexPlanMainShow',
  components: { RealtyComplexName, TwPanelContentBlockFieldView, ViewPanelLink, TwCompanyName },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
