<template>
  <div
    v-if="user && id"
    class="d-flex justify-center align-center"
  >
    <img
      v-if="avatar && !isError"
      :style="avatarStyle"
      :width="size"
      :height="size"
      :src="avatar"
      @error="isError = true"
    >
    <div
      v-else
      class="d-flex justify-center align-center"
      :style="avatarStyle"
      style="background-color: darkgray"
    >
      <v-icon
        size="18px"
        dark
      >
        fa-user
      </v-icon>
    </div>
  </div>
  <div
    v-else
    class="d-flex justify-center align-center"
    :style="avatarStyle"
    style="background-color: darkgray"
  >
    <v-icon
      size="18px"
      dark
    >
      fa-user
    </v-icon>
  </div>
</template>

<script>
import { employeeAvatarListGetLink } from '@/views/Company/Employee/employeeHelpers'
export default {
  name: 'EmployeeAvatar',
  props: {
    id: {
      type: [Number, String]
    },
    size: {
      type: String,
      default: '50px'
    },
    bordered: {
      type: Boolean,
      default: false
    },
    borderColor: {
      type: String,
      default: 'lightgray'
    },
    borderSize: {
      type: String,
      default: '2px'
    },
    borderRadius: {
      type: String,
      default: '50%'
    }
  },
  data () {
    return {
      isError: false
    }
  },
  computed: {
    user () {
      return (this.id && this.$store.getters['users/getUserById'](this.id)) || null
    },
    avatar () {
      return this.user?.file ? employeeAvatarListGetLink(this.user.file) : null
    },
    avatarStyle () {
      return {
        width: this.size,
        height: this.size,
        borderRadius: this.borderRadius,
        border: this.bordered ? `${this.borderSize} solid ${this.borderColor}` : 'none'
      }
    }
  }
}
</script>
