<template>
  <TwPanelContent class="tw-mt-[12px] tw-flex tw-flex-wrap-reverse tw-font-manrope tw-text-main-sm2">
    <div class="tw-flex-1 tw-min-w-[500px]">
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          <div>
            Список предложений застройщика
          </div>
        </template>
        <template #action>
          <TwPanelContentBlockActionButton
            v-if="!isEdit"
            type="add"
            :disabled="false"
            @click="createStock"
          >
            Создать предложение
          </TwPanelContentBlockActionButton>
        </template>
        <div class="tw-flex tw-gap-[12px] tw-flex-col">
          <BuilderStockList
            ref="list"
            :builder-id="id"
            :selected-id="selectedStock ? selectedStock.id : null"
            @selectItem="onSelectStock"
          />
        </div>
      </TwPanelContentBlock>
    </div>
    <div class="tw-flex-1 tw-min-w-[500px]">
      <TwPanelContentBlock
        v-if="selectedStock || isEdit"
        class="tw-sticky tw-top-[6px] tw-mb-[6px]"
      >
        <template #title>
          <div>
            О предложении
          </div>
        </template>
        <template #action>
          <TwPanelContentBlockActionButton
            type="delete"
            :disabled="false"
            @click="remove"
          >
            удалить
          </TwPanelContentBlockActionButton>
          <TwPanelContentBlockActionButton
            type="edit"
            :disabled="false"
            @click="isEdit = !isEdit"
          >
            {{ isEdit ? 'отменить' : 'изменить' }}
          </TwPanelContentBlockActionButton>
        </template>
        <div class="tw-flex tw-gap-[12px] tw-flex-col">
          <RealtyBuilderStockForm
            v-if="isEdit"
            :id="selectedStock ? selectedStock.id : 0"
            :default-form-fields="defaultFormFields"
            @cancel="editFinally"
            @create="editFinally"
            @update="editFinally"
          />
          <RealtyBuilderStockShow
            v-else
            :info="selectedStock"
          />
        </div>
      </TwPanelContentBlock>
    </div>
  </TwPanelContent>
</template>

<script>
import builderStockAPI from '@/Api/ObjectStorage/Builder/stock'
import RealtyBuilderStockForm from '@/views/Dictionary/General/Company/developer/stock/RealtyBuilderStockForm'
import BuilderStockList from '@/views/Dictionary/General/Company/developer/stock/BuilderStockList'
import RealtyBuilderStockShow from '@/views/Dictionary/General/Company/developer/stock/RealtyBuilderStockShow'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'

export default {
  name: 'RealtyBuilderStockTab',
  components: {
    TwPanelContent,
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    RealtyBuilderStockShow,
    BuilderStockList,
    RealtyBuilderStockForm
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      isEdit: false,
      selectedStock: null
    }
  },
  computed: {
    defaultFormFields () {
      return {
        builderId: this?.id || null
      }
    }
  },
  methods: {
    createStock () {
      this.selectedStock = null
      this.isEdit = true
    },
    onSelectStock (data) {
      console.warn('onSelectStock', data)
      this.isEdit = false
      this.selectedStock = data
    },
    editFinally (data) {
      console.warn('editFinally', data)
      this.isEdit = false
      this.$nextTick(() => {
        if (this.$refs.list) {
          this.$refs.list.fetch()
        }
        this.selectedStock = data && data.id ? data : null
      })
    },
    remove () {
      if (this.loading) {
        return
      }
      if (window.confirm('Удалить данное предложение?')) {
        this.loading = true
        builderStockAPI.delete(this.selectedStock.id).then(() => {
          this.$store.commit('alerts/ADD_ALERT', {
            text: 'Успешно удалено!',
            timeout: 2500,
            color: 'success white--text',
            visible: true
          })
        }).catch(() => {
          this.$store.commit('alerts/ADD_ALERT', {
            text: 'Ошибка удаления!',
            timeout: 5000,
            color: 'error white--text',
            visible: true
          })
        }).finally(() => {
          this.loading = false
          this.editFinally(null)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
