<template>
  <div>
    <!--    TODO DEBUG-->
    <!--    <div class="red">-->
    <!--      <div>-->
    <!--        value=<small>{{value}}</small>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        localValue = <small>[{{localValue}}]</small>-->
    <!--      </div>-->
    <!--    </div>-->
    <div>
      <TwSelect
        :placeholder="field ? field.label : '-'"
        class="tw-min-w-[250px]"
        :label="field ? field.label : '-'"
        :dense="true"
        :cleanable="true"
        :persistent-label="true"
        :options="relativeOptionsList"
        :value="localValue.id"
        :disabled="false"
        @input="onInputId"
      >
        <template #listTop>
          <div class="tw-relative tw-text-main-sm2 tw-font-normal tw-px-[14px] tw-mb-[10px]">
            <svg
              class="tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-left-[24px]"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 5.5C2 3.567 3.567 2 5.5 2C7.433 2 9 3.567 9 5.5C9 7.433 7.433 9 5.5 9C3.567 9 2 7.433 2 5.5ZM5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11C6.6575 11 7.73148 10.6424 8.61752 10.0317L11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L10.0317 8.61752C10.6424 7.73148 11 6.6575 11 5.5C11 2.46243 8.53757 0 5.5 0Z"
                fill="#7A8797"
              />
            </svg>
            <input
              v-model="searchStr"
              class="tw-rounded-sm tw-w-full tw-pl-[30px] tw-pr-[16px] tw-py-[8px] tw-border tw-border-solid tw-outline-0 disabled:tw-bg-an-flat-disabled-input-bg disabled:tw-text-an-flat-disabled-input-text"
              :maxlength="200"
              placeholder="Поиск"
            >
          </div>
        </template>
        <template #options="{ options, getIsCheckedItem, clickToItem }">
          <div
            v-for="opt in options"
            :key="opt.value"
            :style="{ color: typesDict[opt.type.value].color }"
            :class="[getIsCheckedItem(opt) ? 'tw-text-an-flat-red-main tw-bg-an-flat-red-lighten hover:tw-bg-an-flat-red-lighten':'']"
            class="tw-leading-normal tw-cursor-pointer tw-py-[10px] tw-pl-[18px] tw-pr-[6px] tw-flex tw-gap-[6px] tw-items-center hover:tw-bg-an-flat-bg-main"
            @click.stop="clickToItem(opt)"
          >
            <div
              v-if="opt.level"
              class="tw-flex tw-gap-[3px]"
            >
              <div
                v-for="lNum in opt.level"
                :key="lNum"
              >
                -
              </div>
            </div>
            {{ opt ? opt.label : '-' }}
            <span
              v-if="opt.type && opt.type.value && typesDict[opt.type.value].label"
              :style="{ backgroundColor: `${typesDict[opt.type.value].color}10` }"
              class="tw-text-main-xs2 tw-px-[8px] tw-py-[2px] tw-rounded-sm"
            >
              {{ typesDict[opt.type.value].label }}
            </span>
          </div>
        </template>
      </TwSelect>
      <div class="tw-mt-[10px]">
        <TwCheckBox
          v-if="localValue.id"
          :value="localValue.sub"
          :disabled="false"
          :dense="true"
          label="Включая подотделы"
          @input="onInputSub"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'TwCompanyStructureSelect',
  components: { TwCheckBox, TwSelect },
  props: {
    value: {},
    field: {}
  },
  data () {
    return {
      searchStr: '',
      defaultValues: {
        id: null,
        sub: false
      }
    }
  },
  computed: {
    relativeOptionsList () {
      const searchStr = this.searchStr?.toLowerCase() || ''
      return (Array.isArray(this.field?.options) && this.field.options.filter((item) => {
        return item.label?.toLowerCase()?.includes(searchStr) || item.value?.toLowerCase()?.includes(searchStr)
      })) || []
    },
    localValue: {
      get () {
        return {
          id: this.value?.id || null,
          sub: this.value?.sub || false
        }
      },
      set () {
        console.error(this.$options.name, ' - Попытка изменить localValue')
        //
      }
    },
    typesDict () {
      return {
        department: {
          label: 'Отдел',
          color: '#0066C3'
        },
        office: {
          label: 'Офис',
          color: '#009479'
        },
        group: {
          label: 'Группа',
          color: '#5400BE'
        },
        franchise: {
          label: '',
          color: '#212121'
        }
      }
    }
  },
  methods: {
    onInputSub (value) {
      console.log(this.$options.name, 'onInputSub', value)
      this.$emit('input', { id: this.localValue?.id, sub: value })
      const optionItem = this.field.options.find(item => item.value === this.localValue.id)
      this.$emit('inputFullData', `${optionItem?.label} ${value ? '+' : ''}`)
    },
    onInputId (id) {
      console.log(this.$options.name, 'onInputId', id, this.defaultValues, this.field)
      this.searchStr = ''
      const optionItem = this.field.options.find(item => item.value === id)
      this.$emit('input', { id: id, sub: this.defaultValues.sub })
      this.$emit('inputFullData', `${optionItem?.label} ${this.defaultValues?.sub ? '+' : ''}`)
      if (!id) {
        this.onInputSub(false)
      }
    }
  }
}
</script>
