<template>
  <div class="tw-font-manrope tw-text-main-sm tw-pr-[14px] tw-py-[16px]">
    <template v-if="targetConfig.useSearch">
      <div
        v-if="label"
        class="tw-uppercase tw-font-semibold tw-pl-[14px] tw-mb-[10px]"
      >
        <div class="tw-flex tw-justify-between tw-items-center">
          {{ label }}
          <button
            v-if="relationName === 'plan'"
            class="tw-font-medium tw-text-main-xs tw-border tw-border-solid tw-border-an-flat-green-btn-bg-active tw-text-an-flat-green-btn-bg-active tw-px-[7px] tw-py-[2px] tw-rounded-sm enabled:hover:tw-bg-an-flat-green-light"
            @click.prevent="createNewRealtyPlan"
          >
            Создать
          </button>
        </div>
      </div>
      <div class="tw-relative tw-text-main-sm2 tw-font-normal tw-pl-[14px] tw-mb-[10px]">
        <svg
          class="tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-left-[24px]"
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 5.5C2 3.567 3.567 2 5.5 2C7.433 2 9 3.567 9 5.5C9 7.433 7.433 9 5.5 9C3.567 9 2 7.433 2 5.5ZM5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11C6.6575 11 7.73148 10.6424 8.61752 10.0317L11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L10.0317 8.61752C10.6424 7.73148 11 6.6575 11 5.5C11 2.46243 8.53757 0 5.5 0Z"
            fill="#7A8797"
          />
        </svg>
        <input
          ref="searchInput"
          v-model="searchString"
          class="tw-rounded-sm tw-w-full tw-pl-[30px] tw-pr-[16px] tw-py-[13px] tw-border tw-border-solid tw-outline-0 disabled:tw-bg-an-flat-disabled-input-bg disabled:tw-text-an-flat-disabled-input-text"
          :disabled="!targetConfig.api"
          :maxlength="targetConfig.searchMaxlength"
          :placeholder="searchPlaceholder"
          @input="onSearch"
          @keydown.enter.stop="onSearch"
        >
      </div>
    </template>
    <div
      v-if="!targetConfig.api"
      class="tw-text-center tw-text-an-flat-red-main"
    >
      API - Not Found!
    </div>
    <div
      v-else
      class="tw-h-[200px] tw-flex-nowrap tw-overflow-y-auto"
    >
      <div
        v-if="!searchString && !viewDataList.length"
        class="tw-text-center tw-text-an-flat-disabled-text tw-mt-5"
      >
        Введите данные
      </div>
      <div
        v-else-if="searchProcess && searchString"
        class="tw-text-center tw-text-an-flat-disabled-text tw-mt-5"
      >
        <TwCircleLoader
          border-color-class="tw-text-an-flat-disabled-text"
          width="30px"
          height="30px"
          border-width="2px"
        />
      </div>
      <div
        v-else-if="!viewDataList.length"
        class="tw-text-center tw-text-an-flat-disabled-text tw-mt-5"
      >
        Нет найдено
        <div
          v-if="allowCreate && searchString"
          class="tw-my-[15px]"
        >
          <button
            v-if="relationName === 'person'"
            class="tw-font-medium tw-border tw-border-solid tw-border-an-flat-green-btn-bg-active tw-text-an-flat-green-btn-bg-active tw-px-[7px] tw-py-[3px] tw-rounded-sm enabled:hover:tw-bg-an-flat-green-light"
            @click.prevent="createNewPerson"
          >
            Создать нового клиента
          </button>
        </div>
      </div>
      <div
        v-else
        class="tw-flex tw-flex-col"
      >
        <button
          v-for="(item, index) in viewDataList"
          :key="index"
          type="button"
          :class="{ 'tw-text-an-flat-disabled-text': checkDisabled && checkDisabled(item) }"
          class="tw-text-left tw-cursor-pointer tw-text-main-sm tw-flex tw-gap-[10px] tw-pl-[14px] tw-py-[7px] hover:tw-bg-an-flat-btn-gray"
          @click.prevent="onClick(item)"
        >
          <div
            v-if="item.imageUrl !== undefined"
            :style="{ backgroundImage: `url(${item.imageUrl})` }"
            class="tw-min-w-[38px] tw-min-h-[38px] tw-w-[38px] tw-h-[38px] tw-border-2 tw-border-solid tw-border-white tw-rounded-full tw-bg-no-repeat tw-bg-cover"
          />
          <div>
            <div
              v-if="item.label !== undefined"
              class="tw-font-medium tw-flex tw-items-center tw-gap-[6px]"
            >
              <div
                v-if="item.inBlackList"
                class="tw-flex"
              >
                <TwBlackListIcon
                  tag="span"
                />
              </div>
              <div>
                {{ item.label }}
              </div>
              <div v-if="item.locked">
                <svg
                  width="16px"
                  height="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.25 10.0546V8C5.25 4.27208 8.27208 1.25 12 1.25C15.7279 1.25 18.75 4.27208 18.75 8V10.0546C19.8648 10.1379 20.5907 10.348 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.40931 10.348 4.13525 10.1379 5.25 10.0546ZM6.75 8C6.75 5.10051 9.10051 2.75 12 2.75C14.8995 2.75 17.25 5.10051 17.25 8V10.0036C16.867 10 16.4515 10 16 10H8C7.54849 10 7.13301 10 6.75 10.0036V8ZM14 16C14 17.1046 13.1046 18 12 18C10.8954 18 10 17.1046 10 16C10 14.8954 10.8954 14 12 14C13.1046 14 14 14.8954 14 16Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
            <div
              v-if="item.desc !== undefined"
              class="tw-text-an-flat-disabled-text"
              v-html="item.desc"
            />
          </div>
          <div class="tw-ml-auto tw-flex tw-items-center tw-justify-center">
            <TwCheckBox
              :value="selectedItems.some(i => i.value === item.value)"
              :multi="multi"
            />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import leadStatusAPI from '@/Api/Crm/Lead/Status'
import dealStatusAPI from '@/Api/Crm/Deal/Status'
import { API as OsApi } from '@/Api/ObjectStorage'
import companyAPI from '@/Api/Crm/Company'
import personAPI from '@/Api/Crm/Person'
import dealContractAPI from '@/Api/Crm/Deal/Contract'
import userAPI from '@/Api/Auth/User'
import lodashDebounce from 'lodash/debounce'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import { mapActions } from 'vuex'
import TwBlackListIcon from '@/components/tw-ui/user/TwBlackListIcon.vue'

const structureToString = function (userStructure = []) {
  if (Array.isArray(userStructure) && userStructure.length) {
    let result = ''
    for (const structureIndex in userStructure) {
      const { structure, position } = userStructure[structureIndex]
      // Структура
      if (structure) {
        // Формат структуры
        result += structure?.entity?.label ? `${structure.entity.label} ` : ''
        const structureFormat = structure?.entity?.value || null
        // Название структуры
        if (structure.name) {
          result += structure.name ? `${structure.name} - ` : ' '
        } else if (structure[structureFormat]) {
          result += structure[structureFormat]?.name ? `${structure[structureFormat].name} - ` : ''
        }
      }
      // Должность
      if (position) {
        result += position.name || ''
      }
      // Разделитель
      if (structureIndex < userStructure.length - 1) {
        result += ', '
      }
    }
    return result
  }
  return 'Не указано'
}

const defaultImage = function () {
  return require('@/assets/images/no_image.svg')
}

export default {
  name: 'TwSelectRelationSearchList',
  components: { TwCheckBox, TwCircleLoader, TwBlackListIcon },
  props: {
    value: {
      type: [Array, String, Number],
      default: null
    },
    relationName: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    searchPlaceholder: {
      type: String,
      default: 'Поиск...'
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    multi: {
      type: Boolean,
      default: false
    },
    // нужно ли кидать пред запрос с получением списка
    prefetchRequest: {
      type: Boolean,
      default: false
    },
    defaultFilters: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    /*
    * Метод который будет проверять нужно ли установить DISABLE на элемент + Выводить уведомление если нужно
    * function checkDisabled (item, isAlert = false) {
    *  if (item.value === '1') {
    *   if (isAlert) {
    *     alert('Данный элемент нельзя выбрать Потому что .....')
    *   }
    *   return true
    *  }
    * }
    * */
    checkDisabled: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      searchProcess: false,
      searchString: '',
      controllerAbort: null,
      list: []
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    }),
    createNewPerson () {
      let newPhone = this.searchString
      const regex = /^\d+$/
      const result = regex.test(newPhone)
      if (result) {
        if (newPhone[0] === '9') {
          newPhone = '8' + newPhone.slice(0, 10)
        }
      }
      return this.showPanel({
        component: 'person-show',
        props: {
          id: 0,
          workToClose: true,
          searchedPhone: result ? newPhone : null
        },
        label: 'Клиент',
        callback: (result) => {
          this.$emit('set-selected', this.itemAdapter(result.data))
        }
      })
    },
    createNewRealtyPlan () {
      console.log('createNewRealtyPlan')
      return this.showPanel({
        component: 'realty-plan-show',
        props: {
          id: 0,
          workToClose: false,
          complexId: Array.isArray(this.defaultFilters?.complexId) && this.defaultFilters?.complexId.length ? this.defaultFilters?.complexId[0] || null : null,
          builderId: Array.isArray(this.defaultFilters?.builderId) && this.defaultFilters?.builderId.length ? this.defaultFilters?.builderId[0] || null : null
        },
        label: 'Планировка',
        callback: (result) => {
          this.$emit('set-selected', this.itemAdapter(result.data))
        }
      })
    },
    onClick (item) {
      // console.warn(this.$options.name, 'onClick', item)
      if (this.checkDisabled && this.checkDisabled(item, true)) {
        return
      }
      this.$emit('select', item)
    },
    onSearch () {
      if (this.searchProcess && this.controllerAbort) {
        this.controllerAbort.abort()
        this.searchProcess = false
      }
      this.debouncedFetch()
    },
    debouncedFetch: lodashDebounce(function () {
      this.controllerAbort = new AbortController()
      this.searchProcess = true
      this.list = []
      if (this.searchString || (this.defaultFilters && Object.keys(this.defaultFilters).length)) {
        this.targetConfig?.apiSearch({ [this.targetConfig?.searchKey]: this.searchString, limit: this.targetConfig?.limit, ...this.defaultFilters }, { signal: this.controllerAbort.signal })
          .then(({ data: result }) => {
            // console.log('onSearch', result)
            this.list = result?.data || []
          })
          .catch((error) => {
            console.error('onSearch error', error)
          }).finally(() => {
            this.searchProcess = false
          })
      }
    }, 300),
    onInitValue (valId) {
      if (!this.selectedItems.some(i => i.value === valId)) {
        // console.warn('onInitValue', { valId })
        this.targetConfig?.apiShow(valId)
          .then(({ data: result }) => {
            // console.warn('onInitValue', this.itemAdapter(result.data))
            this.$emit('set-selected', this.itemAdapter(result.data))
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    itemAdapter (item) {
      switch (this.relationName) {
        case 'user':
          return {
            value: item.id,
            label: `${item.surname || ''} ${item.name || ''} ${item.fatherName || ''}`,
            desc: structureToString(item.userStructure),
            imageUrl: item.file?.preview || item.file?.url || defaultImage() || undefined
          }
        case 'person':
          return {
            value: item.id,
            label: `${item.surname || ''} ${item.name || ''} ${item.fatherName || ''}`,
            inBlackList: !!item.blackList,
            desc: item.phones.map(phone => {
              return (phone + '').replace(/(\d)(\d\d\d)(\d\d\d)(\d\d)(\d\d)/, '+$1 ($2) $3-$4-$5')
            }).join(', ')
          }
        case 'company':
          return {
            value: item.id,
            label: item.name,
            inBlackList: !!item.blackList
          }
        case 'developer':
          return {
            value: item.id,
            label: item.name,
            inBlackList: !!item.blackList
          }
        case 'bank':
          return {
            value: item.id,
            label: item.name,
            inBlackList: !!item.blackList
          }
        case 'insurance':
          return {
            value: item.id,
            label: item.name,
            inBlackList: !!item.blackList
          }
        case 'complex':
          return {
            value: item.id,
            label: item.name,
            desc: `${item.addressName || ''} ${item.addressName && item.builder?.name ? ' - ' : ''} ${item.builder?.name || ''}`
          }
        case 'plan':
          return {
            value: item.id,
            label: `${item.name} - ${item.builder?.label || ''} ${item.complex?.label || ''}`,
            desc: `${item.roomCount ? typeof item.roomCount === 'string' ? item.roomCount + ' ' : item.roomCount + 'к. ' : ''}${item.totalArea ? item.totalArea + 'м² ' : ''}${item.commercial ? 'Коммерч.' : ''}${item.studio ? 'Студия' : ''}`
          }
        case 'dealStatus':
          return {
            value: item.id,
            label: item.name,
            desc: item.stage?.label
          }
        case 'leadStatus':
          return {
            value: item.id,
            label: item.name,
            desc: item.stage?.label
          }
        case 'contract':
          return {
            value: item.id,
            label: item.contractNum,
            signed: !!item?.signed,
            locked: !!item?.locked,
            desc: item?.signed ? `
            <small class="tw-text-an-flat-green-btn-bg/80">
                Подписан в заявке <i>#${item.deal?.value || '-'}</i>
            </small>
            ` : `
            <small class="tw-text-an-flat-red-main/80">
                Не подписан
            </small>
            `
          }
        default:
          return this.list
      }
    }
  },
  computed: {
    targetConfig () {
      switch (this.relationName) {
        case 'user':
          return {
            api: userAPI,
            apiSearch: userAPI.getListNew, // TODO переведено на новый список для ускорения
            apiShow: userAPI.showShort,
            searchKey: 'fio',
            searchMaxlength: 35,
            useSearch: true
          }
        case 'person':
          return {
            api: personAPI,
            apiSearch: personAPI.getListSearch,
            apiShow: personAPI.show,
            searchKey: 'q',
            searchMaxlength: 35,
            useSearch: true
          }
        case 'company':
          return {
            api: companyAPI,
            apiSearch: companyAPI.getListNew,
            apiShow: companyAPI.show,
            searchKey: 'name',
            searchMaxlength: 35,
            useSearch: true
          }
        case 'developer':
          return {
            api: companyAPI,
            apiSearch: companyAPI.getListNew,
            apiShow: companyAPI.show,
            searchKey: 'name',
            searchMaxlength: 35,
            useSearch: true
          }
        case 'bank':
          return {
            api: companyAPI,
            apiSearch: companyAPI.getListNew,
            apiShow: companyAPI.show,
            searchKey: 'name',
            searchMaxlength: 35,
            useSearch: true
          }
        case 'insurance':
          return {
            api: companyAPI,
            apiSearch: companyAPI.getListNew,
            apiShow: companyAPI.show,
            searchKey: 'name',
            searchMaxlength: 35,
            useSearch: true
          }
        case 'complex':
          return {
            api: OsApi.Complex,
            apiSearch: OsApi.Complex.getListNew,
            apiShow: OsApi.Complex.showShort,
            searchKey: 'name',
            searchMaxlength: 35,
            useSearch: true
          }
        case 'plan':
          return {
            api: OsApi.Editor.Plan,
            apiSearch: OsApi.Editor.Plan.getList,
            apiShow: OsApi.Editor.Plan.show,
            searchKey: 'name',
            searchMaxlength: 35,
            limit: 500,
            useSearch: true
          }
        case 'leadStatus':
          return {
            api: leadStatusAPI,
            apiSearch: leadStatusAPI.getList,
            apiShow: leadStatusAPI.show,
            searchKey: 'search',
            searchMaxlength: 35,
            useSearch: false
          }
        case 'dealStatus':
          return {
            api: dealStatusAPI,
            apiSearch: dealStatusAPI.getList,
            apiShow: dealStatusAPI.show,
            searchKey: 'search',
            searchMaxlength: 35,
            useSearch: false
          }
        case 'contract':
          return {
            api: dealContractAPI,
            apiSearch: dealContractAPI.getListNew,
            apiShow: dealContractAPI.show,
            searchKey: 'contractNum',
            searchMaxlength: 35,
            useSearch: true
          }
        default:
          return {
            api: null, // Объект с API методами
            apiSearch: null, // метод используемый для поиска
            apiShow: null, // метод используемый для получение подробностей по ИД
            searchKey: 'search', // ключ параметра для поиска
            searchMaxlength: 35, // максимальное кол-во символов при вводе в поисковое поле
            useSearch: false // Используется ли поиск
          }
      }
    },
    viewDataList () {
      switch (this.relationName) {
        case 'user':
          return this.list.map((item) => this.itemAdapter(item))
        case 'person':
          return this.list.map((item) => this.itemAdapter(item))
        case 'company': // все компании
          return this.list.map((item) => this.itemAdapter(item))
        case 'developer': // компании застройщики
          return this.list.map((item) => this.itemAdapter(item))
        case 'bank': // компании банки
          return this.list.map((item) => this.itemAdapter(item))
        case 'insurance': // компании страховые
          return this.list.map((item) => this.itemAdapter(item))
        case 'complex':
          return this.list.map((item) => this.itemAdapter(item))
        case 'plan':
          return this.list.map((item) => this.itemAdapter(item))
        case 'dealStatus':
          return this.list.map((item) => this.itemAdapter(item))
        case 'leadStatus':
          return this.list.map((item) => this.itemAdapter(item))
        case 'contract':
          return this.list.map((item) => this.itemAdapter(item))
        default:
          return this.list
      }
    }
  },
  watch: {
    value: {
      handler (newValue) {
        // console.warn(this.$options.name, 'WATCH value: ', newValue) TODO RM - && newValue !== []
        if (newValue !== null) {
          if (Array.isArray(newValue)) {
            newValue.forEach((val) => {
              this.onInitValue(val)
            })
          } else {
            this.onInitValue(newValue)
          }
        }
      },
      immediate: true
    },
    visible (newValue) {
      if (newValue) {
        if (!this.list.length) {
          this.onSearch()
        }
        if (this.$refs?.searchInput) {
          this.$nextTick(() => {
            this.$refs.searchInput?.focus()
          })
        }
      } else {
        this.controllerAbort?.abort()
        this.searchString = ''
      }
    },
    defaultFilters: {
      handler () {
        // console.warn(this.$options.name, 'WATCH: defaultFilters ', newValue)
        this.onSearch()
      }
      // immediate: true // TODO отключено - ибо кидает запросы при инициализации
    }
  },
  mounted () {
    if (!this.targetConfig?.api) {
      console.error(this.$options.name, 'API - Not Found!')
    } else {
      if (this.prefetchRequest) {
        this.onSearch()
      }
    }
  },
  beforeDestroy () {
    this.controllerAbort?.abort()
  }
}
</script>

<style scoped>

</style>
