import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/DocCheck'

export const path = '/kontur/check/person/full'

export default {
  create (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/create`, $config)
  },
  store (data: object) {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(path, data, $config)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  }
}
