<template>
  <div v-cloak>
    <div v-if="info">
      <CardBlockField>
        <template #title>
          Ежемесячный доход
        </template>
        <template #content>
          {{ (info.salaryMonth || 0).toLocaleString() }} руб.
        </template>
      </CardBlockField>
      <CardBlockField>
        <template #title>
          Общий стаж
        </template>
        <template #content>
          <div v-if="info.experience">
            {{ $Global.calculateExperience(info.experience) }} (с {{ $Global.DateFormat.DateShort(info.experience) }})
          </div>
          <div v-else>
            Нет
          </div>
        </template>
      </CardBlockField>
      <CardBlockField>
        <template #title>
          Стаж на последнем месте работы
        </template>
        <template #content>
          <div v-if="info.experienceLast">
            {{ $Global.calculateExperience(info.experienceLast) }} (с {{ $Global.DateFormat.DateShort(info.experienceLast) }})
          </div>
          <div v-else>
            Нет
          </div>
        </template>
      </CardBlockField>
      <CardBlockField>
        <template #title>
          Способ подтверждения дохода
        </template>
        <template #content>
          {{ info.confirmationMethod && info.confirmationMethod.label }}
        </template>
      </CardBlockField>
      <CardBlockField>
        <template #title>
          Зарплатный проект
        </template>
        <template #content>
          <div
            v-if="info.salaryProjectCard || info.salaryProjectCompany"
            class="px-3 sub-white-bordered-block"
          >
            <div
              v-if="info.salaryProjectCompany"
              class="my-2"
            >
              <small class="text-muted">Банк</small>
              <div>
                <ViewPanelLink
                  :id="info.salaryProjectCompany.id"
                  label="Компания"
                  component="company-show"
                >
                  {{ info.salaryProjectCompany.name }}
                </ViewPanelLink>
              </div>
            </div>
            <div
              v-if="info.salaryProjectCard"
              class="my-2"
            >
              <small class="text-muted">
                Номер зарплатной карты
              </small>
              <div>
                {{ info.salaryProjectCard }}
              </div>
            </div>
          </div>
          <div v-else>
            Нет
          </div>
        </template>
      </CardBlockField>
      <!-- ОСНОВНОЕ МЕСТО РАБОТЫ -->
      <CardBlockField>
        <template #title>
          Основное место работы
        </template>
        <template #content>
          <div
            v-if="mainCompanyShow"
            class="px-3 sub-white-bordered-block"
          >
            <div class="my-2">
              <small class="text-muted">Название</small>
              <div>
                {{ info.mainCompanyName || 'Нет' }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                Телефон
              </small>
              <div>
                <SipCallLink
                  v-if="info.mainCompanyPhone"
                  :phone="info.mainCompanyPhone"
                />
                <div v-else>
                  Нет
                </div>
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                ИНН
              </small>
              <div>
                {{ info.mainCompanyInn || 'Нет' }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                Адрес
              </small>
              <div>
                {{ info.mainCompanyAddress || 'Нет' }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                Сфера деятельности
              </small>
              <div>
                {{ info.mainCompanyDirection || 'Нет' }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                Должность
              </small>
              <div>
                {{ info.mainCompanyPosition || 'Нет' }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                Веб сайт
              </small>
              <div>
                {{ info.mainCompanySite || 'Нет' }}
              </div>
            </div>
          </div>
          <div v-else>
            Нет
          </div>
        </template>
      </CardBlockField>
      <CardBlockField>
        <template #title>
          Сумма дополнительного дохода
        </template>
        <template #content>
          {{ info.additionalSalarySum && info.additionalSalarySum > 0 ? `${info.additionalSalarySum.toLocaleString()} руб.` : 'Нет' }}
        </template>
      </CardBlockField>
      <!-- ДОП МЕСТО РАБОТЫ -->
      <CardBlockField>
        <template #title>
          Дополнительное место работы
        </template>
        <template #content>
          <div
            v-if="additionalCompanyShow"
            class="px-3 sub-white-bordered-block"
          >
            <div class="my-2">
              <small class="text-muted">Название</small>
              <div>
                {{ info.additionalCompanyName || 'Нет' }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                Телефон
              </small>
              <div>
                <SipCallLink
                  v-if="info.additionalCompanyPhone"
                  :phone="info.additionalCompanyPhone"
                />
                <div v-else>
                  Нет
                </div>
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                ИНН
              </small>
              <div>
                {{ info.additionalCompanyInn || 'Нет' }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                Адрес
              </small>
              <div>
                {{ info.additionalCompanyAddress || 'Нет' }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                Сфера деятельности
              </small>
              <div>
                {{ info.additionalCompanyDirection || 'Нет' }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">
                Должность
              </small>
              <div>
                {{ info.additionalCompanyPosition || 'Нет' }}
              </div>
            </div>
          </div>
          <div v-else>
            Нет
          </div>
        </template>
      </CardBlockField>
      <!-- НАКОПЛЕНИЯ -->
      <CardBlockField>
        <template #title>
          Есть накопления
        </template>
        <template #content>
          {{ info.accumulation ? 'Да' : 'Нет' }}
        </template>
      </CardBlockField>
    </div>
    <div
      v-else
      class="text-muted text-center"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import CardBlockField from '@/components/core/ViewPanel/Card/CardBlockField'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import SipCallLink from '@/components/telephony/SipCallLink'
export default {
  name: 'PersonSalaryShow',
  components: { SipCallLink, ViewPanelLink, CardBlockField },
  props: {
    info: {},
    dictionary: {}
  },
  computed: {
    mainCompanyShow () {
      return this.info && (
        this.info.mainCompanyName ||
        this.info.mainCompanyInn ||
        this.info.mainCompanyAddress ||
        this.info.mainCompanyAddress ||
        this.info.mainCompanyDirection ||
        this.info.mainCompanyPhone ||
        this.info.mainCompanySite ||
        this.info.mainCompanyPosition)
    },
    additionalCompanyShow () {
      return this.info && (
        this.info.additionalCompanyName ||
        this.info.additionalCompanyInn ||
        this.info.additionalCompanyAddress ||
        this.info.additionalCompanyAddress ||
        this.info.additionalCompanyDirection ||
        this.info.additionalCompanyPhone ||
        this.info.additionalCompanyPosition)
    }
  }
}
</script>
