import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { AlertsState, AlertItem } from '@/store/alerts/types'

const namespaced = true

export const alerts: Module<AlertsState, RootState> = {
  namespaced,
  state: {
    list: []
  },
  getters: {
    getLastUnvisibleAlert (state) {
      return state.list.find(item => item.visible)
    },
    getLastUnvisibleAlertIndex (state) {
      return state.list.findIndex(item => item.visible)
    }
  },
  actions: {},
  mutations: {
    ADD_ALERT (state, payload: AlertItem) {
      state.list.push(payload)
    },
    CHANGE_ALERT_VISIBLE (state, index: number) {
      state.list[index].visible = !state.list[index].visible
    },
    ALL_ALERTS_UNVISIBLE (state) {
      for (const i in state.list) {
        state.list[i].visible = false
      }
    }
  }
}
