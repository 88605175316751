import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from '@/store/types'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { profile } from './profile/index'
import { filters } from './filters'
import { alerts } from './alerts/index'
import { reports } from './reports/index'
import { imageViewer } from './image-viewer/index'
import { viewPanels } from './view-panel/index'
import { aFilter } from './afilter/index'
import { introTeach } from './intro-teach/index'
import { cards } from './cards/index'
import { users } from './users/index'
import { chat } from './chat/index'
import { sockets } from './sockets/index'
import { tmpCache } from './tmp-cache'
import { objectStorage } from './os'
import { realtyMiner } from './realty-miner'
import { mainMenu } from './main-menu'
import { favorite } from './favorite'
import { twFilter } from '@/store/twfilter'
import { tableConfig } from '@/store/table-config'
import { notify } from './notify/index'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state,
  modules: {
    profile,
    filters,
    aFilter,
    twFilter,
    tableConfig,
    alerts,
    reports,
    introTeach,
    cards,
    users,
    imageViewer,
    viewPanels,
    tmpCache,
    realtyMiner,
    mainMenu,
    chat,
    sockets,
    objectStorage,
    favorite,
    notify
  },
  mutations,
  getters
}

export default new Vuex.Store<RootState>(store)
