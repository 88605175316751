<template>
  <div
    v-if="formProcessing"
    class="tw-py-3 tw-flex tw-w-full"
  >
    <TwCircleLoader class="tw-m-auto" />
  </div>
  <div v-else>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <TwInputText
          v-model="form.name"
          type="text"
          placeholder="Название компании"
          label="Название"
          :required="true"
          :disabled="formProcessing"
          :show-label="true"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.code"
          type="text"
          placeholder="Код"
          label="Код"
          :required="true"
          :disabled="formProcessing"
          :show-label="true"
          :error-text="form.code ? '' : 'Это поле обязательное'"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.dateFoundation"
          type="date"
          label="Дата основания"
          :disabled="formProcessing"
          :show-label="true"
          :max="currentDate"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwTextarea
          v-model="form.description"
          rows="5"
          placeholder="Информация о компании"
          :disabled="formProcessing"
          :show-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <div class="tw-flex tw-justify-between tw-text-an-flat-blue-btn-active tw-text-main-sm2 tw-mb-[10px]">
          <div>
            Логотип компании:
          </div>
        </div>
        <TwUploadFilesList
          label="Изображение c логотипом компании"
          :value="form.logoFileId ? [form.logoFileId] : []"
          :is-public="true"
          :api="fileApi"
          :has-inline="false"
          :multiple="false"
          @input="form.logoFileId = $event[0] || null"
        />
      </TwFlexCell>
      <TwFlexRow>
        <TwFlexCell>
          <TwCheckBox
            v-model="form.active"
            :disabled="formProcessing"
            background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
            label="Активность"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwCheckBox
            v-model="form.hidePrice"
            :disabled="formProcessing"
            background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
            label="Скрыть цены"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwCheckBox
            v-model="form.priority"
            :disabled="formProcessing"
            background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
            label="Приоритетный"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexCell>
        <TwCardSimpleFormSaveBlock
          :loading="formProcessing"
          :is-sticky="true"
          :dense="false"
          :disable-success="formProcessing"
          :disable-cancel="formProcessing"
          :visible="true"
          @success="handleSubmit"
          @cancel="handleCancel"
        >
          <template #info>
            <div
              v-if="serverErrorMessage"
              class="tw-text-an-flat-red-main tw-text-center tw-p-[8px] tw-mb-[5px] tw-bg-white"
            >
              {{ serverErrorMessage }}
            </div>
          </template>
        </TwCardSimpleFormSaveBlock>
      </TwFlexCell>
    </TwFlexCol>
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'RealtyBuilderMainForm',
  components: {
    TwCheckBox,
    TwFlexCol,
    TwFlexCell,
    TwFlexRow,
    TwCircleLoader,
    TwInputText,
    TwTextarea,
    TwCardSimpleFormSaveBlock,
    TwUploadFilesList
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      fileApi: API.File,
      API: API.Builder,
      currentDate: new Date().toISOString().substr(0, 10),
      form: {
        active: true,
        code: null,
        dateFoundation: null,
        hidePrice: true,
        priority: false,
        logoFileId: null,
        status: null
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            logoFile: 'logoFileId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1) {
                this.$set(this.form, adaperMap[key], (this.preset[key] && this.preset[key].id) || this.defaultFormFields[adaperMap[key]])
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.form.code) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).catch((error) => {
          this.serverErrorMessage = error?.serverErrorMessage || 'Ошибка!'
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
