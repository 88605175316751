<template>
  <div class="item-wrapper mt-5">
    <div
      :style="{backgroundColor: listColors[variant].bg}"
      class="item-icon"
    >
      <v-icon
        size="18px"
        color="white"
      >
        fa-exclamation
      </v-icon>
    </div>
    <div
      v-if="item"
      class="card item-hovered-visible-block"
    >
      <div
        v-if="item"
        class="card-wrapper"
        style="width: 100%"
      >
        <div
          class="card-left"
          style="flex: auto"
        >
          <div class="card-header">
            <div class="card-header-info">
              <p class="card-title">
                <span>{{ item.attachEntity && item.attachEntity.label }}:</span>
              </p>
              <p class="card-time">
                {{ $Global.DateFormat.DateTimeShort(item.date) }}
              </p>
            </div>
          </div>
          <div class="card-notification-info">
            <div class="card-notification-text d-flex">
              <span class="mr-3">
                <ViewPanelLink
                  :id="item.attachEntityId"
                  label="Объект"
                  component="nb-selection-apartment-modal-show"
                >
                  Ссылка на объект {{ item.attachEntityId }}
                </ViewPanelLink>
              </span>
            </div>
            <!--            <EmployeeLinkBlock :userId="entityData.userId"/>-->
          </div>
        </div>
        <div class="card-right">
          <ChronologyPinned
            v-if="!(variant === 'other' && item.pin)"
            :id="item.id"
            :pin-status="item.pin"
            @refresh="$emit('refresh')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listColors } from '@/components/core/Chronology/ListConfig'
import ChronologyPinned from '@/components/core/Chronology/ChronologyPinned'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'

export default {
  name: 'ChronologyBookingView',
  components: { ViewPanelLink, ChronologyPinned },
  props: {
    item: {
      type: Object,
      required: true
    },
    variant: {
      type: String,
      required: true // other, plan, pin
    }
  },
  computed: {
    listColors () {
      return listColors
    },
    entityData () {
      return this.item?.attachEntityData || null
    },
    toStatus () {
      return this.entityData?.status?.name || null
    },
    fromStatus () {
      return this.entityData?.oldStatus?.name || null
    }
  }
}
</script>

<style scoped>

</style>
