import store from '@/store'
import { RouteConfig, RouteMeta } from 'vue-router'
import { DEPLOYMENT_ENV, RouteKeys } from '@/router/types'

import lead from '@/router/routes/lead'
import deal from '@/router/routes/deal'
import person from '@/router/routes/person'
import support from '@/router/routes/support'
import iframe from '@/router/routes/iframe'
import management from '@/router/routes/management'
import tools from '@/router/routes/management/tools'
import reports from '@/router/routes/management/reports'
import knowledge from '@/router/routes/knowledge'
import link from '@/router/routes/link'
import dictPermissionRules from '@/Permissions/dictRules'

type CrmAppRouteConfig = RouteConfig & {
  meta?: RouteMeta
}

const routes: Array<CrmAppRouteConfig> = [
  link,
  management,
  {
    path: '/',
    name: 'root',
    meta: { name: 'Главная', icon: 'dashboard', hideInMenu: true },
    redirect: { name: 'dashboard' }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { name: 'Главная', icon: 'dashboard', isAdmin: false, hText: 'Моя статистика' },
    component: () => import(/* webpackChunkName: "Dashboard", webpackPreload: true */ '@/views/Dashboard.vue')
  },
  lead,
  deal,
  person,
  tools,
  {
    path: '/calls',
    name: `${RouteKeys.reports}-calls`,
    meta: { name: 'Звонки', icon: 'reports-calls', ruleNamespace: dictPermissionRules.AppServicePermissionRulesROIReportsReportCalllsRule },
    component: () => import('@/views/Reports/Calls/List.vue')
  },
  reports,
  {
    path: '/news',
    name: `${RouteKeys.crmNews}-grid`,
    meta: { name: 'Новости', icon: 'crm-news' },
    component: () => import('@/views/News/Grid/Grid.vue')
  },
  {
    path: '/change-log',
    name: `${RouteKeys.changeLog}-grid`,
    meta: { name: 'Обновления crm', icon: 'change-log-viewing', ruleNamespace: dictPermissionRules.AppServicePermissionRulesDOCChangeLogReadChangeLogRule },
    component: () => import('@/views/ChangeLog/Grid/Grid.vue')
  },
  knowledge,
  {
    meta: { name: 'Структура компании', icon: 'structure-tree' },
    name: 'Структура компании',
    path: '/structure-tree',
    component: () => import(/* webpackChunkName: "ServiceStatus", webpackPrefetch: true */ '@/views/CompanyStructure/CompanyStructurePage.vue')
  },
  // Ниже описаны скрытые из меню элементы навигации
  {
    path: '/profile',
    name: 'profile',
    meta: { name: 'Профиль пользователя', icon: 'user', hideInMenu: true },
    component: () => import(/* webpackChunkName: "EmployeeCard", webpackPrefetch: true */ '@/views/Company/Employee/EmployeeCard.vue'),
    props: () => ({ id: store.getters['profile/getUser'].id })
  },
  {
    meta: { name: 'Восстановление пароля', hideInMenu: true },
    name: 'reset_password',
    path: '/reset_password',
    component: () => import(/* webpackChunkName: "forDeveloper", webpackPrefetch: true */ '@/resetPassword.vue')
  },
  {
    path: '/404',
    meta: { name: 'Ошибка 404', hideInMenu: true },
    component: () => import(/* webpackChunkName: "NotFound", webpackPrefetch: true */ '@/views/NotFound.vue')
  },
  {
    path: '*',
    meta: { name: 'Неизвестный маршрут', hideInMenu: true },
    redirect: '/404'
  },
  support,
  iframe,
  {
    meta: { name: 'Чат DEV', icon: 'comments-o', isAdmin: DEPLOYMENT_ENV === 'prod', hideInMenu: true },
    name: 'Чат DEV',
    path: '/messenger',
    props: (route:any) => ({
      chatId: route.query?.chatId ? Number(route.query.chatId) : null,
      messageId: route.query?.messageId ? Number(route.query.messageId) : null
    }),
    component: () => import(/* webpackChunkName: "ServiceStatus", webpackPrefetch: true */ '@/views/Messenger/MessengerPage.vue')
  }
]

export default routes
