<template>
  <div>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <TwSelect
          v-model="form.objectType"
          label="Тип объекта"
          placeholder="Выбрать"
          :options="dictionary.objectType || []"
          :return-object="true"
          :dense="true"
          :error-text="$v.form.objectType.$invalid ? '*Обязательное поле':''"
          :disabled="formProcessing"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            ref="cadastralNumber"
            v-model="form.cadastralNumber"
            type="text"
            label="Кадастровый номер"
            placeholder="00:00:000000:000"
            :disabled="formProcessing"
            :dense="true"
            :show-label="true"
            persistent-label
            :error-text="$v.form.cadastralNumber.$invalid ? 'Заполните поле':''"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            ref="landArea"
            v-model="form.landArea"
            type="number"
            label="Площадь участка(сот.)"
            placeholder="0"
            :disabled="formProcessing"
            :dense="true"
            :show-label="true"
            persistent-label
            :error-text="$v.form.landArea.$invalid ? 'Заполните поле':''"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow v-if="form.objectType === 'uncompleted_house' || form.objectType === 'completed_house'">
        <TwFlexCell>
          <TwInputText
            ref="houseArea"
            v-model="form.houseArea"
            type="number"
            label="Площадь дома(кв.м)"
            :dense="true"
            placeholder="0"
            :disabled="formProcessing"
            :show-label="true"
            persistent-label
            :error-text="$v.form.houseArea.$invalid ? 'Заполните поле':''"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            ref="houseCompletedDate"
            v-model="form.houseCompletedDate"
            type="date"
            placeholder="Дата готовности дома"
            :error-text="$v.form.houseCompletedDate.$invalid ? '*Обязательное поле':''"
            :disabled="formProcessing"
            :dense="true"
            :show-label="true"
            :persistent-label="true"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexCell>
        <TwSelectRelations
          v-model="form.companyId"
          :multi="false"
          label="Застройщик"
          list-label="Найти застройщика"
          relation-name="developer"
          :persistent-label="true"
          :dense="true"
          placeholder="Не выбран"
          :disabled="formProcessing"
          :error-text="$v.form.companyId.$invalid ? 'Заполните поле':''"
        />
      </TwFlexCell>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            ref="objectPrice"
            type="text"
            :dense="true"
            placeholder="Стоимость объекта"
            max-length="13"
            :value="$Global.inputFormatPrice.getStringSpaced(form.objectPrice)"
            :disabled="formProcessing"
            :show-label="true"
            :error-text="$v.form.objectPrice.$invalid ? 'Заполните поле':''"
            @input="form.objectPrice = $Global.inputFormatPrice.getNumber($event)"
          >
            <template #append>
              руб.
            </template>
          </TwInputText>
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            ref="dealDate"
            v-model="form.dealDate"
            type="date"
            placeholder="Дата сделки"
            :max="currentDate"
            :error-text="$v.form.dealDate.$invalid ? '*Обязательное поле':''"
            :disabled="formProcessing"
            :dense="true"
            :show-label="true"
            :persistent-label="true"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexCell>
        <TwSelect
          v-model="form.payMethod"
          label="Способ оплаты"
          placeholder="Выбрать"
          :options="dictionary.payMethod || []"
          :error-text="$v.form.payMethod.$invalid ? '*Обязательное поле':''"
          :disabled="formProcessing"
          :dense="true"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            ref="deadlinePayDDU"
            v-model="form.deadlinePayDDU"
            type="date"
            placeholder="Крайняя дата полной оплаты ДДУ"
            :min="currentDate"
            :error-text="$v.form.deadlinePayDDU.$invalid ? '*Обязательное поле':''"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            ref="preliminaryCommission"
            type="text"
            placeholder="Предварительная комиссия"
            max-length="13"
            :value="$Global.inputFormatPrice.getStringSpaced(form.preliminaryCommission)"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :error-text="$v.form.preliminaryCommission.$invalid ? 'Заполните поле':''"
            @input="form.preliminaryCommission = $Global.inputFormatPrice.getNumber($event)"
          >
            <template #append>
              руб.
            </template>
          </TwInputText>
        </TwFlexCell>
      </TwFlexRow>
    </TwFlexCol>
    <div class="tw-my-[15px]">
      <div class="tw-mb-[15px] tw-font-semibold">
        Прикрепление файлов договора:
      </div>
      <TwUploadFilesList
        v-model="form.contractFileIds"
        label="Загрузить файлы ДДУ"
        no-data-text="Нет прикрепленнных файлов"
        :has-inline="true"
        :is-public="true"
        :multiple="true"
        :disabled="formProcessing"
      />
    </div>
    <TwCardSimpleFormSaveBlock
      :loading="formProcessing"
      bg-color="transparent"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/Result'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { required, minValue } from 'vuelidate/lib/validators'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'

export default {
  name: 'DealBuyNbLandHouseResultForm',
  components: {
    TwFlexRow,
    TwFlexCell,
    TwFlexCol,
    TwSelectRelations,
    TwCardSimpleFormSaveBlock,
    TwUploadFilesList,
    TwInputText,
    TwSelect
  },
  mixins: [FormCreateEditMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      currentDate: new Date().toISOString().substr(0, 10),
      API: API,
      form: {
        id: null,
        dealId: null, // Заявка
        deadlinePayDDU: null, // Крайняя дата оплаты ДДУ клиентом
        dealDate: null, // Дата сделки
        objectPrice: 0, // Стоимость объекта
        preliminaryCommission: 0, // Предварительная Комиссия
        payMethod: null, // Способ оплаты
        contractNum: null, // Номер договора ДДУ, или ДКП
        objectData: null,
        // для ИЖС
        cadastralNumber: null, // 43:40:000333:302
        fiasGuid: null,
        landArea: null,
        houseArea: null,
        houseCompletedDate: null,
        objectType: null,
        companyId: null
      }
    }
  },
  validations () {
    return {
      form: {
        preliminaryCommission: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
          }
        },
        objectPrice: {
          required: function (v) {
            return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
          }
        },
        companyId: {
          required
        },
        payMethod: {
          required
        },
        dealDate: {
          required
        },
        deadlinePayDDU: {
          required
        },
        cadastralNumber: {
          required
        },
        objectType: {
          required
        },
        fiasGuid: {
          // required
        },
        landArea: {
          required,
          minValue: minValue(1)
        },
        houseArea: {
          ...(this.form?.objectType === 'uncompleted_house' || this.form?.objectType === 'completed_house') && {
            required,
            minValue: minValue(1)
          }
        },
        houseCompletedDate: {
          ...(this.form?.objectType === 'uncompleted_house' || this.form?.objectType === 'completed_house') && {
            required
          }
        }
      }
    }
  },
  created () {
    this.createQueryParams = {
      dealId: this.dealId
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            deal: 'dealId',
            company: 'companyId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1) {
                console.warn('presetData ', this.preset[key] && this.preset[key].id, this.defaultFormFields[adaperMap[key]])
                this.$set(this.form, adaperMap[key], (this.preset[key] && this.preset[key].id) || this.defaultFormFields[adaperMap[key]])
              }
            } else if (key === 'objectType') {
              this.$set(this.form, key, this.preset[key]?.value || this.preset[key] || null)
            } else if (key === 'fiasGuid') {
              this.$set(this.form, key, '93b3df57-4c89-44df-ac42-96f05e9cd3b9')
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      console.warn(this.$options.name, ' handleSubmit ', this.$v.form, this.$v.form.$invalid)
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.dealId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    }
  }
}
</script>
