<template>
  <v-col cols="12">
    <v-card
      max-width="400px"
      outlined
      class="mx-auto"
    >
      <v-card-title class="error--text">
        Внимание!
      </v-card-title>
      <v-card-subtitle class="text-muted">
        Произошла ошибка получения информации с сервера
      </v-card-subtitle>
      <v-card-text class="error--text text-center">
        <div>
          Попробуйте перезагрузить страницу!
        </div>
        <small class="text-muted">
          Если ошибка повторяется, обратитесь к администратору
        </small>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'ErrorFetch'
}
</script>
