<template>
  <div class="tw-font-manrope">
    <h3>{{ statusInfo.name }}</h3>
    <TwTextarea
      v-model="form.comment"
      rows="5"
      :persistent-label="true"
      label="Причина"
      maxlength="250"
      show-length
      resize="y"
      :error-text="form.comment ? '':'Обязательное поле'"
      :show-label="true"
    />
    <div v-if="statusInfo.code === 'suspended'">
      <TwInputText
        v-model="form.dateSuspense"
        type="date"
        placeholder="Дата до окончания приостановки"
        :min="currentDate"
        :show-label="true"
        :persistent-label="true"
        :error-text="form.dateSuspense ? '':'Обязательное поле'"
      />
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="false"
      :cancel-confirm="false"
      :is-sticky="true"
      :disable-success="!form.comment"
      :disable-cancel="false"
      :visible="true"
      :dense="false"
      success-title="Сохранить"
      cancel-title="Отмена"
      @success="onApply"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCardSimpleFormSaveBlock from '../tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'DealFailStatusForm',
  components: { TwInputText, TwTextarea, TwCardSimpleFormSaveBlock },
  props: {
    statusInfo: {
      required: true
    }
  },
  data () {
    return {
      currentDate: new Date().toISOString().substr(0, 10),
      form: {
        comment: '',
        dateSuspense: ''
      }
    }
  },
  methods: {
    onApply () {
      if (this.form.comment || this.form.comment) {
        this.$emit('apply', this.form)
        this.form = {
          comment: '',
          dateSuspense: ''
        }
      }
    },
    onCancel () {
      this.form = {
        comment: '',
        dateSuspense: ''
      }
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
