<template>
  <!--  <div>-->
  <TwDialog
    v-model="show"
    max-width="500px"
  >
    <template #header>
      <div class="tw-font-bold tw-text-[18px] tw-text-an-flat-black-main">
        Скопировать / Импортировать параметры подбора
      </div>
    </template>
    <template #body>
      <div>
        <div class="tw-mb-[20px]">
          <TwSelectButton
            :dense="true"
            label=""
            :value="type"
            :options="linkSelect"
            @input="type = $event"
          />
        </div>
        <div v-if="type === 'copy'">
          <div class="tw-mb-[14px] tw-px-[10px] tw-py-[8px] tw-rounded-sm tw-bg-white tw-border tw-border-solid tw-border-an-flat-border-solid tw-overflow-auto">
            <textarea
              ref="link"
              style="width: 100%"
              rows="5"
              :value="linkStringParams"
              readonly
            />
          </div>
          <button
            title="Скопировать ссылку"
            class="tw-transition-all tw-text-an-flat-dark-blue-link tw-underline hover:tw-no-underline"
            @click="onCopyLink"
          >
            Скопировать параметры
          </button>
          <div class="">
            <div
              v-if="copyResult.success"
              class="tw-text-an-flat-green-btn-bg-active tw-py-4"
            >
              {{ copyResult.success }}!
            </div>
            <div
              v-if="copyResult.error"
              class="tw-text-an-flat-red-main tw-py-4"
            >
              {{ copyResult.error }}!
            </div>
          </div>
        </div>
        <div v-if="type === 'paste'">
          <div class="tw-mb-[14px]">
            <TwTextarea
              ref="link"
              v-model="pastedString"
              placeholder="Вставьте скопированную ссылку с параметрами для импорта"
            />
          </div>
          <button
            class="tw-transition-all tw-bg-an-flat-dark-blue-link tw-text-white tw-rounded-sm tw-text-center tw-px-[14px] tw-py-[8px] tw-font-bold hover:tw-opacity-50 active:tw-opacity-70 disabled:tw-opacity-40"
            :disabled="!Object.keys(pastedParams).length"
            title="Применить параметры к подбору"
            @click="onApplyParams"
          >
            Применить параметры к подбору
          </button>
        </div>
      </div>
    </template>
    <template #footer>
      <button
        class="tw-block tw-transition-all tw-w-full tw-mt-[26px] tw-p-[14px] tw-rounded-sm tw-bg-an-flat-gray-16 hover:tw-bg-an-flat-btn-hover tw-font-bold tw-text-an-flat-black-main"
        @click="show = !show"
      >
        Закрыть
      </button>
    </template>
  </TwDialog>
</template>

<script>
import { paramsToUrlFromObject, paramsToObjectFromUrl } from '@/views/Mortgage/Variant/Matrix/variantParamsCopyPasteHelper'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwSelectButton from '@/components/tw-ui/ui-kit/button/TwSelectButton.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  name: 'VariantParamsCopyPasteModal',
  components: {
    TwTextarea,
    TwSelectButton,
    TwDialog
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fromIframe: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      openDialog: false,
      type: 'copy', // copy || paste
      show: false,
      copyResult: {
        success: '',
        error: ''
      },
      pastedString: '',
      linkSelect: [
        {
          label: 'Скопировать',
          value: 'copy'
        },
        {
          label: 'Импортировать',
          value: 'paste'
        }
      ]
    }
  },
  computed: {
    stringParams () {
      return paramsToUrlFromObject(this.params)
    },
    linkStringParams () {
      return `${this.fromIframe ? 'https://crm.anflat.ru' : location.origin}/tools/mortgage-variant-matrix?variantParams=${this.stringParams}`
    },
    pastedParams () {
      const targetKey = '?variantParams='
      if (this.pastedString && this.pastedString.includes(targetKey)) {
        const targetString = this.pastedString.substring(this.pastedString.indexOf(targetKey) + targetKey.length)
        return paramsToObjectFromUrl(targetString)
      }
      return {}
    }
  },
  mounted () {
    this.initial()
  },
  methods: {
    onCopyLink () {
      console.warn(this.$refs.link, this.linkStringParams)
      // this.$clipboard(this.linkStringParams)
      try {
        this.$refs.link?.select()
        console.error('!!!!!1 this.$refs.link?.select()', this.$refs.link?.select())
        document.execCommand('copy')
        this.copyResult.success = 'Успешно скопировано'
      } catch (e) {
        this.copyResult.error = 'Произошла ошибка'
      }
    },
    onApplyParams () {
      console.log('123123')
      this.$emit('updateVariantParams', this.pastedParams)
      this.$nextTick(() => {
        this.closeModal()
      })
    },
    closeModal () {
      this.show = false
    },
    showModal () {
      this.show = true
    },
    initial () {
      if (this.$route.query?.variantParams) {
        const obj = paramsToObjectFromUrl(this.$route.query?.variantParams)
        this.$emit('updateVariantParams', obj)
        // Очитить адрес от параметров после применения
        this.$router.replace({
          query: {
            ...this.$route.query,
            variantParams: undefined
          }
        })
      }
    }
  }
}
</script>
