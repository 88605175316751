<template>
  <div
    v-if="Array.isArray(propScheme) && propScheme.length"
    :class="wrapperClass"
  >
    <div
      v-for="(prop, index) in propScheme"
      :key="prop.key + index"
      :data-prop-type="prop.type"
      :data-prop-key="prop.key"
      :class="[prop.type === 'Polygon' ? 'tw-min-w-full' : 'tw-min-w-[250px]']"
      :title="prop.label"
      class="tw-flex-1"
    >
      <div
        v-if="prop.type === 'Boolean'"
        class="tw-max-w-[300px]"
      >
        <TwSelectButton
          :label="prop.label"
          :multi="false"
          :dense="true"
          :value="propForm[prop.key]"
          :required="prop.required"
          :options="boolList"
          @input="change(prop, $event)"
        />
      </div>
      <div
        v-else-if="prop.type === 'Enum'"
        class="tw-max-w-[300px]"
      >
        <TwSelect
          :ref="prop.key"
          :error-text="formErrors[prop.key]"
          :value="propForm[prop.key]"
          :placeholder="prop.label"
          :label="prop.label"
          :return-object="true"
          :multi="prop.multiple"
          :dense="true"
          :cleanable="true"
          :persistent-label="true"
          :options="prop.options"
          @input="change(prop, $event)"
        />
      </div>
      <div v-else-if="prop.type === 'String'">
        <TwInputText
          :ref="prop.key"
          :error-text="formErrors[prop.key]"
          type="text"
          :label="prop.label"
          :placeholder="prop.label"
          clearable
          :value="propForm[prop.key]"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
          @input="change(prop, $event)"
        >
          <template
            v-if="prop.unit"
            #append
          >
            {{ prop.unit }}
          </template>
        </TwInputText>
      </div>
      <div v-else-if="prop.type === 'Text'">
        <TwTextarea
          :ref="prop.key"
          :error-text="formErrors[prop.key]"
          type="text"
          :label="prop.label"
          :placeholder="prop.label"
          clearable
          :value="propForm[prop.key]"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
          rows="3"
          resize="y"
          @input="change(prop, $event)"
        />
      </div>
      <div v-else-if="prop.type === 'Int' || prop.type === 'Float'">
        <TwInputText
          :ref="prop.key"
          :error-text="formErrors[prop.key]"
          type="text"
          :sub-type="prop.type === 'Int' ? 'int' : prop.type === 'Float' ? 'float' : 'number'"
          :max-length="prop.type === 'Float' ? '6' : '13'"
          :label="prop.label"
          :placeholder="prop.label"
          clearable
          :value="propForm[prop.key]"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
          @input="change(prop, $event)"
        >
          <template
            v-if="prop.unit"
            #append
          >
            {{ prop.unit }}
          </template>
        </TwInputText>
      </div>
      <div v-else-if="prop.type === 'Date'">
        <TwInputText
          :ref="prop.key"
          :error-text="formErrors[prop.key]"
          type="date"
          :label="prop.label"
          :placeholder="prop.label"
          clearable
          :value="propForm[prop.key]"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
          @input="change(prop, $event)"
        />
      </div>
      <div
        v-else-if="prop.type === 'Polygon'"
        class="tw-mt-[15px]"
      >
        <div class="tw-text-an-flat-blue-btn-active tw-text-sm2 tw-mb-[5px]">
          Область на карте:
        </div>
        <RealtyComplexBuildingMapMarking
          :buildings-list="(otherObjects && otherObjects.allObjectsMapPolygon) || []"
          :value="propForm[prop.key]"
          @input="mapPolygonChange(prop, $event)"
        />
      </div>
      <div v-else>
        <code class="error--text">Не описан тип поля</code> {{ prop.key }}[{{ prop.type }}]: {{ properties[prop.key] }}
      </div>
    </div>
  </div>
  <div
    v-else
    class="tw-text-an-flat-disabled-text tw-text-center"
  >
    Свойства отсутствуют
  </div>
</template>

<script>
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwSelectButton from '@/components/tw-ui/ui-kit/button/TwSelectButton.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import { numeric, required, decimal } from 'vuelidate/lib/validators'
import { GridWrapperForPanelShowAndForm } from '@/utils/tw-classes'
import RealtyComplexBuildingMapMarking from '@/views/Newbuildings/Editor/OtherComponents/Map/RealtyComplexBuildingMapMarking.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  name: 'RealtyObjectPropsForm',
  components: { RealtyComplexBuildingMapMarking, TwInputText, TwSelectButton, TwSelect, TwTextarea },
  props: {
    hasGrid: {
      type: Boolean,
      default: false
    },
    targetPropKey: {
      type: String,
      default: 'prop'
    },
    properties: {
      type: Object,
      required: true
    },
    scheme: {
      type: Object,
      required: true
    },
    otherObjects: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      boolList: [
        {
          label: 'Не указано',
          value: null
        },
        {
          label: 'Есть',
          value: true
        },
        {
          label: 'Нет',
          value: false
        }
      ]
    }
  },
  validations () {
    return {
      properties: {
        ...this.validateFields
      }
    }
  },
  computed: {
    wrapperClass () {
      return this.hasGrid ? GridWrapperForPanelShowAndForm.threeCols : 'tw-flex tw-flex-wrap tw-gap-y-[12px] tw-gap-x-[26px] tw-w-full tw-my-[12px]'
    },
    propScheme () {
      return Array.isArray(this.scheme[this.targetPropKey]) && this.scheme[this.targetPropKey].length ? this.scheme[this.targetPropKey] : []
    },
    propForm () {
      return this.propScheme.reduce((obj, cur) => {
        return {
          ...obj,
          [cur.key]: this.properties[cur.key] !== undefined ? this.properties[cur.key] : null
        }
      }, {})
    },
    validateFields () {
      return this.propScheme.filter((prop) => {
        return prop.required
      }).reduce((obj, cur) => {
        return {
          ...obj,
          [cur.key]: {
            required,
            ...(cur.type === 'Number' || cur.type === 'Int') && {
              numeric
            },
            ...(cur.type === 'Float') && {
              decimal
            }
          }
        }
      }, {})
    },
    formErrors () {
      const errors = {}
      // Сбор локальных ошибок
      for (const fieldName in this.$v.properties.$params) {
        errors[fieldName] = []
        if (this.$v.properties[fieldName].$invalid) {
          errors[fieldName].push('Обязательное поле')
          if (this.$refs[fieldName] && this.$refs[fieldName]?.[0]) {
            this.$Global.scrollToElem(this.$refs[fieldName][0]?.$el)
          }
        } else {
          delete errors[fieldName]
        }
      }
      this.$emit('invalid', !!Object.keys(errors).length)
      return errors
    }
  },
  methods: {
    change (field, value) {
      // console.warn('changeProperty', field, value, this.$v.properties)
      if (field.type === 'Number' || field.type === 'Int' || field.type === 'Float') {
        this.$emit('changeProperty', { key: field.key, value: Number(value) || null })
      } else if (field.type === 'Date') {
        this.$emit('changeProperty', { key: field.key, value: value || null })
      } else {
        this.$emit('changeProperty', { key: field.key, value })
      }
    },
    mapPolygonChange (field, polygonData) {
      // console.warn('mapPointChange', polygonData.length)
      this.change(field, polygonData.length ? polygonData : null)
    }
  }
}
</script>

<style scoped>

</style>
