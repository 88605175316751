<template>
  <v-dialog
    v-model="show"
    :max-width="maxWidth"
    persistent
    :fullscreen="false"
    :scrollable="true"
    @keydown.esc="show = false"
  >
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="disabled ? '':on"
      >
        <slot name="trigger">
          <v-btn
            color="primary"
            dark
          >
            Открыть модальное окно
          </v-btn>
        </slot>
      </div>
    </template>

    <v-card>
      <v-card-title class="pa-0 ma-0">
        <v-toolbar
          elevation="0"
          height="50px"
          dark
          color="primary"
        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="show = false"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="py-3"
        style="height: calc(100vh - 50px)"
      >
        <v-card-title
          v-if="!$slots.form"
          class="align-center justify-center"
        >
          Не передан компонент формы
        </v-card-title>
        <slot
          v-else
          name="form"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormModalWrapper',
  props: {
    title: {
      type: String,
      default: 'Заголовок модального окна'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      default: '1000px' // undefined
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    closeModal () {
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>
