<template>
  <v-form ref="form">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <v-container
      fluid
      class="px-0"
    >
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="form.realtyType"
            label="Вид программы"
            attach
            dense
            item-text="label"
            item-value="value"
            :items="dictionary.realtyType"
            :disabled="formProcessing"
            :rules="requireRules"
            outlined
          />

          <div>
            <div class="grey--text">
              Наличие материнского капитала:
            </div>
            <div class="d-flex">
              <v-radio-group
                v-model="form.matCapitalUse"
                class="mt-1"
                dense
                :disabled="formProcessing"
                :column="false"
                :row="true"
                :rules="requireRules"
              >
                <v-radio
                  v-for="item in dictionary.matCapitalUse"
                  :key="item.value"
                  class="mr-2"
                  :label="item.label"
                  :value="item.value"
                />
              </v-radio-group>
            </div>
          </div>
          <div class="mt-3">
            <div class="grey--text">
              Комплект документов:
            </div>
            <v-radio-group
              v-model="form.documents"
              class="mt-1"
              dense
              :column="false"
              :row="true"
              :rules="requireRules"
              :disabled="formProcessing"
            >
              <v-radio
                v-for="item in dictionary.documents"
                :key="item.value"
                class="mr-2"
                :label="item.label"
                :value="item.value"
              />
            </v-radio-group>
          </div>
          <div class="mt-3">
            <div class="grey--text">
              Брачный договор:
            </div>
            <v-radio-group
              v-model="form.marriageContract"
              class="mt-1"
              dense
              :column="false"
              :row="true"
              :rules="requireBooleanRules"
              :disabled="formProcessing"
            >
              <v-radio
                v-for="item in boolDict"
                :key="item.value"
                class="mr-2"
                :label="item.label"
                :value="item.value"
              />
            </v-radio-group>
          </div>
          <div class="mt-3">
            <div class="grey--text">
              Согласие от супруга(и):
            </div>
            <v-radio-group
              v-model="form.consentSpouse"
              class="mt-1"
              dense
              :column="false"
              :row="true"
              :rules="requireBooleanRules"
              :disabled="formProcessing"
            >
              <v-radio
                v-for="item in boolDict"
                :key="item.value"
                class="mr-2"
                :label="item.label"
                :value="item.value"
              />
            </v-radio-group>
          </div>
          <v-textarea
            v-model="form.suspensionComment"
            dense
            class="mt-5"
            :disabled="formProcessing"
            label="Отлагательные условия"
            placeholder="Комментарий"
            persistent-placeholder
            outlined
            rows="3"
          />
        </v-col>
      </v-row>
    </v-container>
    <CardSimpleFormSaveBlock
      :loading="formProcessing"
      bg-color="transparent"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </v-form>
</template>

<script>
import API from '@/Api/Crm/Deal/Mortgage/ApprovalInfo/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import CardSimpleFormSaveBlock from '@/components/core/ViewPanel/Card/CardSimpleFormSaveBlock'

export default {
  name: 'MortgageApproveForm',
  components: { CardSimpleFormSaveBlock },
  mixins: [FormCreateEditMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      form: {},
      boolDict: [
        {
          label: 'Есть',
          value: true
        },
        {
          label: 'Нет',
          value: false
        }
      ]
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'realtyType' || key === 'matCapitalUse' || key === 'documents') {
              this.$set(this.form, key, this.preset[key]?.value || this.preset[key])
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
          this.form.dealId = this.form?.dealId || this.dealId || null
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      console.warn(this.$options.name, ' handleSubmit ', this.$refs.form.validate())
      if (this.$refs.form.validate()) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.form)
            .then(({ data }) => {
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    }
  }
}
</script>
