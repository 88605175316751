<template>
  <div
    :class="[sizeClasses, disabled ? 'tw-pointer-events-none tw-opacity-70':'']"
    class="tw-relative tw-text-an-flat-black-main tw-select-none tw-w-auto tw-font-manrope tw-font-medium tw-leading-none tw-block"
    @click.passive="changeFocusToInput"
    @focus="changeFocusToInput"
  >
    <div class="tw-flex tw-gap-[6px] tw-w-full">
      <div
        v-if="label || persistentLabel"
        :class="[persistentLabel || isValue ? 'tw-text-an-flat-dark-blue-link ':'']"
        class="tw-flex-1 tw-truncate tw-px-[5px] tw-leading-[21px]"
      >
        {{ label }}
        <span
          v-if="isRequired"
          class="tw-text-an-flat-red-main tw-text-main-sm"
        ><slot name="requiredSymbol">*</slot></span>
      </div>
      <div
        v-if="isRequired || label || persistentLabel || errorText"
        class="tw-h-[21px] tw-text-right tw-text-[12px] tw-leading-[21px] tw-relative tw-text-an-flat-red-main"
        :class="labelError"
      >
        <span
          v-for="(err, index) in errorValues"
          :key="index"
        >
          {{ err }}<span v-if="errorValues.length - 1 > index">,</span>
        </span>
      </div>
    </div>
    <div
      ref="trigger"
      role="button"
      tabindex="0"
      :class="[sizeClasses, errorText ? classesError : '', disabled ? 'tw-pointer-events-none tw-bg-an-flat-disabled-input-bg tw-text-an-flat-disabled-input-text':'tw-bg-white', visibleList ? 'tw-border-an-flat-blue-btn-active' : '']"
      class="tw-group tw-relative tw-z-0 tw-flex tw-gap-[10px] tw-items-center tw-justify-between tw-px-[20px] tw-py-[7px] tw-border tw-border-solid tw-rounded-sm hover:tw-drop-shadow-input"
      @click.stop="show"
    >
      <input
        v-model="querySearch"
        class="tw-w-full tw-truncate tw-border-none tw-outline-none"
        type="text"
        autocomplete="off"
        :name="inputName"
        :disabled="disabled"
        :placeholder="placeholder"
        @keyup.enter="onKeyPress($event, KeyEvent.Enter)"
        @keyup.esc="onKeyPress($event, KeyEvent.Esc)"
        @keyup.up="onKeyPress($event, KeyEvent.Up)"
        @keyup.down="onKeyPress($event, KeyEvent.Down)"
        @focus="onInputFocus"
        @blur="onInputBlur"
      >
      <div @click.stop="visibleList = !visibleList">
        <svg
          class="tw-transition-all tw-duration-200"
          :class="visibleList ? 'tw-rotate-90':''"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :class="visibleList ? 'tw-fill-an-flat-red-main': disabled ? 'tw-fill-an-flat-disabled-input-text' : 'tw-fill-an-flat-black-main'"
            d="M10.9836 9.54635L8.39713 6.95971C8.83659 6.26008 9.09164 5.43316 9.09164 4.54573C9.09164 2.03504 7.05631 0 4.54573 0C2.03514 0 0 2.03504 0 4.54573C0 7.0565 2.03504 9.09136 4.54573 9.09136C5.51149 9.09136 6.40603 8.78942 7.14212 8.27637L9.69779 10.8322C9.87537 11.0096 10.1082 11.098 10.3407 11.098C10.5735 11.098 10.806 11.0096 10.9839 10.8322C11.3387 10.477 11.3387 9.90151 10.9836 9.54635ZM4.54573 7.61861C2.8488 7.61861 1.47303 6.24294 1.47303 4.54592C1.47303 2.84889 2.8488 1.47313 4.54573 1.47313C6.24275 1.47313 7.61842 2.84889 7.61842 4.54592C7.61842 6.24294 6.24275 7.61861 4.54573 7.61861Z"
            fill="#545961"
          />
        </svg>
      </div>
      <div
        ref="prepend"
        class="tw-absolute tw-top-1/2 tw-left-0 tw-transform -tw-translate-x-0 -tw-translate-y-1/2 tw-ml-[15px]"
      >
        <slot name="prepend" />
      </div>
      <div
        ref="append"
        class="tw-absolute tw-top-1/2 tw-right-0 tw-transform -tw-translate-x-0 -tw-translate-y-1/2 tw-mr-[15px]"
      >
        <slot name="append" />
      </div>
      <div
        v-if="cleanable && isValue"
        class="tw-absolute tw-top-1/2 tw-right-[15px] tw-transform -tw-translate-x-0 -tw-translate-y-1/2 tw-mr-[15px] tw-hidden group-hover:tw-block"
      >
        <button
          class="tw-p-[5px]"
          @click.stop="onClearValue"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 5C10 7.76142 7.76143 10 5 10C2.23857 10 0 7.76142 0 5C0 2.23858 2.23857 0 5 0C7.76143 0 10 2.23858 10 5ZM3.71445 3.71445C3.89197 3.53694 4.17976 3.53694 4.35727 3.71445L5 4.35719L5.64273 3.71445C5.82024 3.53694 6.10805 3.53694 6.28555 3.71445C6.46307 3.89196 6.46307 4.17976 6.28555 4.35728L5.64282 5.00001L6.28575 5.64293C6.46326 5.82044 6.46326 6.10824 6.28575 6.28575C6.10823 6.46326 5.82043 6.46326 5.64293 6.28575L5 5.64283L4.35709 6.28574C4.17957 6.46326 3.89177 6.46326 3.71426 6.28574C3.53674 6.10824 3.53674 5.82043 3.71426 5.64292L4.35718 5.00001L3.71445 4.35728C3.53694 4.17977 3.53694 3.89197 3.71445 3.71445Z"
              fill="#E63114"
            />
          </svg>
        </button>
      </div>
    </div>
    <div
      v-if="visibleList || inputFocused"
      ref="tooltip"
      v-click-outside="onInputBlur"
      class="tw-absolute tw-z-[1000] tw-w-full tw-bg-white tw-border tw-border-solid tw-rounded-sm tw-py-[9px] tw-shadow"
    >
      <ul
        role="listbox"
        :style="{ maxHeight: selectPlaceMaxHeight, padding: 0 }"
        class="tw-overflow-auto"
      >
        <li
          v-if="!querySearch"
          class="tw-min-h-[42px] tw-pl-[18px] tw-pr-[6px] tw-flex tw-justify-center tw-items-center tw-text-an-flat-disabled-text"
        >
          Начните вводить адрес
        </li>
        <li
          v-else-if="loading"
          class="tw-min-h-[42px] tw-pl-[18px] tw-pr-[6px] tw-flex tw-justify-center tw-items-center tw-text-an-flat-disabled-text"
        >
          Идёт поиск...
        </li>
        <template v-else-if="Array.isArray(results) && results.length">
          <li
            v-for="(item, index) in results"
            :key="`result_item_${index}`"
            :class="getIsCheckedItem(item) ? 'tw-text-an-flat-red-main tw-bg-an-flat-red-lighten hover:tw-bg-an-flat-red-lighten':''"
            class="tw-leading-normal tw-cursor-pointer tw-min-h-[42px] tw-pl-[18px] tw-pr-[6px] tw-flex tw-gap-[15px] tw-items-center hover:tw-bg-an-flat-bg-main"
            @click.stop="onItemClick(item)"
          >
            {{ item.fullAddress }}
          </li>
        </template>
        <li
          v-else
          class="tw-min-h-[42px] tw-pl-[18px] tw-pr-[6px] tw-flex tw-justify-center tw-items-center tw-text-an-flat-disabled-text"
        >
          {{ noDataText }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import FiasSearch from '@/Api/ObjectStorage/Fias/Search'
import lodashDebounce from 'lodash/debounce'
import { createPopper } from '@popperjs/core'

const KeyEvent = {
  Enter: 'enter',
  Esc: 'esc',
  Up: 'up',
  Down: 'down'
}

export default {
  name: 'RealtyObjectSearchByAddressInput',
  props: {
    value: {
      type: Object,
      default: null
    },
    fiasLevel: {
      type: String,
      default: 'street' // street | house
    },
    options: {
      type: Array,
      default: () => []
    },
    // сжатые поля
    dense: {
      type: Boolean,
      default: false
    },
    // КНопка очистки поля
    cleanable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Поиск адреса'
    },
    noDataText: {
      type: String,
      default: 'Нет данных'
    },
    persistentLabel: {
      type: Boolean,
      default: true
    },
    selectPlaceMaxHeight: {
      type: String,
      default: '200px'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableErrorBorder: {
      type: Boolean,
      default: false
    },
    labelErrorClass: {
      type: String,
      default: ''
    },
    errorText: {
      type: [String, Boolean, Array],
      default: null
    }
  },
  data () {
    return {
      popperInstance: null,
      inputFocused: false,
      visibleList: false,
      KeyEvent,
      loading: false,
      querySearch: '',
      errorMsg: '',
      results: []
    }
  },
  methods: {
    show () {
      this.visibleList = true
      this.$nextTick(() => {
        this.popperInstance = createPopper(this.$refs.trigger, this.$refs.tooltip, {
          // strategy: 'fixed',
          placement: 'bottom',
          rootBoundary: 'document',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 5]
              }
            }
          ]
        })
      })
      // this.popperInstance.update()
    },
    hide () {
      this.querySearch = this.value?.fullAddress || ''
      this.results = []
      this.visibleList = false
      this.popperInstance?.destroy()
      this.popperInstance = null
    },
    onClearValue () {
      this.hide()
      this.$emit('input', null)
    },
    getIsCheckedItem (item) {
      return item?.fiasGuid === this.value?.fiasGuid
    },
    onItemClick (targetItem) {
      this.$emit('input', targetItem)
      this.hide()
    },
    onKeyPress (keyboardEvent, keyEvent) {
      if (this.disabled) {
        return
      }
      keyboardEvent.preventDefault()
      console.warn({ keyboardEvent, keyEvent })
      if (keyEvent === KeyEvent.Esc) {
        this.hide()
      }
    },
    fetchWithDebounce: lodashDebounce(async function () {
      this.results = await this.searchByAddress()
    }, 1000),
    onInputFocus (event) {
      if (this.disabled) {
        return
      }
      this.inputFocused = true
      this.$emit('focus', event)
    },
    onInputBlur () {
      if (this.disabled) {
        return
      }
      this.inputFocused = false
    },
    searchByAddress () {
      this.errorMsg = ''
      this.loading = true
      return FiasSearch.searchByAddress({ address: this.querySearch, fiasLevel: this.fiasLevel })
        .then(({ data }) => {
          return data?.data || []
        })
        .catch(error => {
          console.log('error', error)
          this.errorMsg = error.message || 'Ошибка на сервере'
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeFocusToInput () {
      this.$el.getElementsByTagName('input')[0]?.focus()
    }
  },
  computed: {
    inputName () {
      return `RealtyObjectSearchByAddressInput-${this._uid}`
    },
    isRequired () {
      // определение обязательности поля
      // - если тестовая ошибка имеет дефолтное значение
      // и если текст лейбла показан над полем ввода
      return this.errorText !== null && (this.persistentLabel || this.isValue)
    },
    errorValues () {
      if (Array.isArray(this.errorText)) {
        return this.errorText
      } else if (this.errorText) {
        return [this.errorText]
      }
      return []
    },
    sizeClasses () {
      if (this.dense) {
        return 'tw-min-h-[40px] tw-text-main-xs tw-min-w-[160px]'
      } else {
        return 'tw-min-h-[52px] tw-text-main-sm2 tw-min-w-[180px]'
      }
    },
    classesError () {
      if (this.disableErrorBorder) {
        return ''
      }
      return 'tw-text-an-flat-red-main tw-border-an-flat-red-main'
    },
    labelError () {
      if (this.classesError) {
        if (this.labelErrorClass) {
          return `${this.classesError} ${this.labelErrorClass}`
        } else {
          return `${this.classesError}`
        }
      }
      return ''
    },
    isValue () {
      return this.value?.fullAddress !== null || this.querySearch
    }
  },
  watch: {
    value: {
      handler: async function (newVal) {
        this.querySearch = newVal?.fullAddress || ''
      },
      immediate: true,
      deep: true
    },
    querySearch: {
      handler: async function () {
        await this.fetchWithDebounce()
      },
      immediate: true
    }
  }
}
</script>
