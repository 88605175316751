import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/DocCheck'

export const path = '/kontur/check'

export default {
  listInit (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/list-init`, $config)
  },
  getList (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig,
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}`, $config)
  }
}
