<template>
  <div style="position:relative;">
    <APopoverWrapper :z-index="zIndex">
      <template #trigger>
        <div
          class="employee-select-input-block d-flex align-center"
          tabindex="0"
        >
          <div
            class="employee-select-container"
            style="width: 100%"
          >
            <div
              v-if="selectedValue"
              class="employee-select-badge px-2"
            >
              <div
                class="employee-select-badge-text"
                :title="selectedValue.name"
              >
                {{ selectedValue.name }}
              </div>
            </div>
            <div
              v-else
              class="text-muted"
            >
              {{ noSelectText }}
            </div>
            <div
              v-if="selectedValue"
              class="cursor-pointer"
              style="position:absolute; right: 5px;"
              @click.stop="clear"
            >
              <v-icon
                class="ma-auto"
                small
              >
                fa-times-circle
              </v-icon>
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <div style="width: 500px; height: 300px; background-color: white; display: flex; flex-direction: column">
          <!--          <div class="employee-select-popover my-2">-->
          <!--            <input type="text" @input="onSearch" placeholder="Поиск"/>-->
          <!--          </div>-->
          <div
            v-if="searchProccess"
            class="text-center pa-2"
          >
            <v-progress-circular
              size="30"
              color="primary"
              indeterminate
            />
          </div>
          <div
            v-else-if="Array.isArray(relativeItems) && relativeItems.length"
            style="overflow: auto; display: flex; flex-wrap: wrap"
          >
            <div
              v-for="(item, index) in relativeItems"
              :key="index"
              class="employee-select-list-item"
              @click.stop="onSelect(item)"
            >
              <div>
                <div
                  class="mr-2 d-flex justify-center align-center"
                  style="width: 30px; height: 30px;border-radius: 50%; background-color: darkgray"
                >
                  <v-icon
                    x-small
                    dark
                  >
                    fa-user
                  </v-icon>
                </div>
              </div>
              <div class="d-flex align-center">
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-center text-muted font-weight-regular"
          >
            Не найдено
          </div>
        </div>
      </template>
    </APopoverWrapper>
  </div>
</template>

<script>
import APopoverWrapper from '@/components/filters/afilter/fields/apopover/APopoverWrapper'
import lodashDebounce from 'lodash/debounce'
import API from '@/Api/Auth/Structure/Position'

export default {
  name: 'APositionSimpleSelect',
  components: { APopoverWrapper },
  props: {
    defaultFilters: {
      type: Object,
      default: () => {
        return {}
      }
    },
    value: {
      type: [String, Number, null],
      default: null
    },
    noSelectText: {
      type: String,
      default: 'Не выбрано'
    },
    zIndex: {
      type: [String, Number, null],
      default: 99
    }
  },
  data () {
    return {
      searchStr: '',
      initValue: false,
      searchProccess: false,
      selectedValue: null,
      relativeItems: []
    }
  },
  methods: {
    onSearch: lodashDebounce(function (event) {
      this.searchStr = event?.target?.value || ''
      this.searchProccess = true
      API.getList({ search: this.searchStr, ...this.defaultFilters })
        .then(({ data }) => {
          this.relativeItems = Array.isArray(data?.data) && data.data.length ? data.data : []
        }).catch((err) => {
          console.log('error', err)
        }).finally(() => {
          this.searchProccess = false
        })
    }, 300),
    onSelect (item) {
      console.error('onSelect', item)
      this.selectedValue = item
      this.$emit('input', item.id)
      this.$emit('inputFullData', item)
    },
    valueInit (id) {
      if (id) {
        this.initValue = true
        API.show(id).then((result) => {
          console.log('onSelect full', result.data.data)
          this.selectedValue = result.data.data
        }).finally(() => {
          this.initValue = false
        })
      } else {
        this.selectedValue = null
      }
    },
    clear () {
      console.warn(this.$options.name, 'clear')
      this.$emit('input', null)
      this.$emit('inputFullData', null)
    }
  },
  watch: {
    value (newValue) {
      if (newValue !== this.selectedValue?.id) {
        this.valueInit(newValue)
      }
    }
  },
  mounted () {
    this.onSearch()
    if (this.value) {
      this.valueInit(this.value)
    }
  }
}
</script>

<style lang="less">
.employee-select-input-block {
  font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
  width: 100%;
  border: 1px solid rgba(83, 92, 105, .2);
  border-radius: 2px;
  padding: 0 5px;
  background: #fff;
  min-height: 38px;
  outline: none;
  &:focus{
    border: 1px solid #bcedfc;
  }
  .employee-select-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 3px;
    height: 100%;
    .employee-select-badge {
      margin: 3px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 22px 5px 7px;
      background: #bcedfc;
      //margin-left: 5px;
      border-radius: 2px;
      &-text {
        padding: 0;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
      }
    }
  }
}

.employee-select-list-item {
  font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
  width: 48%;
  display: flex;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 3px;
  //margin: 5px;
  &:hover {
    background-color: #eaeaea;
  }
}

.employee-select-popover {
  font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
  width: 100%;
  border: 1px solid rgba(83, 92, 105, .2);
  border-radius: 2px;
  padding: 0 5px;
  background: #fff;

  input {
    width: 100%;
    height: 36px;
    outline: none;
  }
}
</style>

<!--
   -->
