<template>
  <transition name="slide-save-block">
    <div
      class="sticky-save-block"
      :style="{backgroundColor: bgColor, position: isSticky ? 'sticky' : 'relative'}"
    >
      <div v-if="visible">
        <v-btn
          color="success"
          class="mx-2"
          elevation="0"
          tile
          :disabled="loading || disableSuccess"
          @click="onSuccess"
        >
          {{ successTitle }}
        </v-btn>
        <v-btn
          text
          class="mx-2"
          color="secondary"
          tile
          link
          :disabled="loading || disableCancel"
          @click="preCancel"
        >
          {{ cancelTitle }}
        </v-btn>
        <v-dialog
          v-model="confirmDialog"
          persistent
          max-width="300"
        >
          <v-card>
            <v-card-title class="title primary--text">
              Подтверждение
            </v-card-title>
            <v-divider />
            <v-card-text class="pt-2">
              Уверены, что хотите отменить изменения?
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                @click="onCancel"
              >
                Да
              </v-btn>
              <v-btn
                text
                @click="confirmDialog = false"
              >
                Нет
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!--      <div v-if="visible" style="padding-bottom: 60px"></div>-->
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CardSimpleFormSaveBlock',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    disableCancel: {
      type: Boolean,
      default: false
    },
    disableSuccess: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    cancelConfirm: {
      type: Boolean,
      default: false
    },
    successTitle: {
      type: String,
      default: 'Сохранить'
    },
    isSticky: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: '#f9fafb'
    },
    cancelTitle: {
      type: String,
      default: 'Отменить'
    }
  },
  data () {
    return {
      confirmDialog: false
    }
  },
  methods: {
    onSuccess () {
      this.$emit('success')
      this.confirmDialog = false
    },
    preCancel () {
      if (this.cancelConfirm) {
        this.confirmDialog = true
      } else {
        this.onCancel()
      }
    },
    onCancel () {
      this.$emit('cancel')
      this.confirmDialog = false
    }
  }
}
</script>

<style scoped>
/* Блок сохранения в карточках объектов */
.sticky-save-block {
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  /*background-color: #f9fafb;*/
  padding: .5em 0;
  /*border: 2px solid #dadada;*/
  /*box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, .2);*/
  /*z-index: 11;*/
  /*padding: 0.3em;*/
  /*box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.3), 0px 10px 14px 1px rgba(0, 0, 0, 0.3), 0px 4px 18px 3px rgba(0, 0, 0, 0.3);*/
}

/* Анимация для блока сохранения */
.slide-save-block-enter-active, .slide-save-block-leave-active {
  transition: transform 0.5s;
}

.slide-save-block-leave-to {
  transform: translateY(100%);
}

.slide-save-block-enter {
  transform: translateY(100%);
}
</style>
