interface IService {
  [key: string]: {
    key: string;
    apiUrl: string;
    host: string;
    useReports?: boolean;
    useWidgets: boolean;
  }
}

// определим сервисы из env
export const SERVICES: IService = Object.freeze({
  BACKEND: {
    key: 'BACKEND',
    apiUrl: process.env.VUE_APP_BACKEND_HOST ? `${process.env.VUE_APP_BACKEND_HOST}/api/v1` : 'https://crm-backend.anflat.ru/api/v1',
    host: process.env.VUE_APP_BACKEND_HOST ? process.env.VUE_APP_BACKEND_HOST : 'https://crm-backend.anflat.ru',
    useReports: true,
    useWidgets: true
  },
  AUTH: {
    key: 'AUTH',
    apiUrl: process.env.VUE_APP_AUTH_HOST ? `${process.env.VUE_APP_AUTH_HOST}/api/v1` : 'https://auth.anflat.ru/api/v1',
    host: process.env.VUE_APP_AUTH_HOST ? process.env.VUE_APP_AUTH_HOST : 'https://auth.anflat.ru',
    useReports: true,
    useWidgets: true
  },
  ROI: {
    key: 'ROI',
    apiUrl: process.env.VUE_APP_ROI_HOST ? `${process.env.VUE_APP_ROI_HOST}/api/v1` : 'https://roi.anflat.ru/api/v1',
    host: process.env.VUE_APP_ROI_HOST ? process.env.VUE_APP_ROI_HOST : 'https://roi.anflat.ru',
    useReports: true,
    useWidgets: false
  },
  TELEPHONY: {
    key: 'TELEPHONY',
    apiUrl: process.env.VUE_APP_TELEPHONY_HOST ? `${process.env.VUE_APP_TELEPHONY_HOST}/api/v1` : 'https://telephony.anflat.ru/api/v1',
    host: process.env.VUE_APP_TELEPHONY_HOST ? process.env.VUE_APP_TELEPHONY_HOST : 'https://telephony.anflat.ru',
    useReports: true,
    useWidgets: true
  },
  VISION: {
    key: 'VISION',
    apiUrl: process.env.VUE_APP_COMPUTER_VISION_HOST ? `${process.env.VUE_APP_COMPUTER_VISION_HOST}/api/v1` : 'https://cv.anflat.ru/api/v1',
    host: process.env.VUE_APP_COMPUTER_VISION_HOST ? process.env.VUE_APP_COMPUTER_VISION_HOST : 'https://cv.anflat.ru',
    useWidgets: false
  },
  LOGGER: {
    key: 'LOGGER',
    apiUrl: process.env.VUE_APP_LOGGER_HOST ? `${process.env.VUE_APP_LOGGER_HOST}/api/v1` : 'https://logger-backend.anflat.ru/api/v1',
    host: process.env.VUE_APP_LOGGER_HOST ? process.env.VUE_APP_LOGGER_HOST : 'https://logger-backend.anflat.ru',
    useWidgets: false
  },
  SITE: {
    key: 'SITE',
    apiUrl: process.env.VUE_APP_SITE_HOST ? `${process.env.VUE_APP_SITE_HOST}/api/v2` : 'https://anflat.ru/api/v2',
    host: process.env.VUE_APP_SITE_HOST ? process.env.VUE_APP_SITE_HOST : 'https://anflat.ru',
    useWidgets: false
  },
  SUPPORT: {
    key: 'SUPPORT',
    apiUrl: process.env.VUE_APP_SUPPORT_HOST ? `${process.env.VUE_APP_SUPPORT_HOST}/api/v1` : 'https://support.anflat.ru/api/v1',
    host: process.env.VUE_APP_SUPPORT_HOST ? process.env.VUE_APP_SUPPORT_HOST : 'https://support.anflat.ru',
    useWidgets: true
  },
  REALTY_MINER: {
    key: 'REALTY_MINER',
    apiUrl: process.env.VUE_APP_REALTY_MINER_HOST ? `${process.env.VUE_APP_REALTY_MINER_HOST}/api/v1` : 'https://realty-miner.anflat.ru/api/v1',
    host: process.env.VUE_APP_REALTY_MINER_HOST ? process.env.VUE_APP_REALTY_MINER_HOST : 'https://realty-miner.anflat.ru',
    useReports: true,
    useWidgets: false
  },
  OS: {
    key: 'OS',
    apiUrl: process.env.VUE_APP_OS_HOST ? `${process.env.VUE_APP_OS_HOST}/api/v1` : 'https://os.anflat.ru/api/v1',
    host: process.env.VUE_APP_OS_HOST ? process.env.VUE_APP_OS_HOST : 'https://os.anflat.ru',
    useReports: true,
    useWidgets: false
  },
  MESSENGER: {
    key: 'MESSENGER',
    apiUrl: process.env.VUE_APP_MESSENGER_HOST ? `${process.env.VUE_APP_MESSENGER_HOST}/api/v1` : 'https://messenger.anflat.ru/api/v1',
    host: process.env.VUE_APP_MESSENGER_HOST ? process.env.VUE_APP_MESSENGER_HOST : 'https://messenger.anflat.ru',
    useReports: true,
    useWidgets: true
  },
  PASSPORT: {
    key: 'PASSPORT',
    apiUrl: process.env.VUE_APP_PASSPORT_HOST ? `${process.env.VUE_APP_PASSPORT_HOST}/api/v1` : 'https://passport.anflat.ru/api/v1',
    host: process.env.VUE_APP_PASSPORT_HOST ? process.env.VUE_APP_PASSPORT_HOST : 'https://passport.anflat.ru',
    useReports: true,
    useWidgets: false
  },
  MODEM_HUB: {
    key: 'MODEM_HUB',
    apiUrl: process.env.VUE_APP_MODEM_HUB_HOST ? `${process.env.VUE_APP_MODEM_HUB_HOST}/api/v1` : 'https://modem-hub-beta.anflat.ru/api/v1',
    host: process.env.VUE_APP_MODEM_HUB_HOST ? process.env.VUE_APP_MODEM_HUB_HOST : 'https://modem-hub-beta.anflat.ru',
    useWidgets: false
  },
  SEARCH: {
    key: 'SEARCH',
    apiUrl: process.env.VUE_APP_SEARCH_HOST ? `${process.env.VUE_APP_SEARCH_HOST}/api/v1` : 'https://search.anflat.ru/api/v1',
    host: process.env.VUE_APP_SEARCH_HOST ? process.env.VUE_APP_SEARCH_HOST : 'https://search.anflat.ru',
    useWidgets: false
  },
  DOC: {
    key: 'DOC',
    apiUrl: process.env.VUE_APP_DOC_HOST ? `${process.env.VUE_APP_DOC_HOST}/api/v1` : 'https://doc.anflat.ru/api/v1',
    host: process.env.VUE_APP_DOC_HOST ? process.env.VUE_APP_DOC_HOST : 'https://doc.anflat.ru',
    useWidgets: false
  },
  ADS: {
    key: 'ADS',
    apiUrl: process.env.VUE_APP_ADS_HOST ? `${process.env.VUE_APP_ADS_HOST}/api/v1` : 'https://ads.anflat.ru/api/v1',
    host: process.env.VUE_APP_ADS_HOST ? process.env.VUE_APP_ADS_HOST : 'https://ads.anflat.ru',
    useReports: true,
    useWidgets: false
  },
  BX_PROXY_RABBIT: {
    key: 'BX_PROXY_RABBIT',
    apiUrl: process.env.VUE_APP_BX_PROXY_RABBIT_HOST ? `${process.env.VUE_APP_BX_PROXY_RABBIT_HOST}/api/v1` : 'https://bx-proxy-rabbit.anflat.ru/api/v1',
    host: process.env.VUE_APP_BX_PROXY_RABBIT_HOST ? process.env.VUE_APP_BX_PROXY_RABBIT_HOST : 'https://bx-proxy-rabbit.anflat.ru',
    useReports: true,
    useWidgets: false
  },
  BUILDER_IMPORTER: {
    key: 'BUILDER_IMPORTER',
    apiUrl: process.env.VUE_APP_BUILDER_IMPORTER_HOST ? `${process.env.VUE_APP_BUILDER_IMPORTER_HOST}/api/v1` : 'https://builder-importer.anflat.ru/api/v1',
    host: process.env.VUE_APP_BUILDER_IMPORTER_HOST ? process.env.VUE_APP_BUILDER_IMPORTER_HOST : 'https://builder-importer.anflat.ru',
    useReports: true,
    useWidgets: true
  },
  REVIEW: {
    key: 'REVIEW',
    apiUrl: process.env.VUE_APP_REVIEW_HOST ? `${process.env.VUE_APP_REVIEW_HOST}/api/v1` : 'https://review.anflat.ru/api/v1',
    host: process.env.VUE_APP_REVIEW_HOST ? process.env.VUE_APP_REVIEW_HOST : 'https://review.anflat.ru',
    useReports: true,
    useWidgets: true
  },
  EMAIL: {
    key: 'EMAIL',
    apiUrl: process.env.VUE_APP_EMAIL_HOST ? `${process.env.VUE_APP_EMAIL_HOST}/api/v1` : 'https://email.anflat.ru/api/v1',
    host: process.env.VUE_APP_EMAIL_HOST ? process.env.VUE_APP_EMAIL_HOST : 'https://email.anflat.ru',
    useReports: true,
    useWidgets: true
  },
  FIAS: {
    key: 'FIAS',
    apiUrl: process.env.VUE_APP_FIAS_HOST ? `${process.env.VUE_APP_FIAS_HOST}/api/v1` : 'https://fias.anflat.ru/api/v1',
    host: process.env.VUE_APP_FIAS_HOST ? process.env.VUE_APP_FIAS_HOST : 'https://fias.anflat.ru',
    useReports: true,
    useWidgets: false
  },
  ACCOUNTING: {
    key: 'ACCOUNTING',
    apiUrl: process.env.VUE_APP_ACCOUNTING_HOST ? `${process.env.VUE_APP_ACCOUNTING_HOST}/api/v1` : 'https://accounting.anflat.ru/api/v1',
    host: process.env.VUE_APP_ACCOUNTING_HOST ? process.env.VUE_APP_ACCOUNTING_HOST : 'https://accounting.anflat.ru',
    useReports: true,
    useWidgets: true
  },
  MORTGAGE: {
    key: 'MORTGAGE',
    apiUrl: process.env.VUE_APP_MORTGAGE_HOST ? `${process.env.VUE_APP_MORTGAGE_HOST}/api/v1` : 'https://mortgage.anflat.ru/api/v1',
    host: process.env.VUE_APP_MORTGAGE_HOST ? process.env.VUE_APP_MORTGAGE_HOST : 'https://mortgage.anflat.ru',
    useReports: true,
    useWidgets: false
  },
  KANT_LANDING: {
    key: 'KANT_LANDING',
    apiUrl: 'https://landing.kant.one/api/v1',
    host: 'https://landing.kant.one',
    useReports: true,
    useWidgets: false
  },
  KANT_INTEGRATION: {
    key: 'KANT_INTEGRATION',
    apiUrl: 'https://integration.kant.one/api/v1',
    host: 'https://integration.kant.one',
    useReports: true,
    useWidgets: false
  },
  KANT_RTD: {
    key: 'KANT_RTD',
    apiUrl: process.env.VUE_APP_KANT_RTD_HOST ? `${process.env.VUE_APP_KANT_RTD_HOST}/api/v1` : 'https://rtd-test.anflat.ru/api/v1',
    host: process.env.VUE_APP_KANT_RTD_HOST ? process.env.VUE_APP_KANT_RTD_HOST : 'https://rtd-test.anflat.ru',
    useReports: true,
    useWidgets: true
  },
  DOC_CHECK: {
    key: 'DOC_CHECK',
    apiUrl: process.env.VUE_APP_DOC_CHECK_HOST ? `${process.env.VUE_APP_DOC_CHECK_HOST}/api/v1` : 'https://doc-check-test.anflat.ru/api/v1',
    host: process.env.VUE_APP_DOC_CHECK_HOST ? process.env.VUE_APP_DOC_CHECK_HOST : 'https://doc-check-test.anflat.ru',
    useReports: true,
    useWidgets: true
  }
})
