export default {
  computed: {
    isVisibleFromType () {
      return {
        fullName: true,
        phone: true,
        objectType: true,
        goal: this.form.objectType === 'house' || this.form?.objectType === 'apartment',
        budgetMin: this.form?.objectType !== 'parking_space',
        budgetMax: true,
        decoration: this.form.objectType === 'house' || this.form?.objectType === 'apartment',
        contractType: this.form?.objectType !== 'parking_space',
        commonAreaMin: this.form?.objectType !== 'parking_space',
        kitchenAreaMin: this.form?.objectType === 'apartment',
        wcType: this.form?.objectType === 'apartment',
        sidesWindow: this.form?.objectType === 'apartment',
        roomsCount: this.form?.objectType === 'apartment',
        balconyType: this.form?.objectType === 'apartment',
        readyQuarter: this.form?.objectType === 'apartment' || this.form?.objectType === 'commercial',
        paymentMethod: true,
        subsidy: this.form.objectType === 'house' || this.form?.objectType === 'apartment',
        initialPayment: this.form.paymentMethod === 'mortgage' && (this.form.objectType === 'house' || this.form?.objectType === 'apartment'),
        complexIds: this.form?.objectType === 'parking_space',
        landAreaMin: this.form?.objectType === 'house'
      }
    }
  }
}
