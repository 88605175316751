<template>
  <div
    v-cloak
    v-if="false"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView no-data-text="Нет примечания">
      <template #title>
        Примечание
      </template>
      <div v-if="info.note">
        {{ info.note }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView no-data-text="Нет Названия">
      <template #title>
        Название
      </template>
      <div v-if="info.name">
        {{ info.name }}
      </div>
    </TwPanelContentBlockFieldView>

    <!-- СОЗДАТЕЛЬ -->
    <TwPanelContentBlockFieldView v-if="info.creatorId">
      <template #title>
        Создал
      </template>
      <div>
        <div>
          <ViewPanelLink
            :id="info.creatorId"
            label="Пользователь"
            component="employee-show"
          >
            <EmployeeName :id="info.creatorId" />
          </ViewPanelLink>
        </div>
      </div>
    </TwPanelContentBlockFieldView>
  </div>
  <div v-else>
    <pre>
      {{ info }}
    </pre>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'DocCheckKonturPersonShow',
  components: { TwPanelContentBlockFieldView, EmployeeName, ViewPanelLink },
  props: {
    info: {},
    dictionary: {}
  },
  methods: {
    refreshParent (id) {
      console.warn(this.$options.name, ' refreshParent ', id)
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
