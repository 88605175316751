<template>
  <div
    v-click-outside="clickOutSide"
    :class="[sizeClasses, disabled ? 'tw-pointer-events-none':'']"
    class="tw-relative tw-text-an-flat-black-main tw-select-none tw-font-manrope tw-font-medium tw-leading-none tw-block"
  >
    <div
      v-if="isRequired || label || persistentLabel || errorValues.length"
      class="tw-h-[21px] tw-w-full tw-text-right tw-text-[12px] tw-leading-[21px] tw-relative tw-text-an-flat-red-main"
    >
      <div
        class="tw-text-an-flat-red-main"
        :class="classesError"
      >
        <span
          v-for="(err, index) in errorValues"
          :key="index"
        >
          {{ err }}<span v-if="errorValues.length - 1 > index">,</span>
        </span>
      </div>
    </div>
    <div
      ref="trigger"
      role="button"
      tabindex="0"
      :class="[sizeClasses, errorText ? classesError : '', visibleList ? 'tw-drop-shadow-input' : '', disabled ? 'tw-pointer-events-none tw-bg-an-flat-disabled-input-bg tw-text-an-flat-disabled-input-text':'tw-bg-white']"
      class="tw-relative tw-z-0 tw-flex tw-gap-[10px] tw-items-center tw-justify-between tw-px-[20px] tw-py-[7px] tw-border tw-border-solid tw-rounded-sm hover:tw-drop-shadow-input"
      @click.stop="visibleList ? hide() : show()"
    >
      <div class="tw-truncate">
        <label
          :class="isValue || persistentLabel ? '-tw-translate-x-[15px] tw-text-an-flat-dark-blue-link ' + (dense ? '-tw-translate-y-[41px]':'-tw-translate-y-[46px]'):''"
          class="tw-truncate tw-absolute tw-z-[1] tw-top-1/2 -tw-translate-y-1/2 tw-transition-all tw-duration-200 tw-ease-out motion-reduce:tw-transition-none"
        >
          {{ label }}
          <span
            v-if="isRequired"
            class="tw-text-an-flat-red-main tw-text-main-sm"
          >
            <slot name="requiredSymbol">*</slot>
          </span>
        </label>
        <div
          v-if="isValue"
          class="tw-flex tw-gap-[7px]"
        >
          <template v-for="(sItem, sIndex) in selectedItems">
            <div
              v-if="sIndex < 2"
              :key="sItem.value"
              :title="sItem.label"
              class="tw-relative tw-cursor-default tw-leading-none tw-flex tw-gap-1 tw-truncate tw-text-an-flat-dark-blue-link tw-bg-an-flat-dark-blue-link tw-bg-opacity-10 tw-rounded-xs tw-px-[7px] tw-py-[5px]"
            >
              <div class="tw-truncate tw-pr-[12px]">
                {{ sItem.label }}
              </div>
              <button
                v-if="!disabled"
                title="Удалить"
                class="tw-absolute tw-right-[3px] tw-rounded-full"
                @click.stop="removeItem(sItem)"
              >
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 0.375C3.62262 0.375 0.875 3.12262 0.875 6.5C0.875 9.87738 3.62262 12.625 7 12.625C10.3774 12.625 13.125 9.87738 13.125 6.5C13.125 3.12262 10.3774 0.375 7 0.375ZM9.49683 8.37817C9.66772 8.54907 9.66772 8.82593 9.49683 8.99683C9.41138 9.08228 9.29944 9.125 9.1875 9.125C9.07556 9.125 8.96362 9.08228 8.87817 8.99683L7 7.11865L5.12183 8.99683C5.03638 9.08228 4.92444 9.125 4.8125 9.125C4.70056 9.125 4.58862 9.08228 4.50317 8.99683C4.33228 8.82593 4.33228 8.54907 4.50317 8.37817L6.38135 6.5L4.50317 4.62183C4.33228 4.45093 4.33228 4.17407 4.50317 4.00317C4.67407 3.83228 4.95093 3.83228 5.12183 4.00317L7 5.88135L8.87817 4.00317C9.04907 3.83228 9.32593 3.83228 9.49683 4.00317C9.66772 4.17407 9.66772 4.45093 9.49683 4.62183L7.61865 6.5L9.49683 8.37817Z"
                    fill="#2D82D0"
                  />
                </svg>
              </button>
            </div>
          </template>
          <div
            v-if="selectedItems.length - 2 > 0"
            class="tw-font-semibold tw-leading-none tw-text-an-flat-dark-blue-link tw-bg-an-flat-dark-blue-link tw-bg-opacity-10 tw-rounded-full tw-px-[7px] tw-py-[5px]"
          >
            +{{ selectedItems.length - 2 }}
          </div>
        </div>
        <div
          v-else
          class="tw-text-an-flat-disabled-text"
        >
          {{ placeholder }}
        </div>
      </div>
      <div>
        <svg
          :class="visibleList ? '':'tw-rotate-180'"
          class="tw-transition-all tw-duration-200"
          width="9"
          height="5"
          viewBox="0 0 9 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :class="visibleList ? 'tw-stroke-an-flat-red-main': disabled ? 'tw-stroke-an-flat-disabled-input-text' : 'tw-stroke-an-flat-black-main'"
            d="M7.25 4L4.25 1.5L1.25 4"
            stroke="#1B1918"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
    <div
      v-show="visibleList"
      ref="tooltip"
      class="tw-absolute tw-z-[1000] tw-w-full tw-bg-white tw-border tw-border-solid tw-rounded-sm tw-drop-shadow-input"
    >
      <div role="listbox">
        <TwSelectRelationSearchList
          ref="list"
          :value="value"
          :check-disabled="checkDisabled"
          :prefetch-request="prefetchRequest"
          :selected-items="selectedItems"
          :multi="multi"
          :allow-create="allowCreate"
          :default-filters="defaultFiltersFromRelationType"
          :label="listLabel"
          :relation-name="relationName"
          :visible="visibleList"
          @select="onSelectItem"
          @set-selected="setSelectedItem"
        />
        <div
          v-if="showSucessButton"
          class="tw-text-left tw-px-[14px] tw-pb-[14px]"
        >
          <button
            class="tw-mt-[12px] tw-px-[7px] tw-py-[4px] tw-font-medium tw-text-main-sm2 tw-rounded-sm tw-bg-an-flat-green-btn-bg tw-text-white"
            @click.stop="hide"
          >
            Готово
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import TwSelectRelationSearchList
  from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelationSearchList.vue'
export default {
  name: 'TwSelectRelations',
  components: { TwSelectRelationSearchList },
  props: {
    value: {
      type: [Array, String, Number],
      default: null
    },
    relationName: {
      type: String,
      required: true
    },
    persistentLabel: {
      type: Boolean,
      default: false
    },
    // сжатые поля
    dense: {
      type: Boolean,
      default: false
    },
    // разрешено ли создавать новые элементы
    allowCreate: {
      type: Boolean,
      default: false
    },
    // Пред запрос
    prefetchRequest: {
      type: Boolean,
      default: false
    },
    multi: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    listLabel: {
      type: String,
      default: ''
    },
    selectPlaceMaxHeight: {
      type: String,
      default: '200px'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableErrorBorder: {
      type: Boolean,
      default: false
    },
    // Возаращать объекты значений
    returnObject: {
      type: Boolean,
      default: false
    },
    // не совсем текст - раширено - добавлена возможность использовать массив ошибок
    errorText: {
      type: [String, Array, Boolean],
      default: null
    },
    searchPlaceholder: {
      type: String,
      default: 'Поиск...'
    },
    // TODO Из-за watch этого свойства может вызытаться бесконечно - Нужно передавать объект из data а не писать его в template
    defaultFilters: {
      type: Object,
      default: null
    },
    // при пред-установке дефолтных фильтров искать только активные
    filteredOnlyActive: {
      type: Boolean,
      default: true
    },
    /*
    * Метод который будет проверять нужно ли установить DISABLE на элемент + Выводить уведомление если нужно
    * function checkDisabled (item, isAlert = false) {
    *  if (item.value === '1') {
    *   if (isAlert) {
    *     alert('Данный элемент нельзя выбрать Потому что .....')
    *   }
    *   return true
    *  }
    * }
    * */
    checkDisabled: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      popperInstance: null,
      visibleList: false,
      selectedItems: []
    }
  },
  methods: {
    show () {
      this.visibleList = true
      this.popperInstance.update()
    },
    hide () {
      this.visibleList = false
    },
    clickOutSide () {
      if (this.visibleList) {
        this.hide()
      }
    },
    setSelectedItem (item) {
      // console.warn('setSelectedItem', item)
      this.onSelectItem(item)
    },
    removeAllItems () {
      this.selectedItems = []
      if (this.multi) {
        this.$emit('input', [])
        if (this.returnObject) {
          this.$emit('input-object', [])
        }
      } else {
        this.$emit('input', null)
        this.$emit('input-object', null)
      }
    },
    removeItem (item) {
      this.onSelectItem(item)
    },
    onSelectItem (item) {
      // console.warn('onSelectItem', this.multi ? 'MULTI' : 'SIMPLE', item)
      const itemIndex = this.selectedItems.findIndex(i => i.value === item.value)
      if (this.multi) {
        if (itemIndex === -1) {
          this.selectedItems.push(item)
        } else {
          this.selectedItems.splice(itemIndex, 1)
        }
        this.$emit('input', this.selectedItems.map(i => i.value))
        if (this.returnObject) {
          this.$emit('input-object', this.selectedItems)
        }
      } else {
        if (itemIndex === -1) {
          this.selectedItems = [item]
        } else {
          this.selectedItems = []
        }
        this.$emit('input', this.selectedItems.length ? this.selectedItems[0].value : null)
        if (this.returnObject) {
          this.$emit('input-object', this.selectedItems.length ? this.selectedItems[0] : null)
        }

        if (this.selectedItems.length) {
          this.hide()
        }
      }
    },
    keypressHandler (e) {
      console.warn('e', e)
      if (e.key === 'Enter') {
        this.hide()
        e.preventDefault()
      }
    }
  },
  computed: {
    showSucessButton () {
      return !!this.multi
    },
    classesError () {
      if (this.disableErrorBorder) {
        return ''
      }
      return 'tw-text-an-flat-red-main tw-border-an-flat-red-main'
    },
    isRequired () {
      // определение обязательности поля
      // - если тестовая ошибка имеет дефолтное значение
      // и если текст лейбла показан над полем ввода
      return this.errorText !== null && this.errorText !== '' && (this.persistentLabel || this.isValue)
    },
    errorValues () {
      if (Array.isArray(this.errorText)) {
        return this.errorText
      } else if (this.errorText) {
        return [this.errorText]
      }
      return []
    },
    sizeClasses () {
      if (this.dense) {
        return 'tw-min-h-[40px] tw-text-main-xs tw-min-w-[200px]'
      } else {
        return 'tw-min-h-[52px] tw-text-main-sm2 tw-min-w-[200px]'
      }
    },
    isValue () {
      if (this.multi) {
        return Array.isArray(this.value) && this.value.length
      }
      return this.value !== null
    },
    defaultFiltersFromRelationType () {
      if (this.defaultFilters) {
        return this.defaultFilters
      }
      if (this.relationName === 'user') {
        return {
          active: this.filteredOnlyActive
        }
      }
      if (this.relationName === 'developer') {
        return {
          type: 'developer',
          active: this.filteredOnlyActive
        }
      }
      if (this.relationName === 'bank') {
        return {
          type: 'bank',
          active: this.filteredOnlyActive
        }
      }
      if (this.relationName === 'insurance') {
        return {
          type: 'insurance',
          active: this.filteredOnlyActive
        }
      }
      return null
    }
  },
  watch: {
    defaultFiltersFromRelationType: {
      handler () {
        // console.warn(this.$options.name, 'WATCH: defaultFilters ', newValue)
        this.removeAllItems()
      },
      immediate: false
    },
    visibleList (newValue) {
      if (newValue) {
        this.$el.addEventListener('keypress', this.keypressHandler)
      } else {
        this.$el.removeEventListener('keypress', this.keypressHandler)
      }
    }
  },
  created () {
    if (this.multi && !Array.isArray(this.value)) {
      this.$emit('input', [])
    } else if (!this.multi && Array.isArray(this.value)) {
      this.$emit('input', null)
    }
  },
  mounted () {
    this.popperInstance = createPopper(this.$refs.trigger, this.$refs.tooltip, {
      // strategy: 'fixed',
      placement: 'bottom',
      rootBoundary: 'document',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 5]
          }
        }
      ]
    })
  }
}
</script>
