<template>
  <v-sheet
    tabindex="0"
    :color="fileInfo ? 'grey darken-3' : 'red'"
    :a-qcolor="disabled || processUpload ? 'grey lighten-5':'indigo lighten-5'"
    :width="width"
    :height="height"
    style="position: relative"
    class="pa-1 ma-1 d-flex flex-column"
  >
    <v-sheet
      :title="title"
      height="100%"
      @dragenter="dragHandler"
      @dragleave="dragHandler"
      @dragover="dragHandler"
      @drop="dragHandler"
    >
      <input
        ref="upload"
        type="file"
        :value="null"
        :accept="accept"
        :disabled="disabled || processUpload"
        :multiple="multiple"
        style="display:none"
        @change="inputChange"
      >
      <div
        style="height: 100%;"
        class="ma-1 d-flex align-center justify-center flex-column"
      >
        <template v-if="!processUpload">
          <template v-if="fileInfo && fileInfo.mime && fileInfo.mime.indexOf('image') !== -1">
            <img
              :alt="fileInfo.name"
              :src="getFileResizeByKey(fileInfo, 'default')"
              style="width: 100%;max-height: 100%; margin-bottom: auto"
              class="cursor-pointer"
              @click="viewImage"
            >
          </template>
          <template v-else-if="fileInfo && fileInfo.mime && fileInfo.mime.indexOf('image') === -1">
            <v-icon
              color="success"
              size="40"
            >
              fa-file-text
            </v-icon>
            <div
              class="text-center"
              style="font-size: small"
            >
              {{ fileInfo.name }}<br>
              <small
                v-if="fileInfo.size"
                class="ml-2 text-muted"
              >
                {{ $Global.formatBytes(fileInfo.size) }}
              </small>
            </div>
          </template>
          <div
            v-else
            class="text-center cursor-pointer"
            style="align-self: center; justify-self: flex-start"
            @click="dragZoneClick"
          >
            <v-icon
              v-if="!dragover"
              :color="disabled || processUpload ? 'grey darken-2':'indigo darken-2'"
              size="40"
            >
              fa-cloud-upload
            </v-icon>
            <v-icon
              v-if="dragover"
              :color="disabled || processUpload ? 'grey darken-2':'indigo darken-2'"
              size="40"
            >
              fa-plus
            </v-icon>
          </div>
        </template>
        <template v-else>
          <v-progress-circular
            :size="40"
            :width="3"
            color="indigo"
            indeterminate
          />
        </template>
      </div>
    </v-sheet>
    <div
      class="text-left grey darken-3"
      style="width: 100%;position: absolute; bottom: 0; left: 0"
    >
      <div
        class="mx-1 text-center"
        style="font-size: medium"
        :class="disabled || processUpload ? 'grey--text lighten-1':'white--text lighten-1'"
      >
        <small>
          {{ label }}
        </small>
      </div>
    </div>
    <div
      class="d-flex justify-space-between"
      style="width: 100%;position: absolute; top: 0; right: 0"
    >
      <v-btn
        v-if="fileInfo"
        fab
        x-small
        color="grey"
        class="ma-2"
        @click="download"
      >
        <v-icon
          small
          color="white"
        >
          fa-download
        </v-icon>
      </v-btn>
      <v-btn
        v-if="fileInfo"
        fab
        x-small
        color="error"
        class="ma-2"
        @click="clear"
      >
        <v-icon
          small
          color="white"
        >
          fa-trash
        </v-icon>
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import fileAPI from '@/Api/Crm/File'
import docAPI from '@/Api/Crm/Dictionary/General/Document'

export default {
  name: 'SimpleDocumentUploadAndView',
  props: {
    value: {
      default: null
    },
    title: {
      type: String,
      default: 'Загрузка файла'
    },
    label: {
      type: String,
      default: 'Название документа'
    },
    width: {
      type: String,
      default: '100px'
    },
    height: {
      type: String,
      default: '130px'
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/bmp'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileInfo: null,
      dragover: false,
      processUpload: false
    }
  },
  watch: {
    value: {
      handler (newValue, OldValue) {
        if (!OldValue && newValue) {
          console.warn('Value INIT change:  ', newValue, OldValue)
          this.fetchValueInfo(newValue)
        }
      },
      immediate: true
    }
  },
  methods: {
    getFileResizeByKey: getFileResizeByKey,
    download () {
      docAPI.download(this.fileInfo.url, this.fileInfo.name)
    },
    clear () {
      this.fileInfo = null
      this.$emit('input', 0)
    },
    dragZoneClick () {
      // console.warn('dragZoneClick', e)
      this.$refs.upload.click()
    },
    inputChange (e) {
      // console.warn('++', this.inputSelected, e)
      this.filesSelected(e.target.files)
    },
    dragHandler (e) {
      // console.warn('dragHandler', e)
      e.preventDefault()

      if (this.disabled) return

      if (e && e.type) {
        switch (e.type) {
          case 'dragenter':
            this.dragover = true
            break
          case 'dragleave':
            this.dragover = false
            break
          case 'dragover':
            this.dragover = true
            break
          case 'drop':
            if (e.dataTransfer) {
              this.filesSelected(e.dataTransfer.files)
            }
            break
        }
      }
    },
    filesSelected (files) {
      console.log('filesSelected ', files, files.length)
      this.dragover = false
      if (files && files.length) {
        this.upload(files[0])
      }
    },
    upload (file) {
      this.processUpload = true
      console.log(this.$options.name, 'upload', file)
      const formData = new FormData()
      formData.append('file', file)
      return fileAPI.upload(formData)
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.fileInfo = data.data
          console.log('link: ', data.data)
          this.$emit('input', data.data.id)
        })
        .catch((error) => {
          console.warn(this.$options.name, error)
        })
        .finally(() => {
          this.processUpload = false
        })
    },
    viewImage () {
      this.$store.dispatch('imageViewer/showImages', {
        files: [
          {
            path: this.fileInfo.url
          }
        ],
        startIndex: 0
      })
    },
    fetchValueInfo (id) {
      this.processUpload = true
      fileAPI.show(id)
        .then(({ data: { data } }) => {
          // console.warn(data)
          this.fileInfo = data
        })
        .catch((error) => {
          console.error({ error })
        })
        .finally(() => {
          this.processUpload = false
        })
    }
  }
}
</script>
