<template>
  <div class="mortgage-approve-list">
    <v-data-table
      :headers="columns"
      :items="data"
      :loading="loading"
      item-key="id"
      hide-default-footer
      :disable-sort="true"
    >
      <template #item.actions="{ item }">
        <div>
          <v-btn
            title="Изменить"
            icon
            @click.stop="onEdit(item)"
          >
            <v-icon small>
              fa-cog
            </v-icon>
          </v-btn>
        </div>
      </template>
      <template #item.dates="{ item }">
        {{ $Global.DateFormat.DateTimeShort(item.updatedAt) || $Global.DateFormat.DateTimeShort(item.createdAt) }}
      </template>
      <template #item.suspensionComment="{ item }">
        <ReadMoreExpander
          v-if="item.suspensionComment"
          :text="item.suspensionComment"
          :less-length="50"
        />
      </template>
      <template #item.marriageContract="{ item }">
        {{ item.marriageContract ? 'Есть':'Нет' }}
      </template>
      <template #item.consentSpouse="{ item }">
        {{ item.consentSpouse ? 'Есть':'Нет' }}
      </template>
      <template #item.documents="{ item }">
        {{ item.documents && item.documents.label }}
      </template>
      <template #item.matCapitalUse="{ item }">
        {{ item.matCapitalUse && item.matCapitalUse.label }}
      </template>
      <template #item.realtyType="{ item }">
        {{ item.realtyType && item.realtyType.label }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DataListMixin from '@/mixins/DataListMixin'
import API from '@/Api/Crm/Deal/Mortgage/ApprovalInfo/index'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander'

export default {
  name: 'MortgageApproveList',
  components: { ReadMoreExpander },
  mixins: [DataListMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dealId: {
      type: [String, Number],
      required: true
    },
    dealType: {
      type: [String, Object],
      required: true
    }
  },
  data () {
    return {
      API,
      defaultFilters: {
        'DealApprovalInfo.deal': this.dealId
      },
      columns: [
        { text: '', value: 'actions', cellClass: '', class: 'grey darken-2' },
        { text: 'Вид программы', value: 'realtyType', cellClass: 'text-no-wrap caption', class: 'grey darken-2 white--text' },
        { text: 'Отлагательные условия', value: 'suspensionComment', cellClass: 'caption', class: 'grey darken-2 white--text' },
        { text: 'Наличие материнского капитала', value: 'matCapitalUse', cellClass: 'caption', class: 'grey darken-2 white--text' },
        { text: 'Комплект документов', value: 'documents', cellClass: 'caption', class: 'grey darken-2 white--text' },
        { text: 'Брачный договор', value: 'marriageContract', cellClass: 'caption', class: 'grey darken-2 white--text' },
        { text: 'Согласие от супруга(и)', value: 'consentSpouse', cellClass: 'caption', class: 'grey darken-2 white--text' },
        { text: 'Дата изменения', value: 'dates', cellClass: 'text-no-wrap caption', class: 'grey darken-2 white--text' }
      ]
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    onEdit (item) {
      this.$emit('on-edit-item', item.id)
    }
  }
}
</script>
