<template>
  <details
    class="tw-select-none tw-font-manrope tw-text-main-sm"
    :class="calcClasses.details"
    :open="open"
    @toggle="onToggle"
  >
    <summary :class="[titleClasses, calcClasses.summary]">
      <slot
        name="title"
        v-bind="{ open }"
      >
        Title Slot Empty
      </slot>
    </summary>
    <div
      class="tw-text-left"
      :class="contentClasses"
    >
      <slot v-bind="{ open }" />
    </div>
  </details>
</template>

<script>
export default {
  name: 'TwSpoiler',
  props: {
    titleVariant: {
      type: String,
      default: 'link' // link || block
    },
    contentClasses: {
      type: String,
      default: ''
    },
    titleClasses: {
      type: String,
      default: ''
    },
    arrowToRight: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    calcClasses () {
      const result = {
        summary: [],
        details: []
      }
      if (this.titleVariant === 'block') {
        // todo детально описан вариант ссылки - блок нужно стилизовать
      } else if (this.titleVariant === 'link') {
        result.details.push('tw-text-right') // ссылка вправо
        result.summary.push('before:tw-hidden') // скрыть стрелочку
        result.summary.push('hover:tw-text-an-flat-dark-blue-link-active hover:tw-underline') // стилизовать под ссылку
        if (this.open) {
          result.summary.push('tw-text-an-flat-dark-blue-link tw-underline')
        } else {
          result.summary.push('tw-text-an-flat-gray-4')
        }
      }
      if (this.arrowToRight) {
        result.summary.push('before:tw-right-[0.3em]') // стрелка справа
      } else {
        result.summary.push('before:-tw-left-[0.3em]') // стрелка слева
      }
      return {
        summary: result.summary.join(' '),
        details: result.details.join(' ')
      }
    }
  },
  methods: {
    onToggle (event) {
      this.open = !!event?.target?.open
    }
  }
}
</script>

<style scoped>
  details{
    display:block;
    background: #fff;
    width: 100%;
    overflow:initial;
    margin-bottom: 1.5rem;
  }
  summary::-webkit-details-marker{display:none;}
  summary::-moz-list-bullet{list-style-type:none;}
  summary::marker{display:none;}
  summary {
    display:inline;
    //padding: .3em 1em .3em .9em;
    //border-bottom: 1px solid #e2e8f0;
    //font-size:1.4em;
    cursor: pointer;
    position: relative;
  }
  summary:before {
    top: .4em;
    color: transparent;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTYuNTkgOC41OUwxMiAxMy4xNyA3LjQxIDguNTkgNiAxMGw2IDYgNi02eiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=") no-repeat 50% 50% / 1em 1em;
    width: 1em;
    height: 1em;
    content: "";
    position: absolute;
    transition: transform .5s;
  }
  details[open] > summary:before {
    transform: scale(1,-1);
  }
  details[open] summary ~ *{
    animation: sweep .5s ease-in-out;
  }
  @keyframes sweep {
    0%    {opacity: 0;}
    100%  {opacity: 1;}
  }
  summary:focus {
    outline:0;
  }
</style>
