import ListMenu from '@/components/popup/menu/ListMenu'
import tableOptions from '../views/Company/Office/tableOptions'
import { mapActions, mapGetters } from 'vuex'
import lodashDebounce from 'lodash/debounce'

export default {
  props: {
    fromPanel: {
      type: Boolean,
      default: false
    }
  },
  components: { ListMenu },
  data () {
    return {
      listFetchController: null,
      isUseShowMore: false,
      isScrollToTopEnable: true, // включение скролла вверж при запросах
      permissionDenied: false,
      filterSchemaKey: null, // Ключ подключенной схемы фильтров
      data: [],
      pagination: {},
      defaultFilters: {},
      loading: false,
      columns: tableOptions,
      API: null,
      loadingText: 'Загрузка... Пожалуйста подождите',
      noDataText: 'Нет данных',
      noResultsText: 'Не найдено',
      loadingErrorText: null,
      itemsPerPage: 25,
      menuList: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' }
      ]
    }
  },
  methods: {
    ...mapActions({
      readTableConfig: 'tableConfig/readTableConfig'
    }),
    changeSort: lodashDebounce(function (value) {
      console.log('changeSort', value)
      this.fetch()
    }, 300),
    onSortUpdate (eventData = {}) {
      console.log('onSortUpdate', this.defaultFilters, eventData)
      this.defaultFilters.sortField = eventData?.sortField || null
      this.defaultFilters.sortDirection = eventData?.sortDirection ? eventData?.sortDirection : null
      this.changeSort()
    },
    changePagination (eventData) {
      console.log('changePagination - eventData: ', eventData)
      if (eventData !== null && typeof eventData === 'object') {
        // новый вариант принимающий сразу набор параметров целевая страница и кол-во на странице
        this.fetch({
          ...eventData
        })
      } else {
        // старая механика для которой [eventData] был номером страницы в пагинации
        this.fetch({
          page: eventData
        })
      }
    },
    // методы действий с объектами!
    // Если метод отсутствует - действия не добавляются в список
    // show: () => {},
    // edit: () => {},
    // remove: () => {},
    // TODO Old - remove ???
    doubleRowClick (event, rowInfo) {
      console.log('doubleRowClick: ', this.$options.name)
      switch (this.$route && this.$route.name) {
        case 'program-category-list':
          if (this.isAdmin) {
            this.$router.push({
              name: 'program-category-edit',
              params: { id: rowInfo.item.id }
            })
          }
          break
        case 'requirement-list':
          this.$router.push({
            name: 'requirement-show',
            params: { id: rowInfo.item.id }
          })
          break
        case 'lead-source-list':
          if (this.isAdmin) {
            this.$router.push({
              name: 'lead-source-edit',
              params: { id: rowInfo.item.id }
            })
          }
          break
        case 'lead-status-list':
          if (this.isAdmin) {
            this.$router.push({
              name: 'lead-status-edit',
              params: { id: rowInfo.item.id }
            })
          }
          break
        case 'deal-status-list':
          if (this.isAdmin) {
            this.$router.push({
              name: 'deal-status-edit',
              params: { id: rowInfo.item.id }
            })
          }
          break
        case 'program-list':
          this.$router.push({
            name: 'program-show',
            params: { id: rowInfo.item.id }
          })
          break
        case 'company-list':
          if (this.isAdmin) {
            this.$router.push({
              name: 'company-edit',
              params: { id: rowInfo.item.id }
            })
          }
          break
        default:
          console.warn('doubleRowClick - Действия не заданы.')
      }
    },
    // обновление списка объектов из локальных параметров компонента, с сохранением пагинации
    refresh () {
      this.fetch({
        page: this.isUseShowMore ? 1 : (this.pagination?.current_page || 1)
      })
    },
    async fetch (params = {}) {
      this.permissionDenied = false
      // При активном запросе нужно оборвать старое соединение
      if (this.loading) {
        this.listFetchController?.abort()
      }
      this.listFetchController = new AbortController()

      if (!this.API) {
        throw Error('not set API')
      }

      this.loading = true

      if (this.isScrollToTopEnable) {
        this.scrollToTop()
      }

      // TODO OLD ??? если компонент открыт из панели то не учитываю URL параметры
      if (!this.fromPanel) {
        const queryParams = this.$Global.RouterUrlManager.get()
        if (queryParams) {
          // Добавляю к параметрам query парамерты из url
          // eslint-disable-next-line no-param-reassign
          params = { ...queryParams, ...params }
        }
      }

      // Добавление дефолтной фильтрации
      if (this.defaultFilters && Object.keys(this.defaultFilters).length) {
        for (const fieldKey in this.defaultFilters) {
          if (this.defaultFilters[fieldKey] !== null) {
            this.$set(params, fieldKey, this.defaultFilters[fieldKey])
          }
        }
      }

      // добавление полей фильтрации из VUEX
      const VuexFilters = this.getCurrentFilterSchemaByKey(this.filterSchemaKey) || this.getTwCurrentFilterSchemaByKey(this.filterSchemaKey)
      if (this.filterSchemaKey && Array.isArray(VuexFilters)) {
        for (const field of VuexFilters) {
          if (field.inputValue !== undefined) {
            this.$set(params, field.key, field.inputValue)
          }
        }
      }

      // добавление параметров из конфига таблицы
      if (this.filterSchemaKey) {
        // замена косой линии - т.к. конфиг ключ используется в url
        const tableConfig = this.currentTableConfigBySchemaKey(this.tableConfigSchemaKey) || await this.readTableConfig(this.tableConfigSchemaKey)
        if (tableConfig?.loading) {
          // console.warn('tableConfig +++++++++++++++', tableConfig.loading, params)
          // TODO это для обходного пути в списках с фильтрами, не удалось отстледить иными средствами состояние получения списка фильтров
          // конфиг таблицы еще грузится и там мб быть сортировка и лимиты - поэтому прерываю выполнение и вызываю рекурсию
          setTimeout(() => {
            this.fetch(params)
          }, 500)
          return
        }
        if (tableConfig?.limit) {
          this.$set(params, 'limit', tableConfig.limit)
        }
        if (tableConfig?.sortDirection) {
          this.$set(params, 'sortDirection', tableConfig.sortDirection)
        }
        if (tableConfig?.sortField) {
          this.$set(params, 'sortField', tableConfig.sortField)
        }
      }

      if (this.isUseShowMore && (!params.page || params.page === 1)) {
        this.data = []
      }

      // Очистить ошибку загрузки если была в предыдущей итерации
      this.loadingErrorText = null

      params.limit = params.limit || this.itemsPerPage
      // console.error('REQ PARAMS: ', params)
      // TODO целевой апи вариативен(для совместимости апишек). Если есть getListNew то делаю запрос на него,
      //  чтобы ускорить основные списки и не сломать прочие компоненты которым нужен старый getList
      const targetApi = this.API.getListNew || this.API.getList
      targetApi(params, { signal: this.listFetchController.signal })
        .then((response) => {
          // console.warn(response.data.data)
          const { data = [] } = response.data
          if (this.isUseShowMore) {
            this.data = [...this.data, ...data]
          } else {
            this.data = response.data.data
          }
          if (response.data.meta) {
            this.pagination = response.data.meta
            this.itemsPerPage = response.data.meta.per_page || 25
          }
          this.loading = false
        })
        .catch((error) => {
          if (error === 'ECONNABORTED') {
            this.loadingErrorText = 'Запрос отменён! Превышено время ожидания.'
            this.loading = false
          } else if (error !== 'ERR_CANCELED') {
            this.loading = false
            this.loadingErrorText = error.message || error || 'Произошла ошибка!'
          }

          const { status = null, data = null } = error?.response || {}
          // обработка отсутствия прав на просмотр
          if (status === 403) {
            this.permissionDenied = true
            this.loadingErrorText = 'Недостаточно прав доступа к списку'
          } else if (status && data && status !== 500) {
            if (Object.keys(data) && Object.keys(data).length) {
              this.loadingErrorText = ''
              for (const field of Object.keys(data)) {
                if (Array.isArray(data[field]) && data[field].length) {
                  this.loadingErrorText += `${data[field].join('; ')}<br/><br/>`
                } else {
                  this.loadingErrorText += `${field} - Ошибка`
                }
              }
            }
          }

          // очистка данных
          this.data = []
          this.pagination = {}
        })
    },
    scrollToTop () {
      const block = document.getElementById('scrolling-content') // целевой блок
      if (!block) return
      const isVerticalScroll = block.scrollHeight > block.clientHeight // вертикальный скролл
      const isHorizontalScroll = block.scrollWidth > block.clientWidth // горизонтальный скролл
      console.log({ isVerticalScroll, isHorizontalScroll })
      if (isVerticalScroll || isHorizontalScroll) {
        block.scrollTo({
          top: 0,
          left: 0
          // behavior: 'smooth' // плавная прокрутка дергается - отключил
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      getCurrentFilterSchemaByKey: 'aFilter/getCurrentFilterSchemaByKey',
      getTwCurrentFilterSchemaByKey: 'twFilter/getCurrentFilterSchemaByKey',
      currentTableConfigBySchemaKey: 'tableConfig/currentTableConfigBySchemaKey',
      isAdmin: 'profile/isAdmin'
    }),
    // Формирую список действий для элементов списка
    listItemActions () {
      const actions = []
      if (this.show) {
        actions.push({
          code: 'show',
          label: 'Просмотреть',
          color: 'primary',
          disabled: false,
          method: this.show
        })
      }
      if (this.edit) {
        actions.push({
          code: 'edit',
          label: 'Изменить',
          color: 'primary',
          disabled: false,
          method: this.edit
        })
      }
      if (this.remove) {
        actions.push({ divider: true, code: 'remove' })
        actions.push({
          code: 'remove',
          isConfirmation: true,
          label: 'Удалить',
          color: 'red',
          disabled: false,
          method: this.remove
        })
      }
      return actions
    },
    tableConfigSchemaKey () {
      return this.filterSchemaKey?.replaceAll('/', '__')
    },
    pageCount () {
      return Math.ceil(this.pagination.total / this.itemsPerPage)
    }
  },
  updated () {
    // проверяю наличие поключенного VuetifyDataTableScroller и проверяю наличие скролла
    if (this.$refs.scroller) {
      // console.error('updated scroller')
      this.$refs.scroller.checkTableScroll()
    }
  },
  beforeDestroy () {
    this.listFetchController?.abort()
  }
}
